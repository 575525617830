import React from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
import './Login.scss';

const login = async (instance) => {
  try {
    await instance.loginRedirect(loginRequest);
  } catch (error) {
    console.error('error occured:', error);
  }
};

const Login = () => {
  const { instance } = useMsal();

  return (
    <div className="wrapper">
      <div className="login">
        <img
          // eslint-disable-next-line
          src={require(`../../assets/mpya-digital-logo.svg`)?.default}
          alt="book icon"
        />
        <button type="button" className="login-btn" onClick={() => login(instance)}>
          <span>Login</span>
        </button>
      </div>
    </div>
  );
};

export default Login;
