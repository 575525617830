import React from 'react';
import { useHistory } from 'react-router-dom';
import './Back.scss';

const Back = () => {
  const history = useHistory();
  return (
    <button type="button" className="back" onClick={history.goBack}>
      {'< Back'}
    </button>
  );
};

export default Back;
