import React from 'react';
// import AzureAD from 'react-aad-msal';
import ReactDOM from 'react-dom';
// import { HashRouter } from 'react-router-dom';
import App from './App';
import './index.scss';
// import { authProvider } from './providers/authProvider';
import * as serviceWorker from './serviceWorker';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';

export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

ReactDOM.render(
  // <HashRouter>
  // {/* <AzureAD provider={authProvider} forceLogin> */}
  <MsalProvider instance={msalInstance}>
    <App pca={msalInstance} />
  </MsalProvider>,
  // {/* </AzureAD> */}
  // </HashRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
