import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import s from './Button.module.scss';

const Button = ({ children, onClick, disabled, variant = 'filled', size = 'medium' }) => (
  <button
    type="button"
    onClick={onClick}
    disabled={disabled}
    className={classNames({
      [s.button]: true,
      [s[`${variant}Button`]]: true,
      [s[`${size}`]]: true,
      [s.spinner]: disabled,
      [s.disabled]: disabled,
    })}
  >
    {disabled ? 'Loading' : children.toUpperCase()}
  </button>
);

export default Button;

Button.propTypes = {
  variant: PropTypes.oneOf(['outlined', 'filled', 'delete']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};
