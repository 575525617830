import React, { useContext, useEffect, useState } from 'react';
import { FormContext } from '../../providers/formProvider';

const From = ({ schedual }) => {
  const { formName, bookFrom, setBookFrom } = useContext(FormContext);
  const [timeSlots, setTimeSlots] = useState([]);
  const [nowIsAvailable, setNowIsAvailable] = useState(false);

  const getTimeIndex = (date) => Math.floor((date - new Date().setHours(0, 0)) / 1000 / 60 / 15);

  useEffect(() => {
    const slots = [...schedual.availabilityView.slice(getTimeIndex(Date.now()))]
      .map((digit, i, arr) => {
        const slot = new Date();

        if (i === 0) {
          if (digit === '0' && arr[i + 1] === '0') {
            setNowIsAvailable(true);
          } else {
            setNowIsAvailable(false);
          }
        }
        if (digit === '0' && i !== 0) {
          slot.setMinutes(slot.getMinutes() + (15 * i - (slot.getMinutes() % 15)), 0);
          return slot;
        }
        return null;
      })
      .filter((t) => t);
    setTimeSlots(slots);
    if (nowIsAvailable) {
      setBookFrom(new Date());
    } else {
      setBookFrom(slots[0]);
    }
  }, [setTimeSlots, schedual, setBookFrom, nowIsAvailable]);

  return (
    <>
      <select
        form={formName}
        value={bookFrom}
        onChange={(e) => {
          setBookFrom(new Date(e.target.value));
        }}
      >
        {nowIsAvailable && <option value={new Date()}>Now</option>}

        {timeSlots.map((date) => (
          <option key={date.getTime()} value={date}>
            {`${date.getHours()}:${date.getMinutes() === 0 ? '00' : date.getMinutes()}`}
          </option>
        ))}
      </select>
    </>
  );
};

export default From;
