export const msalConfig = {
  auth: {
    clientId: '4ece4ec6-fc38-4a7b-a001-5cd186fa948f',
    authority: 'https://login.microsoftonline.com/aea5ed0a-d315-4fb1-b4f3-9137000c093b',
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [
    'Calendars.ReadWrite',
    'Calendars.ReadWrite.Shared',
    'User.ReadWrite',
    'User.ReadBasic.All',
    'User.Read.All',
    'People.Read',
  ],
  //loginHint: 'preferred_username',
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
