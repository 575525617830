import React from 'react';
import './HandbookCard.scss';

const HandbookCard = () => {
  return (
    <a href="https://handbook.mpya.digital/" target="_blank" rel="noopener noreferrer">
      <div className="handbook-card">
        <img
          // eslint-disable-next-line
          src={require(`../../assets/mployee-handbook.svg`)?.default}
          alt="book icon"
        />
        <h2>Handbook</h2>
        <p className="description">The mployee handbook for all MPYAns.</p>
      </div>
    </a>
  );
};

export default HandbookCard;
