import React from 'react';
import PropTypes from 'prop-types';
import s from './Space.module.scss';

const Space = ({ size = 'medium' }) => <div className={s[`${size}`]}></div>;

export default Space;

Space.propTypes = {
  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'large', 'xlarge']),
};
