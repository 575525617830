import createIsoDate from '../utils/createIsoDate';

const getCalendarsEventsToday = (apiGet) => (roomId) =>
  apiGet(
    `/users/${roomId}/events?$filter=start/dateTime gt '${createIsoDate(
      new Date().setHours(0, 0, 0, 0)
    )}' and end/dateTime lt '${createIsoDate(new Date().setHours(23, 59, 59, 999))}'`
  );

export default getCalendarsEventsToday;
