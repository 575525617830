import createIsoDate from '../utils/createIsoDate';
// Should maybe use /events instead of calendarView?
const getMyEventsToday = (apiGet) => () =>
  apiGet(
    `/me/events?$filter=start/dateTime gt '${createIsoDate(
      new Date().setHours(0, 0, 0, 0)
    )}' and end/dateTime lt '${createIsoDate(
      new Date().setHours(23, 59, 59, 999)
    )}' and isOrganizer eq true`
  );

export default getMyEventsToday;
