import React from 'react';

// export const initialState = sessionStorage.getItem('state')
//   ? JSON.parse(sessionStorage.getItem('state'))
//   :
export const initialState = {
  loggedIn: false,
  rooms: [
    {
      id: '8a9949bd-c8f2-4bde-9a9e-94e824a2e147',
      size: 8,
      homeAccountIdentifier:
        'OGE5OTQ5YmQtYzhmMi00YmRlLTlhOWUtOTRlODI0YTJlMTQ3.YWVhNWVkMGEtZDMxNS00ZmIxLWI0ZjMtOTEzNzAwMGMwOTNi',
      email: 'joysrus@mpya.se',
      name: 'Joys ”R” Us',
      clockImg: 'joysrus.svg',
      img: 'joysrus.jpg',
      accountState: 'c271da44-4eef-44b6-a757-c6c757d98693',
      info: "Joy's 'R' Us is our largest conference room that fits around 8-10 people."
    },
    {
      id: 'a6bf7e72-3f3e-46bb-bca4-3d5bb59f65ac',
      size: 4,
      homeAccountIdentifier:
        'YTZiZjdlNzItM2YzZS00NmJiLWJjYTQtM2Q1YmI1OWY2NWFj.YWVhNWVkMGEtZDMxNS00ZmIxLWI0ZjMtOTEzNzAwMGMwOTNi',
      email: 'tomcrews@mpya.se',
      name: 'Tom Crews',
      clockImg: 'tomcrews.svg',
      img: 'tomcrews.jpg',
      accountState: '2aed3c4e-297f-4448-94b9-2e8534c61ec1',
      info: 'Tom Crews is a cozy room that fits around 2 people. With some love, you can fit 4.'
    },
    {
      id: '5ab35a13-f298-46d7-b2da-9772c54193e3',
      size: 10,
      homeAccountIdentifier:
        'YTZiZjdlNzItM2YzZS00NmJiLWJjYTQtM2Q1YmI1OWY2NWFj.YWVhNWVkMGEtZDMxNS00ZmIxLWI0ZjMtOTEzNzAwMGMwOTNi',
      email: 'Badcrewkor@mpya.se',
      name: 'Badcrewkor',
      clockImg: 'badcrewkor.svg',
      img: 'badcrewkor.png',
      accountState: '',
      info:
        'Badcrewkor is our largest meeting room and will fit 10-12 people. Here you can plot to take over the world just fine.'
    },
    {
      id: '8b7c02c1-01f5-4fcb-98ea-f93e28030f18',
      size: 6,
      homeAccountIdentifier:
        'YTZiZjdlNzItM2YzZS00NmJiLWJjYTQtM2Q1YmI1OWY2NWFj.YWVhNWVkMGEtZDMxNS00ZmIxLWI0ZjMtOTEzNzAwMGMwOTNi',
      email: 'Bosse@mpya.se',
      name: 'Bosse',
      clockImg: 'bosse.svg',
      img: 'bosse.png',
      accountState: '',
      info:
        'Bosse is equiped with a whiteboard and is optimal for collaborations and interviews. It fits around 6 people.'
    },
    {
      id: 'ae21a914-c12c-41cf-bbad-adf3ebfbc5a8',
      size: 2,
      homeAccountIdentifier:
        'YTZiZjdlNzItM2YzZS00NmJiLWJjYTQtM2Q1YmI1OWY2NWFj.YWVhNWVkMGEtZDMxNS00ZmIxLWI0ZjMtOTEzNzAwMGMwOTNi',
      email: 'penelopecrewsroom@mpya.se',
      name: 'Penelopecrews',
      clockImg: 'penelopecrews.svg',
      img: 'penelopecrews.png',
      accountState: '',
      info: 'Penelopecrews is a cozy little room perfect for 1on1s. It fits 2 people like a glove!'
    },
    {
      id: '09b096b3-70ab-405d-ac4a-d7b738c685db',
      size: 1,
      homeAccountIdentifier:
        'YTZiZjdlNzItM2YzZS00NmJiLWJjYTQtM2Q1YmI1OWY2NWFj.YWVhNWVkMGEtZDMxNS00ZmIxLWI0ZjMtOTEzNzAwMGMwOTNi',
      email: 'crewbb@mpya.se',
      name: 'CrewBB',
      clockImg: 'crewbb.svg',
      img: 'tomcrews.png',
      accountState: '',
      info:
        "CrewBB is the perfect room to take a call in private. It fits only 1 person but you'll fit well!"
    }
  ]
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_STATE':
      return action.payload;

    case 'SET_LOGGED_IN':
      return {
        ...state,
        loggedIn: action.payload
      };

    case 'ADD_ROOMS':
      return {
        ...state,
        rooms: action.payload
      };

    case 'UPDATE_ROOMS':
      return {
        ...state,
        rooms: action.payload
      };

    case 'ADD_SCHEDUALS':
      return {
        ...state,
        rooms: state.rooms.map(room => {
          return {
            ...room,
            schedual: action.payload.value.find(s => s.scheduleId === room.email)
          };
        })
      };

    case 'UPDATE_SCHEDUAL':
      return {
        ...state,
        rooms: state.rooms.map(room => {
          return {
            ...room,
            schedual: action.payload.scheduleId === room.id ? action.payload : room.schedual
          };
        })
      };

    case 'SET_USER':
      return {
        ...state,
        user: action.payload
      };

    default:
      return state;
  }
};

export const State = React.createContext();
