import React from 'react';
import './SignOut.scss';
// import { authProvider } from '../../../providers/authProvider';
import { useMsal } from '@azure/msal-react';

const signOut = (instance) => () => {
  sessionStorage.clear();
  instance.logoutRedirect().catch((e) => {
    console.error(e);
  });
};

const SignOut = () => {
  const { instance } = useMsal();

  return (
    <button className="sign-out" onClick={signOut(instance)} type="button">
      Sign out
    </button>
  );
};

export default SignOut;
