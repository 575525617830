import React, { useRef, useEffect, useState } from 'react';
import './Clock.scss';

const Clock = ({ eventState, img, events }) => {
  const svgRef = useRef(null);
  const timeToDegree = (time) => (time - new Date().setHours(6, 0, 0, 0)) / 1000 / 60 / 3;
  const [timeAngle, setTimeAngle] = useState(timeToDegree(new Date().getTime()));
  const [active, setActive] = useState(false);
  const [selectedEvent, setSelectedEvent] = eventState || [null, null];

  useEffect(() => {
    const interval = setInterval(() => setTimeAngle(timeToDegree(new Date().getTime())), 30000);
    return () => clearInterval(interval);
  }, []);

  const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  };

  const describeArc = (x, y, radius, startAngle, endAngle) => {
    const start = polarToCartesian(x, y, radius, endAngle - 3);
    const end = polarToCartesian(x, y, radius, startAngle + 3);
    const startOuter = polarToCartesian(x, y, radius + 8, endAngle - 3);
    const endOuter = polarToCartesian(x, y, radius + 8, startAngle + 3);
    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';
    const d = [
      'M',
      startOuter.x,
      startOuter.y,
      'A',
      radius + 8,
      radius + 8,
      0,
      largeArcFlag,
      0,
      endOuter.x,
      endOuter.y,
      'L',
      end.x,
      end.y,
      'A',
      radius,
      radius,
      0,
      largeArcFlag,
      1,
      start.x,
      start.y,
      'Z',
    ].join(' ');
    return d;
  };

  const timeHasPast = (time) => time < new Date();

  const event = (startTime, endTime, e) => {
    return (
      <path
        key={startTime}
        d={describeArc(
          150,
          150,
          78.5,
          timeToDegree(startTime.getTime()),
          timeToDegree(endTime.getTime())
        )}
        fill="none"
        stroke={
          // eslint-disable-next-line no-nested-ternary
          selectedEvent && active === startTime.getTime()
            ? '#009CDE'
            : timeHasPast(endTime)
            ? '#e5e5e5aa'
            : '#00b7acaa'
        }
        strokeWidth="8"
        strokeLinejoin="round"
        onClick={() => {
          if (eventState) {
            setSelectedEvent(e);
            setActive(startTime.getTime());
          }
        }}
      />
    );
  };

  return (
    <div className="clock">
      <img
        id="circleRing"
        className="circle"
        // eslint-disable-next-line global-require
        src={require(`../../assets/white-circle.svg`)?.default}
        alt="clock circle asset"
      />
      {img && (
        // eslint-disable-next-line
        <img src={require(`../../assets/${img}`)?.default} alt="clock graphics specific for room" />
      )}
      {/* eslint-disable-next-line global-require */}
      <img src={require(`../../assets/clock-ticks-time.svg`)?.default} alt="clock ticks asset" />

      <svg ref={svgRef} viewBox="20 20 260 260" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <circle
          cx={polarToCartesian(150, 150, 91, timeAngle).x}
          cy={polarToCartesian(150, 150, 91, timeAngle).y}
          r="2"
          fill="#dd2424"
        />
        {events &&
          events.map((e) => {
            return event(new Date(`${e.start.dateTime}Z`), new Date(`${e.end.dateTime}Z`), e);
          })}
      </svg>
    </div>
  );
};

export default Clock;
