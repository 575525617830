import createEvent from '../queries/createEvent';
import deleteEvent from '../queries/deleteEvent';
import getCalendarEventById from '../queries/geCalendarEventById';
import getCalendarsEventsToday from '../queries/getCalendarsEventsToday';
import getMe from '../queries/getMe';
import getMyEventById from '../queries/getMyEventById';
import getMyEventsToday from '../queries/getMyEventsToday';
import getMySchedual from '../queries/getMySchedual';
import getSchedual from '../queries/getSchedual';
import useMsGraphCall from './useMsGraphCall';

const useMsGraph = () => {
  const { apiGet, apiPost, apiDelete } = useMsGraphCall();

  return {
    getMe: getMe(apiGet),
    getMyEventById: getMyEventById(apiGet),
    getCalendarEventById: getCalendarEventById(apiGet),
    getCalendarsEventsToday: getCalendarsEventsToday(apiGet),
    getMyEventsToday: getMyEventsToday(apiGet),
    getSchedual: getSchedual(apiPost),
    getMySchedual: getMySchedual(apiPost),
    createEvent: createEvent(apiPost),
    deleteEvent: deleteEvent(apiDelete),
  };
};

export default useMsGraph;
