import React, { useEffect, useState } from 'react';
import s from './EventInfo.module.scss';
import useMsGraph from '../../hooks/useMsGraph';
import { useMutation } from 'react-query';
import Button from '../Button/Button';
import usePollForDeletion from '../../hooks/usePollForDeletion';

const EventInfo = ({ event, setEvent, calendarId }) => {
  const duration = (new Date(event.end.dateTime) - new Date(event.start.dateTime)) / 1000 / 60;
  const { deleteEvent } = useMsGraph();
  const [isLoading, setIsLoading] = useState(false);

  const { deleted, startPolling, stopPolling } = usePollForDeletion({
    roomId: calendarId,
    iCalUId: event.iCalUId,
  });
  const { mutate: deleteEventMutation } = useMutation(deleteEvent(event.id), {
    onSuccess: () => {
      startPolling(event.id);
    },
    onError: (error) => {
      stopPolling();
      alert(error);
    },
  });

  useEffect(() => {
    if (deleted) {
      setIsLoading(false);
      setEvent(null);
    }
  }, [deleted, setEvent]);

  const getLocalTime = (date) => {
    const timeZoneOffset = date.getTimezoneOffset() * 60000;
    const localTime = new Date(date - timeZoneOffset);
    return `${localTime.getHours().toString().length === 1 ? 0 : ''}${localTime.getHours()}:${
      localTime.getMinutes().toString().length === 1 ? 0 : ''
    }${localTime.getMinutes()}`;
  };

  const handleDeleteEvent = () => {
    setIsLoading(true);
    deleteEventMutation();
  };

  return (
    <div className={s.eventInfo}>
      <span className={s.close} onClick={() => setEvent(null)}>
        x
      </span>
      <div>
        <h4 className={s.subjectText}>{event.subject}</h4>
        <p className={s.timeText}>{`${Math.ceil(duration)}min`}</p>
        {event.onlineMeeting?.joinUrl && (
          <p className={s.timeText}>
            <a href={event.onlineMeeting?.joinUrl} target="_blank" rel="noreferrer">
              Join Teams meeting
            </a>
          </p>
        )}
        {`${getLocalTime(new Date(event.start.dateTime))} to ${getLocalTime(
          new Date(event.end.dateTime)
        )}`}
      </div>
      {event.isOrganizer && (
        <div className={s.deleteButton}>
          <Button onClick={handleDeleteEvent} variant="delete" size="small" disabled={isLoading}>
            Delete 🗑
          </Button>
        </div>
      )}
    </div>
  );
};

export default EventInfo;
