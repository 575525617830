import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import s from './Room.module.scss';
import PeopleSymbol from '../PeopleSymbol/PeopleSymbol';
import { useQuery } from 'react-query';
import useMsGraph from '../../hooks/useMsGraph';
import BookButton from '../BookingForm/BookButton';
import timeNowInUTC from '../../utils/timeNowInUTC';
import { useMsal } from '@azure/msal-react';

const Room = ({ room }) => {
  const { accounts } = useMsal();
  const [status, setStatus] = useState({});
  const { getSchedual } = useMsGraph();
  const { data } = useQuery(['getSchedual', room.id], getSchedual(room.email, room.id), {
    refetchInterval: 1000 * 60 * 5,
    refetchIntervalInBackground: true,
  });

  const parseTimeToString = (time) =>
    new Date(Date.parse(time) - new Date().getTimezoneOffset() * 60 * 1000).toLocaleTimeString(
      'sv-SE',
      {
        hour: '2-digit',
        minute: '2-digit',
      }
    );

  const upcommingEvents = data?.value?.[0].scheduleItems.filter(
    (item) => new Date(item.end.dateTime) >= timeNowInUTC()
  );

  const getNowTimeIndex = () =>
    Math.floor((new Date() - new Date().setHours(0, 0)) / 1000 / 60 / 15);

  useEffect(() => {
    const startTime = new Date(upcommingEvents?.[0]?.start?.dateTime);
    const endTime = new Date(upcommingEvents?.[0]?.end?.dateTime);
    const now = timeNowInUTC();
    const availabilityView = data?.value?.[0]?.availabilityView;
    if (availabilityView) {
      const timeslots = availabilityView.slice(getNowTimeIndex());
      if (timeslots[0] === '0' && timeslots[1] === '0') {
        setStatus({
          label: 'free',
          color: 'rgb(235, 254, 232)',
        });
      } else if (timeslots[0] === '0' && timeslots[1] !== '0') {
        setStatus({
          label: 'short',
          color: 'rgb(255, 254, 235)',
        });
      }
      if (startTime < now && endTime > now) {
        setStatus({
          label: 'booked',
          color: 'rgb(251, 234, 233)',
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.value]);

  const checkWhenFree = (upcommingEvents) => {
    if (upcommingEvents.length === 0) {
      return <p className={s.freeText}>Free for the day</p>;
    }
    const startTime = new Date(upcommingEvents[0].start?.dateTime);
    const endTime = new Date(upcommingEvents[0].end?.dateTime);
    const now = timeNowInUTC();
    if (startTime < now && endTime > now) {
      return <p className={s.busyText}>{'Busy until ' + parseTimeToString(endTime)}</p>;
    }
    if (startTime > now) {
      return <p className={s.freeText}>{'Free until ' + parseTimeToString(startTime)}</p>;
    }
  };

  return (
    <Link className={s.link} to={`/rooms/${room.id}`}>
      <div className={s.room}>
        <svg className={s.statusCorner} viewBox="0 0 180 180">
          <path id="corner" d="M0 180L180 180L0 0" fill={status.color || '#ffffff'} />
        </svg>
        <PeopleSymbol people={room.size} />
        <div className={s.clock}>
          <img
            // eslint-disable-next-line
            src={require(`../../assets/${room.clockImg}`)?.default}
            alt="clock graphics specific for room"
          />
        </div>
        <h2 className={s.title}>{room.name}</h2>
        {upcommingEvents && checkWhenFree(upcommingEvents)}
        {(upcommingEvents?.length === 0 ||
          new Date(upcommingEvents?.[0]?.start?.dateTime) > timeNowInUTC(30 * 60 * 1000)) && (
          <BookButton
            label={`🚀 book - ${
              parseInt(localStorage.getItem('defaultDuration'))
                ? parseInt(localStorage.getItem('defaultDuration')) / 1000 / 60
                : 30
            }min`}
            bookFrom={new Date()}
            duration={parseInt(localStorage.getItem('defaultDuration')) || 30 * 60 * 1000}
            roomId={room.id}
            roomEmail={room.email}
            roomName={room.name}
            userDisplayName={accounts?.[0]?.name}
            size="small"
          />
        )}
      </div>
    </Link>
  );
};

export default Room;
