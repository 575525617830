import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Button from '../Button/Button';
import s from './Settings.module.scss';

const DURATIONS = [15, 30, 45, 60, 90, 120].map((minutes) => ({
  value: minutes * 60 * 1000,
  label: `${minutes >= 60 ? `${Math.floor(minutes / 60)}h` : ''} ${minutes % 60}min`,
}));

const SettingsModal = ({ saveAndExit }) => {
  const [duration, setDuration] = useState(
    (localStorage.getItem('defaultDuration') !== 'undefined' &&
      parseInt(localStorage.getItem('defaultDuration'))) ||
      DURATIONS[1].value
  );

  useEffect(() => {
    if (duration.value) {
      localStorage.setItem('defaultDuration', duration.value);
    }
  }, [duration]);

  return ReactDOM.createPortal(
    <>
      <div className={s.root} />
      <div className={s.modal}>
        <h2 className={s.title}>Settings</h2>
        <div className={s.defaultTime}>
          <p>Default time:</p>
          <select
            value={duration.value}
            onChange={(e) => {
              setDuration(DURATIONS.find((time) => time.value === parseInt(e.target.value, 10)));
            }}
          >
            <option>Choose a default time</option>
            {DURATIONS.map((time) => (
              <option key={time.label} value={time.value}>
                {time.label}
              </option>
            ))}
          </select>
        </div>
        <div className={s.buttonWrapper}>
          <Button size="medium" onClick={saveAndExit}>
            Save & Exit
          </Button>
        </div>
      </div>
    </>,
    document.getElementById('modal')
  );
};

const Settings = () => {
  const [isOpen, setIsOpen] = useState();

  const saveAndExit = () => {
    setIsOpen(false);
    window.location.reload();
  };

  const openSettings = () => {
    setIsOpen(true);
  };
  return (
    <>
      <div className={s.switch} onClick={openSettings}>
        ⚙
      </div>
      {isOpen && <SettingsModal saveAndExit={saveAndExit} />}
    </>
  );
};

export default Settings;
