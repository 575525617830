import React, { useEffect, useState } from 'react';
import { initialState } from '../../store';
import Clock from '../../components/Clock/Clock';
import EventInfo from '../../components/EventInfo/EventInfo';
import BookingForm from '../../components/BookingForm/BookingForm';
import './RoomDetails.scss';
import useMsGraph from '../../hooks/useMsGraph';
import { useQuery } from 'react-query';
import compareEmailAddresses from '../../utils/compareEmailAddresses';

const RoomDetails = ({ match }) => {
  const [selectedEvent, setSelectedEvent] = useState();

  const { id, name, clockImg, email, info } = initialState.rooms.find(
    (room) => room.id === match.params.roomId
  );
  const [events, setEvents] = useState();
  const { getCalendarsEventsToday, getMyEventsToday } = useMsGraph();
  const { data: calendarEventsToday } = useQuery(
    ['getCalendarsEventsToday', id],
    getCalendarsEventsToday(id)
  );
  const { data: myEventsToday } = useQuery(['getMyEventsToday', id], getMyEventsToday());

  useEffect(() => {
    if (myEventsToday?.value && calendarEventsToday?.value) {
      const events = [
        ...calendarEventsToday.value.filter(
          (event) => !myEventsToday.value.map((i) => i.iCalUId).includes(event.iCalUId)
        ),
        ...myEventsToday.value,
      ].filter(({ attendees }) =>
        attendees.find(
          ({ emailAddress, status }) =>
            emailAddress.address &&
            compareEmailAddresses(emailAddress.address, email) &&
            status.response === 'accepted'
        )
      );
      setEvents(events);
    }
  }, [myEventsToday, calendarEventsToday, email]);

  return (
    <>
      {events && (
        <div className="room-details">
          <h1>{name}</h1>
          <Clock img={clockImg} events={events} eventState={[selectedEvent, setSelectedEvent]} />
          <p className="room-details-info-text">{info}</p>
          {selectedEvent && (
            <EventInfo calendarId={id} event={selectedEvent} setEvent={setSelectedEvent} />
          )}
          <BookingForm roomId={id} roomEmail={email} roomName={name} />
        </div>
      )}
    </>
  );
};

export default RoomDetails;
