import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import useMsGraph from './useMsGraph';

const usePollForDeletion = ({ roomId, iCalUId }) => {
  const [refetchInterval, setRefetchInterval] = useState(1000);
  const [deleted, setDeleted] = useState();
  const [eventId, setEventId] = useState();
  const queryClient = useQueryClient();

  const { getCalendarsEventsToday } = useMsGraph();

  const { error, isFetching } = useQuery(
    ['getCalendarsEventsToday'],
    getCalendarsEventsToday(roomId, eventId),
    {
      refetchIntervalInBackground: true,
      refetchInterval,
      onSuccess: (data) => {
        if (data.value.find((item) => item.iCalUId === iCalUId)) {
          // keep polling if event still exists
          setRefetchInterval(1000);
          setDeleted(false);
        } else {
          setRefetchInterval(false);
          setDeleted(true);
          queryClient.invalidateQueries('getMyEventsToday');
          queryClient.invalidateQueries('getCalendarsEventsToday');
          queryClient.invalidateQueries(['getSchedual', roomId]);
        }
      },
      onError: () => {
        setRefetchInterval(false);
      },
      enabled: Boolean(eventId),
    }
  );

  const startPolling = (id) => setEventId(id);
  const stopPolling = () => {
    setEventId(null);
    setRefetchInterval(false);
  };

  return { startPolling, stopPolling, deleted, eventId, error, isFetching };
};

export default usePollForDeletion;
