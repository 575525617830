const getMySchedual = (apiPost) => (email) => {
  const startOfDay = new Date(new Date().setHours(0, 0, 0, 0));
  const endOfDay = new Date(new Date().setHours(23, 59, 59, 999));
  const scheduleInformation = {
    schedules: [email],
    startTime: {
      dateTime: startOfDay,
      timeZone: 'UTC',
    },
    endTime: {
      dateTime: endOfDay,
      timeZone: 'UTC',
    },
    availabilityViewInterval: 15,
  };
  return apiPost(`/me/calendar/getSchedule`, scheduleInformation);
};

export default getMySchedual;
