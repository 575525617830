import React from 'react';
import From from './From';
// import Attendees from './Attendees';
import SelectTime from './SelectTime';
import { FormProvider } from '../../providers/formProvider';
import BookButton from './BookButton';
import { useMsal } from '@azure/msal-react';
import { useQuery } from 'react-query';
import useMsGraph from '../../hooks/useMsGraph';

const BookingForm = ({ roomId, roomEmail, roomName }) => {
  const { accounts } = useMsal();
  const { getSchedual } = useMsGraph();
  const { data } = useQuery(['getSchedual'], getSchedual(roomEmail, roomId));

  return (
    <FormProvider formName="booking-form">
      {data?.value && <From schedual={data.value[0]} />}
      <SelectTime />
      {/* <Attendees /> */}
      <BookButton
        roomEmail={roomEmail}
        roomName={roomName}
        roomId={roomId}
        userDisplayName={accounts[0].username}
        label="Book!"
      />
    </FormProvider>
  );
};

export default BookingForm;
