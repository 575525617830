import React from 'react';
import { BrowserRouter as Router, Route, useHistory } from 'react-router-dom';
// import { State, initialState, reducer } from './store';
import Home from './containers/Home/Home';
import RoomDetails from './containers/RoomDetails/RoomDetails';
import Footer from './components/Footer/Footer';
import './App.scss';
// import Luncher from './containers/Luncher/Luncher';
// import Redies from './containers/Redies/Redies';
import { CustomNavigationClient } from './utils/NavigationClient';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import Login from './components/Login/Login';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const App = ({ pca }) => {
  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);
  pca.setNavigationClient(navigationClient);

  // const [state, dispatch] = useReducer(reducer, initialState);
  const { accounts } = useMsal();
  const queryClient = new QueryClient();

  return (
    <>
      <AuthenticatedTemplate>
        <QueryClientProvider client={queryClient}>
          {/* <State.Provider value={{ state, dispatch }}> */}
          <Router>
            <Route path="/" exact component={Home} />
            <Route path="/rooms/:roomId" component={RoomDetails} />
            {/* <Route path="/luncher" component={Luncher} />
              <Route path="/redies" component={Redies} /> */}
            {accounts[0] && <Footer user={accounts[0]} />}
          </Router>
          <div id="modal" />

          {/* </State.Provider> */}
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </>
  );
};

export default App;
