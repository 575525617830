import React from 'react';
import SignOut from './SignOut/SignOut';
import Back from './Back/Back';
import s from './Footer.module.scss';
import Settings from '../Settings/Settings';
import { useLocation } from 'react-router';

const Footer = () => {
  const location = useLocation();

  return (
    <footer className={s.footer}>
      <SignOut />
      {location.pathname === '/' ? <Settings /> : <Back />}
    </footer>
  );
};

export default Footer;
