import React from 'react';
import s from './OfficeMap.module.scss';

const HIGHLIGHT_COLOR = '#ef5497';
const INACTIVE_COLOR = '#b9ab9a';

const OfficeMap = ({ roomId }) => {
  return (
    <div className={s.root}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="90%"
        height="90%"
        viewBox="0 0 1302.795 828.958"
        enableBackground="new 0 0 1302.795 828.958"
      >
        <g id="Layer_1"></g>
        <g id="Layer_2">
          <g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#231F20"
              d="M1287.79,598.938c-2.06-0.67-2.109-2.29-1.939-4.04
			c0.319-3.38-0.391-6.6-1.62-9.729c-1.67-4.221-2.181-8.601-2.19-13.15c-0.01-46.12-0.12-92.25-0.229-138.38
			c-0.011-2.1,0-4.34,2.5-4.74c2.649-0.42,2.5-2.13,2.63-3.97c0.55-7.44-0.44-14.91,0.58-22.35c0.24-1.75-0.82-3.43-2.7-3.67
			c-2.78-0.35-2.95-1.99-2.9-4.22c0.15-6.16,0.16-12.32,0.16-18.48c0.01-51.79-0.01-103.58,0.05-155.37c0-3.48-2.029-8.03,3.94-9.1
			c1.22-0.22,1.01-1.58,1-2.53c-0.08-6.15-0.21-12.29-0.32-18.43c-0.54-0.32-1.01-0.24-1.41,0.25c-1.76,4.35-0.609,8.9-0.949,13.34
			c-0.311,4.21-1.971,5.61-6.061,5.24c-3.41-0.32-4.819-2.22-5.3-5.5c-0.46-3.12-0.7-3.08-3.17-2.39c-1.311,3.71-2.75,8.21,1.1,10.5
			c5.45,3.23,5.28,7.76,5.28,12.82c-0.05,53.95-0.03,107.91-0.03,161.86c0,1.83,0,3.66-0.06,5.5c-0.07,2.32-0.75,4.16-3.15,5.14
			c-2.63,1.07-3.59,3.34-3.67,6.02c-0.2,6.82-0.229,13.65,0.021,20.48c0.08,2.05,0.55,4.3,2.529,5.13
			c4.061,1.7,4.36,4.899,4.351,8.58c-0.03,45.3,0,90.59-0.04,135.89c-0.01,7.74-0.851,8.53-8.68,8.54
			c-21.311,0.029-42.631,0.02-63.94,0.01c-1.17,0-2.33-0.05-3.5-0.1c-3.6-0.15-5.5-1.95-5.62-5.61c-0.05-1.66-0.07-3.33-0.08-4.99
			c-0.05-17.45,1.061-34.91-0.02-52.35c-0.44-7.141,0.449-13.95,4.189-20.19c0.33-5.52-1.779-9.43-6.109-11.31
			c-2.23,1.21-3.19,4.29-5.641,4.77c-6.5,1.271-6.779,5.79-6.439,10.91c0.14,2.16-0.141,4.33,0.01,6.48
			c0.189,21.39-0.33,42.79-0.01,64.189c-0.15,2.17,0.05,4.33-0.03,6.5c-0.21,5.99-1.53,7.51-7.58,8.46
			c-4.47,0.7-8.979,0.351-13.47,0.351c-11.57,0.01-12.34-0.74-13.08-12.091c-0.16-2.55-0.36-5.109-3.24-6.18
			c-1.29-0.479-2.729-0.5-3.75,0.561c-1.14,1.18,0.07,2.149,0.66,3.05c2.36,3.55,2.75,7.43,2.05,11.5
			c-0.479,2.81-0.68,5.62-0.47,8.46c1.689,8.51,4.229,10.97,12.67,12.31c3.14,0.49,6.33,0.69,9.46,0.21
			c5.8-0.89,11.62-0.77,17.45-0.77c16.479-0.01,32.97-0.021,49.46,0c8.83,0.01,17.66-0.18,26.479,0.09c5.65,0.17,7,1.71,7.23,7.28
			c0.05,1.33,0.05,2.67,0.02,4c-0.439,21.79,0.19,43.59-0.66,65.41c-0.51,13.05,0.641,26.25,0.761,39.399
			c-0.09,1.59,0.17,3.19-0.101,4.78c-0.09,24.8,0.021,49.59-0.04,74.399c-0.02,7.75-0.63,8.431-8.109,8.431
			c-66.91,0.04-133.82,0.02-200.73,0.04c-3.22,0-6.29-0.45-9.189-1.92c-2.2-0.011-4.391,0.34-6.53,0.68
			c1.189-0.24,2.38-0.69,3.52-1.49c-0.359-5.59-4.29-8.27-8.659-10.529c-2.15-1.11-3.41,0.239-4.41,1.97
			c-0.49,0.67-0.99,1.34-1.55,1.95c-5.101,5.489-5.811,5.449-10.761-0.011c-2.109-2.319-4.27-4.739-7.899-4.399
			c-6.391,3.39-11.811-1.48-16.54-2.46c3.66-1.07,8.85,1.59,13.6-0.891c1.05-5.08,3.41-9.09,8.73-10.71
			c0.439-0.14,0.62-0.739,0.81-1.199c0.561-3.4-1.85-5.16-4.02-6.91c-5.03-4.061-7.221-9.271-7.21-15.69
			c0.05-31.41,0.06-62.83-0.011-94.25c-0.01-6.36,2-11.689,6.63-16.06c1.57-1.48,3.12-3,4.28-4.851c1.271-2.01,1.75-4.39-0.05-5.979
			c-4.05-3.55-6.07-8.71-10.16-12.17c-1.95-0.78-3.63-0.07-5.229,1.01c-2.83,2.439-6.181,2.979-9.79,2.97
			c-13.98-0.04-27.971-0.05-41.95,0.01c-2.61,0.011-5.38-0.43-7.61,1.58c-0.96,1.44-1.08,3.101-1.08,4.74
			c-0.01,10.87-0.229,21.73,0.12,32.601c0.05,0.939,0.09,1.88,0.22,2.81c1.94,11,2.46,22.02,0.811,33.11
			c-1.021,6.85,0.24,8.119,7.42,7.85c3.07-0.7,6.06-1.71,9.29-1.7c9.31,0.05,18.62-0.069,27.92,0.05
			c7.14,0.091,8.21,1.301,8.27,8.62c0.021,1.66-0.069,3.33,0.011,4.99c0.17,3.67-1.101,6.05-5.09,6.59
			c-3.061,0.41-4.881,2.46-4.73,5.48c0.57,11.13-3.19,21.67-4.11,32.58c-0.27,3.25-1.76,4.729-4.819,5.229
			c-4.971,0.811-9.96,0.55-14.931,0.44c-5.38-0.13-7.3-1.891-8.54-7.24c-0.569-2.43-0.8-4.91-0.81-7.42
			c-0.03-5.98,0.07-11.96-0.06-17.95c-0.061-2.82-0.11-5.72-2.83-7.58c-6.73-1.74-13.58-0.5-20.37-0.82
			c-2.88-0.14-5.63-0.06-7.3-3.149c-1.11-2.061-2.381-1.19-3.261,0.38c-1.71,3.04,0.101,6.09,4.13,6.66
			c4.301,0.609,8.66,0.03,12.98,0.38c6.89,0.56,7.61,1.3,7.63,8.19c0.03,17.819,0.01,35.64,0,53.46c0,2.17,0.021,4.329-0.15,6.489
			c-0.22,2.78-1.649,4.21-4.43,4.41c-1.49,0.11-2.99,0.15-4.49,0.15c-60.96,0.01-121.92,0.01-182.88,0c-8.18,0-8.83-0.67-8.87-8.82
			c-0.05-9.5,0.08-18.99-0.06-28.479c-0.05-3.431-0.45-6.79-4.65-7.841c-4.21,3.381-8.46,3.23-12.75,0.2
			c-2.06,0.22-2.909,1.65-3.39,3.431c-0.76,10.149-0.23,20.31-0.32,30.47c-0.1,11.02-0.02,11.03-10.819,11.03H319.46
			c-1.5,0-3,0.039-4.5-0.03c-5.23-0.24-6.9-1.41-6.39-6.66c1.11-11.63,0.11-23.33,1.54-34.92c0.87-7.101,0.92-7.09,8.03-5.59
			c1.27,0.109,2.53,0.13,3.79-0.17c7.37-1.63,14.66-2.94,21.93,0.77c2.86,1.47,4.99-0.939,7-2.64c1.47-1.25,1.11-2.811-0.03-3.96
			c-4.47-4.49-3.4-10.15-3.51-15.58c-0.06-2.88,0.58-4.98,4.2-4.44c1.12,0.17,2.44,0.141,2.73-1.45c0.19-1.05-0.1-2.319-1.01-2.609
			c-3.71-1.21-5.94-4.05-7.87-7.3c-2.29,2.04-2.02,4.3-2.06,6.399c-0.11,5-0.02,10-0.04,15c-0.05,8.78-1.38,10.15-10.13,10.24
			c-7.16,0.09-14.33-0.13-21.49,0.09c-5.15,0.17-6.78,1.84-7.27,6.94c-0.18,1.989-0.11,4-0.11,6
			c-0.01,16.16,0.08,32.319-0.05,48.489c-0.04,6.11-1.47,7.221-7.56,6.79c-6.99-0.479-13.99-0.47-20.98,0.021
			c-5.6,0.38-7.31-0.98-7.32-6.69c-0.09-45.489-0.08-90.979,0-136.47c0.01-5.729,1.34-6.95,7.21-7.07
			c6.83-0.13,13.67-0.09,20.5-0.02c7.21,0.07,8.14,0.99,8.18,8.06c0.06,9.49-0.03,18.99,0.04,28.49
			c0.05,8.58,1.79,10.33,10.21,10.41c6.5,0.07,12.99,0.01,19.49,0.03c2.33,0,4.79-0.32,6.9,0.75c4.18,2.109,7.34,0.77,10.15-2.41
			c0.06-0.69-0.21-1.21-0.82-1.55c-2.18-1.101-4.54-0.91-6.86-0.92c-8.83-0.04-17.66,0.05-26.48-0.03
			c-6.88-0.08-8.37-1.391-8.41-8.17c-0.05-9.66-0.42-19.32,0.33-28.971c0.53-6.75-1.96-9.51-8.83-9.76
			c-6.49-0.24-12.99-0.069-19.49-0.08c-11.83-0.02-12.08-0.239-12.08-11.979c0-82.45,0.01-164.891,0-247.33
			c-0.01-39.97,0.07-79.94-0.07-119.91c-0.48-2.17,0.53-4.8-1.98-6.34c-1.99,1.02-1.95,2.96-2.1,4.76c-0.18,2.33-0.09,4.67-0.09,7
			c0,120.99,0,241.98-0.01,362.97c0,2.66,0.19,5.341-0.37,7.98c-0.31,1.479-1.23,2.34-2.82,2.22c-1.4-0.1-1.97-1.05-2.22-2.3
			c-0.35-1.81-0.32-3.65-0.32-5.48c-0.08-131.489,0.15-262.97-0.12-394.479c0.36-2.38,2.12-2.06,3.75-1.99
			c10.66-0.01,21.32,0.34,31.98-0.31c2.69-0.17,5.22-0.68,5.85-3.62c0.97-4.51,4.08-5.05,7.87-5.03
			c10.16,0.05,20.33-0.11,30.49,0.04c29.48,0.41,58.97-1.06,88.44,0.94c6.09,0.42,8.16,1.43,8.31,6.3
			c0.46,15.82,0.37,31.65,0.04,47.48c-0.02,0.99-0.35,1.96-0.61,2.93c-1.18,4.5-0.39,5.84,4.19,6.16
			c6.34,0.45,12.67,0.51,18.68-2.17c1.67-0.75,3.41-1.14,5.32-1.08c16.82,0.48,33.63,1.08,50.43-0.47
			c4.14-0.39,8.34-0.34,12.49,0.13c10.59,1.17,21.2,2.24,31.79,3.45c5.79,0.66,11.65,0.48,17.47,0.05c1.13-0.09,2.55-0.34,2.79-1.68
			c0.21-1.18-1.1-1.42-1.96-1.79c-6.9-2.95-7.82-3.97-7.13-11.42c1.22-13.14,1.08-26.27,0.38-39.43c-0.2-3.7,1.25-6.31,5.05-7.6
			c4.18-1.42,8.4-1.85,12.77-1.85c57.49,0.04,114.979,0.02,172.479,0.03c5.16,0,10.311-0.02,15.45,0.71
			c4.391,0.63,8.7,0.22,12.09-3.24c6.141-4.1,12.101-8.34,13.811-16.21c2.47-3.09,5.649-5.53,8.12-8.59
			c2.319-2.86,5.109-5.67,4.06-9.96c-1.36-1.48-3.189-1.49-4.99-1.59c-2.27-0.12-4.62,0.22-6.689-1.12
			c-0.641-0.5-1.15-1.14-1.69-1.73c-0.38-0.42-0.78-0.82-1.26-1.14c-6.03-2.91-7.34-2.61-11.45,2.4c-2.74,3.34-5.85,6.45-8.35,9.94
			c-3.73,5.22-8.45,6.52-14.62,6.51c-67.83-0.17-135.66-0.11-203.49-0.11c-2.33,0-4.66-0.06-6.99,0.13
			c-2.85,0.23-5.16,1.29-6.31,4.12c-0.89,4.13-0.3,8.31-0.46,12.46c-0.12,3.1,0.03,6.22-0.01,9.33c-0.03,3.04-0.23,6.06-1.04,9.05
			c0.18,4.89,1.76,9.68,0.97,14.63c-0.64,4.94,1.47,10.09-1.16,14.87c-3.08,0.97-6.26,0.54-9.4,0.51
			c-16.3-0.14-32.6-0.24-48.92,0.04c-7.33,0.06-14.65,0.08-21.96,0.04c-6.27-0.04-6.65-0.47-6.72-6.9
			c-0.03-2.49,0.14-4.99-0.16-7.46c2.85,2.59,4.09,6.86,8.27,8.14c2.54-1.01,5.96-0.61,6.82-4.29c-1.18-2.37-3.21-2.03-5.25-1.67
			c-2.63,0.18-3.69-1.61-4.61-3.62c-1.64-3.57-2.98-7.22-3.18-11.19c-0.67-0.95-1.29-1.92-1.61-2.97c-0.26-0.83-0.32-1.72-0.09-2.7
			c0.14-7.65,0.14-15.32,0.01-22.98c-0.12-7.44-2.17-9.55-9.62-9.56c-49.65-0.04-99.3-0.13-148.96,0.04
			c-13.3,0.22-26.6,0.16-39.9,0.05c-5.24-0.05-10.47-0.1-15.7-0.13c-0.63-0.01-1.26-0.02-1.89-0.02c-2.76-0.59-3.66-2.5-3.84-5.09
			c-0.25-3.67-0.3-7.33-0.18-10.99c0.19-6.17,0.24-12.34,0-18.5c-0.25-6.58-3.81-8.48-9.67-5.36c-1.77,0.94-3.34,2.25-5.19,3
			c-6.28,2.51-8.46,7.06-8.3,13.76c0.34,14.66,0.14,29.33,0.08,44c-0.02,6.97-0.55,7.45-7.36,7.29c-5.34-0.13-10.67-0.13-15.91,1.13
			c-6.06,1.45-8.26,5.19-7.47,12.59c0.81,7.56,2.45,9.28,9.48,9.35c12,0.12,24,0,36,0.05c8.37,0.03,9.27,0.89,9.27,9.12
			c0.02,119.5,0.01,238.99,0.01,358.49c0,1.83,0.04,3.67-0.38,5.47c-0.54,2.28-2.06,3.3-4.33,3.38c-2.29,0.08-3.82-0.93-4.53-3.13
			c-0.68-2.1-0.49-4.28-0.61-6.44c-0.22-3.96,0.65-8.01-0.89-11.869c-0.15-0.29-0.34-0.48-0.56-0.591
			c-0.02-0.02-0.05-0.029-0.08-0.04c-0.26-0.109-0.57-0.1-0.93,0.03c-1.67,1.51-1.59,3.561-1.6,5.521
			c-0.04,6.439-0.18,12.89,0.07,19.33c0,0.149,0,0.29,0.01,0.43c0.07,4.37,2.7,5.91,6.89,6.22c6.22,0.47,7.01,1.391,6.99,7.75
			c-0.02,7.15,0.31,14.3-0.43,21.45c-0.29,2.74-0.76,6.09,3.11,7.29c1.65-1.99,1.27-4.4,1.31-6.68c0.1-6.801-0.11-13.61,0.13-20.41
			c0.04-1.38-0.09-3.44,1.96-3.42c2.08,0.02,1.83,2.09,1.88,3.46c0.21,6.3-0.15,12.62,0.16,18.91c0.21,4.22-0.8,7.42-4.82,9.25
			c-0.58,1.109,2.63,3.149-0.92,3.6c-1.64,0.2-3.76,0.63-3.61,2.66c0.16,2.1,2.47,2.02,4,2.01c4.2-0.02,5.37,2.13,5.29,5.99
			c-0.17,7.649,0.02,15.319-0.08,22.979c-0.08,5.91-1.77,7.66-7.59,6.891c-7.65-1.011-15.3-0.73-22.95-0.7
			c-3.01,0.01-8.47-0.92-8.34,1.899c0.18,4.061,5.46,2.94,8.76,3.03c8.37,0.2,16.75-0.04,25.89,0.141
			c-4.17,3.76-4.57,7.819-4.19,12.39c0.98,11.96,0.39,23.96,0.34,35.95c-0.02,3.6-1.86,5.529-5.59,5.5
			c-1.83-0.021-3.67,0.029-5.5,0.029c-42.97,0-85.94,0.051-128.91-0.02c-14.66-0.03-14.07,2.16-13.78-15.18
			c0.08-4.24,2.26-6.771,6.31-7.88c2.77-0.761,5.58-0.82,8.42-0.9c2.62-0.07,5.37,0.46,7.7-1.33c0.3-0.62,0.19-1.16-0.33-1.62
			c-6.93-2.149-14.01-0.77-21.04-0.899c-13.05,3.489-26.4,3.06-39.71,2.949c-2.69-0.029-5.33-0.17-7.75-1.56
			c-4.59-2.64-13.62-19.29-13.39-24.71c0.86-1.91,2.56-1.771,4.25-1.78c13.97-0.11,27.95-0.06,41.93-0.02
			c3.09,0.01,5.91,0.77,8.1,3.13c2.19,0.88,4.35,1.02,6.48-0.19c0.88-0.75,1.1-1.76,0.57-2.689c-1.83-3.23-1.53-6.74-1.53-10.221
			c-0.01-21.949-0.01-43.909,0-65.859c0-1.99-0.04-3.99,0.19-5.98c0.32-2.87,1.86-4.5,4.75-4.89c2.15-0.29,4.31-0.23,6.47-0.23
			c28.94-0.01,57.88,0,86.81-0.01c2,0,3.99,0.01,5.99-0.08c4.04-0.17,6.58-1.88,6.61-6.21v-0.149c0.16-2.5,0.34-5-0.43-7.431
			c-0.2-0.62-0.5-1.34-1.16-1.5c-1.02-0.25-1.6,0.59-1.73,1.351c-0.87,4.97-4.51,4.899-8.33,4.89
			c-19.64-0.05-39.27-0.021-58.91-0.021c-13.48,0-26.96,0.03-40.44-0.029c-2.45-0.01-5,0.24-6.71-2.33
			c-0.82-1.24-1.18-3.67-2.74-3.771c-0.06-0.01-0.13-0.01-0.2-0.01c-0.23,0.01-0.49,0.08-0.78,0.2c-1.95,0.8-1.11,3.54-0.93,5.5
			c0.1,1,0.2,1.99,0.43,2.97c0.09,0.48,0.17,0.97,0.25,1.45c2.78,16.71,1.37,33.58,1.77,50.37c0.12,5.229-0.16,10.28-2.83,14.939
			c2.78,3.87,3.12,8.23,2.82,12.801c-0.22,3.26-1.81,4.81-5.02,4.92c-3.99,0.14-7.98,0.069-11.97,0.069
			c-9.64,0.011-19.29,0.12-28.93-0.05c-4.72-0.08-5.59-1.13-5.51-5.62c0.17-9.939,0.77-10.52,10.76-10.37
			c1,0.021,2.02-0.069,2.99,0.131c9.79,2.1,19.55-0.29,29.32-0.23c1.49,0.01,3.02-0.18,4.04-1.53c-0.76-2.33-2.77-2.149-4.63-2.27
			c-11.73-0.29-23.46-0.17-35.2-0.05c-3.9,0.12-6.45-0.641-6.92-5.58c-1.45-15.28-0.28-30.61-1.48-45.88
			c-0.33-4.29,1.54-6.101,5.78-6.48c2.16-0.189,4.33-0.01,6.49-0.13c5-0.26,6.69-1.37,7.06-5.54c0.05-0.53,0.07-1.11,0.08-1.74
			c0.11-8.16,0.01-16.319,0.05-24.479c0.01-1.79-0.41-3.7,0.96-5.271c0.86-0.779,1.98-1.05,3.03-1.47c2.2-0.88,2.17-2.1,0.49-3.53
			c-0.08-0.02-0.17-0.029-0.25-0.04c-5.9-1.13-7.78,0.311-7.99,6.311c-0.18,5.319-0.3,10.66,0.01,15.95
			c0.3,5.109-2.22,8.869-4.87,12.72c-1.09,1.6-2.59,1.27-4.02,0.899c-4.55-1.18-7.28-4.609-7.34-9.35c-0.1-7.98,0-15.96,0-23.93
			c0.02-11.59-0.13-23.19,0.06-34.78c0.13-12.48,0.01-24.96,0.04-37.43c0.01-3.04,0.05-6.03,2.46-8.341
			c0.32-0.56,0.38-1.14,0.16-1.75c-2.25-2.729-2.71-5.92-2.68-9.34c0.05-5.14,0.42-10.319-0.2-15.43
			c-1.46-11.93-0.46-23.88-0.72-35.82c-0.08-4,1.95-5.63,5.85-5.609c2.78,0.01,5.68,0.479,8.29-1.021c0.49-0.42,0.64-0.93,0.44-1.55
			c-2.14-1.76-4.72-1.26-7.16-1.31c-7.78-0.171-9.4-2.03-8.05-9.59c0.48-2.65,0.54-5.271,0.01-7.9c-1-4.96-0.86-9.87,0.67-14.729
			c0.51-1.131,1.19-2.051,2.6-1.98c2.08-0.07,4.18,0.03,6.27-0.02c2.41-0.23,5.22,0.81,6.75-2.171c-1.94-2.689-6.01-3.649-5.83-8.1
			c0.21-5.32-0.2-10.66,0.14-15.97c0.33-5.201,2.15-6.96,7.33-7.73c1.47-0.22,3.02-0.15,4.21-1.27c-0.88-3.56-3.87-2.13-6.04-2.46
			c-2.2-0.34-4.93,0.44-5.29-3.03c-0.73-5.29,0.56-10.72-1.03-15.96c-0.04-0.09-0.08-0.18-0.14-0.26c-0.86-1.68-2.33-2.64-4.16-2.74
			c-4.95-0.27-5.9-3.46-5.85-7.54c0.23-20.97-0.24-41.94-0.96-62.9c-0.27-8.03,1.48-9.49,9.62-9.71
			c10.33-0.28,20.62,0.73,30.93,0.89c21.97,0.33,43.96,0.11,65.94,0.1c2.67-0.01,5.33,0.06,7.95-0.63c3.62-0.94,6.38-3.31,6.09-6.94
			c-0.54-6.83,0.53-12.99,4.66-18.52c-0.06-1.44-0.38-2.58-0.93-3.46c-0.16-0.28-0.36-0.54-0.58-0.77
			c-1.21-1.27-3.09-1.75-5.45-1.69c-1.67,0.04-3.33-0.01-5-0.02c-22.65-0.18-45.21,2.37-67.86,2.03c-7.13-0.11-14.27-0.31-21.34,0.5
			c1.39-0.77,5.94-0.08,1.77-3.54c-1.12-0.94-0.2-0.54,0.2-0.75c2.67-1.45,0.99-2.24-0.53-2.77c-1.75-0.63-3.61-0.51-5.44-0.23
			c-0.2,0.05-0.4,0.09-0.6,0.13c-0.08,0.01-0.15,0.03-0.23,0.04c-3.19,0.51-6.34-0.47-9.51-0.55c-1.98-0.05-2.86-1.53-2.6-3.5
			c0.25-1.86,1.64-2.28,3.25-2.44c3.16-0.3,4.38,1.69,5.27,4.24c0.38,1.07,0.92,2.39,2.29,2.3c0.6-0.03,0.95-0.29,1.18-0.66
			c0.18-0.28,0.28-0.64,0.35-1.01c0.06-0.33,0.1-0.68,0.16-0.99c0.24-1.29,0.13-2.71,1.61-3.42c1.64-0.91,5.05-0.13,4.87-2.35
			c-0.2-2.52-3.4-1.79-5.42-1.93c-2.33-0.17-4.67,0.01-6.99-0.07c-1.87-0.06-3.89,0.34-5.58-1.25c1.81-2.23,4.11-1.71,6.17-1.81
			c3.82-0.19,7.66,0.18,11.47-0.25c1.53-0.17,3.07-0.61,3.27-2.49c0.21-1.99-1.05-3.04-2.76-3.64c-1.43-0.5-2.94-0.5-4.44-0.52
			c-3.33-0.05-6.66,0.08-9.99-0.07c-2.49-0.11-5.55-0.09-5.35-3.65c0.19-3.22,3.16-2.26,5.13-2.38c3.49-0.2,7,0.15,10.48-0.23
			c1.21-0.13,2.9-0.19,3.09-1.6c0.01-0.04,0.01-0.08,0.01-0.13c0.01-0.02,0.01-0.05,0.01-0.07c0.04-1.5-1.7-1.83-2.93-2.27
			c-0.94-0.35-1.98-0.2-2.95-0.34c-1.24-0.18-2.49-0.25-3.73-0.26c2.78-0.03,5.56,0.05,8.34,0.28c2.4,0.21,4.57-0.81,4.76-3.35
			c0.2-2.85-2.46-2.72-4.34-2.84c-5.26-0.33-10.54,0.07-15.8-0.29c5.48-0.02,11.01,0.65,16.37-1.08c2.09-0.68,4.01-1.64,1.16-3.13
			c-0.05-0.03-0.1-0.05-0.15-0.08c-0.12,0.22-0.23,0.44-0.34,0.66c-5.41,0.1-10.83,0.33-16.23,0.23c-1.77-0.04-4.89,1.52-4.85-2.21
			c0.03-3.6,3.02-2.65,5.19-2.82c5.59-0.42,11.2,0.32,16.82-0.66c3.03-0.52,3.82-1.76,3.81-4.51c-0.01-3.97,0.25-7.93,0.4-11.9
			c-0.8-7.89-0.8-7.91,6.8-8c11.63-0.14,23.26,0.09,34.88-0.61c5.51-0.33,7,1.5,7,7.11c0,5.33,0.05,10.65-0.17,15.97
			c-0.16,3.85,0.85,6.03,5.27,5.7c5.64-0.42,11.26,0.53,16.89,0.72c9.85,0.32,13.46-4.03,11.58-13.58
			c-0.79-3.99-0.35-7.96-0.55-11.93c-0.12-2.45,1.14-3.95,3.46-3.9c2.19,0.04,3.58,1.3,3.56,3.86c-0.06,12.48-0.04,24.96-0.02,37.43
			c0.01,3.34-2.07,4.47-5.17,4.73c6.93,2.82,9.09,1.35,9.34-5.53c0.21-5.92-1.05-11.91,0.7-17.78c-0.81,7.6,0.4,15.28-0.51,22.81
			c0.2-0.34,0.37-0.55,0.51-0.86v-0.23c0.28-0.76,0.45-1.6,0.5-2.51c0.31-6.29-0.61-12.63,0.57-18.91
			c0.85-5.09,0.73-10.26,0.34-15.34c-0.44-5.61,0.15-10.47,5.16-13.88c0.04-0.13,0.07-0.27,0.1-0.4c0.04-0.14,0.06-0.28,0.08-0.41
			c0.26-1.62-0.2-3.05-1.32-4.3c-0.02-0.03-0.05-0.06-0.08-0.09c-0.08-0.09-0.16-0.18-0.25-0.27c-1.34-0.6-2.72-0.37-3.81,0.36
			c-7.33,4.96-15.61,3.32-23.62,3.54c-2.77,0.08-4.98-0.6-5.1-3.95c-0.05-0.81-0.01-1.65-0.17-2.44c-0.05-0.28-0.09-0.59-0.13-0.9
			c-0.05-0.35-0.11-0.71-0.22-1.02v-0.01c-0.21-0.62-0.6-1.08-1.47-1.04c-0.87,0.04-1.25,0.61-1.43,1.32
			c-0.08,0.26-0.12,0.54-0.17,0.82c-0.04,0.33-0.07,0.66-0.12,0.95c-0.27,1.74,0.25,3.67-1.29,5.09c-1.51,1.24-3.32,1.42-5.17,1.34
			c-10.13-0.39-20.27-0.06-30.4-0.2c-5.31-0.07-5.34-0.19-3.72-5.31c0.64-2.02,1.89-4.81-0.39-5.84c-2.38-1.07-3.93,1.69-5.36,3.45
			c-0.51,0.64-0.67,1.52-0.77,2.35c-0.47,3.87-2.82,5.33-6.51,5.34c-11.63,0.04-23.26,0.11-34.89-0.03
			c-4.5-0.06-6.07-1.74-5.46-6.29c0.55-4.13,0.51-8.26,0.7-12.39c0.01-0.36,0.03-0.72,0.05-1.08c0.02-0.48,0.05-0.95,0.09-1.43
			c0.01-3.35,1.84-4.66,5.04-4.69c5.14-0.05,10.29-0.01,15.44-0.05c9.52-0.07,11.42-1.87,11.46-11.36
			c0.08-19.92,0.03-39.84,0.02-59.77c0-3.31,0.2-6.65-0.14-9.95c-0.65-6.23-4.72-8.27-10.5-5.32c-0.03,0.21-0.02,0.39,0.01,0.56
			c0,0.02,0.01,0.04,0.01,0.05c0.15,0.66,0.73,1.02,1.35,1.17c3.65,0.88,4.44,3.4,4.33,6.76c-0.12,4.15-0.2,8.31,0.59,12.43
			c0.62,3.2-0.84,5.06-3.94,5.81c-3.79,0.92-7.56,0.72-11.37,0.09c-3.19-0.52-4.43-2.26-4.53-5.44c-0.21-7.17-0.24-14.25,3.6-20.66
			c0.01-0.03,0.02-0.06,0.05-0.08c0.06-0.13,0.14-0.25,0.22-0.38c-0.95-1.37-2.18-1.73-3.47-1.82c-1.07-0.07-2.19,0.05-3.26-0.04
			c-0.22-0.02-0.43-0.04-0.64-0.08c-2.16,0.38-5.03-0.41-5.26,3.19c0.01,0.05,0.03,0.1,0.04,0.15c0.02,0.08,0.04,0.15,0.06,0.23
			c1.84,6.97,0.24,14.07,0.9,21.08c-0.81-7,0.88-14.11-1.02-21.05c-0.02-0.08-0.04-0.16-0.07-0.23c-0.02-0.11-0.06-0.23-0.09-0.34
			c-0.45-4.27-1.08-8.54-0.91-12.88c0.2-5.35,0.74-6.06,6.08-6.11c11.62-0.12,23.25-0.09,34.87-0.02c6.02,0.03,6.9,0.91,6.95,6.99
			c0.11,10.46,0.01,20.92,0.05,31.38c0.01,2.3-0.25,4.68,1.08,6.78c0.13,0.15,0.27,0.27,0.42,0.34c0.03,0.02,0.05,0.03,0.07,0.04
			c0.33,0.15,0.7,0.14,1.1-0.03c0.01-0.03,0.02-0.05,0.03-0.08c0.02-0.04,0.04-0.09,0.06-0.13c1.72-4.24,3.43-9.22,2.53-13.07
			c-1.89-8.14-0.99-16.14-1.25-24.19c-0.23-7.27,0.7-7.87,7.85-8.19c2.38-0.11,5.37,0.99,6.73-2.24c0.02-0.04,0.04-0.09,0.06-0.14
			c-0.02-0.02-0.03-0.03-0.05-0.04c-2.07-1.9-4.67-1.37-7.08-1.34c-3.49,0.05-6.56-0.5-8.87-3.49c-1.72-2.23-4.21-2.25-6.84-1.99
			c-7.96,0.82-8.9-0.11-8.91-8.26c0-1,0.03-2,0.03-3c-0.02-2.8,1.22-4.41,4.15-4.44c6.66-0.06,12.55-3.1,18.75-4.94
			c0.95-0.28,2.52-0.39,2.6-1.71c0,0,0.01-0.01,0-0.02v-0.01c0.03-0.28-0.03-0.6-0.17-1.01c-1.1-0.89-2.32-1.18-3.58-1.26h-2.31
			c-0.27,0-0.54,0.01-0.81,0.01c-20.36-0.02-40.72-0.01-61.08-0.02c7.33,0,14.66-0.01,21.99-0.01c13.3,0,26.6,0.01,39.9,0.02
			c0.77-0.02,1.55-0.05,2.31,0c13.58,0.01,27.17,0,40.75-0.03c2.35-0.01,4.89,0.38,6.59-2.25c0,0,0.01,0,0.01-0.01
			c0.08-0.12,0.15-0.24,0.23-0.38c0-0.01,0.01-0.03,0.02-0.04c-14.93-0.38-29.84-0.57-44.21-0.67c16.05-0.03,32.75-0.01,49.45,0.32
			c35.14,0.69,70.3,0.11,105.45,0.02c77.8-0.19,155.6-0.05,233.4-0.05c13.27,0,26.49,0.92,39.74,1.67
			c13.13,0.74,26.29,0.73,39.46,0.59c0.52,0,1.04-0.01,1.56-0.02c0.01,0,0.02,0,0.02,0c10.96-0.11,21.94-0.3,32.9-0.19
			c1.17,0.15,2.33,0.07,3.49,0.07c12.45,0.01,24.89-0.24,37.34-0.13c2.28,0.02,4.89-0.35,4.69,3.21c0,0.04-0.01,0.09-0.01,0.13
			c0.47,4.15,2.3,8.01,2.44,12.23c0.19,5.9-0.51,6.61-6.31,6.63c-7.85,0.04-15.71-0.43-23.54,0.21c-0.47,0.04-0.94,0.08-1.41,0.13
			c-3.62,1.25-3.73,4.34-3.76,7.38c-0.1,8.99-0.03,17.99-0.04,26.98c-0.02,11.49,0.01,22.98-0.01,34.47
			c-0.01,3.2-0.51,5.92-4.27,6.89c-3.46,0.9-4.48,3.72-4.61,6.93c-0.23,5.66-0.18,11.32-0.07,16.98c0.16,8.1,4.15,11.88,12.28,11.49
			c3.48-0.17,6.98-0.43,10.45-0.89c2.59-0.85,5.25-0.82,7.91-0.82c19.14,0.01,38.28,0.03,57.419-0.01c2-0.01,4.16,0.28,6.04-1.23
			c0.29-0.22,0.57-0.5,0.841-0.82c-4.641-2.97-9.46-0.95-14.03-2.02c1.979-0.39,3.78-0.61,5.57-0.86c2.42-0.34,6.68,0.91,6.569-2.14
			c-0.08-2.45-4.5-1.58-7.06-1.66c-4.82-0.14-9.65,0.06-14.471-0.1c-1.43-0.05-3.38,0.16-3.689-1.75c-0.36-2.17,1.7-1.99,3.06-2.37
			c0.16-0.04,0.33-0.04,0.5-0.08c1.641-0.32,3.54-0.52,3.63-2.69c0.101-2.2-1.75-2.52-3.399-2.91c-1.32-0.31-2.93-0.18-3.59-1.81
			c1.109-0.93,2.14-0.77,3.109-0.88c3-0.32,5.2-1.71,5.181-4.94c-0.021-3.32-2.681-3.81-5.271-4.07
			c-3.649-0.36-7.29-0.59-10.959-0.11c-1.7,0.22-4.55,1.19-4.72-1c-0.18-2.37,2.74-1.58,4.41-1.58c3.49,0.02,7,0.29,10.47-0.14
			c1.8-0.23,4.04-0.59,4.11-2.82c0.069-2.02-2.07-2.27-3.66-2.73c-0.4-0.11-0.94-0.09-1.04-0.72c1.09-0.84,2.58-0.52,3.71-1.22
			c0.56-0.35,1.37-1.25,0.899-1.42c-2.76-0.99-1.119-5.59-4.89-5.68c5.57-0.18,11.04-0.19,16.51-0.18c3.931,0,8.301-0.85,9.03,5.04
			c0.11,0.92,1.14,1.73,2.271,1.45c0.869-0.22,1.109-1.15,1.3-1.97c0.47-2-0.08-3.98-0.141-5.96c-0.189-6.47-2.529-8.82-8.88-7.98
			c-10.81,1.43-21.58-0.08-32.37,0.15c-1.83,0.04-3.39-0.51-3.6-2.61c-0.19-1.97,0.95-3.12,2.83-3.48c1.77-0.34,3.69-0.16,5.15-1.55
			c0.54-0.64,0.67-1.37,0.47-2.17c-1.43-1.95-3.83-1.8-5.77-2.64c-0.97-0.37-2.01,0.47-2.96-0.18c-0.79-4.16-0.22-8.39-0.35-12.59
			c0.01-1,0.01-2.01,0-3.01c-0.32-4.19,0.4-7.71,5.34-8.7c0.93-0.18,2.12-0.5,2.2-1.67c0.08-1.22-1.13-1.57-2.02-1.89
			c-4.37-1.55-5.44-5.02-5.51-9.13c-0.04-2.5-0.05-5-0.01-7.5c0.15-11.54,3.07-13.6,14.12-10c2.34,1.27,4.71,0.84,7.08,0.09
			c5.71-2.17,11.04-2.09,15.779,2.4c1.65,1.57,3.881,1.81,6.091,1.83c3.17,0.03,6.329,0.02,9.5,0.13
			c3.67,1.05,7.43,0.88,11.189,0.87c2.3-0.31,4.16,0.73,5.97,1.91c1.641-0.02,2.15-1.95,3.66-2.18c4.36-0.32,3.69,3.01,3.721,5.48
			c0.199,16.96-0.07,33.92-0.03,50.88c0,2.07-0.45,4.03-1.891,5.54c-2.63,2.77-2.97,6.12-3.06,9.71
			c-0.19,7.97,0.68,15.97-0.57,23.92c-0.27,1.66-1.62,4.07,0.96,4.6c2.53,0.52,3.051-1.98,3.391-3.99
			c0.279-1.65,0.189-3.32,0.2-4.98c0.029-5-0.091-9.99,0.069-14.98c0.07-1.8,0.05-4.12,2.41-4.34c4.54-0.42,9.09-2.18,13.69,0.19
			c4.239,2.17,6.029,0.77,5.68-3.97c-0.23-3.09-1.9-4.66-4.8-5.56c-5.54-1.73-6.181-2.94-5.45-8.74c0.1-0.83,0.26-1.64,0.26-2.48
			c0.061-1.09,0.03-2.17-0.26-3.24c-0.061-1.04-0.07-2.08-0.08-3.12c-0.08-0.95-0.04-1.9,0.01-2.83c0.01-1.41,0.021-2.78-0.029-4.06
			c0.21,1.27,0.1,2.64,0.029,4.06c-0.01,0.93-0.02,1.87-0.01,2.83c0.08,0.96,0.26,1.92,0.65,2.86c0.39,0.35,0.84,0.45,1.35,0.35
			c2.1-2.56,1.27-5.62,1.25-8.46c-0.03-3.86,1-6.08,5.35-4.94c0.841-0.15,1.551-0.46,1.87-1.34c-0.069-3.82-2.88-4.54-5.79-4.84
			c-3.63-0.37-4.79-2.37-4.56-5.76c0.7-10.14,0.27-20.31,0.28-30.47c-0.03-1.08,0-2.17-0.08-3.26c0.04-2.95,2.18-3.54,4.41-3.59
			c11.25-0.26,22.5-0.19,33.75-0.04c2.25,0.03,3.93,0.85,3.489,3.6c-0.05,0.83-0.04,1.66,0.11,2.49c0.35,1.8,0.7,3.58,0.66,5.44
			c-0.44,18.66-0.11,37.32-0.19,55.99c-0.02,4.21,0.26,8.23-4.52,10.66c-2.69,1.37-1.88,4.35-0.96,6.76
			c0.949,2.46,2.97,1.65,4.68,1.19c2.76-0.74,5.22-0.46,7.54,1.33c2.54,1.96,3.52,0.66,3.67-1.98c0.05-1-0.22-1.99-0.24-2.98
			c-0.229-15.65-0.859-31.29-0.989-46.93c-0.07-8.1,0.76-16.28,0.76-24.44c-0.01-2.33-0.181-4.66-0.37-6.98
			c-0.16-2.01,0.729-3.34,2.74-3.4c2.02-0.07,2.909,1.33,3,3.24c0.05,1.17-0.13,2.33,0,3.5c0.35,3.17,2.069,5.16,5.149,6.02
			c9.09,2.56,14.75-0.87,16.33-10.04c0.33-1.9,1.021-3.13,3.101-3.16c1.899-0.03,3.17,0.81,3.689,2.69
			c0.311,1.13,0.38,2.3,0.39,3.47c0.04,10.33-0.06,20.66,0.051,30.99c0.05,3.65-1.13,5.61-5.061,5.69
			c-3.66,0.08-6.83,1.83-7.359,5.55c-0.71,4.94-2.29,10.27,2.029,14.6c0.36,0.36,0.91,0.37,1.41,0.45
			c0.851-0.34,1.23-1.04,1.47-1.85c0.58-2.83,0-5.61-0.25-8.41c-0.149-1.73,0.101-3.64,2.141-3.91c2.33-0.31,2.41,1.79,2.63,3.4
			c0.36,2.61-0.32,5.32,0.83,7.84c0.479,0.64,1.01,1.09,1.85,0.59c0.53-1.04,0.73-2.11,0.79-3.2c0-0.79-0.02-1.57-0.03-2.37
			c-0.05-1.32-0.13-2.66,0.08-4.01c-0.109,1.35-0.109,2.69-0.08,4.01c0.04,0.8,0.08,1.59,0.03,2.37c0,1.55-0.05,3.08-0.34,4.6
			c-0.229,1.32-0.02,2.66-0.17,3.99c-0.4,3.46,1.35,7.59-2.73,9.96c-3.329,1.93-4.229,4.89-4.239,8.42
			c-0.021,6.32-0.011,12.65-0.021,18.97c0,2.83,0.16,5.68-1.78,8.1c-1.329,1.65-0.529,2.7,1.07,3.49c2.78,1.37,3.97,3.66,3.061,6.57
			c-0.96,3.12-3.91,2.68-6.32,2.68c-8.49-0.03-16.96-0.01-25.4,1.07c-1.89,0.24-4.09-0.23-5.239,2.17c2.72,2.45,5.88,1.66,9.52,1.13
			c-4.17,1.08-7.89,0.26-11.49,0.92c-1.489,0.27-3.8,0.35-3.12,2.45c0.101,0.32,0.271,0.69,0.53,1.12
			c18.86,0.25,37.75-0.14,56.55,1.9c0.48,0.04,0.96,0.1,1.44,0.15c5.95-1.59,7.18-3.22,7.2-10.57c0.09-25.29,0.05-50.58,0.02-75.86
			c-0.01-5.67,0.11-11.31,1.061-16.92c1.439-3.2,4.409-2.53,6.909-2.48c14.051,0.3,28.04-1.7,42.091-1.09
			c1.649,0.08,3.31-0.03,4.84-0.84c0.56-0.32,0.819-0.79,0.77-1.44c-0.39-0.57-0.91-1.1-1.569-1.11
			c-5.011-0.07-9.431-1.18-11.96-6.12c-0.211-0.4-0.891-0.01-1.29,0.29c-2,3.44-4.851,5.68-8.95,5.13
			c-8.3-1.1-16.62-0.77-24.94-0.91c-3.81-0.07-5.72-2.19-6.5-5.69c-0.85-3.8-0.62-7.64-0.53-11.46c0.11-5.45,1.381-6.74,6.921-6.83
			c8.819-0.15,17.659-0.11,26.489-0.02c5.61,0.06,6.37,1.04,6.641,6.89c0.149,3.26-0.84,6.7,1.31,9.7c2.44-0.07,2.22-2.06,2.42-3.56
			c0.32-2.29,0.08-4.61,0.181-6.91c0.239-5.34,1.75-6.83,6.989-6.44c4.771,0.34,9.53,0.26,14.311,0.24
			c1.1,0.01,2.189,0.05,3.29-0.01c5.479,0.49,10.83-1.32,16.29-0.95c1.979,0.14,4.529-0.48,5.159,2.56
			c0.051,1.07,0.101,2.14,0.16,3.21c0.061,1.39,0.17,2.76,1.16,3.88c0.54,0.24,1.05,0.18,1.521-0.2
			c5.489-12.67,5.229-10.92,16.939-9.57c0.66,0.07,1.33,0.07,1.99,0.03c3.04-0.18,5.18,0.77,5.58,4.18
			c0.12,0.96,0.479,1.94,1.7,2.52c2.64-1.37,2.77-5.13,5.739-6.4c3.671-1.56,7.471-1.24,11.271-1.27c3.43-0.02,5.75,1.2,6.14,5
			c0.141,1.38,0.12,3.05,1.96,3.56c0.67-0.34,1.021-0.89,1.261-1.52c2.569-6.85,3.899-7.77,10.899-6.68
			c4.29,0.66,8.59,0.58,12.891,0.65c1.71-0.37,3.439-0.62,5.149-0.77c-1.7,0.49-3.35,1.22-4.92,2.19c-0.35,2.6,1.58,3,3.39,3.51
			c6.99,0.39,13.99,0.2,20.98,0.1c1.14-0.04,2.27,0.08,3.4-0.05c3.329,0.13,6.569-0.56,9.859-0.83c5.15-0.43,8.07,2.23,7.45,7.26
			c-0.61,4.92,1.49,7.49,5.83,8.87c0.78,0.17,1.56,0.17,2.35,0c1.12-0.28,2.2-0.95,3.36-0.85c6.43,0.57,12.85,0.66,19.31,0.19
			c2.79-0.21,3.561,1.89,3.82,4.22c0.16,1.12,0.41,2.2,1.35,2.96c1.78,0.03,2.53-1.14,3.04-2.6c0.74-4.78,0.32-9.59,0.351-14.39
			c0.06-12.69,0.54-13.11,13.09-11.69c3.22,0.36,5.16,1.88,5.87,5.07c0.359,1.63,0.28,3.6,2.13,4.41c0.93-0.44,1.24-1.03,1.44-1.66
			c2.369-7.58,2.369-7.54,10.529-7.92c2.04-0.09,4.4,0.45,5.67-1.98c-1.569-2.34-4.069-1.89-6.31-1.95
			c-7.96-0.21-15.9-0.16-23.87-0.74c-0.63-0.05-1.26-0.08-1.9-0.1h-1.229c-0.98-0.06-1.99-0.06-2.98,0h-0.22
			c-4.64,0.16-9.31,0.8-13.91,1.69c-1.81,1.83,0.48,3.88-0.319,5.75c1.27,3,1.8,5.96-0.45,8.77c-2.74,2.49-12.63,4-15.83,2.48
			c-1.19-0.57-1.48-1.6-1.88-2.71c-1.78-4.92,0.66-8.51,3.77-11.76c1.061-1.1,1.42-1.69,0.101-2.6
			c-10.011-1.92-20.25-1.49-30.311-1.55c-11.07-0.07-22.14-0.11-33.21-0.15c-0.64,0-1.29-0.01-1.939-0.01
			c-23.971-0.07-47.94-0.1-71.9-0.1c-0.01,0.01-0.021,0.01-0.03,0c-0.01,0.01-0.02,0.01-0.029,0c-0.011,0.01-0.021,0.01-0.04,0
			c-0.011,0.01-0.021,0.01-0.04,0c-0.011,0.01-0.021,0.01-0.04,0c-0.011,0.01-0.021,0.01-0.03,0c-0.01,0.01-0.021,0.01-0.03,0
			c-0.01,0.01-0.02,0.01-0.03,0c-0.01,0.01-0.02,0.01-0.029,0c-0.01,0.01-0.021,0.01-0.03,0c-0.12,0.01-0.19,0.01-0.32,0
			c-32.409,0-64.819,0.05-97.229,0.08c-17.75-0.02-35.51-0.03-53.271-0.04c-23.12,0-46.25,0.01-69.38,0.01
			c-23.38,0-46.77,0.01-70.16,0.02c-6.69,0-13.38,0.01-20.07,0.01c-78.96,0.42-157.91-0.04-236.88,0.6
			c-107.9,0.87-215.82,0.25-323.74,0.32c-0.77,0.12-1.62,0.05-2.42,0c-1.51,0-3.02,0-4.54,0.01c-8.17,0.06-16.34,0.03-24.51,0.03
			c8.17-0.02,16.34-0.03,24.51-0.03c1.24-0.01,2.49-0.02,3.73-0.03c0.26,0,0.53,0.01,0.81,0.02h2.42c1.12-0.16,2.08-0.73,2.56-2.39
			c-15.47-0.6-30.97,0.65-46.44-0.44c-3.91-0.28-5.34,2.35-5.31,5.73c0.05,5.65,0.58,11.3,0.59,16.95
			c0.06,35.15,0.3,70.29,0.98,105.43c0.57,29.47,0.73,58.95,1.04,88.43c0.35,32.31,0.71,64.62,1,96.94
			c0.37,41.47,0.67,82.94,1.01,124.42c0.32,39.97,0.67,79.949,1,119.93c0.33,40.81,0.67,81.62,1,122.42
			c0.33,41.15,0.67,82.29,0.98,123.43c0.09,12.69,0.05,12.69,12.67,12.69c417.95,0,835.899,0,1253.85,0.01
			c2.33,0,4.66,0.05,6.99,0.17c2.88,0.15,4.4-1.24,4.64-4.01c0.181-1.99,0.181-4,0.21-6c0.24-19.8-1.449-39.64,0.92-59.4
			c0.44-3.649-0.39-4.26-3.989-3.96c-11.23,0.95-12.261-0.199-12.261-11.54c-0.01-23.5-0.01-47-0.01-70.5
			c0-10.79,0-10.79,10.92-11.67c4.221,1.53,4.37-1.22,4.36-4.14c-0.061-16.55-0.07-33.09-0.19-49.64
			c-0.02-2.721,0.061-5.29,2.061-7.381C1288.38,600.118,1288.38,599.528,1287.79,598.938z M1013.021,19.938
			c1.31,0.23,2.62,0.42,3.92,0.59c2.399,0.19,4.76,0.43,7.09,0.63c6.06,0.32,11.74,0.04,16.46-0.56
			c-5.07,1.33-10.63,1.06-16.46,0.56c-2.32-0.12-4.69-0.32-7.09-0.63c-4.03-0.32-8.141-0.5-12.271-0.13
			C1007.4,19.618,1010.23,19.458,1013.021,19.938z M77.65,21.898c-20.63,0.05-40.16,0.18-57.22-0.12
			C37.3,21.828,56.9,21.768,77.65,21.898z M28.871,237.638c1.78-0.14,3.56-0.22,5.34-0.26
			C32.44,237.428,30.65,237.568,28.871,237.638z M1030.471,809.068c2.409-0.28,5.17,0.311,7.939,0.05
			C1035.66,809.528,1033,809.788,1030.471,809.068z M51.791,206.328c0.16,0.05,0.31,0.11,0.45,0.16c0-0.29-0.01-0.59-0.01-0.88
			c-0.38,0.02-0.76,0.04-1.14,0.04C51.281,205.928,51.491,206.228,51.791,206.328z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#231F20"
              d="M206.814,10.944c17.311-0.232,34.62-0.51,51.931-0.689
			c35.129-0.364,70.26-0.662,105.39-1.014c31.298-0.313,62.596-0.678,93.894-0.992c56.112-0.563,112.225-1.093,168.337-1.658
			c30.467-0.308,60.935-0.676,91.403-0.992c33.63-0.349,67.261-0.671,100.891-1.003c33.961-0.335,67.922-0.668,101.883-1
			c34.3-0.335,68.6-0.665,102.898-1.001c33.631-0.33,67.262-0.662,100.893-1c32.633-0.328,65.265-0.747,97.897-0.969
			c22.142-0.149,44.284-0.028,66.791-0.028c-3.59,3.127-3.65,7.597-3.235,12.167c0.14,1.542-0.529,2.525-1.972,3.019
			c-2.05,1.4-4.384,1.402-6.732,1.402c-21.496-0.003-42.992,0.022-64.488-0.034c-2.232-0.006-4.7,0.518-6.74-1.856
			c1.576-2.103,3.843-1.487,5.78-1.492c21.327-0.052,42.654-0.029,63.982-0.132c-151.608,1.404-303.22,0.349-454.83,0.559
			c-63.318,0.044-126.635,0.067-189.951-0.003c-80.958,0.059-161.915-0.094-242.873,0.076
			c-60.468,0.128-120.935,0.022-181.402,0.076c-2.688,0.003-5.573,0.376-8.337-2.27C201.375,10.603,204.274,11.854,206.814,10.944z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#504E4E"
              d="M206.814,10.944c-2.014,1.99-4.877-0.11-7.575,1.763
			c2.701,0,4.811,0,6.92,0c140.534,0.013,281.067,0.025,421.601,0.041c0.999,0,1.997,0.041,2.996,0.063
			c-0.979,2.978-3.599,2.25-5.715,2.41c-5.073,0.385-10.2-0.575-15.252,0.555c-72.999,0.002-146.009-0.773-218.995,0.18
			c-126.791,1.656-253.579,0.187-380.367,0.731c-2.032,0.009-5.841,1.521-5.892-1.272c-0.048-2.672,3.827-1.618,5.977-1.639
			c11.999-0.118,24-0.03,35.999-0.09c1.759-0.008,3.696,0.646,5.242-0.848c5.724-1.675,11.574-0.495,17.352-0.8
			c37.904-1.996,75.845-0.633,113.761-1.362C190.844,10.522,198.832,10.843,206.814,10.944z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#535152"
              d="M820.837,12.838c89.498-0.023,178.995-0.047,268.492-0.07
			c62.498-0.016,124.996-0.037,187.495-0.032c2.113,0,4.298-0.384,6.34,1.641c-25.416,0-50.832,0-76.247,0
			c0.003,0.469,0.006,0.938,0.01,1.407c25.629,0,51.258,0,76.888,0c-1.631,1.663-1.011,3.764-1.016,5.691
			c-0.04,18.114-0.007,36.229-0.047,54.344c-0.005,2.118,0.464,4.328-0.723,6.326c-2.206-1.74-1.604-4.239-1.613-6.482
			c-0.065-16.479-0.02-32.96-0.051-49.439c-0.004-1.8,0.306-3.638-0.731-5.418c-0.487,5.574-0.356,11.174-0.463,16.767
			c-0.046,2.439,0.566,5.041-1.253,7.192c-3.155-0.483-2.493-3.116-2.602-5.046c-0.271-4.811-0.069-9.639-0.109-14.46
			c-0.056-6.58-0.301-6.989-6.621-6.876c-16.608,0.298-33.232-0.824-49.832,0.831c-5.938,0.592-11.967,0.25-17.945,0.091
			c-4.124-0.109-5.581,1.593-5.487,5.618c0.174,7.477,0.074,14.961,0.034,22.442c-0.013,2.313,0.202,4.675-0.928,6.842
			c-3.758,1.014-2.852-2.164-2.947-3.721c-0.368-5.979-0.124-11.979-0.156-17.971c-0.014-2.663,0.025-5.327-0.011-7.989
			c-0.074-5.569-1.967-8.129-7.314-7.558c-5.398,0.577-10.853,0.67-16.216,1.602c-2.068,0.657-4.153,0.637-6.253,0.189
			c-11.496-0.968-22.992-0.899-34.49-0.037c-1.585,0.218-3.137,0.303-4.297-1.112c-1.727-1.515-3.805-0.707-5.722-0.809
			c-7.905-0.42-15.804-0.043-23.751-0.733c-7.036-0.61-14.353,0.158-21.43,1.625c-4.382,1.504-8.758,1.589-13.128-0.049
			c-1.044-1.175-2.448-0.809-3.743-0.858c-27.427-1.034-54.864-1.013-82.304-1.001c-49.956,0.022-99.913-0.035-149.87-0.061
			C821.94,14.899,820.101,14.743,820.837,12.838z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#595657"
              d="M1300.929,590.795c-0.046-1.328-0.133-2.658-0.133-3.986
			c-0.006-192.856-0.006-385.711,0-578.568c0-1.495,0.094-2.991,0.145-4.487c2.473,0.728,1.19,2.746,1.273,4.141
			c0.369,6.264-0.433,12.558,0.438,18.815c0.048,1.496,0.138,2.992,0.138,4.488c0.007,158.885,0.006,317.769,0,476.654
			c0,1.328-0.088,2.658-0.135,3.986c-0.732,1.766-0.365,3.619-0.367,5.426c-0.028,22.953,0.015,45.906-0.094,68.857
			C1302.188,587.645,1303.516,589.852,1300.929,590.795z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#393637"
              d="M1277.54,45.136c0.073-7.959,0.096-15.918,0.262-23.875
			c0.033-1.604-0.973-4.233,1.835-4.319c3.289-0.101,2.12,2.813,2.133,4.504c0.149,20.232,0.19,40.466,0.26,60.699
			c0.047,30.147,0.064,60.296,0.176,90.443c0.015,4.037-1.208,8.364,4.314,10.8c2.85,1.257,0.003,4.855,0.235,7.387l-0.383,0.039
			l-0.384-0.021c-1.794-1.125-1.531-3.645-3.356-4.812c-3.871-2.476-8.766-0.312-9.335,4.231c-0.269,2.143,0.159,4.371-0.509,6.442
			c-0.371,1.148-0.575,3.602-2.795,1.337c-2.332-6.631-2.643-12.733,3.997-17.352c2.742-1.907,2.184-5.053,2.191-7.825
			c0.056-19.993,0.026-39.987,0.028-59.98s-0.021-39.987,0.025-59.98C1276.241,50.233,1275.767,47.479,1277.54,45.136z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2E2A2B"
              d="M1281.545,661.868c3.383,0.65,2.818-2.848,4.351-4.141
			c5.499,5.484,12.039,10.002,15.849,17.055c-0.64,1.938-0.454,3.941-0.456,5.922c-0.019,17.242-0.027,34.482,0.006,51.723
			c0.007,3.809-0.094,7.609-0.363,11.406c-2.918,2.006-1.96-0.738-1.973-1.748c-0.08-6.32-0.04-12.643-0.04-18.965
			c0-13.973,0.018-27.947-0.023-41.92c-0.004-1.617,0.451-3.324-1.145-5.902c0,24.047,0,47.049,0,70.775
			c-2.743-1.727-1.945-3.686-1.949-5.24c-0.056-20.627-0.323-41.262,0.137-61.879c0.138-6.203-4.077-8.855-7.584-11.613
			C1286.104,665.571,1283.778,663.745,1281.545,661.868z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#231F20"
              d="M1300.931,743.833c-1.745-1.414-1.072-3.389-1.076-5.133
			c-0.046-19.66-0.058-39.318,0.019-58.979c0.007-1.752-1.135-4.211,1.871-4.939c0.912,0.988,0.546,2.209,0.554,3.332
			c0.127,19.953,0.239,39.906,0.321,59.859C1302.628,740.063,1303.118,742.35,1300.931,743.833z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#242121"
              d="M1300.929,590.795c1.496-1.74,0.823-3.834,0.827-5.762
			c0.05-22.658,0.015-45.316,0.066-67.975c0.004-1.746-0.711-3.689,0.833-5.221c0.047,26.826,0.15,53.65,0.104,80.477
			c-0.013,7.344-2.172,9.086-9.553,8.215c-1.86-0.221-3.634-0.174-5.437,0.176c0.006-0.592,0.013-1.182,0.019-1.771
			C1295.954,601.647,1300.131,599.061,1300.929,590.795z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2B2728"
              d="M1302.652,26.71c-1.877-4.127-0.425-8.485-0.888-12.71
			c-0.373-3.408,0.868-6.945-0.824-10.246c-0.137-1.919-1.428-2.825-3.813-3.677c4.866-0.574,5.601,2.144,5.63,5.147
			C1302.827,12.386,1302.7,19.548,1302.652,26.71z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#CDCDCC"
              d="M821.71,158.028c-2.52,3.23-2.47,7.12-2.479,11.14
			c-0.021,7.79,0.6,15.6-0.4,23.37c-6.25,5.45-8.939,13.72-15.17,19.22c-1.45,1.29-2.37,3.2-4.33,3.85
			c-0.17,0.06-0.35,0.11-0.54,0.15c-6.13-3.92-9.359-0.28-12.87,4.35c-3.88,5.14-7.689,11.51-13.08,14
			c-5.229,2.42-12.42,0.61-18.739,0.61c-52.62,0.01-105.23,0.02-157.85,0c-13.48-0.01-26.97-0.17-40.45-0.17
			c-3.09,0.01-6.51-0.45-8.2,3.24c-0.73,5.79-0.15,11.6-0.32,17.39c-0.05,2.07-0.28,3.41-0.89,4.07c-0.7,0.79-1.94,0.63-4.02-0.37
			c-5.34-2.56-10.57-2.09-16.03-1.11c-4.14,0.74-7.64-0.11-9.12-4.7c0.94-2.46,2.67-4.42,2.63-7.53c-0.05-3.37,3.45-3.97,7.19-1.3
			c-1.16-1.78-1.11-4.39-3.45-4.51c-2.11-0.11-2.91,2.13-3.49,3.75c-0.88,2.42-1.74,2.18-3.94,1.34c-2.26-0.86-5.64-2.28-6.85,1.98
			c-1.67,4.1-4.57,5.1-8.54,3.39c-0.46-0.47-0.73-1.04-0.97-1.65c-0.77-1.95,0.67-4.92-1.33-5.9c-2.62-1.28-5.74-0.7-8.56,0.38
			c-1.63,0.48-3.04,1.65-4.85,1.57c-3.06-0.98-3.12,1.15-3.25,3.57c1.13-1.02,2.33-1.5,3.71-1.3c4.79,2.32,6.91,5.6,5.85,9.07
			c-1.09,3.54-4.31,5.24-9.33,4.55c-7.42-1.03-13.62,1.63-19.39,5.91c-1.3,0.96-2.56,2.34-4.21,2.04c-0.46-0.08-0.96-0.29-1.49-0.68
			c-0.09-7.98-0.34-15.96-0.23-23.93c0.12-8.67-0.31-9.3-9.21-9.29c-38.92,0.05-77.84,0.14-116.76,0.19
			c-10.31,0.01-20.62-0.1-30.92-0.16c-11.79-0.69-23.59-0.32-35.38-0.19c-2.06,0.02-3.55-0.29-4.57-1.09
			c-1.08-0.84-1.62-2.24-1.76-4.38c-0.17-2.8-0.64-5.6-1.33-8.33c-1.29-1.69-2.76-3.17-4.66-4.25c-5.07-2.9-6.14-2.74-9.68,1.67
			c-3.2,1.99-3.29,3.58-0.34,6.93c0.57,0.44,0.99,0.98,1.18,1.67c0.16,1.46,0.44,2.89,0.88,4.28c0.3,0.96,0.29,1.71,0.01,2.28
			c-0.37,0.78-1.22,1.24-2.42,1.5c-4,0.26-5.13-1.84-5.07-5.54c0.16-10.32,0.11-20.65,0.09-30.98c-0.02-5.7-1.35-6.56-6.49-4.03
			c-3.41,1.67-6.73,3.51-9.99,5.45c-2.53,1.5-3.6,3.64-3.56,6.85c0.23,16.81,0.2,33.63,0.19,50.45c0,5.65-0.8,6.22-6.37,6.13
			c-5.81-0.1-11.62-0.4-17.41,0.63c-5.62,1-7.35,2.88-7.35,8.66c0,1,0,2,0.11,2.99c0.87,7.5,0.88,7.51,8.49,7.51
			c13.15,0,26.31-0.02,39.47,0.01c5.42,0.01,6.85,1.32,6.85,6.74c-0.02,46.63-0.15,93.26-0.16,139.891
			c0,36.31,0.15,72.609,0.18,108.919c0.03,38.811,0.02,77.61-0.01,116.42c0,3.681,0.28,7.631-5.1,8.03
			c-5.69,0.42-7.46-1.13-7.58-7.01c-0.12-5.6-0.16-11.2-0.23-16.8c0.02-0.05,0.05-0.09,0.08-0.141c0-0.01,0.01-0.029,0.01-0.04
			c0.13-0.46,0.21-0.92,0.24-1.359c0.37-2.391-0.6-4.46-2.06-6.4c1.41,1.95,2.28,4.03,2.06,6.4c-0.06,0.43-0.16,0.87-0.32,1.319
			c0,0.011-0.01,0.021-0.01,0.03v0.19c-2,3.27-0.79,6.899-0.99,10.34c-0.26,4.81-0.14,9.64-0.19,14.46c-0.42,0.1-0.84,0.2-1.26,0.29
			c-9.72,2.22-19.44,2.2-29.17,0.01c-0.42-0.09-0.84-0.189-1.26-0.29v-8.6c-0.66,0.26-1.02,0.31-1.05,0.43
			c-1.42,7.11-1.42,7.12-9.06,7.12c-32.92,0-65.84-0.06-98.76,0.06c-5.24,0.021-10.34,0.561-10.32-7.71c0-0.18,0-0.37,0.01-0.55
			c-0.08,0.18-0.15,0.36-0.21,0.54c-1.27,3.73,1.63,6.15,0.08,8.78c-0.31,0.57-0.68,1.01-1.11,1.34c-1.24,0.99-2.87,1.09-4.49,1.12
			c-7.46,0.14-14.92,0.07-22.37,0.03c-2.19-0.011-3.74-0.46-4.6-1.65c-0.66-0.87-0.94-2.16-0.85-3.98
			c0.49-9.909-0.38-19.859,0.45-29.77c1.45-0.09,2.91-0.2,4.36-0.25c1.76-0.06,1.14-1.03,0.76-1.92c-0.15-0.2-0.27-0.41-0.36-0.61
			c-0.38-0.92-0.17-1.87,0.32-2.689c2.79-4.71,2.5-9.9,2.5-15.07c-0.02-30.66-0.08-61.32,0.04-91.99
			c0.03-7.52-0.64-14.97-1.44-22.42c-0.98-9.09-1.09-9.069,7.46-12.09c0.94-0.34,1.86-0.74,2.81-1.03c3.58,0.11,5.69-3.62,9.25-3.58
			c1.22,2.57,2.75,3.051,4.88,0.851c0.65-0.66,1.64-0.98,2.66-0.96c0.47,0.08,0.88,0.26,1.26,0.56c0.88-0.53,0.14,4.76,2.26,0.55
			c0.96-1.92,2.57-0.779,3.32,0.771c1.03,2.11,2.37,3.59,4.96,3.229c3.11-0.42,4.44-1.819,2.75-5.06c-2.62,4.71-3.07,4.77-5.84,0.53
			c-0.59-0.9-1.08-1.67-2.24-1.551c-3.21,0.351-4.02-1.529-3.99-4.239c2.13-3.55,3.89-7.13,4.61-11.39
			c0.79-4.65,4.27-7.58,9.07-7.96c6.3-0.5,12.63-0.32,18.95-0.07c4.14,0.16,6.95,2.5,8.12,6.3c2.41,7.84,6.15,14.33,14.55,17.05
			c2.24,0.73,2.2,3.11,2.71,5c0.49-0.1,0.2,2.09,1.14,0.43c0.6-1.069,1.15-2,2.42-2.279c2.77-0.38,3.54,2.67,5.72,3.31
			c3.8,1.84,6.6-1.3,9.76-2.22c1.57-0.46,1.2-2.75-0.17-4.09c-1.58,0.159-1.75,1.489-2.26,2.46c-1.01,1.87-2.21,3.5-4.63,2.76
			c-2.3-0.7-2.66-2.74-2.75-4.811c-0.05-1.159,0.05-2.319,0.51-3.42c0.84-1.72,2.5-2.649,3.75-3.97c3.33-3.529,6.41-7.1,7.11-12.279
			c0.58-4.341,2.97-7.601,7.34-9.311c7.22-2.819,10.28-8.189,10.16-16.02c-0.24-16.3,0.11-32.61-0.71-48.9
			c-0.23-4.63-2.31-8.27-6.8-10.15c-1.46-3.12-4.4-3.41-7.28-3.82c-2.91-1.65-2.8-4.67-3.3-7.39c-1.05-5.69-3.3-10.45-9.34-12.36
			c-3.54-4.15-5.7,0.17-8.36,1.39c-2.32-0.04-4.62,0.79-6.95,0.1c-1.53-0.65-1.91-2.2-2.97-4.04c-1.71,4.64-5.87,7.94-4.3,13.59
			c1.53-1.25,0.92-2.99,1.68-4.19c0.89-1.41,1.71-1.45,2.5,0.09c0.82,3.06,0.73,6.13,0.07,9.2c-1.09,2.87-3.42,3.79-6.23,4.24
			c-5.12,0.82-10.24,1.16-15.41,0.96c-0.61,0.01-1.21,0.01-1.81,0.02c-11.26,0.32-22.54-0.49-33.8,0.45
			c-1.81,0.14-3.26-0.42-4.07-2.17c0.05-0.66,0.21-1.27,0.45-1.83c0.05-0.05,0.09-0.11,0.13-0.17c0.18-0.33,0.34-0.66,0.46-1
			c0.65-1.06,1.48-2.02,2.15-3.07c1.42-2.21,0.98-4.67,0.83-7.06c-0.06-0.94-0.29-2.23-1.59-1.86c-1.13,0.33-0.54,1.66-0.67,2.56
			c-0.19,3.16,0.5,6.41-0.72,9.43c-0.22,0.37-0.43,0.76-0.59,1.17c-0.33,0.39-0.75,0.46-1.25,0.2c-1.9-2.67-1.27-5.75-1.19-8.65
			c0.12-3.71-0.87-5.66-5.04-5.1c-2.26,0.31-4.63,0.33-6.85-0.56c-0.63-3.19-0.29-5.89,3.54-6.73c2.09-0.47,4.12-0.24,6.11,0.34
			c-6.33-1.93-7.03-1.6-11.65,4.13c-0.87,1.07-2.03,1.65-3.17,2.29c-3.36,1.99-1.74,4.95-1.07,7.38c0.41,1.47,1.94-1.37,3.09-0.13
			c2.01,1.95,1.79,4.29,1.19,6.71c-0.63,1.48-1.49,2.79-2.81,3.75c-4.43,1.46-8.97,0.74-13.49,0.63c-0.21,0-1.14-0.77-0.81,0.4
			c0.07,0.25,0.42,0.46,0.55,0.8c-2.12,2.53-3.13,5.36-2.87,8.72c0.12,1.5,0.16,3.1-0.79,4.38c-0.18,0.25-0.4,0.48-0.66,0.7
			c-0.72-1.98-2.05-2.53-4.19-2.51c-5.55,0.06-6.64-1.14-6.67-6.59c-0.08-13.63-0.06-27.27-0.24-40.9
			c-0.12-8.63-0.59-17.25-0.76-25.88c-0.15-7.29,1.62-9.03,9.11-9c33.92,0.17,67.83,0.37,101.75,0.62
			c8.9,0.06,11.69-2.66,9.88-11.42c-0.98-4.71-0.75-8.09,3.74-10.46c1.15-0.61,1.26-1.87,1.33-3.06v-0.01
			c-0.22-1.24-0.41-2.47-0.58-3.71c-1.5-10.74-1.21-21.49,0.08-32.31v-0.03c0.91-7.53-0.3-15.21,0.51-22.81
			c-0.35-7.12,0.43-14.26-0.45-21.39c-0.57-4.67,3.82-5.68,6.47-7.93c0.24-0.14,0.48-0.28,0.73-0.41c3.22-1.81,6.69-3.16,9.73-5.3
			c0.04-0.03,0.09-0.06,0.13-0.09c0.21-0.11,0.43-0.21,0.64-0.32c9.65-4.86,19.35-9.61,28.83-14.79c0.03-0.02,0.06-0.04,0.09-0.07
			c0.27-0.2,0.56-0.39,0.85-0.57c0-0.01,0-0.01,0-0.01c1.77-1.08,3.78-1.72,5.61-2.73c0.2-0.1,0.39-0.21,0.58-0.33
			c0.05-0.02,0.09-0.05,0.14-0.08c4.75-1.64,8.79-4.7,13.35-6.7c3.77-1.64,4.31-4,2.48-7.56c-4.5-8.71-8.66-17.6-13.24-26.27
			c-11.81-22.34-23.18-44.9-34.6-67.43c-4.36-8.63-9.79-16.72-13.08-25.89c-0.04-0.12-0.09-0.24-0.13-0.36
			c-0.02-0.13-0.04-0.26-0.06-0.39c-0.06-0.58-0.05-1.15,0.04-1.73c0.02-0.13,0.05-0.26,0.08-0.39c0-0.02,0.01-0.03,0.02-0.05
			c0.12-0.43,0.29-0.81,0.52-1.15c0.19-0.3,0.43-0.56,0.7-0.79c0.3-0.27,0.65-0.5,1.04-0.69c1.75-0.79,3.58-0.36,5.39-0.37
			c18.06-0.12,36.11,0.89,54.17-0.64c8.28-0.7,16.62-0.08,24.93-0.06h0.91c0.1-0.02,0.21-0.04,0.31-0.06
			c5.07-0.9,9.9-0.98,13.09,4.19c0.72,0.47,1.45,0.94,2.12,1.44c0.01,0.01,0.01,0.01,0.02,0c0.01,0.01,0.01,0.01,0.02,0.01
			c1.84,1.41,3.18,3.09,2.56,5.93c-0.81,3.75-3.63,5.53-7.08,6.19c-17.73,3.38-19.33,22.08-13.07,33.68
			c1.84,3.39,4.84,5.43,8.4,6.72c1.88,0.69,3.84,1.12,5.74,1.73c4.01,1.29,5.87,4.26,6.19,8.23c0.32,4.08-1.68,7.01-5.16,8.98
			c-1.02,0.58-2.18,0.83-3.29,1.19c-14.08,4.53-20.02,20.12-12.53,32.97c2.67,4.58,6.76,7.43,11.89,8.41
			c5.34,1.01,8.66,4.11,10.36,9.02c1.86,5.42,5.66,7.97,11.19,8.41c17.25,1.37,34.49,3,51.8,0.89c4.78-0.59,9.22-2.73,14.17-2.75
			c2.44,0,4.4-2,5.13-4.27c3.66-11.18,13.85-13.56,23.01-17.21c3.33-1.33,5.8,0.46,7.11,3.7c1.06,2.65,1.41,5.48,1.91,8.27
			c1,5.72,4.3,9.44,10.07,10.31c11.58,1.76,23.22,1.88,34.78-0.22c5.09-0.93,8.1-4.1,9.23-9.28c1.3-5.96,0.77-11.95,0.92-17.93
			c0.05-2-0.17-4.04,0.48-5.96c1.17-3.44,4.65-4.1,7-1.38c0.65,0.76,1.17,1.65,1.47,2.6c1.88,5.94,6.1,8.91,12.11,9.66
			c5.38,0.68,8.48,3.77,10.28,8.88c3.05,8.67,6.14,10.71,15.2,10.69c10.46-0.02,20.94,1.18,31.35,0.73
			c9.48-0.42,18.97-0.34,28.44-0.89c4.84-0.28,8.11-3.12,9.32-7.76c1.58-6.01,5.6-9,11.25-10.86c13.65-4.51,18.84-19.12,11.42-31.59
			c-1.93-3.24-4.6-5.68-8.2-7c-1.87-0.69-3.78-1.31-5.57-2.22c-4.66-2.37-6.66-5.71-6.41-10.31c0.27-4.7,3.42-6.51,7.33-7.67
			c15.64-4.62,17.8-14.34,14.35-31.03c-1.28-6.24-7.09-8.9-12.97-10.48c-4.93-1.33-8.89-3.86-10.71-8.86
			c-0.67-1.86-1.43-3.86-0.07-5.58c0.11-0.14,0.24-0.28,0.38-0.42c0.13-0.13,0.27-0.25,0.43-0.37c7.4-1.61,14.89-0.44,22.33-0.79
			c4.14-0.2,8.29,0.39,12.41-0.33c14.41-0.05,28.82-0.06,43.22-0.16c4.47-0.04,4.1,2.1,2.48,4.98c-0.29-0.14-0.58-0.27-0.87-0.39
			c-5.77-2.48-11.89-2.36-18.02-2.18c-7.95,0.23-10.19,2.87-9.52,10.89c0.3,3.5,1.8,7.08,0.12,10.49c-0.17,0.35-0.38,0.7-0.62,1.05
			c-2.52,0.56-3.3,2.06-3.28,4.67c0.15,21.43,0.11,42.86,0.23,64.29c0.03,4.22-0.12,7.81-5.91,7.23c-2.57-0.26-2.83,1.89-2.88,3.74
			c-0.2,6.47-0.47,12.95-0.39,19.42c0.1,7.8,3.97,9.58,10.99,9.38c3.6-0.09,7.26-0.57,10.9-0.77c9.09-0.71,18.2-0.22,27.31-0.29
			c12.76-0.1,25.53-0.12,38.29,0.02c1.78,0.02,3.54-0.18,5.29-0.44c3.641-0.52,7.261-1.27,10.971-0.72
			c5.06,0.38,10.12,0.09,15.18,0.13c3.11-0.14,6.21-0.07,9.32-0.02c5.26,0.13,10.52,0.08,15.77,0.01c3.11-0.11,6.21-0.08,9.311,0
			c5.04,0.04,10.08,0.15,15.119-0.09c3.57-0.1,7.131-0.27,10.58,0.81c0.32,0.1,0.631,0.21,0.94,0.33
			c17.06,0.01,34.17-1.84,51.16,1.42c1.92,0.37,3.96,0.14,5.95,0.18C822.32,157.288,822,157.658,821.71,158.028z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FBEEB7"
              d="M817.782,193.567c0-9.886,0.271-19.782-0.129-29.652
			c-0.168-4.166,0.969-6.359,5.031-6.973c4.871-0.249,6.252-2.996,6.238-7.603c-0.074-24.737,0.083-49.476,0.14-74.214
			c0.016-7.124-0.171-14.254,0.754-21.345c45.243,5.313,90.646,1.479,135.966,2.039c13.91,0.172,27.939-0.122,41.895-0.718
			c49.086-2.095,98.19-0.4,147.283-0.908c6.336-0.065,12.574-1.47,18.926-1.046c2.311,0.153,4.688-0.163,6.801,1.14
			c1.643,1.544,1.472,3.602,1.479,5.563c0.05,13.389,0.076,26.778-0.003,40.168c-0.038,6.416-0.945,6.916-6.981,4.326
			c-3.546-2.422-7.434-3.363-11.767-3.294c-4.921,0.078-8.958,0.97-9.939,6.699c-0.082,0.477-0.547,0.787-1.031,0.957
			c-0.362,0.007-0.813-0.096-0.852-0.373c-0.968-7.144-5.955-7.744-11.665-7.234c-0.99,0.089-2.005,0.106-2.991,0
			c-4.363-0.469-6.432,1.135-6.353,5.834c0.033,1.976,0.325,5.75-2.515,5.56c-2.675-0.18-2.095-3.488-1.906-5.852
			c0.427-5.341,0.244-5.563-5.372-5.491c-4.278,0.055-8.682-0.794-12.751,1.329c-0.858,0.536-1.558,1.341-2.604,1.563
			c-6.736-4.707-13.967-3.4-21.22-1.721c-0.394,0.29-0.797,0.564-1.197,0.845c-0.849,0.574-1.703,0.962-2.713,0.308
			c-6.249-4.045-12.764-2.852-19.301-0.945c-0.952,0.748-1.315,2.028-2.394,2.665c-3.29,0.525-5.809-1.465-8.587-2.613
			c-6.526-2.153-13.029-2.63-19.487,0.209c-0.585,1.077-0.709,2.419-1.834,3.178c-6.201-7.032-13.903-5.469-21.62-3.757
			c-0.275,0.447-0.517,0.898-0.704,1.387c-0.437,3.979-0.118,7.956-0.2,11.928c-0.075,3.608,0.459,7.303-2.358,10.347
			c-0.918,0.991-0.629,2.531-0.625,3.848c0.021,8.943-0.15,17.888,0.093,26.818c0.11,0.495,0.288,0.975,0.55,1.414
			c1.671,2.604,5.887,3.259,5.658,7.335c-0.982,5.555-5.061,10.182-4.808,16.105c2.139,4.46,5.657,7.678,9.606,10.396
			c2.364,1.627,5.087,1.375,7.651,0.209c4.805-2.185,9.854-2.899,15.083-2.715c1.829,0.064,3.693,0.103,5.467-0.351
			c10.563-2.7,21.118-1.3,31.733-0.321c13.925,1.285,27.886,0.934,41.843,0.13c14.618-0.842,29.269-0.903,43.867,0.032
			c5.83,0.374,11.181,0.077,16.474-2.448c4.081-1.947,5.44-1.064,5.685,3.438c0.225,4.15,0.12,8.315,0.05,12.473
			c-0.101,5.903-0.54,6.237-6.418,5.39c-0.785-0.192-1.478-0.567-2.076-1.103c-1.562-2.198-3.318-3.522-6.048-1.635
			c-1.63,1.127-3.772,0.889-5.488,1.852c-2.782,1.232-5.501,2.588-8.197,4.002c-1.208,0.634-1.184,1.301-0.533,2.253
			c0.406,0.858,0.526,1.759,0.479,2.69c-1.147,2.689,0.981,4.929,1.04,7.458c-0.083,2.509-0.889,4.661-3.014,6.168
			c-3.016,1.421-5.997,0.111-8.987-0.107c-2.715-0.131-4.08,1.039-3.734,3.872c-0.14,0.704-0.527,1.259-1.023,1.752
			c-2.547,1.864-2.787,3.994-1.605,6.965c1.959,4.926,4.251,9.132,9.429,11.582c4.216,1.995,5.151,6.7,6.713,10.714
			c1.321,1.969,3.099,3.534,4.658,5.295c6.182,5.18,12.826,7.979,20.904,4.479c2.23-0.967,3.456,0.306,3.679,2.514
			c0.28,2.776,0.195,5.586,0.777,8.345c-0.387,4.255-3.365,5.753-6.909,5.373c-8.235-0.885-16.406-0.787-24.625,0.051
			c-2.89,0.294-5.59-0.59-7.042-3.533c-0.788-4.396,0.025-8.864-0.268-11.369c0.718,4.287-1.467,10.782,1.566,16.775
			c6.283,2.594,12.763,1.058,19.155,0.955c1.808-0.029,3.605-0.54,5.438-0.604c10.062-0.351,14.896,4.643,13.755,14.729
			c-0.694,6.144-0.315,12.275-0.564,18.406c-0.213,5.244,1.761,9.011,6.713,11.027c3.679,3.728,7.751,7.21,8.521,12.873
			c0.488,1.526,1.203,2.932,2.252,4.159c1.947,2.214,2.015,5.17,3.081,7.732c1.802,2.786,2.221,6.119,3.615,9.068
			c0.282,0.652,0.503,1.326,0.7,2.009c0.465,1.631,1.08,3.21,1.623,4.822c0.175,0.503,0.294,1.012,0.395,1.532
			c0.242,2.943,2.115,6.023-0.723,8.709c-5.72,1.527-11.552,0.441-17.327,0.72c-5.23,0.253-5.827,0.722-5.849,6.283
			c-0.056,14.112-0.051,28.225-0.004,42.337c0.021,5.915,0.634,6.469,6.623,6.585c1.826,0.036,3.652,0.007,5.479-0.007
			c2.05,0.026,4.102,0.039,6.152,0.055c2.037,0.075,4.092,0.113,5.49,1.969c0.466,4.727-0.658,9.001-3.654,12.736
			c-2.621,0.146-2.584-2.174-3.28-3.693c-1.748-3.815-4.744-4.937-8.583-4.06c-3.649,0.834-6.589,2.522-6.363,6.958
			c0.164,3.228-1.164,5.751-3.425,7.939c-1.921,1.857-2.998,4.012-1.602,6.686c1.067,2.043,2.631,3.465,5.086,3.348
			c2.736-0.129,4.648-1.564,5.793-4.037c0.82-1.771,0.974-3.921,2.89-5.076c2.165-0.817,4.299-0.904,5.857,1.045
			c1.568,1.961,0,3.723-0.749,5.484c-2.321,5.465-4.849,10.811-8.908,15.262c-2.545,3.248-4.102,7.693-9.718,6.887
			c-1.816-0.262-1.409,1.883-1.456,3.072c-0.144,3.615-0.15,7.236-0.21,10.855c-0.799,10.555-0.184,21.125-0.336,31.686
			c-0.098,6.805-0.833,7.248-6.927,3.77c-0.574-0.328-1.118-0.707-1.707-0.994c-2.696-0.615-4.035-3.297-6.501-4.271
			c-0.736-0.299-1.397-0.701-1.895-1.336c-1.639-3.508-2.288-7.305-2.606-11.078c-0.583-6.902,1.658-12.621,7.614-16.76
			c6.839-4.752,8.363-16.102,3.367-22.92c-1.394-1.902-3.343-2.613-5.519-2.982c-2.019-0.48-3.58-1.672-4.93-3.182
			c-2.915-2.666-5.946-2.07-9.015-0.309c-3.787,1.828-5.348,5.33-6.74,8.963c-0.405,0.629-0.935,1.127-1.549,1.541
			c-2.496,1.436-2.765,3.93-2.729,6.355c0.055,3.709-1.57,5.791-5.213,6.348c-1.23,0.318-2.484,0.42-3.749,0.398
			c-3.685,0.021-5.921-2.313-7.954-4.945c-1.696-2.197-3.311-4.477-6.26-5.129c-0.452-0.104-0.896-0.244-1.334-0.395
			c-2.59-0.887-5.096-2.002-7.63-3.035c-2.025-0.826-3.727-0.713-5.303,0.818c-4.306,0.465-7.853,2.25-10.313,5.924
			c-3.256,2.969-2.891,6.43-1.364,10.039c0.761,0.559,1.523,1.121,2.563,0.893c1.259-0.414,1.96-2.242,3.688-1.455
			c3.456,2.939-0.406,5.488-0.438,8.244c-0.548,0.436-1.176,0.535-1.85,0.449c0.078,0.568,0.666,0.719,0.935,1.145
			c0.725,1.15,1.759,1.934,3.06,2.363c3.453,1.424,7.079,1.6,10.73,1.537c3.068-0.053,4.82,1.232,5.632,4.355
			c0.98,3.766,1.666,7.855,5.623,10.018c0.745,0.723,1.322,1.566,1.795,2.482c3.321,5.059,7.76,8.574,13.728,10.006
			c2.396,0.576,5.018,0.584,6.846,2.646c1.127,2.426,0.692,5.076,1.108,7.604c-2.416,8.061,4.321,11.549,8.856,14.816
			c4.222,3.039,10.198,1.91,15.021-1.244c2.236-1.461,4.016-3.455,6.096-5.094c1.187-0.934,2.605-1.971,4.062-1.291
			c1.427,0.666,0.639,2.377,0.772,3.631c0.226,2.119-0.398,4.293,0.381,6.385c0.681,14.895-0.114,15.787-15.17,15.68
			c-5.453-0.039-10.913,0.461-16.376-0.48c-3.57-0.617-5.28-2.424-5.337-5.922c-0.041-2.486,0.014-4.973,0.025-7.457
			c0.023-4.627-0.242-4.857-5.201-4.445c7.195,6.273,2.878,14.145,3.035,21.395c-5.346,3.02-12.656,2.371-16.553-1.453
			c-1.121-2.09,0.153-4.668-1.309-7.535c-1.469,2.734-0.142,5.109-1.09,7.109c-2.416,4.055-6.496,3.342-10.225,3.516
			c-1.979,0.094-3.991,0.143-5.913-0.568c-0.558-0.246-1.002-0.627-1.341-1.133c-1.764-3.939,0.574-8.17-0.849-12.137
			c-0.022-2.244-0.171-4.502,0.126-6.717c-0.289,2.521-0.272,5.045,0.028,7.6c0.806,6.867-2.433,10.486-9.456,9.725
			c-7.807-0.844-15.603-0.738-23.411-0.781c-3.646-0.021-7.263,0.059-10.915,0.654c-6.991,1.139-10.314-1.842-10.377-8.992
			c-0.106-12.139,0.019-24.279-0.051-36.42c-0.046-7.945-2.049-9.867-10.1-10.166c-2.932-0.107-6.07,0.529-8.474-1.928
			c-1.791-2.23-0.024-5.469-2.147-7.709c0.92,1.857,1.527,3.672,0.796,5.688c-1.127,1.918-2.98,2.617-5.009,2.76
			c-6.62,0.463-13.158-0.096-19.572-1.879c-1.144-1.143-1.417-2.641-1.364-4.119c0.329-9.205-1.271-18.492,1.101-27.607
			c0.505-1.941-0.624-3.52-0.94-5.281c-0.481-7.297-0.968-14.576,0.555-21.834c1.15-5.486-0.793-10.875-0.804-16.356
			c-0.046-24.599,0.046-49.198,0.009-73.796c-0.04-26.101-0.21-52.202-0.139-78.304c0.01-3.8-0.303-7.771,3.292-10.472
			c1.129-0.711,2.4-0.927,3.704-0.925c1.917,0.004,4.311-0.486,5.337,1.399c1.021,1.876-1.469,2.933-2.143,4.481
			c-1.419,3.674-3.025,7.298-3.205,11.324c-0.246,5.52,1.514,8.606,6.561,10.854c2.099,0.935,3.32,2.047,2.689,4.471
			c-4.959,3.465-5.413,8.412-5.33,13.987c0.256,17.318-0.635,34.682,0.287,51.955c1.048,19.642,0.537,39.258,0.767,58.883
			c0.111,9.519-0.048,9.521,9.739,9.521c30.313,0,60.627,0.002,90.94-0.003c1.831,0,3.673,0.105,5.491-0.127
			c3.224-0.411,5.012-1.987,5.068-5.556c0.105-6.652-0.473-13.32,0.359-19.952c0.646-1.957,2.318-2.658,4.039-3.396
			c9.036-3.876,13.37-11.305,11.054-20.816c-2.67-10.965-2.412-21.674-0.096-32.618c2.493-11.782-0.821-16.999-12.368-21.004
			c-0.962-0.333-1.861-0.731-2.365-1.703c-1.35-7.078-0.361-14.222-0.621-21.329c-0.198-5.419-0.26-5.644-5.632-5.661
			c-18.252-0.059-36.504-0.003-54.756-0.03c-8.329-0.012-9.186-1.032-8.011-9.323c0.072-0.51-0.018-0.983-0.145-1.455
			c-1.711-4.99-5.068-7.556-10.488-7.386c-3.272,0.103-6.02-1.343-7.15-4.428c-1.197-3.266-3.224-5.67-5.68-7.985
			c-1.216-1.146-3.557-2.73-0.674-4.734c2.424-0.854,4.942-0.618,7.428-0.622c17.312-0.023,34.623,0.006,51.936-0.02
			c8.179-0.012,8.546-0.372,9.88-8.438c0.521-3.152-0.065-6.294-0.051-9.439c-0.29,2.319,0.374,4.609,0.238,6.936
			c-0.293,4.986-1.679,6.807-6.567,6.908c-13.646,0.285-27.299,0.305-40.944-0.013c-4.635-0.107-6.172-1.833-6.423-6.554
			c-0.327-6.15,0-12.317-0.151-18.474c-0.142-5.74-1.042-6.664-6.751-6.789c-15.478-0.34-30.957-0.007-46.436,0.021
			c-5.317,0.01-6.405,1.092-6.609,6.509c-0.6,15.927,0.161,31.837,0.482,47.759c0.285,14.098-0.556,28.248-0.772,42.379
			c-0.019,1.17-0.438,2.287-1.306,3.169c-1.608,1.095-3.457,1.15-5.286,1.155c-21.645,0.05-43.288,0.078-64.932-0.011
			c-1.166-0.005-2.329-0.102-3.465-0.379c-1.491-0.377-2.662-1.232-3.569-2.456c-1.608-2.9-0.709-5.765,0.055-8.689
			c4.42-16.909,0.703-32.038-11.019-44.794c-9.22-10.035-19.349-19.227-28.808-29.049c-11.741-12.191-23.958-23.924-35.141-36.665
			c-3.151-3.59-6.924-6.613-9.672-10.644c-2.274-3.337-6.38-3.21-9.793-4.358c-1.46-1.074-1.607-2.581-1.401-4.206
			c1.6-4.143,5.088-6.892,7.691-10.295c1.318-1.723,2.742-3.359,3.38-5.47c1.105-3.653-0.288-5.728-4.065-6.245
			C821.738,194.8,819.393,195.626,817.782,193.567z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FDFDFD"
              d="M1277.54,45.136c-0.011,43.525-0.04,87.052,0.006,130.578
			c0.004,3.141-0.068,5.558-3.746,7.003c-3.661,1.439-4.54,4.98-3.908,8.798c0.377,2.278,0.68,4.569,1.017,6.854
			c0.595,0.938,0.606,1.877,0.008,2.818c-0.334,1.787-0.691,3.571-0.997,5.363c-0.484,2.833,0.405,5.462,3.307,5.745
			c4.76,0.466,4.249,3.618,4.262,6.698c0.088,21.312,0.202,42.623,0.224,63.936c0.036,36.137,0.016,72.274,0.001,108.411
			c-0.001,3.715,1.024,8.079-5.232,7.816c-1.511-0.064-1.735,1.625-1.729,3.085c0.035,7.658,0.05,15.319-0.059,22.976
			c-0.036,2.563,1.159,3.772,3.522,3.771c2.922-0.003,3.506,1.451,3.504,4.104c-0.05,47.287-0.014,94.576,0.002,141.863
			c0.002,8.541-0.265,8.813-8.562,8.813c-22.813,0.002-45.628,0.004-68.441,0c-6.66-0.002-7.604-0.879-7.625-7.311
			c-0.067-21.621,0.611-43.246-0.245-64.865c-0.209-5.299,2.401-9.539,4.938-13.83c3.17-10.711,11.832-18.801,14.564-29.583
			c2.309-9.112,6.811-17.499,8.252-26.857c0.356-2.313,0.832-4.558,0.354-6.893c-0.063-0.831,0.028-1.641,0.264-2.439
			c0.982-1.998,1.198-4.12,1.193-6.316c-0.018-7.665-0.043-15.332,0.016-22.996c0.017-2.228-0.447-4.28-1.356-6.271
			c-0.282-0.797-0.453-1.618-0.534-2.456c0.026-7.541-1.645-14.746-4.355-21.74c-0.403-0.967-0.609-1.983-0.749-3.014
			c-3.317-9.33-5.793-19.056-12.52-26.79c-1.138-1.308-1.393-2.993-2.106-4.485c-0.898-2.317-2.438-4.243-3.813-6.277
			c-3.779-4.813-4.908-10.244-4.9-16.343c0.116-83.783,0.069-167.566,0.112-251.351c0.002-3.091-0.732-6.376,1.426-9.165
			c0.212-10.801,0.586-21.605-0.203-32.396c-0.212-2.901,0.166-4.424,3.854-4.52c25.092-0.647,50.174-1.587,75.282-1.521
			c3.626,0.009,4.291,1.165,4.344,4.516C1277.034,28.952,1275.922,37.087,1277.54,45.136z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill={
                roomId === '5ab35a13-f298-46d7-b2da-9772c54193e3' ? HIGHLIGHT_COLOR : INACTIVE_COLOR
              }
              d="M1106.789,593.743c3.646-0.01,7.309,0.191,10.929-0.117
			c1.977-0.17,4.429-0.104,5.428-2.623c1.092-0.672,2.186-0.67,3.281-0.01c1.099,2.688,3.551,2.652,5.808,2.703
			c3.459,0.076,6.924-0.086,10.387-0.146c2.401,10.605,5.917,13.025,20.017,11.959c9.313-0.705,18.596-0.447,27.89-0.518
			c21.789-0.162,43.579-0.16,65.369-0.209c8.632-0.021,9.911,1.225,9.89,9.629c-0.051,19.627,0.488,39.281-0.374,58.873
			c-0.725,16.455,1.51,32.838,0.192,49.254c-1.813,0.721-2.793-0.672-3.795-1.652c-5.553-5.443-12.02-9.967-16.547-16.443
			c-0.446-0.639-1.014-1.213-2.848-1.252c8.115,8.273,17.262,14.475,23.313,23.635c0.021,26.164,0.051,52.33,0.056,78.494
			c0.001,5.967-0.612,6.59-6.626,6.586c-69.16-0.035-138.32-0.127-207.48-0.016c-3.204,0.006-4.659-1.881-6.714-3.334
			c-1.012-0.266-1.718-0.857-2.008-1.889c-2.284-3.063-4.563-6.088-7.809-8.291c-2.196-1.492-3.351-0.83-4.208,1.334
			c-3.123-5.203-8.417-8.334-12.25-12.854c-1.311-1.545-3.608-2.584-2.974-5.25c0.054-2.641-1.501-4.332-3.481-5.693
			c-6.17-4.244-8.6-10.287-8.582-17.543c0.047-19.299,0.12-38.596,0.129-57.895c0.006-11.811,0.06-23.625-0.186-35.432
			c-0.171-8.24,2.597-14.795,9.219-19.883c4.758-3.656,4.645-5.49-0.111-9.146c-3.473-2.668-4.533-6.881-7.005-10.174
			c-0.477-0.615-0.604-1.303-0.419-2.055c1.322-2.549,3.487-4.412,5.604-6.182c5.196-4.344,10.168-8.861,14.277-14.287
			c0.977-1.289,2.173-2.496,3.831-3.025c0.511-0.078,1.012-0.043,1.509,0.092c4.301,1.611,7.826,4.363,10.926,7.66
			c2.214,2.352,4.164,2.766,6.737,0.34c3.854-3.637,8.673-4.748,13.864-4.201c3.671,0.389,7.273,0.143,10.912-0.447
			c5.981-0.967,11.918,0.18,17.867,0.68c6.927,0.582,12.674-0.838,14.4-8.828C1105.381,594.782,1106.024,594.157,1106.789,593.743z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#CDCDCC"
              d="M1028.775,600.895c-6.542,8.873-14.985,15.84-23.154,23.082
			c-2.282,0.371-3.709-1.049-5.118-2.471c-5.595-5.645-11.205-11.271-16.83-16.887c-0.934-0.932-1.376-2.133-2.21-3.08
			c1.076,2.604,3.379,4.219,5.258,6.15c4.267,4.393,8.717,8.607,12.83,13.148c1.522,1.68,3.444,3.283,2.423,6.023
			c-1.947,3.256-4.979,3.947-8.529,3.939c-17.148-0.031-34.298,0.055-51.446,0.098c-1.694,1.264-3.67,1.447-5.68,1.441
			c-9.282-0.029-18.566,0.195-27.845-0.109c-6.154,0.039-12.314,0.121-18.469-0.045c-6.218-0.17-11.308-2.514-14.511-8.145
			c-2.127-9.33-1.678-18.695-0.379-28.072c2.716-2.629,3.24-5.883,3.229-9.551c-0.081-26.307,0.085-52.613,0.059-78.92
			c-0.001-0.998-0.015-1.998,0.042-2.996c0.361-6.34,3.147-8.688,9.36-7.895c7.149,0.912,10.07-1.559,9.283-8.783
			c-1.161-10.645-0.528-21.286-0.636-31.926c-0.064-6.425,1.282-7.598,7.589-7.61c7.993-0.017,15.989-0.136,23.977,0.067
			c2.758,0.07,6.156-0.646,7.908,3.771c-1.583-4.602-5.053-3.71-7.736-3.79c-7.819-0.231-15.65-0.056-23.477-0.097
			c-2.745-0.014-5.834,0.756-7.327-2.722c-1.456-3.395-0.137-6.158,1.979-8.722c1.901-2.303,4.225-4.279,5.424-7.105
			c2.606-4.799,7.149-7.751,11.183-11.063c4.654-3.822,8.713-8.006,11.828-13.134c1.092-0.423,2.319-0.159,3.396-0.636
			c4.535-2.007,5.376-5.732,1.997-9.32c-2.832-3.007-5.79-5.881-9.046-8.463c-9.054-7.18-11.644-16.858-10.46-27.905
			c0.104-0.97,0.302-1.989,1.438-2.39c24.874-0.01,49.749-0.019,74.624-0.028c2.308,2.522,1.59,5.661,1.581,8.569
			c-0.072,24.274-0.518,48.558,0.155,72.819c0.476,17.114,0.675,34.226,0.924,51.339c0.092,6.318,1.291,7.262,7.494,6.656
			c2.265-0.221,4.596-1.152,6.765,0.4c1.755,2.182,3.567,4.219,0.515,6.947c-0.707,0.631-0.37,2.523-0.373,3.836
			c-0.022,8.441-0.005,16.883,0,25.324c-1.835,2.025-4.313,1.238-6.556,1.529c-2.651,0.342-6.272-1.287-7.588,2.063
			c-1.431,3.641-2.466,7.684-0.149,11.559c0.922,1.541,2.326,2.916,1.729,4.988c-1.496,2.674-4.2,3.322-6.825,3.5
			c-7.149,0.486-14.439-0.768-20.324,2.166c4.836-0.08,10.759-0.129,16.68-0.057c3.726,0.045,7.35,0.455,10.109,3.402
			c0.51,0.607,0.9,1.291,1.222,2.01c2.238,4.789,0.531,9.105-4.749,12.18c-1.306,0.76-2.867,0.799-4.108,1.635
			c-1.447,1.67-0.15,3.488-0.276,5.219c0.069,1.141-0.017,2.289,0.152,3.422c0.48,3.203,1.882,4.486,4.903,2.287
			c5.038-1.717,9.813,0.293,14.679,1.01c6.322,0.021,12.097,2.176,17.81,4.572C1025.247,596.397,1028.498,597.493,1028.775,600.895z
			"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill={
                roomId === '8a9949bd-c8f2-4bde-9a9e-94e824a2e147' ? HIGHLIGHT_COLOR : INACTIVE_COLOR
              }
              d="M907.845,630.792c11.385,0.035,22.769,0.072,34.153,0.107
			c-0.11,12.582-0.221,25.162-0.331,37.744c-2.27,2.219-4.687,0.324-7.02,0.316c2.228,1.043,5.03,0.09,7.098,1.859
			c2.892,9.998,2.206,20.195,1.648,30.379c-0.054,0.975-0.439,1.926-0.555,2.902c-0.754,6.434-0.744,6.436,5.824,6.881
			c1.008,0.752,1.509,1.863,1.465,3.012c-0.271,6.951,1.979,14.086-1.46,20.811c-7.329,0.047-14.657,0.135-21.985,0.129
			c-6.161-0.006-6.16-0.055-7.289-5.65c-2.624,4.75-1.892,6.141,3.142,6.404c5.805,0.305,11.615-0.172,17.435,0.68
			c2.705,0.395,3.689,0.953,3.684,3.791c-0.039,21.998,0.019,43.996,0.164,65.992c0.031,4.768-2.438,5.822-6.633,5.813
			c-38.167-0.082-76.333-0.047-114.499-0.047c-25.167,0-50.333,0.006-75.5-0.004c-6.527-0.004-7.408-1.072-7.358-7.586
			c0.086-11.162-0.045-22.326,0.001-33.488c0.015-3.52-1.51-5.293-5.061-5.242c-2.306,0.691-4.595,0.58-6.875-0.135
			c-5.658-2.807-8.767-7.98-12.064-12.99c-3.532-3.049-4.627-6.926-4.614-11.521c0.051-18.189,1.229-36.34,1.559-54.518
			c0.302-0.504,0.604-1.006,0.905-1.51c3.344-1.641,3.385-5.645,5.731-8.014c1.205-0.574,2.808-0.766,3.498-1.846
			c2.87-4.494,6.968-6.168,12.142-6.344c2.668-0.09,3.12-2.711,3.524-4.836c1.098-5.777,0.83-11.625,0.444-17.434
			c-0.642-9.688,1.415-12.207,10.884-12.211c50.134-0.021,100.268-0.102,150.4,0.086
			C903.595,634.334,906.183,633.801,907.845,630.792z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#ABBBD2"
              d="M738.997,155.657c-3.096,0.02-6.191,0.038-9.287,0.057
			c-5.239-1.951-10.485-1.659-15.735-0.044c-3.098,0.012-6.195,0.022-9.294,0.033c-4.825-2.124-9.674-1.518-14.528-0.122
			c-27.556,0.006-55.111,0.012-82.667,0.018c1.661-1.174,3.554-0.613,5.331-0.617c19.819-0.044,39.637-0.026,59.456-0.024
			c1.804,0,3.608,0,5.412,0c-5.384-2.175-11.246,0.099-17.069-2.443c5.428-3.4,11.199-2.264,16.562-3.727
			c-7.56,0-15.121-0.054-22.68,0.03c-2.468,0.027-4.732,0.308-4.866-3.235c-0.141-3.739,2.178-3.939,4.905-3.82
			c0.83,0.036,1.665,0.011,2.497-0.021c0.885-0.034,1.733-0.303,1.699-1.346c-0.029-0.922-0.732-1.504-1.646-1.541
			c-1.493-0.061-2.994,0.056-4.487,0.002c-2.14-0.078-2.921-1.439-2.7-3.353c0.204-1.76,1.394-2.599,3.156-2.435
			c1.149,0.107,2.314,0.627,3.421,0.503c2.108-0.237,3.229-1.629,3.115-3.784c-0.111-2.089-1.371-2.773-3.372-2.669
			c-4.581,0.238-9.173-1.17-13.775,0.359c-1.96,0.651-4.047-0.027-5.436-1.581c-0.48-0.538-0.432-2.102,0.007-2.802
			c1.215-1.941,3.26-1.742,5.304-1.623c5.422,0.317,10.902,1.146,16.38-0.936c-1.542-2.729-3.953-1.397-5.876-1.786
			c-1.28-0.259-2.95-0.333-2.803-2.071c0.099-1.171,1.429-1.692,2.635-1.876c1.724-0.261,3.565,0.506,5.646-0.789
			c-2.685-0.203-4.937-0.374-7.668-0.582c1.564-2.567,3.952-1.044,5.636-2.184c-1.592-2.578-6.077-0.247-7.186-4.468
			c9.686,0,19.22,0.025,28.755-0.021c2.012-0.01,1.939,1.238,2.006,2.637c0.082,1.746-0.535,3.733,1.608,5.125
			c0.977-3.817-0.278-7.53-0.141-11.222c0.134-3.622-3.973-3.617-6.646-3.272c-7.313,0.94-14.625,0.245-21.929,0.52
			c-4.12,0.155-8.171-0.938-12.378-0.137c-2.654,0.507-4.925-0.807-4.724-4.236c0.182-3.105,1.615-5.202,4.861-5.043
			c1.84,0.09,3.583,0.54,5.225-0.615c1.941-3.505,3.229-7.182,2.623-11.261c-0.538-3.625-2.229-6.126-6.341-6.188
			c-2.32-0.035-4.663-0.181-6.138-2.429c-0.201-8.192,0.17-8.673,8.476-10.3c-2.926-0.78-5.678-1.113-6.979-3.561
			c-1.168-2.199-1.506-4.682-1.349-7.202c0.298-4.776-0.485-9.572,0.531-14.35c1.267-5.959,2.37-6.938,8.292-6.454
			c2.477,0.203,4.833,0.875,7.079,1.938c0.673,1.648-0.27,4.316,2.622,4.571c2.577,0.227,2.708-1.771,2.891-3.611
			c0.032-0.322,0.189-0.615,0.375-0.892c6.18-2.514,12.039-3.229,17.027,2.563c2.013,2.337,4.926,1.289,7.479,1.429
			c2.462,0.134,4.934,0.107,7.4,0.151c2.854,3.615,0.579,8.039,1.896,11.918c0.814,0.24,0.657-0.5,0.68-0.83
			c0.61-9.091,0.605-9.091,9.55-10.069c2.356-0.319,4.44-0.146,5.017,2.764c2.001,3.58,0.371,7.37,0.824,10.295
			c0.433-3.915-0.371-8.703,0.977-13.313c5.441-2.127,7.023-1.074,7.015,4.236c-0.027,17.435,0.039,34.869,0.139,52.303
			c0.02,3.414,0.182,6.759-3.541,8.563c-2.156,1.045-1.682,3.035-1.688,4.809c-0.027,7.649-0.037,15.3,0.004,22.949
			c0.011,2.003,0.171,3.999-1.016,5.769c-0.427,0.637-1.366,1.369-0.213,1.945c1.241,0.622,1.817-0.56,2.045-1.457
			c0.281-1.108,0.271-2.307,0.272-3.466c0.01-6.491,0.077-12.984-0.063-19.472c-0.082-3.798,1.387-5.643,5.313-5.253
			c2.324,0.23,4.602,0.044,6.928-0.431c3.474-0.709,6.467,0.627,8.884,3.396c1.941-7.002,1.475-7.885-4.647-8.863
			c-3.666-0.586-5.007-3.059-5.007-6.347c0-2.13,0.549-4.26,0.852-6.39c1.604-2.02,4.002-2.184,6.243-2.466
			c3.047-0.384,4.166-2.032,4.288-4.96c0.137-3.27-0.585-6.562,0.259-9.808c-0.354-3.941-3.271-4.128-6.139-3.932
			c-4.352,0.3-5.778-2.31-5.423-5.805c1.09-10.717-0.041-21.464,0.73-32.173c3.028-2.085,6.537-2.378,9.986-2.193
			c6.582,0.354,8.297,2.514,7.909,9.204c-0.483,8.327,1.221,12.211,6.443,14.674c-3.138-0.869-4.697-3.109-5.405-6.134
			c-0.499-2.131-0.448-4.29-0.439-6.447c0.033-7.861,0.782-8.607,8.507-8.529c6.894,0.069,6.911,0.098,6.892,10.618
			c0.518-3.434-0.843-7.613,0.736-11.627c2.917-2.603,5.987-2.652,9.187-0.565c1.139,1.094,1.838,2.486,1.751,4.007
			c-1.188,20.799-0.229,41.613-0.441,62.418c-0.038,3.652-0.801,5.641-4.823,6.682c-4.661,1.205-0.174,5.039-1.902,7.865
			c7.034-3.853,9.441-3.609,13.963,0.739c-0.407-16.686-1.079-33.631-1.143-50.579c-0.042-11.284,1.253-22.559-0.127-33.863
			c-0.395-3.228,2.356-4.191,5.013-4.208c2.929-0.019,4.829,1.585,4.477,4.69c-0.351,3.1-1.786,6.828,2.022,8.235
			c4.549,1.68,9.624,1.68,14.063-0.593c2.668-1.366,2.689-4.521,1.966-7.189c-0.847-3.123-0.016-4.985,3.271-5.188
			c3.126-0.193,6.896-0.756,6.938,4.063c0.116,12.999-0.009,26,0.051,38.999c0.016,3.409-2.39,2.464-4.188,2.557
			c-3.357,0.174-6.924,0.015-8.477,3.856c-1.975,4.885-0.462,13.026,2.675,15.517c3.604,0,7.375-0.531,9.861,3.066
			c0.045,2.329,0.059,4.658,0.143,6.985c0.075,2.075-0.766,3.938-2.705,4.257c-4.11,0.673-4.156,3.495-4.153,6.557
			c0.009,7.654-0.07,15.309,0.041,22.961c0.045,3.061-0.197,5.727-4.104,6.655c3.714-0.024,5.753,1.38,6.575,4.861
			c1.29,5.457-0.085,7.604-5.688,7.555c-10.795-0.094-21.563,0.224-32.293,1.81c3.465,0,6.93,0,11.076,0
			c-2.811,3.33-6.066,2.872-9.173,3.293c-2.266,0.308-5.233-1.948-6.807,1.498c-4.011,0.811-8.053,0.355-12.086,0.271
			C748.646,153.83,743.818,153.824,738.997,155.657z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill={
                roomId === '8b7c02c1-01f5-4fcb-98ea-f93e28030f18' ? HIGHLIGHT_COLOR : INACTIVE_COLOR
              }
              d="M194.954,663.776c10.562-0.004,21.124-0.008,31.685-0.012
			c-0.239,4.227,1.962,5.336,5.869,5.148c7.189-0.344,8.616,1.076,8.214,8.135c-0.442,7.738,0.842,15.502-0.644,23.248
			c-0.429,2.232,0.111,4.861,2.325,6.52c0.107,0.146,0.214,0.291,0.321,0.438c0.395,2.689,3.203,2.563,4.981,4.096
			c-2.551,2.736-6.494,0.389-9.51,2.648c10.594,1.316,10.594,1.316,10.594,12.102c0,6.666,0.003,13.332-0.001,19.998
			c-0.004,6.916-0.7,7.973-7.813,7.383c-10.809-0.896-21.61-0.166-32.444-0.139c12.639,2.455,25.595,0.418,38.621,2.338
			c-7.128,2.479-7.143,7.49-6.764,13.785c0.751,12.449,0.411,24.971,0.301,37.461c-0.039,4.441-3.228,5.049-6.995,5.035
			c-24.498-0.088-48.996-0.045-73.494-0.045c-23.165,0-46.329,0.014-69.494-0.01c-6.046-0.006-6.861-0.873-6.91-7.006
			c-0.029-3.666-0.035-7.334,0.002-11c0.046-4.656,2.32-7.23,6.978-8.232c5.623-1.209,11.28-0.648,16.914-0.996
			c1.897-2.232,0.84-4.824,1.013-7.264c-0.154,2.023,0.829,4.197-0.942,6.002c-7.309,0.006-14.618,0.012-21.928,0.02
			c-1.408-1.211-1.636-2.92-1.52-4.543c0.454-6.326-1.605-12.85,1.442-18.975c-3.747-12.088-1.252-24.484-1.668-36.725
			c-0.526-15.463-0.246-30.955-0.279-46.434c-0.014-6.357,1.578-7.965,7.873-7.969c32.459-0.014,64.919-0.051,97.378,0.053
			C192.826,668.85,195.312,668.18,194.954,663.776z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#7CABC5"
              d="M136.84,121.748c-0.01,0.01-0.02,0.03-0.03,0.04
			c-0.02-0.01-0.04-0.03-0.06-0.04c0-0.01,0.01-0.02,0.01-0.03C136.791,121.728,136.82,121.738,136.84,121.748z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#7CABC5"
              d="M116.61,115.028c-0.62,0.53-1,1.12-1.22,1.75
			c-0.06-0.39-0.15-0.78-0.29-1.16c-0.1-0.19-0.23-0.37-0.37-0.54C115.13,114.798,115.73,114.758,116.61,115.028z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#7CABC5"
              d="M124.5,74.868c-0.11,0.12-0.22,0.22-0.35,0.31
			c-0.12,0.09-0.25,0.16-0.39,0.22c-6.98,2.28-13.62,5.24-19.58,9.55c-0.17,0.11-0.33,0.23-0.49,0.35c-0.03,0.03-0.06,0.07-0.1,0.1
			c-0.02,0.02-0.04,0.04-0.05,0.06c-0.26,0.26-0.5,0.54-0.76,0.79c-0.53,0.51-1.12,0.95-1.93,1.06c-0.16,0-0.32-0.01-0.48-0.03
			c-0.19-0.03-0.38-0.08-0.55-0.14c0.29-0.06,0.58-0.17,0.88-0.33c0.78-0.77,1.55-1.55,2.33-2.32c6.28-4.61,12.81-8.67,20.74-9.71
			C124.031,74.788,124.281,74.818,124.5,74.868z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#7CABC5"
              d="M166.831,141.718v0.09h-0.1c0.02-0.02,0.03-0.04,0.05-0.06
			C166.8,141.738,166.811,141.728,166.831,141.718z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#7CABC5"
              d="M94,85.588c0,0.28-0.04,0.64-0.15,1.09
			c0.24,0.1,0.48,0.22,0.72,0.35c0.04-0.15,0.07-0.31,0.09-0.47c0-0.02,0.01-0.04,0.01-0.06C94.4,86.188,94.181,85.878,94,85.588z
			 M194.3,153.958c-1.66-3.46-3.84-6.62-5.52-10.07c-12.37-25.45-25.96-50.28-38.34-75.72c-1.02-2.09-1.98-4.23-3.39-6.1
			c-2.52-3.36-5.66-5.67-10.07-5.55c-4.33,0.13-8.65-0.01-12.98-0.03c-2.55,0-5.05,0.4-6.98,2.2c-1.6,1.5-2.75,1.92-4.46,0.01
			c-2.4-2.67-5.72-2.83-8.78-1.57c-1.58,0.65-1.99,1.73-2.05,2.96c-0.31-1.8-1.19-3.24-3.13-4c-0.14-0.06-0.29-0.11-0.45-0.16
			c-0.45-0.14-0.96-0.26-1.52-0.33c-3.95-0.09-8.06-1.44-11.73,1.15c-0.09,0.06-0.18,0.12-0.27,0.19
			c-3.39,2.17-7.08,0.49-10.62,0.76c-2.95,0.22-5.38,1.55-5.05,4.25c1.22,9.94-3.06,20.3,2.31,29.86c0.37,0.66,0.07,0.88-0.21,1.13
			c-3.76,3.32-1.61,7.8-2.33,11.72c0.08,0.04,0.16,0.07,0.24,0.09c3.35,1.36,5.4-0.92,7.54-2.9c0.06-0.13,0.12-0.26,0.17-0.38
			c0.1-0.19,0.2-0.37,0.31-0.53c1.91-3.06,5.32-1.56,8.06-1.73c3.45-0.22,6.67-0.3,7.43-4.63c0.03-0.12,0.06-0.25,0.09-0.37
			c0.05-0.19,0.09-0.37,0.13-0.56c0.08-0.41,0.14-0.83,0.19-1.25c0.02-0.24,0.05-0.49,0.08-0.73c0.12-0.9,0.32-1.77,0.74-2.59
			c0.05-0.09,0.1-0.19,0.14-0.28c-0.35-0.1-0.72-0.16-1.1-0.18c-0.97,0.76-1.15,1.73-0.76,2.85c-0.08,0.72-0.17,1.45-0.25,2.17
			c-0.86,4.01-3.58,4.74-7.16,4.21c-1.61-0.24-3.28-0.1-4.92-0.13c-2.65-0.04-4.01,1.17-3.79,3.91c-2.25,1.05-4.28,2.64-6.84,2.99
			c1.72-4.24,3.43-9.22,2.53-13.07c-1.89-8.14-0.99-16.14-1.25-24.19c-0.23-7.27,0.7-7.87,7.85-8.19c2.38-0.11,5.37,0.99,6.73-2.24
			c4.24-0.08,8.49-0.16,12.72-0.25c5.3,2.04,2.35,6.95,3.55,10.51c2.21-1.44,1.68-3.64,1.51-5.48c-0.35-3.65,1.74-4.08,4.55-4.12
			c4.2-0.07,5.7,3.22,7.79,6.98c1.51-6.55,5.75-7.12,10.54-6.72c2.15,0.18,4.4,0.67,6.45,0.27c9.06-1.8,13.45,2.86,17.24,10.45
			c14.32,28.66,29.16,57.07,43.85,85.54c1.26,2.45,1.84,4.95,1.75,7.66c-1.01,0.47-1.65,1.53-3.05,1.43
			c-1.28-0.09-2.69-2.43-3.76-0.25c-0.77,1.56,0.4,3.21,1.27,4.6c0.44,0.71,0.98,1.35,1.53,1.99c-0.03,0.03-0.06,0.05-0.09,0.07
			c-9.48,5.18-19.18,9.93-28.83,14.79c-0.24-3.06-2.03-5.59-3.95-8.74c-0.91,5.22-3.96,8.08-7.95,10.06
			c-0.08-0.09-0.16-0.18-0.25-0.27c-1.34-0.6-2.72-0.37-3.81,0.36c-7.33,4.96-15.61,3.32-23.62,3.54c-2.77,0.08-4.98-0.6-5.1-3.95
			c-0.05-0.81-0.01-1.65-0.17-2.44c-0.05-0.28-0.09-0.59-0.13-0.9c-0.05-0.35-0.11-0.71-0.22-1.02c-0.57,1.36-0.79,2.91-0.7,4.73
			c-0.8,3.73,0.72,5.02,4.48,5.05c10.09,0.08,20.28,1.08,29.3-4.99c0.1,0,0.2-0.01,0.3-0.02c4.78-0.27,6.77-3.69,8.25-7.52
			c1.22,1.99,1.47,4.5,2.8,6.52c0.23,0.34,0.47,0.66,0.76,0.96c9.96-5.04,19.92-10.08,29.87-15.11c0.18-0.63,0.11-1.18-0.09-1.69
			c0-0.01,0-0.01,0-0.01c-0.6-1.54-2.32-2.77-1.83-4.96c2.02,0.26,3.98,0.02,5.17-1.66c0.08-0.11,0.15-0.22,0.22-0.34
			c0.15-0.26,0.29-0.55,0.41-0.87C196.38,158.518,195.4,156.228,194.3,153.958z M101.69,64.238c0.05-0.41,0.09-0.81,0.12-1.2
			C101.8,63.448,101.771,63.848,101.69,64.238z M101.811,62.928c0-0.95-0.14-1.93-0.08-2.82
			C101.871,60.978,101.88,61.928,101.811,62.928z M118.65,144.888c-1.62-0.87-2.03-2.62-2.93-4.02c-0.45,0.21-0.91,0.41-1.37,0.62
			c0.05,0.04,0.11,0.08,0.17,0.12c0.06,0.07,0.12,0.15,0.18,0.22c1.07,1.36,1.98,2.85,3.3,4c0.06,0.06,0.12,0.12,0.19,0.17
			c0.18,0.16,0.35,0.32,0.53,0.47C118.701,145.938,118.67,145.418,118.65,144.888z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#C5B47C"
              d="M49.05,234.278c-0.19,2.54-2.36,3.56-4.76,3.35
			c-2.78-0.23-5.56-0.31-8.34-0.28c1.24,0.01,2.49,0.08,3.73,0.26c0.97,0.14,2.01-0.01,2.95,0.34c1.23,0.44,2.97,0.77,2.93,2.27
			c0,0.02,0,0.05-0.01,0.07c-4.89-2.25-9.28-1.32-13.59-1.26c-1.32,0.02-2.64,0.35-3.96,0.36c-1.21,0.01-2.83,0.51-2.92-1.64
			c-0.08-2.05,1.35-1.95,2.7-1.92c5.14,0.09,10.28,0.16,15.42,0.34c1.14,0.04,2.27,0.56,3.41,0.01c1.1-0.53,2.13-1.3,1.85-2.61
			c-0.28-1.34-1.62-0.81-2.5-0.82c-5.65-0.06-11.3,0.02-16.96-0.1c-1.37-0.03-3.81,1.26-3.79-1.58c0.01-2.82,2.23-1.57,3.73-1.49
			c5.64,0.31,11.28,0.6,16.86-0.67c1.66-0.37,1.65-0.77,0.15-1.39c0.11-0.22,0.22-0.44,0.34-0.66c0.05,0.03,0.1,0.05,0.15,0.08
			c2.85,1.49,0.93,2.45-1.16,3.13c-5.36,1.73-10.89,1.06-16.37,1.08c5.26,0.36,10.54-0.04,15.8,0.29
			C46.59,231.558,49.25,231.428,49.05,234.278z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#C5B47C"
              d="M52.21,216.598c-0.03,3.09-1.24,5.08-3.54,6.21
			C49.911,220.798,51.19,218.788,52.21,216.598z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#C5B47C"
              d="M51.09,205.648c0.38,0,0.76-0.02,1.14-0.04
			c0,0.29,0.01,0.59,0.01,0.88c-0.14-0.05-0.29-0.11-0.45-0.16C51.491,206.228,51.281,205.928,51.09,205.648z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#C5B47C"
              d="M144.661,219.838v21.95c0,11.33,0,22.65-0.01,33.97v2.94
			c-0.54-3.3-2.42-4.02-5.72-4.03c-29.05-0.02-58.03,2.93-87.09,2.12c-3.49-0.1-6.92-0.27-10.46-1.42c1.87-1.49,3.97-0.18,5.84-1.08
			c-1.57-3.17-5.19,0.44-6.92-2.16c1.58-2.36,4.29-0.8,6.76-2.01c-2.73-0.21-4.99-0.39-7.24-0.57c-0.08-0.29-0.16-0.57-0.23-0.86
			c-0.05-0.2-0.09-0.4-0.12-0.61c-0.47-2.37-0.34-4.74,0.35-7.12c2.1-0.15,4.2-0.3,6.3-0.45c-0.01-0.25-0.02-0.5-0.04-0.75
			c-5.4,0-10.81,0-16.22-0.01c-1.83,0-3.81,0.25-4.15-2.38c-0.42-3.18,2.2-3.07,3.93-3.17c5.14-0.28,10.31-0.17,15.46-0.25
			c1.44-0.03,3.37,0.3,3.51-1.79c0.15-2.36-1.77-2.4-3.51-2.4c-4.98,0.01-9.97-0.02-14.95,0.02c-3.14,0.02-5.02-1.26-5.37-4.56
			c-0.31-3.03,0.35-4.62,3.97-4.33c5.32,0.43,10.68,0.46,16.79-0.47c-0.19,1.41-1.88,1.47-3.09,1.6c-3.48,0.38-6.99,0.03-10.48,0.23
			c-1.97,0.12-4.94-0.84-5.13,2.38c-0.2,3.56,2.86,3.54,5.35,3.65c3.33,0.15,6.66,0.02,9.99,0.07c1.5,0.02,3.01,0.02,4.44,0.52
			c1.71,0.6,2.97,1.65,2.76,3.64c-0.2,1.88-1.74,2.32-3.27,2.49c-3.81,0.43-7.65,0.06-11.47,0.25c-2.06,0.1-4.36-0.42-6.17,1.81
			c1.69,1.59,3.71,1.19,5.58,1.25c2.32,0.08,4.66-0.1,6.99,0.07c2.02,0.14,5.22-0.59,5.42,1.93c0.18,2.22-3.23,1.44-4.87,2.35
			c-1.48,0.71-1.37,2.13-1.61,3.42c-0.06,0.31-0.1,0.66-0.16,0.99v1.58c0.2-0.04,0.4-0.08,0.6-0.13c1.83-0.28,3.69-0.4,5.44,0.23
			c1.52,0.53,3.2,1.32,0.53,2.77c-0.4,0.21-1.32-0.19-0.2,0.75c4.17,3.46-0.38,2.77-1.77,3.54c7.07-0.81,14.21-0.61,21.34-0.5
			c22.65,0.34,45.21-2.21,67.86-2.03c1.67,0.01,3.33,0.06,5,0.02c2.36-0.06,4.24,0.42,5.45,1.69c-1.5-10.74-1.21-21.49,0.08-32.31
			v-0.03C145.061,235.118,143.851,227.438,144.661,219.838z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F6F6F7"
              d="M799.697,237.765c5.188-5.068,10.305-10.194,13.42-16.908
			c2.466-1.31,3.606,0.815,4.918,2.064c4.193,3.994,8.208,8.175,12.35,12.225c7.663,8.139,16.145,15.48,23.415,24.004
			c0.823,1.225,1.683,2.414,2.709,3.493c4.273,4.496,4.291,5.262,0.254,10.563c2.192-1.253,3.328-3.764,5.993-3.789
			c5.197,3.418,8.303,8.886,12.765,13.062c4.189,3.92,8.828,7.526,11.808,12.621c-0.645,4.209-4.121,1.914-5.642,2.671
			c7.135-0.041,13.017,3.602,17.894,9.278c2.184,2.034,4.391,4.046,5.342,7.021c1.873,12.259,1.347,24.526,0.347,36.813
			c-0.305,3.752-2.123,5.238-5.771,5.297c-3.813,0.063-7.672-0.504-11.434,0.577c-4.126,0.893-7.609-0.019-9.913-3.79
			c-1.414-2.315-3.574-2.59-5.98-2.774c-9.295-0.715-12.728-4.252-12.812-13.557c-0.089-9.782-0.291-19.57,0.132-29.343
			c0.816-2.131,0.704-3.635-1.434-5.379c-4.106-3.35-7.746-7.258-11.075-11.451c-5.069-6.388-11.695-11.241-17.262-17.215
			c-8.488-9.11-16.845-18.381-25.701-27.163C801.789,243.874,798.728,241.777,799.697,237.765z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#C5C5C4"
              d="M61.046,483.633c-3.297,0.762-5.866,3.441-9.79,2.938
			c-1.897-0.244-1.558,2.611-1.418,4.146c0.553,6.08,1.832,12.143,1.862,18.217c0.181,36.279,0.023,72.559,0.177,108.838
			c0.019,4.395-3.078,7.215-4.088,11c-7.015,0.195-7.021,0.195-7.017,7.26c0.004,5.322-0.018,10.646,0.053,15.967
			c0.056,4.197-0.925,7.992-4.501,10.504c-2.704,1.9,1.486,1.541,0.741,2.646c-5.298,2.521-9.175-1.143-13.405-3.094
			c-1.72-0.793-1.885-2.531-1.867-4.287c0.092-8.646,0.129-17.293,0.185-25.939c0.709-3.295,0.339-6.637,0.271-9.945
			c-0.063-3.047,0.977-4.348,4.096-3.854c3.305,0.523,6.658,0.373,9.969,0.154c3.004-0.197,4.829-1.76,4.848-5.133
			c0.099-17.471-0.038-34.943,0.118-52.414c0.198-22.143-1.675-44.246-1.108-66.396c0.129-5.021-1.148-9.885-3.46-14.41
			c-3.129,0.027-6.266-0.082-9.384,0.113c-4.568,0.287-7.661-2.287-7.13-6.238c1.279-9.51-1.97-19.085,0.866-28.472
			c0.088-0.291,0.382-0.521,0.58-0.779c1.833,1.493,1.425,3.617,1.45,5.551c0.084,6.484-0.025,12.971,0.051,19.455
			c0.064,5.47,0.923,6.294,6.113,5.491c6.339-0.979,11.816,0.76,16.725,4.711c1.012,0.816,1.785,2.01,3.513,2.205
			c0.744-2.215-0.783-3.701-1.775-5.234c-3.323-5.137-7.323-9.796-8.804-16.06c-0.887-3.753-4.882-4.743-8.047-6.118
			c-3.957-1.719-6.492-4.525-7.511-8.698c-0.238-0.976-0.369-1.977,0.424-2.816c2.322-0.009,4.645-0.018,6.967-0.027
			c2.897,3.552,6.418,6.119,11.003,7.044c3.407,0.688,4.25,3.283,4.574,6.231c0.764,1.342,2.221,0.712,3.311,1.144
			c0.915,0.402,1.587,1.037,1.965,1.973c0.712,1.974,0.345,4.086,0.813,6.089c3.496,8.149,3.477,8.081,12.215,5.677
			c6.062-1.668,9.88-5.269,10.903-11.605c0.265-1.645,0.671-3.267,0.917-4.915c0.561-3.767,2.802-6.139,6.43-6.729
			c11.856-1.931,23.751-3.479,35.735-0.956c1.621,0.341,3.295,0.611,4.571,1.843c1.581,2.771,0.771,5.845,1.127,8.768
			c1.902,6.14,5.255,11.016,11.493,13.488c1.798,0.713,4.029,1.227,3.412,4.053c-2.462,2.775-1.425,6.758-3.341,9.723
			c-0.372-4.762-3.97-6.104-7.64-7.9c-5.201-2.547-7.865-7.448-9.407-12.869c-2.008-7.061-3.401-8.193-10.8-8.199
			c-4.985-0.004-9.972-0.078-14.955,0.023c-4.574,0.094-9.131,3.397-9.052,7.554c0.094,4.907-2.307,8.099-5.226,11.319
			c-0.146,1.953-0.373,3.873-2.181,5.115l-0.387,0.002h-0.387c-0.555-0.318-1.272-0.52-1.556-1.092
			c-1.743-3.52-3.752-2.428-5.846-0.395c-0.647,0.168-1.33,0.227-1.932-0.029c-3.18-1.355-4.628-0.266-4.595,3.117
			C61.924,482.215,61.574,482.975,61.046,483.633z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#6F98AE"
              d="M52.36,92.208c0.01,19.93,0.06,39.85-0.02,59.77
			c-0.04,9.49-1.94,11.29-11.46,11.36c-5.15,0.04-10.3,0-15.44,0.05c-3.2,0.03-5.03,1.34-5.04,4.69c-0.04,0.48-0.07,0.95-0.09,1.43
			c-0.02,0.36-0.04,0.72-0.05,1.08c-0.57-0.46-1.06-1.05-1.44-1.81c-0.42-6.85-0.42-6.84,6.07-6.84c5.83,0,11.65-0.1,17.47,0
			c7.47,0.12,8.45-0.72,8.46-8.12c0.02-21.3,0.01-42.61,0.01-63.91c0-1.83,0.01-3.66,0-5.5c-0.04-7.55-1.44-8.63-9-6.91
			c-0.03,0.02-0.06,0.04-0.09,0.05c-3.25,2.01-6.57,0.51-9.87,0.12c3.26-1.76,6.58,0.07,9.86-0.17c-0.03-0.17-0.04-0.35-0.01-0.56
			c5.78-2.95,9.85-0.91,10.5,5.32C52.561,85.558,52.36,88.898,52.36,92.208z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#6F98AE"
              d="M79.21,155.138c-0.06,0-0.13,0-0.2-0.01
			c-0.01-0.01-0.03-0.02-0.04-0.02v-0.12C79.07,155.018,79.15,155.068,79.21,155.138z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#6F98AE"
              d="M81.761,154.308c-0.19,0.15-0.39,0.28-0.59,0.39
			c0.16-0.3,0.34-0.6,0.57-0.84C81.75,154.008,81.761,154.158,81.761,154.308z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#6F98AE"
              d="M115.721,140.868c-0.28,0.41-0.63,0.72-1.02,0.96
			c-0.28,0.17-0.58,0.3-0.9,0.4c-0.07,0.03-0.14,0.05-0.21,0.06c-0.05,0.02-0.1,0.04-0.15,0.05c-3.57,0.39-5.83,2.86-8.21,5.15
			c-2.56,2.47-5.23,4.73-8.9,5.07c-0.21,0.03-0.43,0.04-0.65,0.04c0.12-0.21,0.24-0.43,0.36-0.65c4.55-1.13,7.35-4.72,10.78-7.49
			c1.86-1.5,3.34-5.07,6.87-2.67c0.22-0.1,0.44-0.2,0.66-0.3C114.811,141.278,115.271,141.078,115.721,140.868z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#6F98AE"
              d="M32.041,77.248c-0.08,0.13-0.16,0.25-0.22,0.38
			c-2.11-1.86-4.73-0.3-7.01-1.03c0.1-0.5,0.27-0.9,0.5-1.21c1.07,0.09,2.19-0.03,3.26,0.04
			C29.86,75.518,31.09,75.878,32.041,77.248z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#6F98AE"
              d="M94.661,86.558c-0.02,0.16-0.05,0.32-0.09,0.47
			c-0.24-0.13-0.48-0.25-0.72-0.35C94.121,86.608,94.391,86.568,94.661,86.558z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#6F98AE"
              d="M80.21,142.608c-1.77,0.22-3.36,0.01-4.42-1.86
			c-4.68-1.84-6.56-5.08-6.38-10.28c0.18-5.14,1.11-10.42-0.64-15.52c-0.03,5.78-0.04,11.55-0.1,17.33c-0.01,0.71,0.4,1.8-0.76,1.9
			c-0.36,0.03-0.81-0.91-1.21-1.41c-3.16,0.4-3.16-2.25-3.83-4.11c-2.3-6.38-0.03-12.57,0.97-18.81c0.3-0.38,0.6-0.77,0.9-1.15
			c0.05,0.13,0.11,0.25,0.16,0.38c0.01,0.15,0.02,0.3,0.03,0.45c0.12,3.04-0.97,5.9-1.35,8.87c-0.59,4.65-0.58,9.2,2.84,12.95
			c0.11-0.14,0.2-0.28,0.29-0.42c1.75-2.81,0.26-5.94,0.71-8.87c0.36-2.39-0.6-5.03,1.18-7.15c0.09-0.11,0.19-0.22,0.3-0.33
			c2.43,0.61,2.12,2.71,2.23,4.42c0.23,3.47,0.27,6.96,0.04,10.42c-0.28,4.39,1.11,7.77,4.85,10.12c0.41,0.48,0.87,0.93,1.39,1.35
			C78.241,141.558,79.19,142.138,80.21,142.608z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#6F98AE"
              d="M103.63,117.618c-0.29-0.46-0.61-0.9-0.97-1.31
			c-0.16-0.21-0.34-0.4-0.53-0.59c-2.12-2.48-3.84-5.15-4.06-8.53c0.03-0.19,0.07-0.38,0.11-0.57c0.05-0.22,0.1-0.44,0.15-0.66
			c0.24-1.12,0.3-2.18-1.4-2.85c-1.31-0.51-2.3-1.87-1.8-3.12c1.25-3.18-0.61-4.48-2.56-5.78c-0.28-0.19-0.56-0.38-0.83-0.57
			c0.08-0.72,0.17-1.45,0.25-2.17c1.26-0.68,1.44-1.65,0.76-2.85c0.38,0.02,0.75,0.08,1.1,0.18c-0.04,0.09-0.09,0.19-0.14,0.28
			c-0.42,0.82-0.62,1.69-0.74,2.59c-0.03,0.24-0.06,0.49-0.08,0.73c-0.05,0.42-0.11,0.84-0.19,1.25c3.41,0.05,5.86,0.83,3.44,5.57
			c-0.7,1.38-0.13,3.17,1.31,3.14c6.48-0.13,1.14,2.93,1.31,4.46c0.95,2.83,2.18,5.52,3.91,7.96
			C103.081,115.698,103.4,116.648,103.63,117.618z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#6F98AE"
              d="M116.61,115.028c-0.62,0.53-1,1.12-1.22,1.75
			c-0.06-0.39-0.15-0.78-0.29-1.16c-0.1-0.19-0.23-0.37-0.37-0.54C115.13,114.798,115.73,114.758,116.61,115.028z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#6F98AE"
              d="M124.94,100.448c0.13,0.03,0.26,0.07,0.39,0.1
			c-0.22,0.07-0.44,0.13-0.67,0.19c-2.19,1.92-3.96,4.41-7.53,4.01c-2.47-0.28-2.16-1.41-1.47-2.98c0-0.01,0-0.01,0-0.02
			c-2.32-2.64,0.32-6.23-1.69-8.92v9.42c-4.13-3.75-4.06-8.98-7.82-11.55c-0.42,1.14-1.02,2.19-2.45,1.88
			c-1.32-0.29-0.97-1.54-0.99-2.44c-0.02-1.29,0.03-2.59,0.07-3.89c-0.53,0.51-1.12,0.95-1.93,1.06c-0.16,0-0.32-0.01-0.48-0.03
			c-0.19-0.03-0.38-0.08-0.55-0.14c0.29-0.06,0.58-0.17,0.88-0.33c0.78-0.77,1.55-1.55,2.33-2.32c0.08,0.09,0.15,0.18,0.22,0.27
			c0.14,0.2,0.25,0.41,0.34,0.64c0.04,0.09,0.08,0.18,0.11,0.28c0.09,0.13,0.15,0.27,0.19,0.42c0.01,0.03,0.03,0.06,0.03,0.1
			c0.35,1.53-1.09,4.05,1.76,4.2c0.13,0.01,0.26,0.01,0.4,0.01c0.07,0,0.15,0,0.22-0.01c3.71,1.09,4.16,4.73,5.93,7.52
			c0.92-1.63-0.29-4.08,1.74-5.22c0.07-0.05,0.15-0.09,0.23-0.12c0.02-0.01,0.03-0.02,0.05-0.03c0.44,0.31,0.78,0.64,1.04,0.99
			c0.41,0.55,0.63,1.14,0.75,1.76c0.34,1.79-0.21,3.8,0.33,5.54c0.25,2.45,1.54,3,3.66,2c1.58-0.74,2.66-2.42,4.61-2.47
			C124.741,100.398,124.84,100.418,124.94,100.448z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#6F98AE"
              d="M132.94,87.828c-0.05,1.6-0.63,3.4,0.95,4.56
			c3.28,2.39-0.29,2.34-1.1,3.28c-0.23,0.24-0.46,0.48-0.7,0.71c0.06-0.19,0.11-0.38,0.16-0.58c0.04-0.13,0.07-0.26,0.11-0.39
			c2.03-2.48-1.98-5.04,0.07-7.53c0.1-0.16,0.23-0.27,0.37-0.34c0.07-0.04,0.14-0.06,0.22-0.08c0.96-0.23,2.41,0.71,2.58-0.83
			c0.14-1.28-0.9-2.42-1.9-3.32c-0.98-0.89-2.3-0.95-3.5-1.34c-0.28-0.09-0.56-0.21-0.82-0.36c-0.08-0.04-0.16-0.08-0.23-0.13
			c-1.39-1.04-2.72-2.13-2.86-4.04c-0.01-0.02-0.01-0.03-0.01-0.05c0.06-0.38,0.09-0.78,0.11-1.17c0.25,0.47,0.37,1.05,0.38,1.73
			c1,0.95,1.99,1.9,2.99,2.85c5.17,0.52,7.06,2.59,7.67,8.49C135.641,89.678,134.371,88.488,132.94,87.828z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#D0C59A"
              d="M1180.569,55.038c-7.815-0.943-15.564,0.626-23.342,0.648
			c-45.277,0.132-90.562-0.297-135.831,0.34c-15.769,0.223-31.57,0.163-47.329,0.859c-44.405,1.961-88.824,0.281-133.231,0.907
			c-4.194,0.06-7.719-1.652-11.02-4.012c-0.313-3.441,2.056-3.039,4.27-3.081c17.219-0.327,34.438-0.699,51.655-1.057
			c1.993-1.627,4.388-1.251,6.673-1.271c10.42-0.093,20.841,0.035,31.261-0.077c12.542-0.088,25.085,0.059,37.626,0.015
			c4.053-0.148,8.107-0.093,12.163,0.017c0.628-0.001,1.254,0.005,1.882,0c8.849-0.278,17.697-0.063,26.546-0.095
			c9.15,0.157,18.299-0.096,27.448,0.041c14.63,0.218,29.22-0.507,43.791-1.786c2.597-0.229,5.356-0.661,7.688,1.196
			c1.227-0.06,1.682-0.99,2.152-1.903c1.859-0.656,3.744-0.682,5.652-0.231c5.363,2.83,10.996,0.415,16.489,0.843
			c0.563-0.026,1.118-0.038,1.681-0.054c18.122,0.096,36.236-0.149,54.35,0.012c3.023,0.027,5.873-1.165,8.889-0.956
			c1.66-0.049,3.319-0.06,4.984-0.188c4.856-0.374,6.747,1.229,7.617,6.246C1182.302,52.849,1181.969,54.253,1180.569,55.038z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#C5B47C"
              d="M948.668,734.803c-0.001-7.941-0.002-15.883-0.003-23.822
			c2.108-1.504,4.495-2.021,7.043-2.02c10.981,0.008,21.964-0.055,32.944,0.021c6.083,0.043,7.247,1.389,7.13,7.404
			c-0.065,3.32-0.058,6.646,0.01,9.967c0.075,3.727-1.505,5.688-5.395,5.313c-2.931-0.283-5.163,0.398-4.682,3.938
			c1.333,9.805-2.044,19.031-3.33,28.512c-0.134,0.982-0.437,1.963-0.419,2.941c0.164,9.084,0.453,7.182-8.307,8.465
			c-4.729,0.691-9.626,0.275-14.447,0.297c-4.952,0.021-7.499-2.678-8.466-7.379c-1.63-7.92-0.831-15.902-0.805-23.863
			C949.954,741.252,950.186,737.918,948.668,734.803z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill={
                roomId === 'a6bf7e72-3f3e-46bb-bca4-3d5bb59f65ac' ? HIGHLIGHT_COLOR : INACTIVE_COLOR
              }
              d="M193.09,154.108c-14.69-28.47-29.53-56.88-43.85-85.54
			c-3.79-7.59-8.18-12.25-17.24-10.45c-2.05,0.4-4.3-0.09-6.45-0.27c-4.79-0.4-9.03,0.17-10.54,6.72c-2.09-3.76-3.59-7.05-7.79-6.98
			c-2.81,0.04-4.9,0.47-4.55,4.12c0.17,1.84,0.7,4.04-1.51,5.48c-1.2-3.56,1.75-8.47-3.55-10.51c-4.23,0.09-8.48,0.17-12.72,0.25
			c-1.36,3.23-4.35,2.13-6.73,2.24c-7.15,0.32-8.08,0.92-7.85,8.19c0.26,8.05-0.64,16.05,1.25,24.19c0.9,3.85-0.81,8.83-2.53,13.07
			c2.56-0.35,4.59-1.94,6.84-2.99c-0.22-2.74,1.14-3.95,3.79-3.91c1.64,0.03,3.31-0.11,4.92,0.13c3.58,0.53,6.3-0.2,7.16-4.21
			c0.08-0.72,0.17-1.45,0.25-2.17c-0.39-1.12-0.21-2.09,0.76-2.85c0.38,0.02,0.75,0.08,1.1,0.18c-0.04,0.09-0.09,0.19-0.14,0.28
			c-0.42,0.82-0.62,1.69-0.74,2.59c-0.03,0.24-0.06,0.49-0.08,0.73c-0.05,0.42-0.11,0.84-0.19,1.25c3.41,0.05,5.86,0.83,3.44,5.57
			c-0.7,1.38-0.13,3.17,1.31,3.14c6.48-0.13,1.14,2.93,1.31,4.46c0.95,2.83,2.18,5.52,3.91,7.96c0.41,0.92,0.73,1.87,0.96,2.84
			c2.47,10.4-5.42,22.66-16.23,23.76c-2.16,0.21-4.25,0.74-6.38,1.11c-0.27,0.05-0.55,0.09-0.81,0.12
			c-1.77,0.22-3.36,0.01-4.42-1.86c-4.68-1.84-6.56-5.08-6.38-10.28c0.18-5.14,1.11-10.42-0.64-15.52
			c-0.03,5.78-0.04,11.55-0.1,17.33c-0.01,0.71,0.4,1.8-0.76,1.9c-0.36,0.03-0.81-0.91-1.21-1.41c-3.16,0.4-3.16-2.25-3.83-4.11
			c-2.3-6.38-0.03-12.57,0.97-18.81c0.3-0.38,0.6-0.77,0.9-1.15c1.01-1.29,2.02-2.59,3.03-3.88c-0.15-0.07-0.29-0.19-0.42-0.34
			c-1.33-2.1-1.07-4.48-1.08-6.78c-0.04-10.46,0.06-20.92-0.05-31.38c-0.05-6.08-0.93-6.96-6.95-6.99
			c-11.62-0.07-23.25-0.1-34.87,0.02c-5.34,0.05-5.88,0.76-6.08,6.11c-0.17,4.34,0.46,8.61,0.91,12.88
			c0.03,0.11,0.07,0.23,0.09,0.34c0.04,0,0.09-0.01,0.13-0.03c-0.01-0.05-0.03-0.1-0.04-0.15c0.23-3.6,3.1-2.81,5.26-3.19
			c0.21,0.04,0.42,0.06,0.64,0.08c1.07,0.09,2.19-0.03,3.26,0.04c1.29,0.09,2.52,0.45,3.47,1.82c-0.08,0.13-0.16,0.25-0.22,0.38
			c0.02,0.01,0.03,0.03,0.05,0.04c3.26-1.76,6.58,0.07,9.86-0.17c-0.03-0.17-0.04-0.35-0.01-0.56c5.78-2.95,9.85-0.91,10.5,5.32
			c0.34,3.3,0.14,6.64,0.14,9.95c0.01,19.93,0.06,39.85-0.02,59.77c-0.04,9.49-1.94,11.29-11.46,11.36c-5.15,0.04-10.3,0-15.44,0.05
			c-3.2,0.03-5.03,1.34-5.04,4.69c-0.04,0.48-0.07,0.95-0.09,1.43c-0.02,0.36-0.04,0.72-0.05,1.08c-0.19,4.13-0.15,8.26-0.7,12.39
			c-0.61,4.55,0.96,6.23,5.46,6.29c11.63,0.14,23.26,0.07,34.89,0.03c3.69-0.01,6.04-1.47,6.51-5.34c0.1-0.83,0.26-1.71,0.77-2.35
			c1.43-1.76,2.98-4.52,5.36-3.45c2.28,1.03,1.03,3.82,0.39,5.84c-1.62,5.12-1.59,5.24,3.72,5.31c10.13,0.14,20.27-0.19,30.4,0.2
			c1.85,0.08,3.66-0.1,5.17-1.34c1.54-1.42,1.02-3.35,1.29-5.09c0.05-0.29,0.08-0.62,0.12-0.95c-0.15-1.38-0.63-2.66-2.18-3.14
			c-6.68-2.07-9.59-8.24-14.02-12.75c-0.94-0.95-1.95-2.2,0.43-2.77c-6.05-3.53-6.23-3.63-2.19-10.87c0.12-0.21,0.24-0.43,0.36-0.65
			c4.55-1.13,7.35-4.72,10.78-7.49c1.86-1.5,3.34-5.07,6.87-2.67c0.22-0.1,0.44-0.2,0.66-0.3c0.46-0.21,0.92-0.41,1.37-0.62
			c0.9,1.4,1.31,3.15,2.93,4.02c0.02,0.53,0.05,1.05,0.07,1.58c0.03,0.77,0.06,1.54,0.09,2.3c1.14,2.73,3.22,4.78,5.04,7.02
			c4.52,5.58,4.52,5.58-1.2,10.22c-0.43,5.28-4.2,5.02-7.95,4.79c-7.54,3.34-7.5,3.32-13.05-3.64c-1.04-1.3-1.66-3.11-3.5-3.62
			c0.01,0.01,0.01,0.01,0.02,0.02c1.55,5.85,6.16,9.97,10.62,12.47c2.84,1.59,4.33,3.35,5.02,5.28c0.18-0.71,0.56-1.28,1.43-1.32
			c0.87-0.04,1.26,0.42,1.47,1.04v0.01c0.11,0.31,0.17,0.67,0.22,1.02c0.04,0.31,0.08,0.62,0.13,0.9c0.16,0.79,0.12,1.63,0.17,2.44
			c0.12,3.35,2.33,4.03,5.1,3.95c8.01-0.22,16.29,1.42,23.62-3.54c1.09-0.73,2.47-0.96,3.81-0.36c0.09,0.09,0.17,0.18,0.25,0.27
			c3.99-1.98,7.04-4.84,7.95-10.06c1.92,3.15,3.71,5.68,3.95,8.74c9.65-4.86,19.35-9.61,28.83-14.79c0.03-0.02,0.06-0.04,0.09-0.07
			c-0.55-0.64-1.09-1.28-1.53-1.99c-0.87-1.39-2.04-3.04-1.27-4.6c1.07-2.18,2.48,0.16,3.76,0.25c1.4,0.1,2.04-0.96,3.05-1.43
			C194.931,159.058,194.351,156.558,193.09,154.108z M94.661,86.558c-0.02,0.16-0.05,0.32-0.09,0.47c-0.24-0.13-0.48-0.25-0.72-0.35
			c0.11-0.45,0.15-0.81,0.15-1.09c-0.21-0.33-0.36-0.64-0.47-0.95c0.28,0.13,0.46,0.41,0.47,0.95c0.18,0.29,0.4,0.6,0.67,0.91
			C94.67,86.518,94.661,86.538,94.661,86.558z M79.011,155.128c-0.01-0.01-0.03-0.02-0.04-0.02v-0.12c0.1,0.03,0.18,0.08,0.24,0.15
			C79.15,155.138,79.081,155.138,79.011,155.128z M81.17,154.698c0.16-0.3,0.34-0.6,0.57-0.84c0.01,0.15,0.02,0.3,0.02,0.45
			C81.57,154.458,81.371,154.588,81.17,154.698z M115.391,116.778c-0.06-0.39-0.15-0.78-0.29-1.16c-0.1-0.19-0.23-0.37-0.37-0.54
			c0.4-0.28,1-0.32,1.88-0.05C115.991,115.558,115.61,116.148,115.391,116.778z M136.811,121.788c-0.02-0.01-0.04-0.03-0.06-0.04
			c0-0.01,0.01-0.02,0.01-0.03c0.03,0.01,0.06,0.02,0.08,0.03C136.831,121.758,136.82,121.778,136.811,121.788z M132.94,87.828
			c-0.05,1.6-0.63,3.4,0.95,4.56c3.28,2.39-0.29,2.34-1.1,3.28c-0.23,0.24-0.46,0.48-0.7,0.71c-1.35,4.74-2.29,5.28-6.76,4.17
			c-0.22,0.07-0.44,0.13-0.67,0.19c-2.19,1.92-3.96,4.41-7.53,4.01c-2.47-0.28-2.16-1.41-1.47-2.98c0-0.01,0-0.01,0-0.02
			c-2.32-2.64,0.32-6.23-1.69-8.92v9.42c-4.13-3.75-4.06-8.98-7.82-11.55c-0.42,1.14-1.02,2.19-2.45,1.88
			c-1.32-0.29-0.97-1.54-0.99-2.44c-0.02-1.29,0.03-2.59,0.07-3.89c-0.53,0.51-1.12,0.95-1.93,1.06c-0.16,0-0.32-0.01-0.48-0.03
			c-0.19-0.03-0.38-0.08-0.55-0.14c0.29-0.06,0.58-0.17,0.88-0.33c0.78-0.77,1.55-1.55,2.33-2.32c6.28-4.61,12.81-8.67,20.74-9.71
			c0.26,0.01,0.51,0.04,0.73,0.09c0.83-0.9,1.15-2.44,2.42-2.77c-0.54,1.29-0.46,2.72-0.53,4.12c0.25,0.47,0.37,1.05,0.38,1.73
			c1,0.95,1.99,1.9,2.99,2.85c5.17,0.52,7.06,2.59,7.67,8.49C135.641,89.678,134.371,88.488,132.94,87.828z M166.831,141.808h-0.1
			c0.02-0.02,0.03-0.04,0.05-0.06c0.02-0.01,0.03-0.02,0.05-0.03V141.808z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#7CABC5"
              d="M79.21,155.138c-0.06,0-0.13,0-0.2-0.01
			c-0.01-0.01-0.03-0.02-0.04-0.02v-0.12C79.07,155.018,79.15,155.068,79.21,155.138z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#7CABC5"
              d="M81.761,154.308c-0.19,0.15-0.39,0.28-0.59,0.39
			c0.16-0.3,0.34-0.6,0.57-0.84C81.75,154.008,81.761,154.158,81.761,154.308z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#7CABC5"
              d="M113.71,189.548c-1.61,1.5-3.69,1.55-5.63,1.55
			c-11.62-0.02-23.25-0.24-34.87-0.22c-4,0-4.81-1.26-2.79-4.73c1.32-2.26,2.49-4.68,2.02-7.67c-2.4,2.28-5.15,3.8-4.83,7.87
			c0.21,2.74-2.21,4.4-5.05,4.41c-13.45,0.05-26.91,0.06-40.37-0.04c-3.51-0.03-4.3-2.69-4.2-5.58c0.19-5.45,0.54-10.9,0.83-16.36
			c0.5,0.26,1,0.5,1.49,0.73c-0.02,0.36-0.04,0.72-0.05,1.08c-0.19,4.13-0.15,8.26-0.7,12.39c-0.61,4.55,0.96,6.23,5.46,6.29
			c11.63,0.14,23.26,0.07,34.89,0.03c3.69-0.01,6.04-1.47,6.51-5.34c0.1-0.83,0.26-1.71,0.77-2.35c1.43-1.76,2.98-4.52,5.36-3.45
			c2.28,1.03,1.03,3.82,0.39,5.84c-1.62,5.12-1.59,5.24,3.72,5.31c10.13,0.14,20.27-0.19,30.4,0.2c1.85,0.08,3.66-0.1,5.17-1.34
			c1.54-1.42,1.02-3.35,1.29-5.09c0.05-0.29,0.08-0.62,0.12-0.95c-0.15-1.38-0.63-2.66-2.18-3.14c-6.68-2.07-9.59-8.24-14.02-12.75
			c-0.94-0.95-1.95-2.2,0.43-2.77c-6.05-3.53-6.23-3.63-2.19-10.87c0.12-0.21,0.24-0.43,0.36-0.65c0.12,0.18,0.22,0.4,0.29,0.61
			c0.09,0.29,0.11,0.58-0.05,0.78c-3.11,4.07-1.05,7.13,1.76,10.08c0.04,0.04,0.07,0.08,0.11,0.12c0.01,0.01,0.01,0.01,0.02,0.02
			c1.55,5.85,6.16,9.97,10.62,12.47c2.84,1.59,4.33,3.35,5.02,5.28C114.701,183.758,114.3,186.498,113.71,189.548z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill={
                roomId === '09b096b3-70ab-405d-ac4a-d7b738c685db' ? HIGHLIGHT_COLOR : INACTIVE_COLOR
              }
              d="M42.673,630.934c0.035,9.648,0.209,19.299,0.03,28.941
			c-0.066,3.559,0.721,5.082,4.655,4.934c7.808-0.291,15.635-0.096,23.454-0.078c1.674,0.004,3.334,0.033,4.83-0.883
			c3.875,20.113,1.565,40.424,1.96,60.648c0.06,3.07-2.132,4.604-4.071,6.344c-0.648,0.432-1.253,0.426-1.797-0.18
			c-1.059-1.26-2.642-0.713-3.941-1.143c-4.432,0.332-5.458-1.861-4.541-5.822c0.485-2.09,0.705-4.258,1.048-6.389
			c1.084-6.74-2.047-11.891-6.2-16.664c-2.188-3.697-4.73-6.713-9.597-6.924c-2.767-0.121-3.733-2.367-3.771-4.461
			c-0.408,1.729,1.465,5.148-1.838,5.746c-3.369,0.611-4.762-2.148-4.948-5.416c-0.096-1.684-0.777-3.309-2.419-4.117
			c-2.845-1.398-4.28,0.082-4.429,4.637c0.312-2.238,0.715-4.758,3.502-4.635c2.826,0.125,2.43,2.816,2.394,4.652
			c-0.059,3.008,1.788,5.318,2.456,8.033c0.036,0.455-0.01,0.906-0.137,1.348c-2.161,1.896-2.401,4.375-2.088,7.018
			c-0.167,0.637-0.518,1.17-0.927,1.668c-1.365,1.174-0.68,2.635-0.541,4.02c0.056,2.941-1.868,6.236,1.576,8.559
			c2.029,5.385,0.632,7.59-5.557,8.791c-7.598,0.607-7.668,0.221-8.197-7.234c-1.079-15.234-0.105-30.525-1.544-45.746
			c-0.539-5.693,1.662-7.779,7.49-7.807c12.366-0.061,12.366-0.061,12.367-12.223c0-6.984-0.057-13.969,0.044-20.951
			C41.958,634.051,41.147,632.278,42.673,630.934z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F6F6F7"
              d="M1087.848,45.879c-1.641,0.721-3.274,1.117-4.876-0.101
			c-0.061-7.986-0.008-15.977-0.242-23.958c-0.083-2.839,0.774-3.776,3.62-3.667c3.31,0.127,6.636-0.315,9.958-0.391
			c6.439-0.146,8.385,1.744,8.494,8.091c0.014,0.778,0.002,1.558,0.002,2.654c0.652-0.424,1.046-0.559,1.048-0.699
			c0.114-9.04,1.479-10.338,10.425-10.062c2.152,0.066,4.312-0.086,6.467-0.138c1.371-0.003,2.741-0.005,4.111-0.008
			c4.756,1.302,3.701,4.96,3.66,8.032c-0.533,0.545-0.147,0.528-0.083,0.097c1.263-8.444,1.247-8.447,10.174-8.447
			c3.482,0,6.964-0.021,10.446,0.006c5.703,0.045,6.608,1.046,6.266,6.687c-0.053,0.864-0.268,1.785-0.016,2.294
			c0.69-3.124-1.075-7.579,4.415-8.689c2.042,0.007,4.083,0.015,6.124,0.021c3.467-1.109,6.913-0.407,10.255,0.426
			c3.179,0.793,4.231,3.624,4.734,6.475c0.786,4.454,0.739,8.987,0.151,13.447c-0.642,4.866-3.38,6.961-9.519,7.722
			c-2.146,0.266-4.328,0.056-6.489,0.117c-8.021,0.907-10.408-1.291-9.491-8.604c-0.24,1.033-0.442,2.053-0.43,3.16
			c0.034,3.016-1.58,4.61-4.536,4.745c-5.781,0.263-11.58,0.389-17.347-0.037c-4.194-0.31-4.686-1.514-4.535-8.578
			c-3.989-2.984-8.06-0.541-12.707-0.574c1.769,3.06,3.955,1.979,5.881,1.844c1.977-0.138,4.192-0.524,5.355,1.509
			c1.214,2.119,1.329,4.402-0.849,6.227c-7.349,1.841-14.69,1.569-22.025,0.012c-2.356-1.658-1.144-4.194-1.527-6.302
			c-0.1,6.328-0.581,6.913-6.599,6.995C1094.728,46.229,1091.267,46.612,1087.848,45.879z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#4D4B4C"
              d="M241.812,26.624c-26.738,0.468-53.463,1.698-80.217,1.33
			c-2.287-0.032-4.532-1.198-6.839-0.244c-4.626,0.746-9.29,0.431-13.939,0.458c-17.984,0.11-35.971-0.212-53.943,0.396
			c-2.743-0.002-5.608,0.91-8.127-0.991c-1.588-1.379-3.509-0.785-5.278-0.788c-21.323-0.042-42.646-0.025-63.969-0.031
			c-1.331,0-2.665-0.01-3.991-0.103c-0.938-0.065-1.537-0.599-1.401-1.619c0.102-0.77,0.694-1.111,1.4-1.149
			c1.329-0.07,2.661-0.098,3.992-0.098c35.982-0.007,71.965-0.003,107.947-0.031c1.455-0.001,2.988,0.422,5.572-1.146
			c-40.321,0-79.44,0-118.56,0c1.657-2.438,3.822-1.82,5.771-1.821c89.957-0.037,179.913-0.035,269.87-0.037
			c69.799-0.001,139.633-1.275,209.383,0.589c30.522,0.815,61.063,0.162,91.563,1.62c-5.859,1.613-11.862,0.887-17.807,1.063
			c-5.811,0.172-11.646-0.35-17.427,0.65c-1.396,0.938-2.961,1.422-4.617,1.319c-3.277-0.203-4.406,1.426-4.041,4.472
			c0.222,5.01-0.941,9.956-0.559,14.969c-0.906,10.538-0.047,21.094-0.447,31.629c-0.081,2.127,0.781,4.803-2.245,5.883
			c-2.088-1.985-1.613-4.611-1.626-7.043c-0.069-13.808,0.01-27.616-0.05-41.424c-0.029-6.63-0.532-7.143-7.079-7.135
			c-4.994,0.006-9.967-0.42-14.953-0.495c-15.614-3.581-31.493-2.482-47.301-2.659c-1.229-0.014-2.209,0.583-3.209,1.168
			c-2.008,0.905-4.079,0.804-6.173,0.409c-6.862-1.007-13.727-0.901-20.593-0.058c-2.58,0.317-5.242,1.131-7.305-1.271
			c0.191-0.247,0.268-0.859,0.266-0.859c-60.829,1.421-121.667,0.448-182.499,0.76c-4.329,0.022-8.493-1.737-12.384-1.082
			C234.424,23.842,239.134,21.906,241.812,26.624z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FCFCFC"
              d="M600.846,87.313c0-9.326,0.145-18.656-0.076-27.978
			c-0.08-3.355,0.575-5.043,4.31-4.602c5.616,0.664,11.15-1.087,16.889-0.343c5.455,0.708,7.682,3.612,7.723,8.396
			c0.162,18.816,0.092,37.636,0.088,56.454c-0.001,3.056-1.568,4.552-4.657,4.535c-5.994-0.033-11.99,0.06-17.983-0.028
			c-5.296-0.078-5.996-3.946-6.04-7.966c-0.103-9.488-0.032-18.979-0.032-28.469C600.993,87.313,600.919,87.313,600.846,87.313z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F5F5F6"
              d="M922.687,30.671c1.044-10.77,3.744-12.871,14.127-11.076
			c1.468,0.253,2.971,0.369,4.462,0.416c7.03,0.225,7.03,0.208,7.867,7.6c1.433-3.242,1.758-7.007,5.625-7.832
			c5.724-1.22,11.619-1.996,17.188,0.096c3.808,1.43,0.949,5.647,2.261,8.306c1.152-8.518,3.872-10.624,12.539-9.018
			c3.984,0.739,7.973-0.06,11.905,0.679c0.969,0.891,1.05,1.978,0.704,3.159c1.641,4.42,0.075,9.149,1.331,13.608
			c0.242,1.188,0.223,2.368-0.058,3.546c-1.843,5.56-3.379,6.894-9.117,7.018c-5.065,0.108-10.155,0.496-15.202-0.342
			c-4.275-1.633-0.949-5.426-2.238-7.915c-0.111-0.657-1.069,0.033-0.442-0.192c0.218-0.078,0.297,0.037,0.387,0.292
			c0.501,1.416,0.266,2.816-0.045,4.226c-0.271,2.279-1.544,3.684-3.818,3.772c-4.758,0.185-9.449,1.323-14.226,1.061
			c-5.041-0.276-6.318-1.883-6.092-7.265c0.808-2.135,2.772-2.02,4.549-2.356c2.677-0.507,5.524-0.384,8.329-1.905
			c-9.649-3.317-11.288-3.048-13.482,2.014c-0.289,8.921-1.029,9.756-9.784,9.643c-4.944-0.064-9.954,0.748-14.82-0.762
			c-2.705-2.592-3.306-6.67-0.513-7.749c4.86-1.877,3.213-5.155,3.121-8.842c-1.195,1.44-2.16,2.687-3.896,2.307
			C922.279,32.552,922.027,31.731,922.687,30.671z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F5F5F6"
              d="M37.731,441.819c-6.45-1.791-7.138-2.788-6.94-9.307
			c0.14-4.624-0.044-9.262,0.181-13.88c0.397-8.136,2.04-9.621,10.269-9.87c0.825-0.024,1.648-0.11,2.472-0.168
			c1.954-1.702,4.361-1.382,6.647-1.398c9.29-0.069,18.579,0.095,27.869,0.103c9.827,0.009,11.065,1.333,11.052,11.26
			c-0.001,0.829-0.002,1.659-0.028,2.484c-0.072,1.978-0.076,3.96-0.089,5.942c-0.072,10.834-1.936,13.043-12.731,15.109
			c-11.438,0.367-22.879,0.007-34.316,0.1C40.643,442.231,39.135,442.62,37.731,441.819z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F5F5F6"
              d="M30.704,387.911c0.081-2.66,0.477-5.358,0.163-7.971
			c-0.403-3.351,0.779-5.235,3.963-6.016c4.551-2.471,9.509-0.433,14.208-1.286c4.097-0.967,8.267-0.58,12.379-0.431
			c6.939,0.251,13.87-0.037,20.803,0.093c2.594,0.048,5.551,0.363,5.651,3.549c0.299,9.562,1.224,19.157-0.506,28.69
			c-1.962,2.161-4.664,2.34-7.29,2.615c-2.143,0.225-4.309,0.118-6.464,0.091c-11.575-0.148-23.134-1.206-34.725-0.51
			c-2.86,0.173-5.61-0.432-8.087-1.984C30.767,399.139,30.735,393.524,30.704,387.911z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#494748"
              d="M4.456,18.229c274.209,0,548.418,0,822.628,0
			c0,0.331,0.001,0.662,0.001,0.992c-274.213,0-548.426,0-822.64,0C4.449,18.892,4.453,18.561,4.456,18.229z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FDFDFD"
              d="M133.291,52.008c-5.11,0.38-10.26,0.38-15.36,0.07
			c-6.9-0.42-13.36,0.58-19.33,4.01c-0.33,0.19-0.66,0.38-0.99,0.59c-4.23,0.09-8.48,0.17-12.72,0.25
			c-0.09,0.01-0.17,0.01-0.26,0.01c-3.31-0.09-6.63-0.37-9.93-0.2c-2.94,0.14-5.2-0.26-6.23-3.48c-0.77-2.39-2.87-2.47-4.94-2.27
			c-2.13,0.19-4.25,0.57-6.39,0.69c-3.85,0.21-5.37-1.92-5.25-5.54c0.12-3.81-0.69-7.97,0.59-11.34c1.74-4.56,6.76-1.07,10.19-2.41
			c5.18-2.03,10.69-3.25,16.06-4.81c0.01-0.01,0.01-0.01,0.02-0.01c2.36,0.02,4.72,0.05,7.09,0.07c1.63-0.5,3.44-0.91,4.58,0.63
			c0.05,0.06,0.09,0.13,0.12,0.19h0.01c1,1.6-1.06,2.37-1.63,3.55c-1.39,2.93-0.31,5.86,2.44,6.3c1.8,0.29,3.05,0.37,2.64-2.48
			c-0.62-4.23-0.49-6.47,1.01-7.47c0-0.01,0.01-0.01,0.01-0.01h0.08c1.17-0.67,3.15-0.6,6.2-0.1c0.01,0,0.01,0,0.02,0
			c0.01,0,0.01,0,0.02,0c0.01,0,0.01,0,0.02,0c0.01,0,0.01,0,0.02,0h0.13c0.18,0.04,0.3,0.08,0.5,0.12c2.56,0.45,5,1.23,7.45,2.05
			c0.39,0.13,0.79,0.27,1.18,0.4c0.16,0.06,0.32,0.13,0.48,0.19v0.01c4.51,1.78,8.81,3.98,12.96,6.48c0.26,0.15,0.52,0.31,0.78,0.47
			c0.03,0.01,0.06,0.03,0.09,0.05c3.77,2.69,7.66,5.27,10.53,8.97C137.98,50.188,137.34,51.708,133.291,52.008z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FDFDFD"
              d="M967.705,657.836c-3.818,0.014-7.638,0.029-11.456,0.041
			c-4.286,0.014-6.434-2.133-6.457-6.412c-0.004-0.666,0-1.332-0.002-1.998c-0.044-13.461-0.043-13.426,13.244-13.885
			c4.473-0.156,8.935-0.709,13.406-0.775c7.407-0.109,10.85,2.979,10.066,10.188c-1.552,14.281-0.23,28.57-0.871,42.836
			c-0.191,4.25,0.149,7.391,5.831,6.168c2.488-0.537,4.091,1.156,4.243,3.727c0.128,2.148,0.248,5.145-2.634,4.975
			c-8.052-0.469-16.254,1.898-24.163-1.174c-1.453-1.393-0.876-3.084-0.593-4.664c1.354-7.541,1.436-15.084,0.261-22.65
			c-0.41-2.646,0.146-5.254,0.395-7.881C969.249,663.432,970.002,660.381,967.705,657.836z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#BCBCBB"
              d="M500.695,249.736c3.337,0.091,6.991,0.796,7.804-4.018
			c1.616,1.321,3.322,2.385,5.349,3.103c1.473,0.521,3.735,0.418,3.949,2.854c1.054,4.516,4.134,5.798,8.262,4.833
			c5.905-1.381,11.638-2.164,17.161,1.403c2.571,1.661,3.364,0.317,3.577-2.371c0.468-5.912-1.143-11.914,0.803-17.749
			c0.026,8.942,0.019,17.886,0.108,26.827c0.019,1.757,0.018,3.324-1.919,4.078c-2.927-1.338-6.045-2.425-8.158-5.01
			c-1.475-1.806-3.023-1.498-4.749-0.643c-2.53-1.19-4.947-3.84-7.82-0.597c-0.262,0.198-0.519,0.409-0.811,0.549
			c-2.258,1.075-2.527,4.759-5.201,4.753c-2.795-0.006-3.335-3.244-4.906-5.023c-1.046-1.184-2.306-0.984-3.608-0.663
			c-2.442-1.468-2.78-5.033-5.859-5.867c-1.271-1.662-2.614-2.881-4.522-0.756c-0.521,0.422,0.159-0.074-0.37,0.332
			c-8.472,6.515-8.726,8.52-2.157,17.128c1.354,3.541,4.46,3.618,7.436,3.522c4.855-0.155,9.563,0.097,13.8,2.841
			c1.178,0.624,2.088,1.52,2.747,2.673c2.824,6.258,10.337,9.877,18.968,9.124c0.664-0.059-0.158,0.064,0.488-0.08
			c5.681-1.274,6.798-1.698,5.991,5.772c-10.7,4.515-19.985,0.968-28.65-5.27c-3.146-2.265-6.113-4.444-10.23-4.409
			c-4.489,0.039-8.426,1.197-11.753,4.389c-3.223,3.092-6.69,5.913-10.593,8.145c-8.123,0.109-16.248,0.136-24.368,0.366
			c-3.739,0.106-4.978-1.601-4.876-5.121c0.148-5.134,0.038-10.275,0.038-16.841c3.227,5.671,5.703,10.278,9.882,13.537
			c3.161-0.437,6.062,1.178,9.257,1.012c6.972-0.361,11.883-4.271,13.234-11.034c1.229-6.15-0.909-9.465-7.246-11.221
			c-2.562-1.243-3.356-3.825-4.606-6.064c-1.376-3.659-2.988-2.133-4.607-0.201c-0.675,0.895-1.263,1.905-2.55,2.025
			c-2.145-1.079-3.553-0.431-4.263,1.838c-0.166,0.403-0.394,0.768-0.659,1.109c-0.623,0.595-1.351,1.055-2.017,1.597
			c-1.034,0.633-1.818,1.99-3.359,1.193c-1.548-0.922-3.898-1.301-2.736-4.053c4.203-0.023,6.41-3.709,9.584-5.548
			c4.891-2.834,10.002-3.798,15.14-3.082c4.005,0.558,7.327,0.599,8.481-3.463c1.185-4.167-1.648-6.65-5.472-7.937
			c-0.952-1.336-1.092-2.654,0.086-3.943c1.464-0.615,2.928-1.23,4.392-1.845c0.604,0.362,1.215,0.199,1.867,0.125
			c7.057-0.808,7.053-0.795,8.808,5.93C500.002,248.615,500.363,249.175,500.695,249.736z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FCFCFC"
              d="M256.796,750.489c0.002-23.818,0.041-47.639-0.044-71.457
			c-0.011-3.113,0.488-5.215,4.19-5.221c3.581-0.006,4.898,1.498,4.892,5.172c-0.09,47.803-0.092,95.607,0.019,143.412
			c0.008,3.51-1.456,4.98-4.555,5.006c-3.119,0.025-4.599-1.436-4.578-4.957C256.861,798.459,256.793,774.473,256.796,750.489z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#C4B37C"
              d="M85.756,759.911c0.026,7.838,0.051,15.678,0.077,23.518
			c-6.43,2.451-13.174,3.121-19.964,3.283c-6.633,0.16-13.275-0.043-19.911,0.08c-6.93,0.127-11.677-2.797-14.258-9.318
			c-1.46-3.688-3.139-7.297-5.818-10.324c-2.148-2.428-2.411-5.311-1.913-8.355c1.863-1.668,4.024-1.219,6.123-0.764
			c5.01,1.082,10.128,1.523,15.128,2.707c4.24,1.002,8.569,0.766,12.789-0.273c5.665-1.393,11.516-0.998,17.222-1.969
			c1.675-0.285,3.291,0.121,4.661,1.229C81.846,759.786,83.801,759.848,85.756,759.911z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F6F6F7"
              d="M85.707,297.974c-3.773,3.133-8.157,3.825-12.953,3.785
			c-15.832-0.131-31.665-0.022-47.498,0.009c-6.176,0.012-6.545-0.376-6.307-6.426c0.059-1.489-0.072-2.993-0.216-4.48
			c-0.624-6.465-0.086-7.591,6.262-7.704c18.492-0.329,36.99-0.236,55.485-0.412c5.056-0.048,3.225,4.819,5.26,6.891
			C87.832,292.424,87.85,295.203,85.707,297.974z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#7DAAC4"
              d="M67.871,104.688c-0.01,0.06-0.02,0.12-0.03,0.17
			c-0.36,2.03-0.97,3.87-2.91,4.67c-0.32,0.14-0.69,0.25-1.09,0.32c0.3-0.38,0.6-0.77,0.9-1.15c1.01-1.29,2.02-2.59,3.03-3.88
			c-0.15-0.07-0.29-0.19-0.42-0.34c-1.33-2.1-1.07-4.48-1.08-6.78c-0.04-10.46,0.06-20.92-0.05-31.38
			c-0.05-6.08-0.93-6.96-6.95-6.99c-11.62-0.07-23.25-0.1-34.87,0.02c-5.34,0.05-5.88,0.76-6.08,6.11
			c-0.17,4.34,0.46,8.61,0.91,12.88c0.03,0.11,0.07,0.23,0.09,0.34c0.04,0,0.09-0.01,0.13-0.03c-0.01-0.05-0.03-0.1-0.04-0.15
			c0.23-3.6,3.1-2.81,5.26-3.19c0.21,0.04,0.42,0.06,0.64,0.08c1.07,0.09,2.19-0.03,3.26,0.04c-1.26,0.3-2.52,0.69-3.76,1.17
			c-1.55,0.88-4.26-1.31-4.95,1.86v0.01c-0.09,0.23-0.21,0.37-0.35,0.41c-0.04,0.02-0.08,0.03-0.12,0.03c-0.13,0-0.28-0.07-0.44-0.2
			c-3.56-5.66-1.86-11.95-2.05-17.98c-0.07-2.21,1.77-2.95,3.84-2.95c14.12,0.01,28.23-0.01,42.35,0c4.13,0,4.72,2.7,4.72,6.06
			C67.781,77.458,67.84,91.068,67.871,104.688z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F5F5F6"
              d="M21.896,595.688c-0.089-13.539-0.177-27.078-0.266-40.617
			c-0.019-2.844,0.321-5.414,4.075-5.424c2.88-1.969,6.089-1.326,9.19-1.002c2.452,0.258,3.715,2.031,4.186,4.35
			c0.763,3.756,1.366,7.535,1.274,11.385c-0.272,11.463,0.619,22.943-0.739,34.4c-0.511,4.313,0.947,8.559,1.63,12.809
			c0.807,5.018,0.013,6.275-5.074,6.645c-13.699,0.996-13.824,0.842-13.897-12.633C22.258,602.299,22.786,598.961,21.896,595.688z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F6F6F7"
              d="M871.929,42.291c-0.435,4.88-3.831,6.744-7.932,6.294
			c-9.307-1.022-18.606-0.646-27.913-0.764c-3.649-0.046-5.641-2.036-6.438-5.689c-1.193-5.458-0.836-10.944-0.665-16.409
			c0.102-3.242,2.151-4.992,5.617-4.974c10.663,0.058,21.327,0.083,31.989-0.016c3.365-0.032,5.016,1.54,5.144,4.697
			c0.175,4.305,0.132,8.618,0.182,12.929C872.897,39.666,872.835,40.977,871.929,42.291z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F4F4F5"
              d="M25.747,548.833c-3.127-0.314-4.091-2.299-4.129-5.152
			c-0.256-19.262-0.518-38.521-0.852-57.783c-0.063-3.59,1.532-5.221,5.054-5.164c3.656,0.061,7.312,0.08,10.968,0.117
			c1.829,1.883,1.526,4.297,1.563,6.586c0.15,9.115-0.015,18.219,0.856,27.34c0.932,9.756-1.262,19.506-1.001,29.309
			c0.084,3.16-1.604,4.896-5,4.689C30.725,548.622,28.234,548.866,25.747,548.833z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#3E3B3C"
              d="M799.697,237.765c-0.43,2.448,1.674,3.494,2.931,4.827
			c17.729,18.799,35.531,37.531,53.362,56.235c1.354,1.422,2.882,2.922,4.635,3.664c2.796,1.184,0.549,2.177,0.21,3.268
			c-2.204,1.693-4.797,2.384-7.456,2.896c-2.249-0.503-4.422-0.22-6.531,0.683c-0.466-0.042-0.876-0.217-1.23-0.521
			c-2.422,0.273-4.325-0.292-4.891-3.022c0.814-5.148-3.947-12.419-8.874-14.444c-2.263-0.93-1.278,2.785-3.121,1.594
			c-1.689-1.718-3.379-3.436-5.068-5.153c0.293-1.159,1.333-1.668,2.085-2.424c1.446-4.811-2.922-3.844-5.048-5.09
			c-2.388-3.26-3.897-7.624-9.153-7.289c-2.587-0.346-2.896-3.055-4.543-4.376c-1.226-3.865-2.718-7.387-5.62-10.576
			c-2.343-2.574-4.515-3.467-7.631-3.152c-2.322-0.768-4.645-1.536-6.968-2.305c0.381-0.297,0.738-0.63,1.146-0.883
			c4.103-2.545,9.604-3.728,9.281-10.411C797.158,240.154,798.825,238.94,799.697,237.765z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#EBECEE"
              d="M87.4,141.378c-2.16,0.21-4.25,0.74-6.38,1.11
			c-0.27,0.05-0.55,0.09-0.81,0.12c-1.77,0.22-3.36,0.01-4.42-1.86c-4.68-1.84-6.56-5.08-6.38-10.28
			c0.18-5.14,1.11-10.42-0.64-15.52c-1.98,3.58-0.53,7.47-0.99,11.19c-0.28,2.22,0.78,4.67-1.08,6.63c-3.16,0.4-3.16-2.25-3.83-4.11
			c-2.3-6.38-0.03-12.57,0.97-18.81c0.3-0.38,0.6-0.77,0.9-1.15c1.01-1.29,2.02-2.59,3.03-3.88c0.03-0.04,0.07-0.09,0.1-0.13
			c0.29,0.09,0.58,0.08,0.86-0.03c0.1-0.01,0.2-0.02,0.3-0.04c2.56-0.35,4.59-1.94,6.84-2.99c0.38-0.25,0.75-0.49,1.12-0.69
			c4.73-2.79,8.81-2.3,12.94,1.63c1.18,1.12,2.41,1.56,3.95,1.68c1.68,0.14,3.45,0.22,4.45,1.71c0.17,0.24,0.31,0.53,0.43,0.86
			c0.95,2.83,2.18,5.52,3.91,7.96c0.41,0.92,0.73,1.87,0.96,2.84C106.101,128.018,98.21,140.278,87.4,141.378z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F5F5F6"
              d="M71.734,730.661c0.6,0.061,1.199,0.121,1.798,0.18
			c3.665,1.074,4.977,3.529,4.254,7.205c-0.127,0.65-0.202,1.318-0.221,1.98c-0.302,10.221-0.297,10.074-10.658,9.941
			c-12.305-0.16-24.614-0.01-36.921-0.041c-6.143-0.014-7.404-1.543-6.515-7.438c0.193-1.281,0.211-2.639,0.027-3.922
			c-0.852-5.939,0.737-8.029,6.934-7.68C44.212,731.667,57.971,731.549,71.734,730.661z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F6F6F7"
              d="M85.707,297.974c0.011-2.779,0.022-5.559,0.033-8.338
			c0.01-6.753,0.169-6.885,7.052-6.875c13.165,0.018,26.331,0.139,39.492-0.067c4.341-0.067,6.156,1.36,5.718,5.765
			c-0.246,2.468-0.274,5.023,0.078,7.471c0.593,4.122-0.962,5.88-5.089,5.862c-13.998-0.061-27.995-0.038-41.993-0.01
			C88.257,301.787,85.95,301.358,85.707,297.974z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FBFBFB"
              d="M501.921,309.941c-12.998,0-25.996,0.033-38.994-0.027
			c-2.813-0.013-6.263,1.018-6.37-3.941c-0.112-5.179,3.412-4.969,6.74-4.972c21.829-0.023,43.659,0.031,65.489,0.056
			c4.499,0.005,8.999-0.07,13.497,0.001c3.106,0.049,5.542,0.87,5.479,4.808c-0.062,3.829-2.395,4.112-5.347,4.101
			C528.917,309.909,515.419,309.941,501.921,309.941z"
            />
            <g>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill={
                  roomId === 'ae21a914-c12c-41cf-bbad-adf3ebfbc5a8'
                    ? HIGHLIGHT_COLOR
                    : INACTIVE_COLOR
                }
                d="M144.661,219.838c-1.75,5.87-0.49,11.86-0.7,17.78
				c-0.25,6.88-2.41,8.35-9.34,5.53c3.1-0.26,5.18-1.39,5.17-4.73c-0.02-12.47-0.04-24.95,0.02-37.43c0.02-2.56-1.37-3.82-3.56-3.86
				c-2.32-0.05-3.58,1.45-3.46,3.9c0.2,3.97-0.24,7.94,0.55,11.93c1.88,9.55-1.73,13.9-11.58,13.58
				c-5.63-0.19-11.25-1.14-16.89-0.72c-4.42,0.33-5.43-1.85-5.27-5.7c0.22-5.32,0.17-10.64,0.17-15.97c0-5.61-1.49-7.44-7-7.11
				c-11.62,0.7-23.25,0.47-34.88,0.61c-7.6,0.09-7.6,0.11-6.8,8c0.38,0,0.76-0.02,1.14-0.04c0,0.29,0.01,0.59,0.01,0.88
				c-0.14-0.05-0.29-0.11-0.45-0.16c-0.3-0.1-0.51-0.4-0.7-0.68c-0.15,3.97-0.41,7.93-0.4,11.9c0.01,2.75-0.78,3.99-3.81,4.51
				c-5.62,0.98-11.23,0.24-16.82,0.66c-2.17,0.17-5.16-0.78-5.19,2.82c-0.04,3.73,3.08,2.17,4.85,2.21
				c5.4,0.1,10.82-0.13,16.23-0.23c0.11-0.22,0.22-0.44,0.34-0.66c-0.17-0.08-0.35-0.16-0.54-0.25c-5.79-1.78-11.88,0.14-17.73-1.18
				c1.99-1.45,4.16-1.15,6.26-1.2c3.96-0.1,7.94,0.2,11.88-0.6c0.94-0.19,1.78-0.46,2.5-0.81c-0.83,1.33-1.64,2.66-2.37,4.04
				c0.05,0.03,0.1,0.05,0.15,0.08c2.85,1.49,0.93,2.45-1.16,3.13c-5.36,1.73-10.89,1.06-16.37,1.08c5.26,0.36,10.54-0.04,15.8,0.29
				c1.88,0.12,4.54-0.01,4.34,2.84c-0.19,2.54-2.36,3.56-4.76,3.35c-2.78-0.23-5.56-0.31-8.34-0.28c1.24,0.01,2.49,0.08,3.73,0.26
				c0.97,0.14,2.01-0.01,2.95,0.34c1.23,0.44,2.97,0.77,2.93,2.27c0,0.02,0,0.05-0.01,0.07c0.07,0.03,0.15,0.06,0.22,0.1
				c-0.08,0.01-0.15,0.02-0.23,0.03c-0.19,1.41-1.88,1.47-3.09,1.6c-3.48,0.38-6.99,0.03-10.48,0.23c-1.97,0.12-4.94-0.84-5.13,2.38
				c-0.2,3.56,2.86,3.54,5.35,3.65c3.33,0.15,6.66,0.02,9.99,0.07c1.5,0.02,3.01,0.02,4.44,0.52c1.71,0.6,2.97,1.65,2.76,3.64
				c-0.2,1.88-1.74,2.32-3.27,2.49c-3.81,0.43-7.65,0.06-11.47,0.25c-2.06,0.1-4.36-0.42-6.17,1.81c1.69,1.59,3.71,1.19,5.58,1.25
				c2.32,0.08,4.66-0.1,6.99,0.07c2.02,0.14,5.22-0.59,5.42,1.93c0.18,2.22-3.23,1.44-4.87,2.35c-1.48,0.71-1.37,2.13-1.61,3.42
				c-0.06,0.31-0.1,0.66-0.16,0.99v1.58c0.2-0.04,0.4-0.08,0.6-0.13c1.83-0.28,3.69-0.4,5.44,0.23c1.52,0.53,3.2,1.32,0.53,2.77
				c-0.4,0.21-1.32-0.19-0.2,0.75c4.17,3.46-0.38,2.77-1.77,3.54c7.07-0.81,14.21-0.61,21.34-0.5c22.65,0.34,45.21-2.21,67.86-2.03
				c1.67,0.01,3.33,0.06,5,0.02c2.36-0.06,4.24,0.42,5.45,1.69c-1.5-10.74-1.21-21.49,0.08-32.31v-0.03
				C145.061,235.118,143.851,227.438,144.661,219.838z M48.67,222.808c1.24-2.01,2.52-4.02,3.54-6.21
				C52.181,219.688,50.971,221.678,48.67,222.808z"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill={
                  roomId === 'ae21a914-c12c-41cf-bbad-adf3ebfbc5a8'
                    ? HIGHLIGHT_COLOR
                    : INACTIVE_COLOR
                }
                d="M116.187,224.807c-3.548-0.419-6.84-0.077-10.162-0.741
				c-3.688-0.738-5.075-2.934-5.179-6.219c-0.147-4.651-0.194-9.319,0.015-13.966c0.155-3.459,1.699-5.793,5.657-6.343
				c6.647-0.924,13.224-0.784,19.816,0.225c2.612,0.399,3.981,1.953,4.552,4.547c1.125,5.109,0.792,10.256,0.545,15.385
				c-0.192,4-2.305,6.429-6.554,6.614C121.894,224.438,118.914,224.647,116.187,224.807z"
              />
            </g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#838383"
              d="M25.747,548.833c1.645-1.061,3.564-1.141,5.33-0.914
			c4.955,0.639,5.786-1.617,5.92-6.168c0.6-20.309,0.218-40.602-0.209-60.9c-0.025-0.34-0.05-0.68-0.075-1.02
			c1.483,0.453,2.673,1.131,3.063,2.855c0.838,3.717,2.076,7.332,2.088,11.223c0.109,36.75,1.321,73.492,0.856,110.248
			c-0.041,3.273-0.74,6.471-0.183,9.852c0.615,3.734-2.258,5.342-5.555,5.582c-3.308,0.24-6.782,0.602-9.941-0.131
			c-4.183-0.971-4.357,0.902-4.347,4.063c0.009,2.764,0.758,5.652-0.714,8.307c-0.028-12.047-0.055-24.094-0.083-36.141
			c1.572,5.529,0.613,11.182,0.62,16.762c0.004,3.391,1.233,4.361,4.332,4.246c3.313-0.123,6.647-0.121,9.951,0.127
			c4.06,0.303,4.551-1.682,3.419-4.832c-2.179-6.059-2.078-12.223-1.843-18.598c0.492-13.389,1.005-26.82-0.82-40.193
			c-0.32-2.346-1.186-3.141-3.419-3.148c-2.811-0.01-5.62-0.258-8.43-0.404C25.792,549.379,25.807,549.108,25.747,548.833z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FCFCFC"
              d="M597.828,47.641c-0.345-4.947-0.853-9.89-0.993-14.842
			c-0.165-5.788,2.341-8.735,8.151-8.887c5.773-0.15,11.568-0.489,17.381,0.601c2.38,0.446,3.343,1.604,4.37,3.263
			c2.392,5.211,3.355,10.683,3.246,16.409c-0.052,2.677-1.204,4.179-3.811,4.135C616.723,48.163,607.276,47.877,597.828,47.641z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#86847F"
              d="M1221.757,434.771c1.979,5.296-0.976,10.019-1.995,14.893
			c-0.776,3.71-1.591,7.447-4.418,10.797c-1.505,1.782-0.423,6.029-1.854,9.109c-2.894,6.222-5.067,12.976-9.771,17.989
			c-2.91,3.104-1.665,8.207-5.932,10.203c0.049-4.064-3.119-6.561-4.774-9.795c1.541-5.801,5.758-10.273,7.739-15.934
			c1.893-5.408,2.077-5.609-3.853-5.177c-0.481-1.049,0.208-1.735,0.99-1.812c4.474-0.434,6.319-3.615,7.825-7.209
			c2.253-3.681,2.278-8.104,3.99-11.964c0.496-1.313-0.371-2.494-0.374-3.765c0.571-2.284,0.913-4.651,2.43-6.586
			C1215.127,435.737,1218.308,433.456,1221.757,434.771z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F9F9F9"
              d="M966.751,700.69c-3.469,0.262-6.934,0.582-10.406,0.764
			c-2.04,0.107-3.249-0.563-3.612-3.023c-1.749-11.875-0.929-23.793-0.992-35.701c-0.016-3.037,0.931-4.496,4.03-3.848
			c3.724,0.779,8.543-2.656,11.054,2.836c-1.628,5.523,1.729,10.578,1.476,16.014c-0.254,5.445-0.022,10.91-0.072,16.365
			C968.207,696.385,968.479,698.77,966.751,700.69z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F4F4F5"
              d="M871.929,42.291c-0.006-1.311-0.011-2.621-0.017-3.932
			c1.353-3.677,0.745-7.476,0.722-11.243c-0.033-5.253,2.191-7.609,7.18-6.861c5.286,0.793,10.56,0.361,15.833,0.603
			c1.637,1.531,1.588,3.558,1.601,5.549c0.03,4.975,0.075,9.95-0.01,14.923c-0.107,6.354-1.108,7.369-7.609,7.548
			c-1.242,0.034-2.601,0.9-3.806-0.189c-1.3,0.026-2.619-0.086-3.896,0.103C876.647,49.567,873.11,47.768,871.929,42.291z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F6F6F7"
              d="M45.491,104.938c-5.31,0.88-10.54,0.7-15.83,0.04
			c-1.83-0.24-2.8-0.96-2.77-2.77c0.12-6.46-0.6-12.96,0.85-19.38c0.54-2.39,1.66-4.24,4.03-5.12c0.03-0.02,0.07-0.03,0.1-0.04
			c3.26-1.76,6.58,0.07,9.86-0.17c0.03,0.01,0.07,0,0.1,0c7.12,0.67,7.08,0.67,7.14,7.55c0.05,4.8-0.29,9.61,0.65,14.41
			C50.21,102.528,48.92,104.368,45.491,104.938z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#41444A"
              d="M820.837,12.838c0.653,0.962,1.307,1.925,1.959,2.887
			c-71.002,0.017-142.005,0.033-213.006,0.051c4.21-2.173,8.707-0.597,13.058-0.94c2.8-0.222,5.577-0.139,7.909-2.024
			C694.116,12.819,757.477,12.829,820.837,12.838z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F6F6F7"
              d="M48.957,120.476c0,12.933,0,12.933-12.765,13.375
			c-8.445,0.293-9.717-1.023-9.383-9.221c0.155-3.811,0.165-7.644-0.06-11.449c-0.282-4.762,1.921-6.762,6.496-6.579
			c0.498,0.021,0.998,0.003,1.497,0C48.95,106.509,48.957,106.516,48.957,120.476z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#CDCDCC"
              d="M23.783,442.941c1.044,5.436,3.623,9.329,9.334,11.096
			c4.385,1.356,8.019,3.844,8.159,9.425c0.067,2.658,2.103,4.793,3.665,6.946c1.85,2.55,3.734,5.081,5.443,7.726
			c1.181,1.826,2.549,4.336,0.732,5.928c-2.122,1.857-3.405-1.025-4.713-2.246c-5.045-4.713-10.644-6.945-17.67-5.27
			c-5.484,1.309-6.904-0.037-6.939-5.622c-0.057-8.823-0.101-17.646-0.149-26.469C21.643,442.942,22.452,442.573,23.783,442.941z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F6F6F7"
              d="M39.114,134.818c9.843,0.266,9.839,0.266,9.846,9.923
			c0.001,2.651,0.109,5.271,0.537,7.924c0.831,5.152-1.582,7.842-6.785,8.103c-14.96,0.749-15.748,0-15.771-15.023
			C26.924,134.489,26.924,134.489,39.114,134.818z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F5F5F6"
              d="M24.792,208.315c0.001-0.832-0.008-1.665,0.003-2.497
			c0.122-9.26,1.469-10.362,10.739-8.425c2.062,0.431,3.874-0.007,5.845-0.421c5.79-1.219,8.208,0.771,8.097,6.646
			c-0.075,3.972-0.355,7.956-0.833,11.899c-0.404,3.34-2.219,5.435-5.952,5.353c-3.326-0.074-6.657,0.109-9.983,0.044
			c-6.239-0.122-7.824-1.785-7.913-8.105C24.772,211.312,24.79,209.813,24.792,208.315z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F5F5F5"
              d="M613.958,131.558c3.503-0.153,6.607,3.416,10.36,1.114
			c0.218-0.134,0.782-0.061,0.942,0.125c2.588,3.008,1.257,6.646,1.505,10.019c0.165,2.25-0.859,4.185-3.325,4.033
			c-8.311-0.509-16.76,2.758-24.918-0.758c-1.89-0.814-4.795,0.997-5.461-1.003c-1.017-3.051-0.98-6.501-1.088-9.791
			c-0.074-2.288,1.759-3.01,3.71-3.188c2.643-0.242,5.294-0.373,7.942-0.551C607.069,129.842,610.513,129.842,613.958,131.558z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#D6CBA1"
              d="M1171.727,541.809c1.588-0.23,2.896,0.219,4.062,1.375
			c1.155,1.146,2.584,2.773,4.198,1.895c1.612-0.877,0.77-3.031,0.783-4.607c0.075-8.803,0.006-17.609,0.073-26.414
			c0.014-1.768-0.55-3.682,0.785-5.275c0.006,21.998,0.012,43.998,0.018,65.996c-1.939-2.82-0.037-6.193-1.284-9.178
			c-2.594-0.148-3.859,1.854-5.208,3.393c-5.34,6.096-12.217,6.607-19.559,5.754c-1.519-0.176-2.253-1.127-3.013-2.295
			c-1.076-1.654-2.031-3.555-3.958-4.367c-5.314-2.242-4.414-6.105-2.824-10.205c5.144-2.428,7.3-1.25,7.986,4.348
			c0.071,1.186-1.91,1.912-1.421,2.453c0.974,1.078,2.406,0.215,3.601-0.316c2.073-0.006,4.146-0.232,6.218,0.027
			c2.179,1.199,4.346,0.996,5.813-0.918c3.531-4.609,5.896-9.625,3.652-15.623C1170.882,545.799,1170.031,543.803,1171.727,541.809z
			"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FDFDFD"
              d="M241.791,452.776c0-9.806,0.036-19.612-0.023-29.418
			c-0.016-2.625,0.355-4.592,3.659-4.618c3.542-0.027,3.384,2.264,3.38,4.717c-0.029,19.446-0.053,38.892,0.026,58.337
			c0.011,2.727-0.528,4.621-3.637,4.656c-3.345,0.037-3.453-2.207-3.434-4.756C241.833,472.055,241.79,462.416,241.791,452.776z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F1F1F1"
              d="M1002.855,24.831c7.304-0.012,14.607-0.022,21.911-0.033
			c2.605,6.891,1.898,13.864,0.531,20.857c-6.571,4.019-13.572,2.967-20.535,1.563c-2.153-0.435-3.092-2.309-3.285-4.466
			c-0.397-4.424-1.428-8.798-1.052-13.283C1000.461,27.519,1000.849,25.749,1002.855,24.831z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FDFDFD"
              d="M248.79,580.27c0,9.832-0.053,19.664,0.036,29.496
			c0.023,2.541-0.418,4.189-3.451,4.182c-2.907-0.006-3.628-1.441-3.619-4.08c0.063-19.832,0.076-39.664-0.011-59.494
			c-0.012-2.861,1.074-3.801,3.815-3.818c3.17-0.02,3.335,1.74,3.311,4.217c-0.097,9.832-0.04,19.664-0.04,29.498
			C248.817,580.27,248.804,580.27,248.79,580.27z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FDFDFD"
              d="M241.791,322.769c0-9.666,0.052-19.332-0.035-28.997
			c-0.022-2.512,0.342-4.184,3.417-4.192c2.869-0.008,3.672,1.231,3.661,3.955c-0.081,19.832-0.08,39.663-0.002,59.494
			c0.011,2.719-0.794,3.965-3.672,3.952c-3.099-0.015-3.419-1.728-3.4-4.215C241.837,342.767,241.791,332.768,241.791,322.769z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#CACAC9"
              d="M485.829,299.606c6.585-6.264,11.975-14.356,22.768-13.919
			c3.183,0.129,6.073,0.895,8.279,2.873c8.014,7.186,17.087,10.32,27.79,8.235c0.636-0.124,1.325,0.028,1.989,0.051
			c0.764,0.611,0.658,1.307,0.199,2.036c-0.398,0.928-1.233,1.024-2.067,1.021C525.134,299.811,505.481,299.708,485.829,299.606z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#262222"
              d="M817.782,193.567c1.631,0.05,3.262,0.125,4.894,0.143
			c2.23,0.025,4.7-0.81,6.165,1.811c1.622,2.902,1.353,5.901-0.491,8.394c-3.308,4.471-6.586,9.007-10.724,12.8
			c-0.835,2.121-2.364,3.62-4.596,2.57c-4.371-2.056-8.753-0.322-13.111-0.557c-1.33-0.625-1.091-1.85-1.131-2.967
			c3.978-6.185,10.833-10.276,13.226-17.521C813.056,195.086,814.616,193.599,817.782,193.567z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FCFCFC"
              d="M633.75,235.767c6.657,0,13.317,0.107,19.971-0.047
			c3.203-0.074,4.646,1.371,4.745,4.354c0.111,3.313-1.508,4.701-4.904,4.673c-13.148-0.107-26.298-0.091-39.447-0.011
			c-3.266,0.02-5.118-1.136-5.016-4.579c0.099-3.353,1.876-4.532,5.179-4.442C620.764,235.891,627.258,235.767,633.75,235.767z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FBFBFB"
              d="M369.79,235.767c6.167,0,12.337,0.146,18.497-0.058
			c3.422-0.112,5.078,1.419,5.113,4.569c0.037,3.283-1.875,4.505-5.17,4.473c-12.499-0.122-24.999-0.088-37.499-0.022
			c-3.159,0.017-5.052-0.791-5.005-4.475c0.046-3.62,1.901-4.549,5.066-4.51C357.124,235.822,363.458,235.768,369.79,235.767z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F3F3F4"
              d="M1027.797,24.776c3.728-0.314,7.453-0.869,11.182-0.885
			c5.283-0.022,8.593,3.22,7.304,8.124c-1.298,4.937,0.934,6.932,4.554,8.741c1.666,4.675,0.442,6.958-4.261,7.375
			c-3.254,0.287-6.552,0.336-9.81-0.238c-0.484-0.094-0.966-0.296-1.444-0.326c-9.063-0.567-9.153-0.811-8.96-11.257
			C1026.433,32.451,1025.168,28.345,1027.797,24.776z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#EDEDED"
              d="M21.763,31.739c3.5-1.045,7.116-0.95,10.701-0.977
			c10.486-0.077,14.621,5.626,11.473,15.741c-0.339,1.091-0.811,2.14-1.221,3.208c-1.725-0.373-2.681-0.989-4.157-2.676
			c2.164,3.509,1.534,5.148-2.3,5.287c-3.451,0.125-6.936-0.021-10.352-0.628c-3.355-0.597-7.306-6.76-7.311-11.317
			C18.592,37.132,19.603,34.214,21.763,31.739z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#595757"
              d="M983.813,810.805c-9.291,0-18.582,0.045-27.872-0.049
			c-1.396-0.014-3.917,1.008-3.786-1.65c0.102-2.072,2.372-1.289,3.707-1.295c12.484-0.061,25.002-0.574,37.439,0.191
			c6.376,0.391,12.446,0.162,18.599-1.166c1.276-0.275,2.662-0.17,3.984-0.068c1.771,0.137,4.31,0.104,4.323,2.25
			c0.013,2.121-2.464,1.566-4.045,1.695c-0.777,0.063-1.555,0.129-2.313,0.982c10.645,0,21.289,0,32.445,0
			c-3.375,2.861-66.856,2.975-82.54,0.049c6.688,0,13.377,0,20.065,0C983.818,811.432,983.815,811.12,983.813,810.805z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FCFCFC"
              d="M1271.891,414.067c0-2.323,0.057-4.647-0.012-6.969
			c-0.159-5.395,1.509-7.084,7.059-7.03c5.673,0.054,7.063,1.526,6.983,7.063c-0.071,4.988-0.068,9.981,0.056,14.967
			c0.121,4.868-2.794,5.768-6.802,5.701c-3.984-0.065-7.477-0.489-7.327-5.76c0.075-2.655,0.014-5.314,0.014-7.972
			C1271.871,414.067,1271.881,414.067,1271.891,414.067z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FCFCFC"
              d="M1270.916,201.187c-0.003-0.938-0.006-1.878-0.009-2.817
			c2.342-2.002,1.092-4.581,1.015-6.91c-0.197-5.904,0.921-7.521,5.701-7.726c6.829-0.294,8.065,0.749,8.365,7.059
			c-0.016,4.937-0.247,9.884,0.016,14.806c0.251,4.698-2.781,5.576-6.226,5.781c-5.445,0.323-7.62-1.475-7.967-5.727
			C1271.69,204.162,1272.892,202.323,1270.916,201.187z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F4F4F5"
              d="M469.86,265.807c0.674-0.704,1.349-1.409,2.022-2.113
			c1.94-1.417,3.905-1.597,5.903-0.119c1.148,2.188,3.196,3.84,3.784,6.36c3.053,2.777,3.612,6.631,4.163,10.358
			c0.39,2.646-0.782,5.133-3.376,6.095c-3.055,1.133-6.215,2.448-9.646,1.451c-1.596-1.305-3.227-1.424-4.899-0.147
			c-5.638-5.506-4.671-11.969-2.699-18.58C465.806,266.733,467.441,265.706,469.86,265.807z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E2E2E2"
              d="M1279.396,395.867c0-60.06,0-120.119,0-180.179
			c0.281-0.001,0.563-0.002,0.844-0.004c0,60.086,0,120.171,0,180.257C1279.958,395.917,1279.677,395.892,1279.396,395.867z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#515050"
              d="M30.798,404.752c18.633,0.31,37.266,1.725,55.899-0.012
			c1.347-6.557,0.306-13.186,0.585-19.775c0.134-3.151,0.038-6.313,0.02-9.471c-0.022-3.944,1.59-3.866,4.09-1.689
			c0.694,1.19,0.921,2.513,0.922,3.848c0.013,19.152,0.396,38.307-0.18,57.458c-0.469,1.13-0.496,2.998-2.369,2.371
			c-1.316-0.44-1.73-1.859-1.65-3.326c0.226-4.137,0.483-8.277-0.145-12.405c-0.109-2.322-0.572-4.694-0.252-6.957
			c0.641-4.54-1.468-5.811-5.555-5.823c-12.817-0.038-25.634-0.238-38.451-0.375c-2.234-1.664-4.81-0.759-7.209-0.816
			C33.968,407.717,31.939,407.206,30.798,404.752z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#67899C"
              d="M58.946,699.67c7.705,6.83,8.104,15.547,5.648,24.537
			c-1.014,3.709,0.555,4.496,3.199,5.311c-12.005,0.014-24.011,0.027-36.016,0.043c4.103-1.578,6.772-3.98,5.1-8.875
			c0.209-0.186,0.419-0.369,0.626-0.559c6.173-1.65,12.567-0.158,18.766-1.324c1.222-0.607,5.55,2.488,3.024-2.564
			c-0.543-0.742-0.974-1.523-0.885-2.49c1.288-2.314,1.001-4.328-1.145-5.986c-0.686-1.262-1.107-2.58-0.832-4.043
			C57.26,702.366,57.483,700.631,58.946,699.67z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5A5959"
              d="M783.781,252.445c-17.334-0.637-34.415,3.11-51.69,2.333
			c-22.531-1.013-45.046-2.591-67.625-2.083c-0.715,0.016-1.437-0.316-2.901-0.663c2.078-1.647,3.757-1.203,5.263-1.205
			c32.274-0.048,64.55-0.028,96.824-0.127c3.949-0.013,7.893-0.679,11.845-0.941C778.525,249.559,781.309,250.381,783.781,252.445z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F6F7F8"
              d="M1015.715,781.606c3.967,4.484,7.87,9.053,12.768,12.566
			c2.015,1.443,2.723,3.213,2.455,5.539c-1.851,2.064-3.548,4.301-5.595,6.15c-2.284,2.064-4.298,0.557-5.898-1.219
			c-2.644-2.934-5.205-5.93-8.622-8.023c-2.151-0.434-3.431-1.762-3.953-3.863C1006.332,784.959,1006.423,784.846,1015.715,781.606z
			"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#67899C"
              d="M122.65,166.008c-0.43,5.28-4.2,5.02-7.95,4.79
			c-7.54,3.34-7.5,3.32-13.05-3.64c-1.04-1.3-1.66-3.11-3.5-3.62c0.01,0.01,0.01,0.01,0.02,0.02c1.55,5.85,6.16,9.97,10.62,12.47
			c2.84,1.59,4.33,3.35,5.02,5.28c0.18-0.71,0.56-1.28,1.43-1.32c0.87-0.04,1.26,0.42,1.47,1.04v0.01c0.11,0.31,0.17,0.67,0.22,1.02
			c-0.54,1.05-0.86,2.27-0.92,3.71c-0.53-1.68,0.46-3.61-0.78-5.23c-1.48,2.88,0.85,6.33-1.52,9.01c0-2.17,0.06-4.33,0-6.5
			c-0.01-0.31-0.03-0.61-0.07-0.92c-0.15-1.38-0.63-2.66-2.18-3.14c-6.68-2.07-9.59-8.24-14.02-12.75c-0.94-0.95-1.95-2.2,0.43-2.77
			c0.06-0.02,0.12-0.04,0.17-0.05c2.27-0.64,3.07,1.33,4.14,2.61c5.19,6.17,5.35,6.4,11.75,3.94c0.13-0.05,0.27-0.11,0.41-0.16
			c0.1-0.04,0.21-0.08,0.31-0.12c3.17,0.06,6.01-0.49,7.35-3.84c0.02-0.05,0.04-0.1,0.06-0.16c0.07-0.06,0.14-0.12,0.2-0.18
			c2.6-2.42,4.12-4.92,2.09-7.73c-0.16-0.22-0.33-0.43-0.53-0.65c-0.27-0.3-0.58-0.6-0.93-0.91c-1.61-1.88-3.5-3.57-4.5-5.85
			c-0.26-0.57-0.46-1.19-0.58-1.85c-0.01-0.02-0.02-0.05-0.02-0.07c-0.1-0.91-0.13-1.81,0.21-2.62c0.15-0.33,0.36-0.65,0.65-0.94
			c0.02,0.53,0.05,1.05,0.07,1.58c0.03,0.77,0.06,1.54,0.09,2.3c1.14,2.73,3.22,4.78,5.04,7.02
			C128.371,161.368,128.371,161.368,122.65,166.008z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#EDEDEE"
              d="M898.867,20.816c6.107-0.388,12.213-0.798,18.322-1.151
			c2.796-0.162,5.104,0.339,4.497,4.03c-3.324,2.219-7.208,2.164-10.92,2.355c-3.459,0.179-4.524,1.686-4.595,4.933
			c-0.215,9.813-0.295,9.77,9.632,10.388c3.133,0.195,6.806,0.111,5.36,5.186c-1.336,1.786-3.286,2.126-5.302,2.165
			c-4.624,0.091-9.249,0.03-13.874,0.029c-2.225-0.001-4.175-0.674-4.34-3.077c-0.531-7.732-0.761-15.481,0.157-23.209
			C897.88,21.837,898.422,21.311,898.867,20.816z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E2E2E2"
              d="M1280.433,432.042c0,49.485,0,98.97,0,148.456
			c-0.295,0-0.591,0-0.886,0c0-49.486,0-98.971,0-148.456C1279.842,432.042,1280.138,432.042,1280.433,432.042z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#787777"
              d="M262.302,254.839c-6.483,0-12.97-0.147-19.446,0.051
			c-4.514,0.139-6.209-1.677-6.129-6.199c0.239-13.442-1.667-13.199,13.327-12.979c10.968,0.162,21.94-0.052,32.911-0.021
			c5.493,0.016,6.653,1.226,6.818,6.759c0.059,1.993,0.039,3.99,0.003,5.984c-0.076,4.267-2.247,6.363-6.539,6.343
			c-6.981-0.033-13.963-0.009-20.945-0.009C262.302,254.791,262.302,254.815,262.302,254.839z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#4B4843"
              d="M1036.954,46.566c2.492-0.025,4.995-0.219,7.472-0.041
			c5.801,0.416,6.181,0.144,6.41-5.769c0.6-0.01,1.199-0.019,1.799-0.028c2.375,5.106,7.026,4.176,11.212,4.029
			c5.613-0.195,11.212-0.843,16.832-0.177c0.047,1.033,0.094,2.067,0.141,3.102c-26.296,1.912-52.625,2.401-78.979,1.888
			c-2.196-0.773-3.546-2.369-2.643-4.569c0.751-1.832-0.362-3.639,0.533-5.314c0.017-0.968,0.033-1.936,0.05-2.902
			c-0.416-2.963-1.726-6.154,2.056-8.045c0.188,4.399,0.568,8.815,0.685,13.18c0.103,3.813,1.641,4.19,4.559,4.419
			c5.908,0.464,11.768,0.343,17.607-0.671C1028.745,46.383,1032.876,46.117,1036.954,46.566z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#B2A471"
              d="M79.891,759.723c-8.042,0.762-16.082,1.223-24.085,2.723
			c-9.125,1.711-17.945-2-26.925-3.131c-1.633-0.207-3.274-0.35-4.912-0.521c0.183-2.887,2.401-2.83,4.356-2.836
			c15.311-0.043,30.622-0.029,45.934-0.01C76.943,755.95,79.208,756.684,79.891,759.723z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FDFDFD"
              d="M242.725,707.252l0.032-0.371c0,0-0.355-0.066-0.354-0.066
			c-1.353-10.049-0.333-20.145-0.536-30.219c-0.042-2.057,1.627-2.752,3.454-2.727c2.07,0.029,3.479,0.865,3.475,3.242
			c-0.02,9.133,0.116,18.268-0.059,27.396C248.65,709.045,245.586,708.194,242.725,707.252z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#54514F"
              d="M898.867,20.816c-1.142,8.077,0.093,16.169-0.147,24.252
			c-0.03,1.019,0.127,2.207,1.397,2.203c6.957-0.023,14.112,2.616,20.782-1.46c1.188-1.997-0.506-3.84-0.323-5.783
			c-0.004-2.06,0.804-3.855,1.828-5.58c0.337-0.663,0.815-1.214,1.376-1.696c1.707-0.541,2.182-2.347,3.547-3.53
			c1.706,2.704,1.846,5.604,1.656,8.525c-0.165,2.54-0.256,2.534-3.223,2.319c-1.619-0.117-2.447,2.28-2.043,5.917
			c1.039,1.208,1.188,2.407-0.039,3.592c-12.646,0.022-25.291,0.045-37.938,0.068c0.013-0.32,0.039-0.639,0.081-0.956
			c10.06-2.154,10.084-2.154,10.049-12.993c-0.016-4.946-0.146-9.892-0.225-14.837C896.72,20.844,897.794,20.83,898.867,20.816z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#9DAABE"
              d="M723.807,25.758c-1.165-3.174,0.773-4.606,3.325-4.654
			c12.799-0.24,25.602-0.33,38.401-0.233c3.334,0.025,4.466,1.926,2.326,4.95c-3.033,1.48-6.382,1.063-9.577,1.598
			c-9.782-0.297-19.563-0.444-29.345-0.299C727.043,27.147,725.294,27.063,723.807,25.758z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#9FA0A0"
              d="M472.71,287.839c2.194-0.654,4.354-1.479,6.59-1.926
			c4.972-0.991,6.052-2.504,4.772-7.476c-0.736-2.859-1.661-5.671-2.502-8.503c4.411-2.297,6.556,1.2,7.981,3.931
			c4.28,8.194-2.802,19.531-12.104,19.908c-3.824,0.154-7.655,0.279-10.944-2.226C467.665,288.792,471.572,290.63,472.71,287.839z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#969696"
              d="M1058.713,17.644c4.378-0.574,8.755-0.617,13.128,0.049
			c5.431,0.021,0.4,2.735,1.028,3.993c-0.854,2.592-2.646,2.802-4.717,1.659c-3.868-2.137-7.284-0.865-10.709,1.099
			c-1.001,0.573-1.52,1.238-1.262,2.375c0.114,0.508,0.45,0.983,0.994,0.944c0.986-0.07,1.161-1.014,1.598-1.687
			c0.912-1.406,0.962-3.978,3.577-3.286c2.526,0.669,2.458,2.715,2.094,4.854c-0.594,3.476,1.336,2.765,3.102,1.644
			c2.575-1.635,4.346-1.251,5.412,1.684c-0.941,3.039-3.263,3.995-6.151,3.98c-1.827-0.009-3.692,0.04-5.474-0.294
			c-2.767-0.519-7.247,1.805-7.97-1.571c-0.753-3.521-3.817-7.854,1.028-11.955C1055.623,20.089,1055.822,17.337,1058.713,17.644z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#49443C"
              d="M126.596,277.91c-31.279,2.113-62.561,4.007-93.822,0
			C64.048,277.91,95.322,277.91,126.596,277.91z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#AEAFAE"
              d="M546.854,298.883c-0.065-0.679-0.131-1.358-0.197-2.036
			c0.775-4.464-0.15-6.057-5.289-4.584c-8.486,2.432-19.321-3.43-20.549-10.363c3.469-2.694,5.458,0.635,7.947,1.937
			c6.157,4.55,11.396,3.073,12.486-3.545c0.834-2.753,1.862-5.328,5.265-5.712c1.796,2.602-0.038,5.683,1.106,8.37
			C546.996,288.242,548.723,293.649,546.854,298.883z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#727986"
              d="M723.807,25.758c10.124,0.023,20.247,0.037,30.37,0.082
			c1.639,0.007,3.337-0.3,4.861,0.635c-0.191,0.468-0.519,0.811-0.958,1.049c-3.886,1.09-7.865,0.285-12.261,0.649
			c4.508,0.688,8.599-0.097,12.654,0.291c0.177,0.159,0.352,0.321,0.525,0.483c-0.737,4.971,1.156,10.189-1.323,15.718
			c-1.465-2.129-0.886-3.908-0.979-5.537c-0.097-1.66-0.136-3.338,0.001-4.991c0.212-2.582-0.835-3.397-3.425-3.519
			c-9.512-0.445-9.507-0.543-9.502,8.496c0.004,8.494,0.747,9.367,9.371,11.016c-3.654,2.331-9.234,1.288-10.738-2.575
			c-1.471-3.775-3.133-7.806-1.977-12.042c1.274-4.676-0.535-6.797-5.137-7.38c-3.877-0.491-7.583,0.84-11.402,0.701
			C723.86,27.809,723.834,26.783,723.807,25.758z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#958961"
              d="M46.44,226.938c-0.17,0.19-0.33,0.38-0.49,0.58
			c0.11-0.22,0.22-0.44,0.34-0.66C46.34,226.888,46.391,226.908,46.44,226.938z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#958961"
              d="M48.661,222.818c-0.83,1.33-1.64,2.66-2.37,4.04
			c-0.17-0.08-0.35-0.16-0.54-0.25c-5.79-1.78-11.88,0.14-17.73-1.18c1.99-1.45,4.16-1.15,6.26-1.2c3.96-0.1,7.94,0.2,11.88-0.6
			C47.101,223.438,47.94,223.168,48.661,222.818z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#958961"
              d="M52.21,216.598c-0.03,3.09-1.24,5.08-3.54,6.21
			C49.911,220.798,51.19,218.788,52.21,216.598z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#958961"
              d="M52.241,206.488c-0.14-0.05-0.29-0.11-0.45-0.16
			c-0.3-0.1-0.51-0.4-0.7-0.68c0.38,0,0.76-0.02,1.14-0.04C52.23,205.898,52.241,206.198,52.241,206.488z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#ECECED"
              d="M1080.679,44.581c-8.714,2.319-17.6,1.554-26.415,1.194
			c-3.474-0.141-1.718-3.13-1.629-5.047c1.716-1.594,3.736-1.778,5.966-1.496c5.404,0.683,10.828,0.535,16.24,0.072
			C1078.725,38.973,1081.504,39.753,1080.679,44.581z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#979898"
              d="M256.73,218.702c3.728,2.653,2.692,6.928,2.917,10.399
			c0.25,3.872,1.504,4.655,5.032,4.597c10.128-0.167,20.262-0.014,30.392,0.076c1.601,0.014,3.35-0.538,4.755,0.783
			c-19.355,0.03-38.71,0.06-58.066,0.089c1.364-1.027,1.981-2.063,1.219-3.968c-0.413-1.033-1.996-2.465-0.252-3.814
			c0.331-0.022,0.668-0.023,0.964,0.106c8.112,3.538,8.083,3.532,10.51-5.244C254.56,220.429,254.647,218.664,256.73,218.702z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#A1A2A2"
              d="M37.731,441.819c0.972-0.613,1.953-1.194,3.182-0.925
			c6.58,1.532,13.32,0.821,19.965,1.438c2.182,0.203,4.703-0.521,6.113,2.032c-1.583,2.397-4.122,1.833-6.292,1.727
			c-4.168-0.204-7.816,0.792-10.979,3.51c-1.708,0.627-2.588,1.748-2.609,3.675c-0.017,1.593,0.098,3.331-1.389,4.517
			c-0.458-5.222-3.912-7.158-8.538-8.119c-3.56-0.739-5.707-3.155-6.434-6.76C33.02,442.183,35.635,443.66,37.731,441.819z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#939393"
              d="M921.544,39.591c0.067,2.103,0.646,4.258-0.645,6.221
			c-0.073-1.813-0.451-3.329-2.812-3.078c-0.656,0.069-1.325,0.004-1.989,0.001c-11.249-0.037-13.996-3.648-11.286-14.753
			c0.591-2.421,1.819-3.515,4.503-3.513c4.124,0.004,8.248-0.49,12.371-0.773c-0.037,1.064-0.074,2.13-0.111,3.194
			c-3.612,2.703-8.307-0.068-12.588,2.396c4.528,2.12,8.882,0.388,12.927,1.371c0.257,0.023,0.515,0.028,0.772,0.015
			c0.094,0.836,0.108,1.714,1.094,2.081c-0.046,0.707-0.093,1.414-0.14,2.121c-4.773,0.009-9.108,4.172-14.318,0.845
			c0.471,3.156,2.456,2.628,4.056,2.802C916.107,38.815,919.011,37.796,921.544,39.591z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#BBBABA"
              d="M952.607,783.641c6.599-4.977,14.317-3.176,21.663-3.5
			c1.95-0.086,3.956,0.346,5.885,0.771c1.993,0.438,3.975,1.113,3.378,3.898c-3.611,0.408-7.067,0.996-9.505,4.238
			c-1.178,1.566-2.865,1.275-4.318,0.023c-1.535-3.816-3.335-2.033-5.124-0.23c-0.94,1.338-2.334,2.07-3.733,2.793
			c-0.838,0.205-1.6,0.182-2.084-0.686c-0.2-0.254-0.544-0.436-0.65-0.699C956.392,785.983,956.399,785.979,952.607,783.641z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#B0AFAF"
              d="M966.751,700.69c0.015-8.131,0.168-16.266-0.039-24.393
			c-0.063-2.494,1.863-6.104-2.978-7.006c-1.338-0.25-0.127-2.021,0.364-2.941c0.841-1.578,2.608-2.615,2.726-4.633
			c1.98-0.912,0.972-2.5,0.881-3.881c2.733,0.252,3.156,2.438,2.924,4.336c-1.602,13.078,2.141,26.387-1.716,39.354
			C968.192,701.247,967.472,700.969,966.751,700.69z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#8D8B8B"
              d="M21.763,31.739c-0.06,0.821,0.182,1.855-0.225,2.431
			c-4.423,6.252-1.128,11.952,2.852,15.84c2.559,2.499,8.02,2.435,12.339,1.243c0.916-0.252,1.798-0.627,2.991-1.051
			c-0.999-2.457-4.996-3.078-3.693-6.456c3.983,0.075,3.581,4.974,6.689,5.966c0.313,1.143,2.446,2.737-0.671,3.064
			c-7.043,0.739-14.159,0.909-20.947-1.159c-5.688-1.733-6.796-7.396-4.517-16.209C17.392,32.272,18.825,31.261,21.763,31.739z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E8E8E8"
              d="M1072.958,30.972c-1.692-0.617-3.131-3.268-5.182-0.062
			c-1.049,1.641-3.297,1.312-4.955,0.189c-2.145-1.452-0.11-2.498,0.5-3.631c0.829-1.538,0.957-3.232-0.891-3.645
			c-2.719-0.606-1.855,2.378-2.871,3.552c-0.813,0.94-0.195,3.036-1.928,3.026c-1.327-0.007-2.138-1.371-2.818-2.536
			c-0.875-1.499-0.508-3.037,0.351-4.216c1.415-1.946,10.911-3.3,13.049-1.536c2.074,1.711,3.194,0.547,4.656-0.429
			C1075.763,24.754,1075.3,27.854,1072.958,30.972z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F4F4F4"
              d="M603.645,130.942c-3.642-0.246-7.284-0.481-10.923-0.767
			c-0.198-0.016-0.363-0.45-0.742-0.952c1.267-1.048,2.771-0.618,4.103-0.624c10.161-0.042,20.327,0.17,30.48-0.116
			c4.117-0.116,5.358,1.365,4.993,5.193c-0.445,4.677-0.659,9.375-1.04,15.063c-3.592-3.542-2.707-6.951-2.715-10.069
			c-0.02-8.104-0.276-8.43-8.394-8.151c-1.826,0.063-3.648,0.278-5.472,0.424C610.505,131.449,607.075,131.448,603.645,130.942z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E2E2E2"
              d="M255.136,291.068c0,21.487,0,42.975,0,64.461
			c-0.3,0.002-0.601,0.003-0.901,0.005c0-21.504,0-43.007,0-64.51C254.535,291.039,254.835,291.054,255.136,291.068z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F7F7F7"
              d="M655.703,251.756c-14.444,3.54-28.883,2.918-43.286,2.937
			c-1.156,0.002-2.933,0.642-2.745-1.644c0.151-1.836,1.663-1.265,2.67-1.267C626.796,251.744,641.25,251.756,655.703,251.756z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#AAB9D0"
              d="M794.124,22.771c3.056,0.17,7.009-1.281,6.857,4.277
			c-0.103,3.73-1.935,4.939-5.361,4.578c-3.6-0.379-8.708,1.704-8.723-4.774C786.886,21.696,790.962,22.822,794.124,22.771z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#EEEEEE"
              d="M389.243,252.911c-13.496,2.153-27.042,1.983-40.587-0.034
			C362.15,250.654,375.661,250.418,389.243,252.911z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#B3A473"
              d="M39.82,260.958v8.59c-3.47,0.12-6.94,0.2-10.41,0.38
			c-3.23,0.17-3.91-1.71-3.81-4.41c0.09-2.44,0.18-4.51,3.53-4.84c5.37-0.54,8.53,1.28,8.32,7.8
			C39.781,265.718,36.9,262.548,39.82,260.958z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#4E4C4D"
              d="M999.018,22.711c-0.119-0.957-0.238-1.913-0.357-2.87
			c15.336-1.587,30.659,0.866,46.001,0.046c-8.911,3.327-18.096,3.9-27.199,1.714c-6.139-1.475-11.752-0.189-17.52,1.182
			C999.595,23.268,999.284,23.269,999.018,22.711z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#9BA8BD"
              d="M645.796,88.843c-2.275,0.062-4.396-0.349-6.103-2.012
			c-2.276-2.84-2.184-10.344,0.158-12.705c2.08-2.099,5.866-1.216,7.511,1.752C649.116,79.042,648.316,85.669,645.796,88.843z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#797879"
              d="M717.751,752.825c2.196,4.953,6.186,8.359,10.165,11.775
			c-0.728,1.227-1.877,1.338-3.127,1.182c-1.749-0.305-2.334,1.129-3.2,2.143c-4.663-3.99-9.127-8.176-12.903-13.043
			C711.46,753.108,715.188,755.53,717.751,752.825z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#68655F"
              d="M799.919,218.728c4.302-1.319,8.569-3.41,13.025-0.443
			c2.208,1.47,3.141-0.982,4.682-1.57c0.185,1.422-0.271,2.998,0.996,4.161c4.715,2.336,7.972,6.356,11.39,10.128
			c1.265,1.396,3.274,3.479-0.192,4.992c-5.672-4.932-10.287-11.028-16.702-15.139C808.98,218.515,804.105,220.758,799.919,218.728z
			"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F3F3F3"
              d="M969.702,788.678c1.795,0.594,3.039,0.75,4.471-1.328
			c2.171-3.15,5.442-4.645,9.36-2.539c0.996,3.26,0.322,4.756-3.547,5.643c-6.396,1.465-12.661,2.256-19.134,1.182
			c1.015-0.986,2.029-1.975,3.045-2.963C965.833,787.295,967.769,787.102,969.702,788.678z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#A2B0C6"
              d="M802.872,83.669c-0.313-2.933-0.843-5.864-0.873-8.8
			c-0.026-2.507,0.434-5.001,3.9-5.175c3.401-0.17,3.681,2.313,3.844,4.571c0.2,2.782-0.041,5.597-0.091,8.397
			C807.813,85.841,805.441,85.418,802.872,83.669z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#465964"
              d="M1265.728,726.825c-8.335-6.641-14.56-15.617-23.672-21.398
			c-1.847-1.172-1.216-2.627-0.167-3.787c1.659-1.836,2.585-0.166,3.446,1.021c5.636,7.773,13.204,13.566,20.27,19.877
			C1265.646,723.967,1265.687,725.397,1265.728,726.825z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#9CABBF"
              d="M726.839,84.762c-0.021-3.498-0.085-6.996-0.05-10.494
			c0.051-5.133,1.022-5.681,5.997-3.496c2.586,3.323,2.912,9.979,0.481,12.664C731.513,85.375,729.46,86.654,726.839,84.762z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#3D3E44"
              d="M645.796,88.843c1.66-3.836,0.822-7.813,0.633-11.745
			c-0.115-2.412-2.075-2.826-3.875-3.012c-2.202-0.229-2.805,1.213-2.677,3.163c0.21,3.197-2.339,6.344-0.184,9.582
			c-0.646,0.639-1.293,1.277-1.939,1.916c-1.816-2.731-0.587-5.8-0.963-8.69c-0.269-2.064,0.73-4.32-0.922-6.179
			c-0.001-1.08-0.003-2.159-0.004-3.239c1.456,0.296,2.939,0.934,4.364,0.823c4.542-0.351,8.082,1.053,9.201,5.673
			c1.209,4.985,1.127,9.925-3.71,13.381C645.747,89.958,645.771,89.4,645.796,88.843z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#42515A"
              d="M161.9,185.768c-3.53,1.57-6.4,4.67-10.57,4.75
			c-0.21,0.01-0.43,0.01-0.65,0c-0.18-1.59-1.38-2.98-0.89-4.69c0.07-0.04,0.15-0.07,0.22-0.11c3.99-1.98,7.04-4.84,7.95-10.06
			c1.92,3.15,3.71,5.68,3.95,8.74C161.94,184.838,161.94,185.298,161.9,185.768z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#6C6B6B"
              d="M1001.974,626.862c-1.49-4.416-4.961-7.324-8.071-10.477
			c-4.077-4.135-8.267-8.158-12.43-12.207c-1.249-1.215-2.189-2.516-1.741-4.373c0.253-1.045,0.851-1.855,2.012-1.828
			c1.278,0.031,0.953,1.113,0.956,1.854c0.004,1.213,0.218,2.334,1.136,3.178c7.428,6.818,13.196,15.359,21.787,20.969
			c0.024,0.621,0.05,1.242,0.075,1.863C1004.25,625.428,1003.083,626.04,1001.974,626.862z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#7591A1"
              d="M129.977,665.456c-14.229,0-28.458,0-42.686,0
			c-0.003-0.303-0.005-0.605-0.008-0.908c14.229,0,28.457,0,42.686,0C129.971,664.85,129.974,665.153,129.977,665.456z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#504E4E"
              d="M1006.869,792.756c1.675,0.922,3.283,1.914,3.953,3.863
			c-4.993,3.475-9.514,3.027-14.693-1.217c-1.78-1.457-4.254-0.047-6.363-1.041C995.37,792.055,1001.167,792.891,1006.869,792.756z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#454549"
              d="M685.916,21.817c-11.966,0-23.933-0.01-35.898,0.016
			c-1.058,0.002-1.866-0.027-2.817,1.225c-2.122,2.792-4.794,2.387-7.025-0.542c-0.757-0.994-1.806-0.614-2.746-0.674
			c-1.162-0.073-2.331-0.021-3.497-0.022c-0.969-0.001-1.938,0-2.902-0.585c18.423,0,36.844,0,55.266,0
			C686.169,21.429,686.043,21.623,685.916,21.817z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F4F4F5"
              d="M20.464,133.808c-2.325-2.754-1.254-5.506-1.329-8.009
			c-0.167-5.641-0.054-11.29-0.032-16.936c0.003-0.796-0.081-1.782,1.017-1.94c1.125-0.163,1.347,0.696,1.343,1.571
			c-0.039,7.958-0.083,15.916-0.175,23.873C21.282,132.788,20.822,133.203,20.464,133.808z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#AAB9D0"
              d="M642.504,148.701c-3.527,0.174-6.656,0.51-6.485-3.455
			c0.2-4.622,3.968-3.362,6.746-3.464c2.462-0.09,5.279-0.559,5.32,3.287C648.138,150.057,644.368,148.5,642.504,148.701z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F5F5F6"
              d="M20.59,104.878c-2.21,0.19-1.79-1.47-1.78-2.7
			c0.02-7.82,0.09-15.65,0.14-23.47c0.13,0.01,0.25,0,0.37-0.03c0.04,0,0.09-0.01,0.13-0.03c0.15-0.04,0.28-0.1,0.41-0.18v-0.01
			h0.01c1.33,0.17,1.95,0.8,1.95,2.28c-0.01,7.16,0.1,14.32,0.11,21.48C21.94,103.258,22.391,104.708,20.59,104.878z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#CDCDCC"
              d="M465.661,268.572c-0.103,2.982,1.47,6.153-1.318,8.828
			c-1.002,0.961-0.031,2.073,0.589,3.079c1.373,2.227,2.946,4.4,2.88,7.212c-0.636,0.071-1.376,0.393-1.89,0.176
			c-3.549-1.501-7.651-12.207-6.233-16.065c1.023-0.676,2.048-1.352,3.071-2.028c1.306,1.115,1.103,2.542,0.956,4.005
			c0.496-1.635-0.547-3.667,1.116-5.022l-0.062,0.059C464.976,268.4,465.278,268.337,465.661,268.572z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#ABAAAA"
              d="M651.586,247.314c-12.505,0-25.012,0-37.519,0
			c-0.006-0.322-0.012-0.645-0.018-0.967c12.509,0,25.019,0,37.527,0C651.58,246.67,651.583,246.992,651.586,247.314z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#4E4C4D"
              d="M547.624,282.949c-2.035-2.352,0.706-5.854-1.941-8.057
			c-1.502,0.09-1.798-1.192-2.363-2.125c-0.789-1.302-1.579-1.408-2.706-0.323c-2.626,2.527-4.139,1.351-3.362-1.674
			c1.131-4.409-3.247-5.223-4.378-8.047c0.719-3.062,4.091-2.189,4.46-1.425c1.828,3.786,6.655,3.851,8.456,7.397
			C548.077,273.231,548.312,278.03,547.624,282.949z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#DFDFE0"
              d="M921.914,30.656c-4.188,2.348-8.675,0.92-13.021,0.902
			c-1.434-0.006-1.971-1.608-1.873-3.077c0.114-1.729,1.462-1.532,2.584-1.545c3.99-0.042,7.98-0.035,11.972-0.047
			C921.688,28.146,921.801,29.4,921.914,30.656z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#565454"
              d="M90.176,195.262c-11.9,0-23.801,0-35.701,0
			c0-0.329-0.001-0.658-0.001-0.987c11.9,0,23.8,0,35.701,0C90.175,194.604,90.176,194.933,90.176,195.262z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#8D8C8C"
              d="M1036.954,46.566c-4.129,0.249-8.265,0.579-12.268-0.899
			c0.534-6.954,0.426-13.911,0.08-20.869c1.01-0.008,2.021-0.015,3.03-0.021c-0.016,5.439,0.229,10.896-0.135,16.313
			c-0.27,4.028,1.211,5.304,5.004,4.849C1034.079,45.768,1035.647,45.551,1036.954,46.566z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#7C8594"
              d="M701.836,35.939c-0.125,0.107-0.244,0.224-0.377,0.32
			c-2.266,1.646-6.156-1.297-7.417,2.055c-0.874,2.324-0.125,5.222-0.362,7.839c-0.141,1.561,1.122,4.312-1.646,4.299
			c-2.753-0.013-2.188-2.75-2.223-4.65c-0.068-3.626-0.07-7.254-0.101-10.881C693.663,36.331,697.811,35.412,701.836,35.939z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#535051"
              d="M220.425,263.191c10.913,0,21.826,0,32.738,0
			c0.011,0.299,0.021,0.597,0.031,0.896c-10.914,0-21.828,0-32.742,0C220.444,263.788,220.435,263.49,220.425,263.191z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E2E2E2"
              d="M254.726,420.269c0,21.549,0,43.098,0,64.646
			c-0.18,0.002-0.36,0.004-0.541,0.006c0-21.494,0-42.989,0-64.483C254.366,420.38,254.546,420.325,254.726,420.269z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FDFDFD"
              d="M254.05,612.288c0-21.385,0-42.77,0-64.154
			c0.194-0.002,0.389-0.002,0.583-0.002c0,21.334,0,42.668,0,64.002C254.439,612.184,254.244,612.237,254.05,612.288z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#DDDEDF"
              d="M288.5,261.514c-10.312,0-20.623,0-30.935,0
			c-0.006-0.286-0.012-0.572-0.019-0.858c10.296,0,20.592,0,30.889,0C288.458,260.941,288.479,261.228,288.5,261.514z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#ABBBD2"
              d="M641.575,133.603c2.411,0.048,4.429-1.345,5.82,0.458
			c0.942,1.221,0.845,2.869-0.312,4.117c-0.873,0.941-9.3,0.873-10.098-0.043c-1.201-1.377-0.819-2.867,0.142-4.084
			C638.586,132.204,640.503,133.463,641.575,133.603z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#484A51"
              d="M726.839,84.762c4.598,0.194,6.747-2.106,6.629-6.607
			c-0.064-2.472,0.442-5.006-0.682-7.383c0.645-0.01,1.289-0.019,1.933-0.028c1.577,4.2,0.532,8.579,0.79,12.866
			c0.107,1.787-1.321,3.268-3.229,3.124c-2.922-0.22-5.509,1.274-8.351,1.243c-0.047-1.068-0.094-2.137-0.141-3.205
			c0.62-0.588,1.246-0.602,1.878-0.019C726.057,84.756,726.447,84.759,726.839,84.762z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#ABAAAA"
              d="M369.925,813.629c-19.433-0.012-38.866-0.023-58.299-0.033
			c0.917-1.328,2.323-1.104,3.626-1.1c17.142,0.049,34.284,0.115,51.427,0.172C367.862,812.672,369.015,812.725,369.925,813.629z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#575455"
              d="M1081.433,822.241c9.205,0,18.411,0,27.616,0
			c0.01,0.311,0.02,0.621,0.03,0.932c-9.202,0-18.404,0-27.606,0C1081.459,822.862,1081.446,822.551,1081.433,822.241z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#A8A7A7"
              d="M716.989,813.543c-19.111,0.027-38.222,0.053-57.333,0.08
			c2.857-1.961,6.136-0.998,9.159-1.041c14.875-0.207,29.754-0.113,44.631-0.086C714.687,812.499,716.156,812.034,716.989,813.543z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#A6A4A5"
              d="M24.357,132.289c0-8.064,0-16.129,0-24.193
			c0.335-0.001,0.669-0.001,1.004-0.002c0,8.066,0,16.132,0,24.197C25.026,132.291,24.692,132.29,24.357,132.289z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F4F4F4"
              d="M952.607,783.641c0.817,0.104,1.635,0.205,2.452,0.309
			c3.19,0.404,7.615,0.033,3.159,5.174c-0.223,0.258,0.349,1.201,0.55,1.826C950.923,790.334,950.528,789.866,952.607,783.641z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#585556"
              d="M1042.955,806.667c0.669,0.629,1.338,1.26,2.007,1.889
			c-6.407,2.834-12.882,3.029-19.831,0.498c2.22-2.008,4.636-0.975,6.785-1.291C1035.579,807.223,1039.471,808.985,1042.955,806.667
			z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E2E2E2"
              d="M254.05,756.817c0-15.223,0-30.447,0-45.672
			c0.2-0.002,0.399-0.002,0.6-0.004c0,15.166,0,30.33,0,45.496C254.45,756.698,254.25,756.758,254.05,756.817z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#504E4E"
              d="M776.658,236.19c7.278-2.953,14.592-3.172,21.982-0.806
			C791.28,235.37,783.968,236.287,776.658,236.19z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#81A4B8"
              d="M139.529,664.852c13.861,0,27.722,0,41.583,0
			c0.002,0.188,0.003,0.375,0.005,0.564c-13.87,0-27.74,0-41.61,0C139.515,665.227,139.522,665.04,139.529,664.852z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#444649"
              d="M724.789,765.782c0.712-1.268,2.056-0.863,3.126-1.182
			c2.257,0.527,4.764-0.676,6.854,0.994c0.426,0.674,1.344,1.801,1.205,1.951c-2.256,2.443-5.234,0.777-7.878,1.215
			C725.906,769.122,724.557,768.254,724.789,765.782z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5D626D"
              d="M657.804,30.778c0.658,1.746,2.311,3.61-0.064,5.188
			c-1.913,1.271-4.133,1.31-5.998-0.062c-2.265-1.665-0.616-3.479,0.175-5.195C653.858,32.479,655.838,30.992,657.804,30.778z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E1E1E1"
              d="M388.042,247.483c-11.899,0-23.799,0-35.699,0
			c0-0.201,0-0.402,0-0.604c11.893,0,23.786,0,35.679,0C388.028,247.081,388.036,247.282,388.042,247.483z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#53575F"
              d="M802.872,83.669c2.294-0.105,4.741,0.816,6.78-1.006
			c0.317,0.342,0.635,0.685,0.951,1.026c0.893-0.066,1.53,0.409,2.114,0.996c-0.021,1.407-0.043,2.814-0.064,4.223
			c-3.017-1.894-6.927-0.912-9.861-3.066C802.818,85.117,802.846,84.394,802.872,83.669z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#A0AEC1"
              d="M649.256,116.641c-4.406,2.878-8.226,2.455-12.421,0.46
			C640.81,114.028,644.68,114.44,649.256,116.641z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#7BA4BC"
              d="M240.511,666.334c-4.017,2.367-7.611,1.693-11.693-0.822
			C233.238,663.639,236.98,662.395,240.511,666.334z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#656A75"
              d="M708.653,35.686c0.062,3.785,0.121,7.571,0.186,11.357
			c0.04,2.35-0.125,4.576-3.477,5.507c1.872-4.71,0.952-9.333,1.49-13.847C707.453,37.697,708.053,36.691,708.653,35.686z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E1E1E1"
              d="M254.729,675.459c0,10.238,0,20.479,0,30.717
			c-0.182,0.004-0.363,0.008-0.545,0.012c0-10.195,0-20.391,0-30.588C254.365,675.553,254.547,675.506,254.729,675.459z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#A6B3C6"
              d="M812.718,84.686c-0.972,0.234-1.729,0.015-2.114-0.996
			c0.77-4.749-1.283-9.8,2.114-14.354C812.718,74.451,812.718,79.568,812.718,84.686z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#949FB0"
              d="M635.87,73.878c2.202-1.352,2.05,0.332,2.043,1.642
			c-0.023,4.409-0.101,8.818-0.158,13.228c-2.431,0.032-1.819-1.817-1.843-3.135C635.841,81.702,635.875,77.789,635.87,73.878z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#565555"
              d="M765.906,813.956c9.252,0,18.505,0,27.757,0
			c0.002,0.174,0.004,0.348,0.007,0.521c-9.252,0-18.503,0-27.755,0C765.912,814.303,765.909,814.129,765.906,813.956z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#8E98A8"
              d="M725.666,84.753c-0.626,0.007-1.252,0.013-1.878,0.019
			c-0.395-4.802-0.592-9.585,0.479-14.24c0.466,0.028,0.933,0.058,1.398,0.086C725.666,75.329,725.666,80.041,725.666,84.753z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#565455"
              d="M1207.93,825.958c8.908,0,17.815,0,26.723,0
			c0.002,0.166,0.004,0.332,0.006,0.5c-8.928,0-17.856,0-26.784,0C1207.893,826.29,1207.911,826.124,1207.93,825.958z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#646263"
              d="M982.852,819.538c-9.043,0-18.086,0-27.129,0
			c0.001-0.166,0.001-0.332,0.002-0.498c9.043,0,18.086,0,27.13,0C982.854,819.206,982.853,819.372,982.852,819.538z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#575556"
              d="M1018.8,820.981c8.592,0,17.185,0,25.776,0
			c0.004,0.145,0.009,0.289,0.013,0.434c-8.528,0-17.057,0-25.585,0C1018.937,821.27,1018.868,821.125,1018.8,820.981z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#A6B5CA"
              d="M636.12,109.05c4.335-3.177,7.743-2.893,11.708-0.574
			C644.135,110.72,640.733,110.057,636.12,109.05z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#575455"
              d="M892.77,817.057c8.658,0,17.315,0,25.974,0
			c-0.002,0.154-0.003,0.309-0.005,0.463c-8.657,0-17.315,0-25.974,0C892.767,817.366,892.769,817.211,892.77,817.057z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#565555"
              d="M988.556,788.059c4.52,0,7.974,0,11.428,0
			C996.563,790.489,993.142,792.389,988.556,788.059z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#646263"
              d="M1145.799,823.993c8.687,0,17.373,0,26.06,0
			c0.002,0.176,0.004,0.35,0.007,0.525c-8.623,0-17.246,0-25.869,0C1145.931,824.342,1145.864,824.168,1145.799,823.993z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#73716E"
              d="M999.018,22.711c0.309,0.011,0.616,0.034,0.924,0.071
			c0.232,1.734,1.656,1.773,2.914,2.049c-0.34,1.303-0.68,2.605-1.02,3.908c-2.278,2.274-1.531,5.322-2.056,8.045
			C998.577,32.145,998.865,27.424,999.018,22.711z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5A5959"
              d="M18.614,668.346c0,4.645,0,9.043,0,13.643
			C16.07,678.143,16.045,671.76,18.614,668.346z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#575555"
              d="M1269.428,812.983c3.891-1.795,7.848-1.018,11.819-0.387
			C1277.394,814.243,1273.399,813.625,1269.428,812.983z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#656464"
              d="M854.885,816.538c-8.053,0-16.105,0-24.158,0
			c-0.001-0.17-0.001-0.34-0.002-0.51c8.053,0,16.106,0,24.159,0C854.884,816.198,854.884,816.368,854.885,816.538z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#DCDCDC"
              d="M24.105,103.38c0-7.821,0-15.643,0-23.463
			c0.158-0.005,0.316-0.009,0.475-0.013c0,7.821,0,15.643,0,23.464C24.422,103.372,24.264,103.376,24.105,103.38z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#D8D8D8"
              d="M24.091,159.17c0-7.508,0-15.016,0-22.523
			c0.192-0.002,0.385-0.003,0.578-0.004c0,7.508,0,15.016,0,22.523C24.476,159.168,24.283,159.169,24.091,159.17z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#A4A3A4"
              d="M20.741,135.847c2.137,1.653,1.09,3.992,0.974,5.915
			c-0.366,6.009,1.352,12.131-0.904,18.034C19.104,151.817,19.225,143.834,20.741,135.847z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#A4A3A4"
              d="M19.77,159.796c-2.173-6.754-0.479-13.693-0.936-20.532
			c-0.075-1.125-0.822-2.734,1.006-3.417C20.529,143.832,20.623,151.815,19.77,159.796z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F6F6F7"
              d="M19.77,159.796c0.023-7.983,0.046-15.966,0.07-23.949
			c0.3-0.084,0.601-0.084,0.901,0c0.023,7.983,0.046,15.966,0.07,23.949C20.464,159.796,20.117,159.796,19.77,159.796z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5D5B5B"
              d="M9.354,518.645c2.046,3.43,1.497,6.977,0.817,11.883
			C7.901,525.821,9.248,522.176,9.354,518.645z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5D5B5B"
              d="M14.655,619.858c-1.024-3.477-1.131-6.994,0.079-10.523
			C16.104,612.784,15.989,616.288,14.655,619.858z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#475A67"
              d="M941.745,670.819c-3.182-0.412-6.887,1.23-9.673-2.545
			c3.507-1.41,6.522,0.877,9.595,0.369C941.692,669.368,941.719,670.094,941.745,670.819z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#585656"
              d="M1235.401,587.12c-4.781,2.479-8.307,1.545-11.809,0.957
			C1226.916,585.977,1230.438,586.868,1235.401,587.12z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5C5956"
              d="M816.34,105.636c2.999-0.971,5.971-1.253,8.867,0.342
			C822.22,106.666,819.232,107.339,816.34,105.636z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#656464"
              d="M260.801,264.775c-0.764,2.601,2.791,5.084,0.144,7.981
			c-2.689-1.547-0.28-6.07-4.001-6.768C257.719,263.963,259.438,264.935,260.801,264.775z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#B0A275"
              d="M25.837,272.537c4.082-2.905,7.37-1.593,10.673-0.349
			C33.297,272.992,30.038,273.148,25.837,272.537z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#808794"
              d="M637.359,111.926c4.24,0,7.178,0,10.115,0
			C644.564,114.278,641.628,114.464,637.359,111.926z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#526672"
              d="M107.761,783.409c-0.339-2.625-0.531-5.244,0.403-9.066
			c1.84,4.336,3.124,7.414-0.474,10.328C107.714,784.249,107.738,783.829,107.761,783.409z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#D6D5D6"
              d="M484.728,314.63c4.478-2.427,7.98-1.724,12.787,0
			C492.687,314.63,489.169,314.63,484.728,314.63z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#727986"
              d="M759.038,26.475c2.94-0.219,5.881-0.437,8.821-0.654
			c-0.052,0.671-0.104,1.342-0.156,2.014c-2.887,0.487-5.983-0.668-8.704,1.113l-0.341-0.287c-0.487-0.623-0.454-1.229,0.063-1.817
			L759.038,26.475z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#DFDFDF"
              d="M461.535,313.805c4.558,0,9.116,0,13.675,0
			c0.005,0.198,0.011,0.396,0.017,0.594c-4.558,0-9.115,0-13.674,0C461.546,314.2,461.541,314.003,461.535,313.805z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FBFBFB"
              d="M528.318,313.928c4.638,0,9.274,0,13.912,0
			c0.007,0.151,0.014,0.304,0.021,0.455c-4.639,0-9.276,0-13.915,0C528.331,314.231,528.324,314.079,528.318,313.928z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#908E8F"
              d="M603.645,130.942c3.43,0,6.86,0,10.291,0
			c0.098,0.202,0.105,0.407,0.021,0.615c-3.444,0-6.889,0-10.332,0C603.54,131.35,603.545,131.145,603.645,130.942z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#231F20"
              d="M1166.887,44.903c14.459-1.05,14.736-1.289,14.737-12.673
			c0.001-13.474-0.417-13.917-13.783-14.629c0.095-1.572,1.035-2.054,2.37-1.573c3.811,1.37,7.605,1.013,11.1-0.646
			c3.353-1.592,5.643,1.215,8.519,1.383c5.077,0.297,2.594,4.289,2.686,6.455c0.372,8.769,0.19,17.561,0.301,26.342
			c0.021,1.753-0.638,3.673,0.816,5.224c0.003,26.821,0.011,53.643,0.01,80.464c-0.001,56.482-0.003,112.965-0.025,169.447
			c-0.002,3.155,0.197,6.386-0.382,9.452c-1.369,7.261,2.845,12.681,5.555,18.623c-0.598,5.061-4.177,5.435-8.152,5.185
			c-7.236-2.214-9.316-4.691-9.245-12.096c0.062-6.306,0.572-12.607,0.92-18.909c0.583-10.595-2.949-14.185-13.444-12.912
			c-6.805,0.825-13.58,1.027-20.399,0.738c-1.811-0.077-3.727,0.125-4.73-1.926c-0.32-2.421-1.581-4.965,0.918-7.018
			c0.976,2.491,3.128,2.771,5.324,2.621c8.881-0.602,17.748-0.728,26.636-0.025c3.048,0.241,5.81-0.441,5.034-4.642
			c-0.648-9.746-1.809-19.438-1.412-29.269c0.569-14.129-0.826-28.323,0.213-42.403c0.613-8.304,1.528-16.67,0.595-24.817
			c-1.013-8.831-0.629-17.564-0.744-26.344c0.109-12.405,0.123-24.811,0.007-37.218c1.161-19.495,1.004-39.012,1.02-58.527
			c0.003-4.464-0.335-8.94,0.255-13.396c0.396-4.294-2.092-5.562-5.802-5.464c-1.655,0.043-3.308,0.224-4.961,0.342
			C1169.819,45.413,1167.29,47.493,1166.887,44.903z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2C2829"
              d="M1161.717,17.58c-4.696,2.185-3.213,6.704-3.891,10.459
			c-1.132-2.138-1.325-2.147-1.184-5.093c0.192-4.002-1.706-5.293-5.431-5.171c-4.811,0.156-9.634,0.167-14.444,0.017
			c-4.327-0.136-6.253,1.477-5.293,5.968c0.326,1.523,0.63,3.24-1.139,4.632c-1.959-3.549,1.215-8.479-3.481-10.79
			c1.87-1.673,4.137-0.831,6.208-0.858c7.977-0.106,15.955-0.073,23.932-0.018C1158.59,16.736,1160.354,16.159,1161.717,17.58z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E2E2E2"
              d="M1280.467,19.114c0,53.326,0,106.653,0,159.979
			c-0.277,0.001-0.555,0.002-0.832,0.003c0-53.312,0-106.623,0-159.934C1279.912,19.146,1280.189,19.131,1280.467,19.114z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#231F20"
              d="M907.845,630.792c0.024,1.33,0.077,2.662,0.065,3.992
			c-0.015,1.66-0.582,2.631-2.419,1.691c-3.021-1.543-6.236-0.66-9.347-0.668c-48.625-0.121-97.251-0.176-145.877-0.232
			c-9.409-0.012-10.084,0.916-9.751,10.34c0.217,6.137-0.359,12.301-0.244,18.445c0.099,5.209-2.713,6.689-7.226,6.535
			c-3.121-0.107-6.064-0.662-7.075,3.916c-0.727,3.291-4.421,2.896-7.196,3.209c-1.654-4.191,1.956-6.973,2.807-10.494
			c0.347-4.809,0.846-9.613,1.007-14.43c0.306-9.164-3.238-12.418-12.392-11.609c-7.946,0.701-15.851,1.627-23.879,0.621
			c-4.968-0.621-7.399-2.922-7.885-7.875c-0.766-7.814-0.431-15.635-0.463-23.451c-0.065-16.135-0.034-32.271-0.012-48.406
			c0.009-6.475,1.134-7.613,7.475-7.6c9.984,0.023,19.973-0.055,29.95,0.219c4.628,0.129,6.891-1.25,6.47-6.232
			c-0.278-3.299,0.048-6.646,0.104-9.973c0.92-8.387,0.92-8.357,9.381-8.414c4.142-0.027,8.217-0.141,11.698-3.086
			c1.647-1.395,3.995-1.447,4.989-1.479c-4.236,0.439-9.586,1.441-14.993,1.92c-2.394,0.213-5.138,0.553-5.366-3.143
			c16.085-12.428,20.271-29.172,17.701-48.445c-0.95-7.126-0.498-14.265,0.546-21.364c5.826-2.829,16.498-2.172,19.79,1.213
			c0.475,1.86,0.41,3.617-1.026,5.091c-8.843,2.85-8.835,2.85-7.761,12.291c0.341,2.996,0.141,5.961-0.336,8.947
			c-0.956,5.982-0.508,11.936,0.642,17.865c0.548,2.824,2.217,4.357,4.945,4.904c0.654,0.133,1.347,0.371,1.973,0.24
			c8.49-1.777,12.898,2.678,16.611,9.693c4.551,8.598,11.774,15.219,18.643,22.023c1.563,1.549,3.863,2.82,3.468,5.65
			c-1.379,1.605-3.286,1.387-5.096,1.445c-5.329,0.176-10.66,0.012-15.991,0.027c-3.287,0.01-6.553-0.357-9.454,2.109
			c-1.657,1.408-4.167,1.135-6.306,0.895c-11.634-1.313-23.24-1.598-33.373,0.455c13.759-0.668,13.759-0.668,18.213,2.492
			c-7.131,1.219-13.349,0.309-18.406,0.648c6.088-0.154,13.243,0.281,20.393-0.377c1.711-0.158,4.512-0.221,4.592,1.789
			c0.091,2.25-2.707,1.938-4.405,1.988c-6.826,0.205-13.659,0.029-20.489,0.107c-2.048,0.023-4.239-0.26-6.291,1.689
			c2.617,1.727,4.905,0.637,7.116,0.566c2.936-0.092,6.081-0.939,8.222,2.109c1.051,3.092,3.484,3.604,6.354,3.602
			c18.589-0.012,37.178-0.012,56.58-0.008c-3.99-2.6-7.735-2.932-11.575-2.934c-14.776-0.012-29.554,0.033-44.33-0.037
			c-2.426-0.01-5.05,0.467-7.025-1.645c0.716-2.277,2.741-2.307,4.491-2.324c18.623-0.186,37.248-0.297,55.87,0.143
			c1.16,0.027,2.296,0.367,3.396,0.746c10.84,4.848,21.931,3.234,33.03,1.244c8.164-0.201,16.246,1.289,24.42,1.197
			c5.385-0.061,6.885-1.1,7.437-6.42c0.311-2.988,0.507-6.049-0.852-8.848c-1.13-2.33-1-4.48,0.188-6.668
			c1.705-4.338,0.64-8.867,0.861-13.303c0.166-3.313-0.063-6.641,0.103-9.957c0.073-1.473,0.128-3.021,1.712-3.879
			c4.865-2.633,5.298-4.018,2.822-9.043c-1.61,1.25-0.758,3.094-1.147,4.619c-0.244,0.959-0.299,2.164-1.361,2.471
			c-1.419,0.406-1.928-0.885-2.544-1.842c-1.102-5.25-5.021-6.256-9.581-6.641c-2.33-0.197-5.336,1.152-6.704-2.182
			c0.635-0.881,1.558-1.354,2.569-1.314c10.471,0.41,20.948-0.094,31.418,0.273c0.88,0.031,1.735,0.326,2.589,0.381
			c-8.58-0.795-17.198-0.387-25.801-0.512c-1.496-0.021-3,0.088-4.485-0.107c-0.956-0.125-2.201-0.229-2.036-1.576
			c0.14-1.135,1.343-1.277,2.274-1.561c8.62-0.373,17.196-1.535,25.866-1.293c5.261,0.146,5.311-0.283,5.322-5.27
			c0.028-11.959-0.758-23.924,0.633-35.875c0.697-5.996-1.117-7.763-7.223-7.814c-8.314-0.071-16.644,0.277-24.94-0.109
			c-7.122-0.333-13.021,1.95-18.282,6.47c-1.258,1.081-2.487,2.224-4.141,2.715c-1.856,0.315-3.494-0.141-4.903-1.393
			c-1.026-3.605,1.357-5.696,3.76-7.36c7.049-4.883,14.693-8.152,23.519-8.26c5.752-0.07,11.601,0.948,17.242-1.05
			c0.761-0.246,1.539-0.317,2.328-0.188c8.665,3.709,10.532,3.202,18.853-5.121c3.519-3.52,4.557-3.396,7.36,0.881
			c1.762,3.305-0.918,4.868-2.778,6.531c-1.878,1.679-3.859,3.172-4.598,5.726c-1.222,4.227,0.749,6.56,5.123,6.495
			c9.975-0.147,19.951-0.128,29.927-0.173c1.164-0.006,2.422-0.145,2.671,1.484c0.396,2.585,1.015,5.229-1.741,7.296
			c0.698-6.877,0.701-6.99-6.433-7.162c-8.15-0.196-16.306-0.131-24.458-0.247c-3.973-0.057-5.753,1.693-5.601,5.752
			c0.419,11.123-0.091,22.263,0.909,33.386c0.795,8.838-2.339,11.484-11.116,10.121c-6.178-0.957-7.908,0.502-7.914,7.371
			c-0.017,22.799,0.089,45.598,0.15,68.398c0.016,5.658-0.095,11.32,0.123,16.973c0.119,3.104-1.023,4.674-4.092,4.865
			c-2.522,0.805-4.981,1.885-7.663,2.037c-1.402,0.078-2.806-0.363-3.199-1.832c-0.509-1.896,1.309-2.283,2.419-2.389
			c5.621-0.541,6.366-4.08,6.058-8.773c-0.238-3.623-0.288-7.297,0.225-10.932c1.056-4.152-1.883-4.611-4.6-4.416
			c-4.981,0.359-9.938,0.189-14.908,0.094c-0.662-0.014-1.327,0.117-1.991,0.078c-1.702-0.1-4.023-0.395-4.151-2.018
			c-0.167-2.123,2.298-1.941,3.852-2.07c5.295-0.441,10.592-0.992,16.164-0.549c-17.188,0.051-34.238-4.395-51.058,1.137
			c-4.921,0.365-9.957-0.391-14.706,1.559c-0.509,0.168-1.035,0.279-1.563,0.357c-0.817,0.131-1.649,0.152-2.481,0.174
			c-8.094,0.215-8.026,0.23-9.647,8.102c-0.396,1.922,0.096,5.377-2.485,5.105c-2.219-0.232-1.492-3.596-1.649-5.668
			c-0.112-1.49,0.027-2.994-0.06-4.49c-0.116-2.01-0.095-4.326-2.858-4.438c-2.678-0.107-3.093,2.027-3.284,4.121
			c-0.106,1.16,0.007,2.34-0.187,3.486c-0.211,1.256-0.082,3.148-1.695,3.176c-1.787,0.031-1.427-1.871-1.521-3.107
			c-0.115-1.49,0.093-3.006-0.099-4.486c-0.354-2.752-0.875-3.131-4.538-3.768c4.981,1.094,6.533,7.861,3.12,13.6
			c-0.482-0.158-1.012-0.42-1.104-0.889c-1.054-5.367-4.581-6.875-9.686-7.176c-6.368-0.377-6.347-0.738-8.077,5.549
			c-1.905,1.742-2.514,0.16-3.079-1.264c-1.162-2.924-0.554-5.354,2.14-9.539c-2.267,2.152-2.953,4.783-2.6,7.91
			c0.314,2.783,0.163,2.801-2.686,3.016c-1.262-2.502-0.576-5.232-0.925-7.84c-0.213-1.59-0.686-3.016-2.631-3
			c-1.731,0.012-2.701,1.098-3.14,2.689c-0.447,1.619-0.294,3.299-0.426,4.949c-0.135,1.68-0.236,3.633-1.88,4.381
			c-5.974,2.721-5.359,8.471-5.881,13.316c-0.637,5.91-0.201,11.936-0.118,17.912c0.092,1.316-0.207,2.666,0.409,3.92
			c0.589,1.148,1.663,1.639,2.797,1.932c3.474,0.893,4.571,3.32,4.883,6.654c0.557,5.947,1.556,6.922,7.405,6.936
			c39.472,0.09,78.944,0.053,118.416,0.02c1.455-0.002,3.011,0.193,4.479-1.191c-1.162-1.613-2.946-1.086-4.414-1.275
			c-5.479-0.703-10.951-0.604-16.44,0.063c-2.98,0.361-5.704-0.266-6.946-3.523c-1.256-3.293-0.268-9.289,1.774-10.869
			c1.357-1.051,2.407-0.984,2.938,0.854c0.141,0.488,0.144,0.984,0.146,1.484c0.036,7.469,0.036,7.471,7.433,7.484
			c4.83,0.01,9.66-0.004,14.489,0.01c2.19,0.006,4.341,0.137,6.172,1.551c3.018,5.459,8.044,7.092,13.793,6.875
			C896.327,630.479,902.083,630.344,907.845,630.792z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#242021"
              d="M845.617,308.816c0.454-0.007,0.908-0.014,1.362-0.021
			c3.212,3.709,3.212,3.709,5.757-1.019c2.783-0.338,5.085-2.604,8.099-2.019c0.008,9.099,0.012,18.196,0.025,27.295
			c0.003,1.821-0.057,3.65,0.083,5.464c0.585,7.62,2.859,9.787,10.513,10.178c3.608,0.185,7.651-1.338,8.69,4.75
			c0.521,3.047,4.664,2.229,7.444,2.153c3.608-0.663,7.254-0.318,10.873-0.262c2.865,0.046,5.545-0.28,8.033-1.748
			c2.231-0.344,4.42-0.437,6.302,1.145c0.774,0.682,1.55,1.364,2.324,2.047c-2.099,12.938,1.444,23.595,12.363,31.493
			c2.388,1.728,4.246,4.197,6.309,6.363c4.926,5.173,4.714,7.275-0.948,11.561c-1.913,1.447-3.409,0.199-5.038-0.466
			c-1.791-0.421-2.908-1.518-3.33-3.314c2.347-2.635,2.415-4.881-0.503-7.397c-3.508-3.026-6.623-6.506-9.908-9.79
			c-1.947-1.946-4.105-3.365-7.012-3.17c-3.384,0.043-6.488-1.021-9.527-2.349c-0.898-0.426-1.959-0.803-2.408-1.677
			c-0.655-1.274-1.818-2.694-1.137-4.108c0.773-1.604,2.62-1.219,4.045-1.02c2.631,0.369,5.263,0.677,8.474,0.949
			c-2.78-1.078-5.241-1.023-7.477-0.826c-8.852,0.783-17.32-2.582-26.17-2.063c-3.515,0.207-6.113,0.657-7.233,4.308
			c-2.942,1.392-5.694,0.176-8.486-0.572c-3.17-1.146-6.414-1.443-9.791-1.422c-13.148,0.086-26.299,0.163-39.445-0.051
			c-2.947-0.048-6.526,0.601-8.042-3.302c7.854-5.487,16.34-9.52,25.841-11.212c12.761,0.934,25.592-0.21,38.31,0.819
			c14.529,1.177,28.914,0.841,43.674-1.275c-6.807,0-13.249,0.021-19.69,0.011c-4.321-0.007-8.459-0.174-12.65-2.045
			c-4.002-1.787-8.523,0.188-12.819,0.363c-2.67,0.109-5.294,0.256-7.958-0.112c-8.26-1.142-8.265-1.108-8.254-9.589
			c0.053-1.42,0.945-2.624,1.039-4.033c1.507-7.158,1.607-14.326,0.111-21.497c-0.223-1.964-0.913-3.8-1.589-5.641
			C845.786,313.417,845.701,311.116,845.617,308.816z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#4C4A4A"
              d="M799.857,369.928c2.269,1.592,4.818,1.866,7.523,1.855
			c13.961-0.055,27.923-0.005,41.885-0.029c3.013-0.005,5.878,0.486,8.54,1.946c-0.563,1.696-2,2.196-3.513,2.264
			c-12.896,0.573-25.112,3.812-36.801,9.196c-0.446,0.205-0.969,0.201-1.459,0.255c-5.845-1.006-11.094,1.688-16.916,2.729
			c0.967-0.077-0.09-0.014,0.069-0.023c1.62-0.099,3.722-0.995,4.447,0.938c0.65,1.733-1.601,2.025-2.668,2.948
			c-5.878,5.08-13.298,2.834-19.906,4.362c-1.388,0.321-2.725-0.282-2.832-2.021c-0.112-1.806,0.937-2.74,2.65-3.004
			c1.417-0.218,2.893,0.17,3.842-0.075c-3.059-1.238-6.655,2.318-10.101-0.586c0.662-0.731,1.324-1.463,1.986-2.194
			c2.372-0.021,4.707-0.497,6.367-2.201c2.183-2.243,4.623-2.208,6.962-1.583c-2.003-1.473-5.456,1.122-7.354-2.044
			C787.744,377.608,793.281,373.063,799.857,369.928z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2F2B2C"
              d="M764.93,456.999c-0.416-2.63-2.454-2.172-4.211-2.182
			c-4.936-0.028-9.87-0.027-14.806-0.038c0.781-7.488,0.521-15.437,4.78-21.851c1.977-2.978,6.069-5.034,10.042-6.042
			c2.166,0.795,2.188,2.643,2.219,4.486c-0.848,3.616,1.336,5.239,4.277,6.053c2.825,0.782,4.139,2.498,4.234,5.322
			c-0.058,0.482-0.212,0.931-0.449,1.353c-3.409,2.389-2.764,6.238-3.442,9.612C767.003,455.056,766.818,456.717,764.93,456.999z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#4A4848"
              d="M846.806,345.792c0.055,8.075,4.647,11.557,13.456,9.606
			c4.401-0.976,8.968,0.046,13.253-1.591c0.566-0.216,1.56-0.231,1.938,0.115c5.69,5.208,12.597,2.292,18.94,2.787
			c5.447,0.424,10.953,0.088,16.896,0.088c-1.89,3.585-5.374,3.495-7.885,3.706c-12.692,1.064-25.365,2.042-38.184,0.573
			c-8.543-0.979-17.283-0.332-25.933-0.241c-4.677,0.049-9.283,0.025-13.59-2.12c3.764-2.713,8.396-2.588,12.622-3.717
			c7.303-1.952,7.358-1.743,7.629-9.153C846.212,345.469,846.499,345.469,846.806,345.792z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2A2728"
              d="M717.751,752.825c0.293,2.547-1.171,3.139-3.293,2.887
			c-1.93-0.23-3.849-0.549-5.772-0.83l-0.008-0.008c-0.45-0.594-0.561-1.248-0.354-1.959c2.594-2.721,1.228-3.92-1.757-4.508
			c-2.075-1.473-1.215-3.652-1.222-5.52c-0.066-17.254-0.025-34.508-0.061-51.762c-0.004-1.797,0.403-3.65-0.406-5.396
			c-0.115-0.992-0.142-1.953,1.426-2.734c0.184,4.857,2.367,6.227,6.468,3.438c1.484,1.547,0.836,3.48,0.824,5.23
			c-0.108,15.797-0.156,31.6-0.546,47.391C712.918,744.387,713.137,749.266,717.751,752.825z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2F2C2C"
              d="M774.619,390.684c3.98,0,7.961,0,11.941,0
			c0.018,0.283,0.035,0.565,0.053,0.849c-1.79,0.116-3.585,0.181-5.369,0.365c-1.296,0.134-2.323,0.922-2.239,2.236
			c0.128,2.015,1.509,0.913,2.574,0.877c4.803-0.162,9.551-1.083,14.297-1.593c2.061-0.222,4.415-3.004,7.574-3.597
			c-2-1.787-4-0.45-6.193-1.634c6.317-2.137,11.726-5.979,18.499-3.603c-1.969,4.74-7.139,5.574-10.664,8.389
			c-3.089,2.465-6.223,4.797-8.98,7.669c-4.652,4.846-5.119,4.836-10.858,1.351c-0.854-0.519-1.767-0.914-2.681-1.308
			c-3.048-0.951-6.03-2.016-7.989-4.782C769.448,394.127,772.436,392.408,774.619,390.684z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#656464"
              d="M774.583,395.903c2.748,1.253,5.497,2.506,8.246,3.76
			c-3.56,5.76-7.844,11.082-10.446,17.425c-0.511,1.245-1.47,2.211-2.886,2.556c-2.229,0.234-4.26-0.146-5.85-1.888
			c-0.502-5.151,4.689-7.027,6.418-11.028C771.614,403.144,773.667,399.771,774.583,395.903z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#555353"
              d="M727.666,524.588c1.35,2.828,3.65,2.213,5.981,1.711
			c7.825-1.688,16.119-2.018,21.88-0.779c-3.38,2.291-7.35,0.227-10.167,2.221c-6.473,4.578-13.533,4.805-20.954,3.424
			c-1.786-0.334-1.452,1.326-1.604,2.4c-0.245,1.748,0.621,3.676-0.846,5.225C721.658,533.17,720.589,527.243,727.666,524.588z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#626264"
              d="M721.582,667.526c1.025,4.021-2.31,6.877-2.807,10.492
			c0.513,3.938,0.334,7.359-5.098,6.902c-1.479-1.342-4.274-2.799-4.169-4.002c0.241-2.746,2.666-4.795,4.758-6.711
			C716.701,671.977,719.144,669.752,721.582,667.526z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#B8B8B8"
              d="M828.731,292.943c1.51-0.773,0.522-2.457,1.277-3.506
			c7.259,1.237,9.333,7.868,13.054,12.713c2.034,2.647-3.673,1.032-2.336,3.644C837.743,300.563,831.206,298.649,828.731,292.943z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#BBBBBB"
              d="M793.753,254.883c3.007-2.476,5.547-2.509,7.508,1.304
			c0.517,1.005,1.182,2.138,2.094,2.699c3.8,2.34,4.445,5.73,3.649,9.726C801.104,265.466,796.431,261.139,793.753,254.883z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#797879"
              d="M708.899,752.989c-0.073,0.627-0.147,1.256-0.222,1.885
			c-3.121-0.977-3.726-3.467-3.729-6.275c-0.031-20.957-0.049-41.912-0.07-62.869c1.229,1.627,0.69,3.516,0.695,5.283
			c0.042,17.207-0.004,34.414,0.064,51.619c0.01,2.25-0.791,5.316,3.228,5.232C708.938,749.571,708.654,751.282,708.899,752.989z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#797879"
              d="M826.864,286.622c-1.067,0.389-2.134,0.778-3.201,1.168
			c-3.118-2.03-6.815-3.645-5.18-8.631c0.309-0.941-0.874-2.111-1.801-1.856c-3.968,1.089-4.091-2.104-5.136-4.315
			c3.476-2.277,5.7-1.133,7.131,2.513c0.59,1.503,1.411,2.915,2.127,4.369C823.634,281.394,825.854,283.465,826.864,286.622z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#888888"
              d="M846.806,345.792l-0.431-0.002l-0.426,0.056
			c-0.026-10.043-0.053-20.086-0.078-30.129c0.933,1.394,1.865,2.788,2.798,4.183c0.133,7.913,0.139,15.827-0.006,23.74
			C848.928,345.12,847.42,345.07,846.806,345.792z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#888788"
              d="M763.647,417.756c2.036,0.331,4.072,0.663,6.107,0.995
			c-0.668,5.541-4.031,9.462-7.869,13.098c-0.403-1.649-0.034-3.479-1.15-4.963C760.454,423.444,761.484,420.419,763.647,417.756z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2F2C2C"
              d="M782.581,382.661c3.591,1.565,7.446,0.907,11.236,1.207
			c-0.158,0.398-0.277,0.915-0.373,0.91c-5.037-0.225-9.264,1.998-13.456,4.364c-1.263,0.713-2.51,0.878-3.383-0.653
			C777.997,385.931,780.134,384.137,782.581,382.661z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#C5C5C4"
              d="M425.597,23.917c2.143,1.201,4.334,0.902,6.689,0.625
			c7.153-0.845,14.344-1.306,21.489,0.232c-3.281,3.173-7.443,2.247-11.322,2.29c-10.96,0.12-11.013,0.053-10.367,10.872
			c0.22,3.692-1.097,7.221-0.884,10.878c0.084,1.432-0.193,3.424,1.564,3.806c1.887,0.41,2.217-1.652,2.777-2.927
			c2.495-5.679,6.634-9.7,12.645-11.016c7.226-1.58,12.358-4.704,12.759-12.842c0.033-0.669,0.598-1.153,1.206-1.494
			c1.813-0.29,3.195,0.691,3.388,2.216c0.802,6.361,2.439,12.764,0.001,19.13c-0.491,1.281-1.287,2.325-2.703,2.712
			c-0.825,0.104-1.58-0.073-2.235-0.596c-1.233-2.041-3.175-3.417-4.846-5.049c-0.644-0.501-1.37-0.813-2.177-0.94
			c1.017,0.329,2.089,0.566,2.644,1.649c-0.391,2.878-2.739,2.383-4.603,2.631c-3.095,0.41-6.277,0.473-8.862,2.595
			c-2.881,4.002-1.076,8.582-1.59,12.871c0.419,8.209,5.161,13.555,13.19,14.857c6.793,1.103,7.968,2.774,8.914,9.665
			c0.904,6.58,1.351,12.649-5.644,16.34c-1.167,0.462-2.421,0.284-3.615,0.454c-1.677,0.237,0.378,0.721,0.072,1.169
			c-0.005,0.504-0.194,0.903-0.566,1.228c-1.347,0.825-2.846,0.781-4.328,0.819c-7.088,0.177-8.284,1.593-6.71,8.346
			c0.552,2.368,0.329,4.59-0.338,6.837c1.946,3.572,7.943,7.111,11.533,6.699c1.644-0.189,3.273-0.506,4.943-0.527
			c3.732-0.194,7.213,0.022,7.885,4.823c0.176,0.788,0.2,1.583,0.109,2.382c-1.127,4.631-0.647,8.987,2.235,12.929
			c3.753,3.798,7.97,6.123,13.624,5.913c6.808-0.253,13.638,0.376,20.44,0.598c9.269,0.303,18.605-0.58,27.907-1.021
			c4.649-0.22,8.186-2.76,9.354-7.665c1.505-6.313,5.569-9.326,11.911-10.339c7.734-1.236,13.322-8.002,13.291-15.257
			c-0.021-4.99-1.986-8.693-7.017-10.276c-1.012-0.095-1.979-0.36-2.904-0.771c-0.888-0.395-1.709-0.913-2.544-1.403
			c-3.299-1.883-6.736-1.6-10.217-0.643c-2.525,0.183-4.389,3.251-7.316,1.681c-2.009-7.704-1.2-15.58-0.731-23.298
			c0.271-4.453,0.561-8.925,0.624-13.39c0.111-7.74,0.604-8.133,8.012-5.479c0.499,0.085,0.997,0.237,1.465,0.435
			c1.819,1.323,3.373,4.656,5.913,0.801c0.179-0.396,0.387-0.771,0.606-1.142c1.406-2.959,4.83-1.334,6.802-3.014
			c2.903-4.158,3.062-8.351,0.2-12.582c-0.741-1.315-1.899-1.381-3.195-1.05c-1.81,0.082-3.091-0.719-3.908-2.301
			c-0.913-1.894-2.782-1.188-4.254-1.548c-1.111-0.271-2.287-0.512-2.439-1.908c-0.127-1.174,0.775-1.573,1.685-2.02
			c0.568-0.278,0.7-0.523,0.191-0.094c-0.074,0.063-0.28-0.03-0.479-0.051c-4.037,0.146-6.055-2.235-7.284-5.627
			c-0.788-2.172-1.167-4.504-2.533-6.449c-0.951-3.929,0.322-5.768,4.588-5.127c1.69,0.254,3.442,0.09,5.167,0.116
			c0.442,7.804,3.247,11.2,10.929,13.24c10.642,2.824,15.098,8.621,15.022,19.791c-0.022,3.309,0.337,6.62-0.402,9.939
			c-2.047,9.196-9.229,12.405-17.071,14.793c-2.925,0.891-5.263,2.028-5.486,5.512c-0.241,3.743,0.53,6.994,4.162,8.87
			c1.471,0.76,2.997,1.445,4.559,1.993c13.922,4.878,19.554,22.354,11.045,34.367c-2.804,3.958-6.514,6.532-11.298,7.617
			c-5.763,1.307-9.678,4.184-11.019,10.61c-1.171,5.619-5.64,8.193-11.514,8.377c-8.288,0.258-16.575,0.301-24.871,0.716
			c-12.981,0.65-26.064-0.133-39.07-0.891c-5.651-0.329-9.5-4.386-10.748-9.788c-1.564-6.771-5.624-9.436-12.175-10.187
			c-4.869-0.557-8.639-3.332-10.106-8.428c-0.184-0.636-0.405-1.276-0.72-1.854c-0.91-1.674-1.311-4.096-4.108-3.382
			c-2.394,0.611-2.134,2.659-2.141,4.479c-0.018,4.664,0.185,9.338-0.042,13.99c-0.782,16.072-4.512,17.736-16.855,19.01
			c-9.639,0.995-19.577,1.28-29.327-0.632c-7.043-1.382-10.826-5.163-11.753-12.242c-0.129-0.987-0.05-2.009-0.247-2.979
			c-1.712-8.429-2.816-8.967-10.697-5.406c-1.358,0.614-2.742,1.36-4.183,1.573c-7.626,1.128-11.021,6.694-13.854,12.902
			c-1.931,4.233-4.875,6.532-9.787,5.338c-2.159-0.524-3.664,1.41-5.628,1.68c-11.488,1.572-22.937,3.047-34.604,1.354
			c-7.37-1.069-14.883-0.028-22.356-1.111c-6.447-0.935-10.858-3.269-12.788-9.818c-1.233-4.183-3.967-6.559-8.739-7.638
			c-14.178-3.208-17.73-14.085-15.729-28.31c1.3-9.237,7.349-14.234,16.12-16.039c4.576-0.941,7.257-3.461,7.361-7.986
			c0.111-4.833-2.693-7.599-7.498-8.33c-11.448-1.741-16.55-10.561-16.379-24.14c0.13-10.299,6.821-17.841,17.474-19.879
			c3.188-0.61,5.649-2.034,6.385-5.389c0.853-3.884-2.865-4.15-4.813-5.809c1.031-0.996,2.349-1.112,3.676-1.195
			c6.404-0.399,8.359,2.074,7.44,9.499c1.775-1.111-2.06-4.973,2.339-4.684c1.538,2.798,1.006,5.854,1.037,8.816
			c0.065,6.325,0.075,12.651-0.013,18.977c-0.028,1.984,0.027,4.03-1.441,5.653c-2.821,0.043-2.252-2.267-2.374-3.782
			c-0.332-4.094-0.175-8.193,0.873-12.411c-0.663,1.63-0.246,3.476-1.865,4.491c-4.139-1.646-8.108-3.738-12.491-4.732
			c-0.927-3.567,3.134-1.637,3.932-3.782c-4.674-0.532-7.397,3.046-10.885,4.767c-3.23,2.249-4.399,5.269-3.505,9.109
			c0.491,2.11,0.435,4.297,0.785,6.426c1.04,6.334,4.925,10.455,11.138,11.873c1.781,0.407,3.59,0.688,5.353,1.174
			c1.798,0.495,3.411,1.327,4.611,2.797c5.284,6.472,2.843,19.714-4.421,24.078c-4.984,0.723-10.447-0.22-14.378,4.151
			c-4.309,3.959-3.003,9.044-1.798,13.438c0.912,3.323,3.147,6.532,5.35,9.419c2.15,2.817,5.263,3.752,8.785,3.855
			c3.701,0.108,7.317,0.737,9.497,4.343c0.858,2.01,0.458,4.198,0.903,6.266c2.931,5.588,7.754,8.145,13.853,8.205
			c14.988,0.147,29.979,0.177,44.966-0.01c7.096-0.089,12.61-2.861,14.78-10.268c1.665-5.683,5.547-8.494,11.409-8.644
			c5.795-0.148,9.056-3.569,11.152-8.54c2.103-4.987,2.362-9.676-1.978-13.762c-1.19-1.119-1.568-2.976-3.226-3.707
			c-4.762-3.357-10.06-5.092-15.888-5.234c-1.948-1.596-2.575-3.853-2.803-6.174c-0.533-5.439,0.505-10.854,0.422-16.318
			c-0.056-3.684,3.018-6.069,6.629-7.226c2.388-0.765,4.898-0.831,7.326-1.337c7.002-1.457,10.628-7.437,8.425-14.175
			c-0.962-2.942-1.563-6.154-4.102-8.356c-3.788-4.532-8.32-7.502-14.547-6.935c-2.655-1.15-3.655-3.607-4.635-6.021
			c-0.936-2.303-2.488-4.184-3.895-6.183c-0.031-6.652,2.505-10.159,8.081-10.071c16.38,0.259,32.739-0.838,49.113-0.567
			c1.647,0.027,3.836-0.717,4.465,1.904c-1.857,1.91-4.3,1.412-6.571,1.554c-5.487,0.342-8.317,2.626-7.839,8.036
			c1.305,14.767,0.443,29.539,0.746,44.303c0.062,3.005,1.101,4.667,4.343,4.701c1.925,0.021,4.079-0.184,5.333,1.897
			c-1.91,3.072-5.009,1.657-7.6,1.878c-2.42,0.207-4.273,0.592-4.137,3.563c-0.089,17.036-0.006,34.065-0.041,51.095
			c-0.006,2.879-0.045,5.711,3.111,7.193c3.641,3.9,8.089,5.574,13.447,5.345c5.324-0.229,10.67,0.143,15.995-0.136
			c6.287-0.328,8.919-3.04,9.423-9.365c0.159-1.994,0.096-3.998,0.096-5.997c0.002-27.833,0.106-55.666-0.055-83.497
			c-0.059-10.003,1.733-20.27-3.143-29.792C419.718,23.139,422.385,22.729,425.597,23.917z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#231F20"
              d="M212.641,159.978c-5.15,1.79-9.23,5.98-14.85,6.8
			c-0.17-0.18-0.34-0.36-0.5-0.55c-1.01-1.15-1.79-2.43-2.27-3.89c-0.07-0.19-0.13-0.38-0.18-0.57c0.09-2.71-0.49-5.21-1.75-7.66
			c-14.69-28.47-29.53-56.88-43.85-85.54c-3.79-7.59-8.18-12.25-17.24-10.45c-2.05,0.4-4.3-0.09-6.45-0.27
			c-4.79-0.4-9.03,0.17-10.54,6.72c-2.09-3.76-3.59-7.05-7.79-6.98c-2.81,0.04-4.9,0.47-4.55,4.12c0.17,1.84,0.7,4.04-1.51,5.48
			c-1.2-3.56,1.75-8.47-3.55-10.51c0.18-0.26,0.36-0.51,0.54-0.75c2.99-3.86,7.22-5.01,11.99-5c8.63,0.02,17.27,0,26.32,0
			c-2.57-5.97-8.36-8.33-12.64-12.21c-0.01-0.5,0.08-0.9,0.26-1.22c0.49-0.99,1.68-1.28,2.69-1.51c8.18-1.83,16.38-3.64,24.68-4.87
			c0.44-0.06,0.88-0.03,1.28,0.1c0.36,0.11,0.7,0.31,1.01,0.58c17.32,33.68,34.64,67.37,51.97,101.05
			c3.27,6.35,6.55,12.7,9.89,19.02C217.63,155.728,217.141,158.398,212.641,159.978z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F3F3F4"
              d="M122.734,523.735c1.753,0.299,3.506,0.6,5.259,0.898
			c2.049-1.057,3.599,0.076,4.839,1.398c3.296,3.512,6.588,7.141,8.247,11.732c2.652,7.342,3.397,14.803,0.035,22.211
			c-0.174,0.801-0.461,1.557-0.878,2.262c-7.904,7.703-16.914,12.535-28.405,10.885c-1.842,0.018-3.69,0.063-5.467-0.561
			c-3.799-3.834-7.388-7.883-11.403-11.516c-1.877-2.561-2.209-5.676-3.103-8.596c-1.971-9.58,2.713-16.855,8.426-23.65
			c1.536-1.828,3.644-3.365,6.405-3.086c1.09-0.35,2.179-0.699,3.269-1.047C114.025,521.723,118.282,521.385,122.734,523.735z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#242021"
              d="M425.597,23.917c-2.487-0.09-4.773,0.473-6.739,2.074
			c-1.345,3.15,0.653,6.063,0.64,9.184c-0.155,35.203-0.058,70.408-0.16,105.612c-0.011,3.439,0.973,7.566-3.571,9.416
			c-0.685-0.018-1.141-0.355-1.363-1.005c1.858-18.044,0.501-36.145,0.845-54.22c0.075-3.966-1.924-5.736-5.821-5.697
			c-2.993,0.03-6.003-0.193-8.694,1.504c-1.082,0.556-2.146,0.372-3.201-0.076c-5.497-2.417-11.081-2.19-16.705-0.504
			c-0.912,0.435-1.812,0.957-2.875,0.486c-0.436-2.729,0.633-3.97,3.485-3.892c3.122,0.084,6.251-0.094,9.377-0.158
			c2.155-1.584,4.941-1.368,7.242-2.552c0.822-0.139,1.625-0.061,2.419,0.176c0.64,0.173,1.254,0.442,1.902,0.58
			c11.005,2.338,12.969,0.778,12.97-10.296c0.002-13.598,0.064-27.196-0.055-40.794c-0.023-2.613,1.202-6.674-2.444-7.183
			c-3.876-0.541-8.625-2.604-11.856,2.005c-1.55,3.611-0.459,7.41-0.838,11.094c-0.203,1.974,0.509,4.389-2.266,5.275
			c-2.167-2.165-1.456-4.953-1.539-7.527c-0.128-3.955,0.309-7.963-1.47-11.705c-6.603-2.412-13.445-1.425-20.052-0.673
			c-10.821,1.23-21.619,0.52-32.421,0.711c-5.933,0.105-8.165,2.397-7.736,9.066c1.128,3.009,0.546,6.136,0.493,9.193
			c-0.067,3.821,0.886,7.407,1.842,11.025c0.398,3.608,0.404,7.216,0,10.823c-1.932,7.07-1.709,14.306-1.75,21.534
			c-0.013,2.352,0.576,5.044-2.069,6.64c-1.236-0.027-2.185-0.72-3.136-1.393c-2.442-1.729-5.052-3.328-0.701-5.998
			c2.093-1.285,1.949-3.723,1.954-5.895c0.031-13.818,0.069-27.638-0.014-41.456c-0.036-6.085-0.853-6.813-6.95-7.052
			c-1.497-0.06-2.998-0.047-4.47-0.314c-3.229-0.543-6.25-1.839-9.368-2.794c-2.46-0.753-2.962,0.59-3.254,2.466
			c-4.661,1.896-6.518,5.386-5.982,10.311c1.426,13.115,0.93,26.284,0.993,39.435c0.016,3.297,1.411,4.665,4.682,5.125
			c2.834,0.397,6.506-0.459,7.898,3.465c-2.316,1.829-5.08,1.292-7.679,1.313c-3.327,0.027-4.852,1.477-4.851,4.81
			c0.001,4.15-0.08,8.304-0.005,12.452c0.165,9.136-0.103,18.252-1.021,27.343c-0.513,5.073,0.582,9.524,4.683,12.912
			c-3.143,4.166-7.061,2.495-10.897,1.45c-0.488-0.447-0.689-1.004-0.611-1.66c1.508-3.395,1.875-6.955,1.849-10.655
			c-0.089-12.43-0.026-24.86-0.037-37.29c-0.008-9.029-0.208-9.14-9.084-9.372c-2.639-0.069-5.758,0.952-7.591-2.168
			c2.549-2.75,5.906-1.621,8.937-1.728c7.012-0.245,7.645-0.838,7.803-7.782c0.285-12.469-0.597-24.945,0.783-37.414
			c0.752-6.8-2.447-10.455-9.113-10.199c-7.426,0.286-14.994-0.597-22.277,1.715c-1.623,1.737-0.286,4.151-1.571,6.548
			c-0.145-1.34-0.364-2.188-0.306-3.017c0.336-4.789-1.479-7.162-6.605-6.335c-1.411,0.228-2.921-0.162-4.386-0.269
			c-2.392-4.914-6.559-4.885-11.045-3.964c-4.774-3.42-10.362-2.772-15.745-3.534c67.91,0,135.82,0,203.883,0
			C428.57,24.32,426.922,23.255,425.597,23.917z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#ACADAC"
              d="M138.728,474.592c-0.307-0.537-0.535-1.473-0.934-1.553
			c-7.997-1.613-11.52-7.899-15.025-14.221c1.34-1.389,3.136-1.397,4.866-1.613c1.612,0.014,2.844,0.771,3.847,1.97
			c1.988,2.186,4.755,0.845,7.064,1.558c1.683,0.521,4.185,0.521,4.14,2.77c-0.044,2.209-2.524,1.581-4.024,1.953
			c-1.526,0.379-3.09,0.586-4.139,0.041c7.889,0.66,12.232-1.893,15.193-9.284c0.739-1.846,0.084-3.57-0.498-5.317
			c-0.564-1.693-2.053-3.093-1.648-5.107c2.609-3.87,6.983-3.508,10.761-4.27c8.362-1.687,9.032-2.118,8.953-10.686
			c-0.125-13.617-1.339-27.209-0.986-40.846c0.141-5.438,0.786-10.854,0.63-16.301c4.276,0.544,9.327,5.796,8.47,10.399
			c-0.92,4.943,0.33,9.526,0.35,14.269c0.054,12.641,0.033,25.282,0.013,37.922c-0.01,6.738-3.459,11.263-9.49,13.74
			c-5.171,2.123-7.719,5.279-8.29,11.258c-0.486,5.094-4.742,8.948-8.796,12.227c-0.9,0.727-1.988,1.197-2.468,2.348
			c-1.538,1.432-0.865,3.293-0.872,4.973c-0.008,1.75,0.401,3.523-1.049,4.945c-2.197-0.617-2.068-4.135-4.915-3.939
			C139.609,479.397,141.982,476.545,138.728,474.592z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#787878"
              d="M166.927,373.686c2.592,3.824,0.915,7.961,0.982,12.001
			c0.193,11.602-0.787,23.225,0.744,34.812c0.432,3.269,0.15,6.635,0.136,9.956c-0.043,9.979-0.736,10.538-10.378,12.521
			c-3.57,0.734-7.765,0.189-10.5,3.574c0.129,1.94,0.608,3.884,0.195,5.822c-0.31,1.453,0.01,3.471-2.462,3.401
			c-1.213-1.323-1.372-3.01-1.272-4.636c0.249-4.072-1.95-5.016-5.506-4.935c-3.429,0.078-6.926,0.464-10.282-0.761
			c-2.41-1.54-5.13-1.04-7.739-1.136c-14.722-0.539-29.435-1.502-44.181-0.911c-1.885-0.98-0.903-1.815,0.137-2.646
			c10.513-2.754,10.829-3.163,11.007-14.061c0.027-1.646,0.107-3.293,0.163-4.939c1.995,4.222,0.103,8.756,1.123,13.035
			c0.055,0.229-0.119,4.606,1.954,0.71c4.356,2.95,9.434,1.365,14.124,2.229c1.323,0.293,2.668,0.293,4.016,0.315
			c16.413,0.517,32.825,0.082,49.235,0.229c3.967,0.035,5.854-1.771,5.803-5.668c-0.106-7.948,0.551-15.905-0.271-23.844
			c-0.086-2.008-0.115-4.021-0.484-6.012c-0.425-7.479-0.101-14.965-0.217-22.446c-0.093-5.953-0.99-7.37-7.017-6.86
			c-14.267,1.204-28.584,0.211-42.831,1.453c-1.665,0.146-3.313,0.337-4.931-0.273c-1.557-0.842-2.25-2.066-1.688-3.84
			c5.092-0.267,10.192-0.053,15.284-0.788c2.482-0.358,4.466-0.916,5.728-3.159c3.287-0.275,6.463,0.899,9.795,0.581
			c3.982-0.38,6.133-2.022,5.881-6.264c-0.107-1.803-0.142-3.647,0.458-5.416c0.197-0.491,0.515-0.887,0.946-1.192
			c3.033,0.2,3.806,2.293,3.851,4.814c0.026,1.486-0.183,2.979,0.069,4.46c0.056,0.991-0.046,1.979-0.504,2.866
			c-1.065,2.063,0.023,2.684,1.833,2.737c3.233,0.097,6.47,0.115,9.695,0.404C162.686,370.2,166.804,368.27,166.927,373.686z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#B8B9B8"
              d="M150.794,556.768c2.388,6.168,4.587,12.359,8.947,17.609
			c4.115,4.957,3.073,15.141-2.099,19.92c-6.851,6.33-12.471,7.184-18.782,2.928c-2.487-1.678-4.767-3.563-6.865-5.721
			c-10.322-10.619-15.282-9.441-25.377,0.205c-4.58,4.375-9.036,6.881-15.375,6.123c-4.617-0.553-8.192-2.234-10.29-6.633
			c-1.908-4-3.758-7.984-4.134-12.477c4.821,0.318,5.594,5.305,8.739,7.559c8.006,8.199,10.188,8.201,18.509-0.008
			c2.471-2.438,4.818-5.057,8.515-5.666c1.32-0.205,2.662-0.068,3.967-0.236c8.618-1.111,16.052,0.652,21.086,8.447
			c0.761,0.689,1.304,1.549,1.843,2.404c0.559,0.793,0.975,1.686,1.981,2.1c5.442,2.232,9.522-0.281,13.309-3.797
			c3.013-2.797,4.871-5.865,2.121-9.9c-1.851-1.809-3.65-3.658-4.887-5.971c-0.679-2.232-3.017-3.52-3.393-5.932
			c-0.388-2.439-0.416-4.895-0.147-7.326C148.628,558.885,148.465,556.971,150.794,556.768z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#B2B2B2"
              d="M150.794,556.768c-2.107,3.883-0.459,8.088-1.04,12.1
			c-1.18,0.912-2.289,0.361-3.403-0.152c-1.051-0.59-1.843-1.432-2.403-2.492c-0.524-2.35-3.005-2.906-4.242-4.605
			c0.075-0.674,0.15-1.346,0.225-2.02c3.641-3.605,4.686-8.148,3.446-12.824c-1.651-6.227-1.94-11.939,2.73-17.012
			c1.115-2.514,3.482-3.816,5.382-5.572c1.549-0.635,2.743-1.627,3.305-3.361c-1.5-0.09-1.375,3.332-3.534,1.631
			c-1.078-2.25-2.524-4.268-3.808-6.387c-0.989-1.635-1.061-3.457-0.114-5.223c0.943-1.178,2.261-1.158,3.584-1.121
			c12.279,4.414,15.292,16.453,5.938,25.338C150.31,541.293,151.296,549.122,150.794,556.768z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#555454"
              d="M153.738,31.794c-8.171,1.65-16.339,3.319-24.517,4.938
			c-1.918,0.38-3.875,0.576-5.401,1.986c-4.387-2.285-8.773-4.569-13.16-6.854c-0.805-1.202-2.541-2.324-1.766-3.816
			c0.772-1.484,2.718-0.729,4.137-0.736c10.764-0.051,21.528-0.041,32.293-0.02c2.993,0.006,5.987,0.11,8.323,2.422
			C153.678,30.407,153.708,31.101,153.738,31.794z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E3E3E4"
              d="M85.831,514.616c2.569-1.572,5.324-1.227,7.34,0.508
			c2.838,2.439,6.695,3.594,8.755,6.971c-2.433,6.168-7.187,10.33-12.265,14.221c-3.567,0.326-5.786-2.166-8.334-3.979
			c-1.536-2.217-2.926-4.492-2.657-7.361c0.933-1.768,1.205-3.945,3.152-5.084C82.879,517.92,85.547,517.172,85.831,514.616z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E9E8E9"
              d="M147.896,510.665c-0.479,2.709-0.232,5.193,2.223,6.955
			c1.854,1.33,1.618,3.395,1.913,5.277c0.395,0.574,0.372,1.139-0.037,1.695c-1.275,2.174-3.275,3.582-5.275,4.99
			c-1.309,2.24-3.647,2.916-5.481,1.742c-4.038-2.584-8.272-5.248-9.48-10.494c-0.476-3.232,2.192-4.328,4.057-5.971
			c0.355-4.67,4.598-6.348,7.551-8.309C146.019,504.792,148.044,507.366,147.896,510.665z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F2F2F3"
              d="M84.987,571.833c1.242-2.598,2.538-5.16,4.804-7.063
			c0.921-1.615,2.22-1.479,3.643-0.797c4.032,3.412,8.481,6.287,12.553,9.658c0.374,2.287-1.485,3.338-2.635,4.787
			c-1.505,1.494-3.276,2.803-4.418,4.563c-2.131,3.281-4.595,3.838-7.769,1.643c-3.987-1.148-6.633-3.998-9.061-7.135
			C81.492,574.801,81.898,572.631,84.987,571.833z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#EEEEEF"
              d="M144.648,354.898c-0.038,2.301-0.085,4.602-0.112,6.902
			c-0.065,5.635-1.107,6.88-6.888,6.822c-3.318-0.033-6.972,0.968-9.849-1.792c-0.011-3.368-0.021-6.736-0.032-10.104
			c-0.368-2.944-0.151-5.442,3.852-5.058c2.415,0.438,4.851-1.849,7.255,0.055C141.586,351.349,144.261,351.043,144.648,354.898z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E2E2E3"
              d="M50.807,368.688c-0.134-2.385,0.348-4.865-1.092-7.048
			c-1.976-2.782-0.862-5.313,0.634-7.813c0.719-0.622,1.48-0.721,2.293-0.182c3.761-0.033,7.571,0.283,11.267-0.226
			c2.42-0.333,2.899,0.334,2.881,2.378c-0.039,4.26-0.028,8.521-0.038,12.78C60.934,371.853,54.753,371.896,50.807,368.688z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#696869"
              d="M50.807,368.688c5.334,2.771,10.63-0.102,15.945-0.11
			c0.36,0.078,0.721,0.155,1.082,0.233l-0.081-0.07c0.454,2.127,1.558,2.807,3.89,2.651c11.37-0.758,22.758-1.11,34.155-0.599
			c1.06,1.934,0.333,3.307-1.371,4.376c-4.525-0.193-9.203,1.088-13.502-1.364c-2.548-1.403-3.074-0.211-3.058,2.187
			c0.051,7.909,0.031,15.82-0.072,23.729c-0.022,1.7,0.774,3.684-1.098,5.019c0.136-9.129,0.168-18.26,0.466-27.383
			c0.103-3.162-1.108-3.91-4.052-3.867c-11.456,0.17-22.915,0.122-34.373,0.155c-0.549-0.659-0.54-1.306,0.046-1.938
			C48.942,370.355,49.429,369.224,50.807,368.688z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#B8B8B8"
              d="M92.907,564.676c-1.055-0.51-2.092-0.408-3.116,0.094
			c1.248-10.943-2.499-19.814-11.135-26.602c-3.725-2.928-4.234-6.867-3.862-11.18c0.122-1.412,0.744-2.127,2.174-2.088
			c1.791,2.137,2.155,5.258,4.725,6.859c2.855,0.873,4.729,3.723,7.911,3.939c3.605,3.195,1.884,7.402,2.081,11.223
			c0.105,2.039-0.843,4.271,1.433,5.748c0.902,2.676,1.804,5.354,2.706,8.029C95.456,562.469,94.222,563.602,92.907,564.676z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#EEEEEF"
              d="M256.73,218.702c-1.172,1.308-2.004,3.084-1.581,4.566
			c1.014,3.547-1.006,5.395-3.404,6.913c-2.746,1.739-5.068-0.021-7.248-1.592c-0.662-0.477-1.185-1.146-1.772-1.727
			c-0.319-0.365-0.638-0.731-0.957-1.097c-2.97-2.717-2.836-5.356,0.197-7.924c2.182-6.131,3.155-6.433,9.819-3.092
			C253.718,215.72,255.722,216.584,256.73,218.702z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#262223"
              d="M153.648,29.714c-14.44-4.013-29.181-0.849-43.415-2.173
			c-2.02,2.036,2.234,2.602,0.428,4.323c-3.506-0.859-6.991-1.816-10.525-2.536c-1.713-0.35-4.084-1.127-5.077,0.497
			c-1.164,1.904-1.157,4.434-0.138,6.689c0.462,1.024,1.928,1.403,1.795,2.989c-3.18-0.172-6.887,2.31-9.122-2.202
			c-1.799-3.632-1.042-6.492,2.594-8.433c-1.339-1.706-3.047-0.622-4.348-1.234c1.176-1.346,2.764-0.865,4.193-0.867
			c20.189-0.026,40.379-0.024,60.569,0.006c1.424,0.002,3.024-0.493,4.153,0.937C154.386,28.378,154.017,29.046,153.648,29.714z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#4D4A4B"
              d="M131.759,520.831c2.258,4.049,6.499,6.113,9.499,9.445
			c1.758,1.953,3.669-0.086,5.462-0.693c1.827,1.773-0.033,2.826-0.997,3.572c-2.982,2.314-3.315,4.898-1.992,8.309
			c1.404,3.621,0.824,7.551,0.877,11.361c0.047,3.365-1.258,5.799-4.676,6.773c5.236-12.727,0.356-23.09-7.664-32.701
			c-1.093-1.311-2.597-1.986-4.275-2.264C128.011,522.139,127.297,518.922,131.759,520.831z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#ADAEAE"
              d="M159.823,369.822c-3.953,1.671-8.075,0.797-12.098,0.585
			c-1.876-0.098-3.805-1.187-0.9-3.234c0.995-0.701,1.213-1.914,0.98-3.138c0.868-3.009,1.339-6.044,0.578-9.151
			c0.031-1.876-2.226-3.002-1.673-5.049c6.254,1.063,10.141,5.58,11.007,13.126C158,365.423,158.151,367.829,159.823,369.822z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#4B4849"
              d="M93.117,552.67c-3.626,0.318-3.007-2.877-2.737-4.322
			c0.813-4.357,0.225-8.48-0.776-12.648c4.107-4.535,8.215-9.07,12.322-13.605c1.021,1.953,4.366,0.846,4.764,3.619
			C96.615,531.905,92.042,540.866,93.117,552.67z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#767574"
              d="M517.796,251.675c-2.378-0.691-4.34-2.492-6.995-2.489
			c-2.385,0.003-2.038-1.972-2.302-3.467c0.318-1.446,1.721-3.86,1.979-3.457c2.038,3.193,7.303-3.124,7.74,4.723
			c0.691-4.981,2.023-7.563,4.954-8.91c1.053-0.483,2.868-0.116,2.856,1.134c-0.028,2.723,4.879,4.623,1.131,7.992
			c-0.558-0.903-1.064-1.742-1.59-2.57c-0.78-1.229-1.725-2.177-3.225-1.083c-1.184,0.864-2.683,1.813-1.554,3.592
			C523.248,251.006,520.354,251.197,517.796,251.675z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#626061"
              d="M76.618,479.782c0.595-1.76,0.11-3.982,2.181-5.115
			c0.658,0.898,1.866,2.598,1.895,2.578c4.765-3.27,5.074,0.018,5.389,3.516c0.045,0.5,0.79,1.313,1.224,1.32
			c0.897,0.02,1.386-0.857,1.476-1.682c0.125-1.139-0.155-2.344,0.089-3.443c0.339-1.535,1.865-1.885,2.508-0.732
			c1.152,2.07,1.513,4.895,1.446,6.9c-0.058,1.742-3.825,2.619-6.375,2.682c-2.471,0.063-4.069-1.16-4.622-3.633
			c-0.165-0.734,0.143-1.697-1.067-2.068c-1.801,1.174-0.663,3.84-2.531,5.082C76.945,483.637,77.18,481.581,76.618,479.782z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#787777"
              d="M198.888,476.84c3.022-1.17,6.019-2.459,9.378-2.152
			c2.029,0.186,3.727,0.869,3.138,3.338c-0.818,3.432-3.821,5.986-6.352,6.912c-1.756,0.643-6.271,1.389-8.129-2.281
			C197.454,480.676,200.904,479.682,198.888,476.84z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#514F4F"
              d="M461.782,184.861c-4.245,0.207-8.067,2.547-12.659,1.769
			c-2.536-0.43-3.44-1.504-2.003-2.689c2.028-1.674,0.382-5.602,3.187-5.612c3.207-0.012,1.712,4.167,3.959,6.178
			c0.582-2.351,0.601-4.765,1.724-6.436c1.907-2.838,3.981-0.614,5.854,0.613C463.505,180.76,463.445,182.818,461.782,184.861z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#6A6969"
              d="M273.742,211.729c5.272-2.045,10.742-0.424,16.104-0.848
			c1.149-0.091,2.251,0.786,1.359,1.773c-2.341,2.588-1.064,6.084-2.563,9.04c-3.309-1.374,0.419-8.312-5.656-7.068
			c-4.283,0.877-0.541,5.134-3.028,7.024c-2.041-0.736-1.181-2.684-1.297-4.074c-0.158-1.893-0.352-3.384-2.82-2.747
			C274.707,214.09,274.176,212.943,273.742,211.729z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#71706F"
              d="M144.795,485.766c-0.038-2.906-0.044-5.811-0.127-8.713
			c-0.05-1.748,0.72-1.902,2.048-1.205c0,0.826-0.022,1.654,0.004,2.48c0.056,1.781-0.339,3.867,2.222,4.289
			c2.319,0.383,2.481-1.512,3.144-3.027c0.749-1.717,1.525-4.988,3.277-4.17c2.514,1.176,3.162,4.463,2.544,7.447
			c-0.114,0.549-0.967,1.477-1.167,1.402C152.472,482.665,149.154,488.522,144.795,485.766z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#7B797A"
              d="M461.782,184.861c0.021-2.06,0.041-4.118,0.062-6.178
			c6.081-2.756,8.123-2.229,8.802,2.414c0.361,2.474,0.506,5.357-2.354,6.299C465.836,188.206,463.099,187.883,461.782,184.861z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#9C9C9B"
              d="M176.816,485.745c-1.46,0.113-2.942,0.104-4.375,0.363
			c-3.093,0.561-4.763,0.107-2.666-3.35c0.337-0.557,0.677-0.947,0.58-1.711c-0.758-5.992,1.019-7.336,6.608-5.078
			C178.833,479.256,178.187,482.506,176.816,485.745z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#595757"
              d="M131.619,351.67c-2.854,0.49-2.945,3.084-3.852,5.058
			c-1.851,1.596-0.781,3.717-1.003,5.604c-0.144,1.223-0.489,2.381-1.932,2.429c-1.452,0.049-1.981-1.127-2.079-2.31
			c-0.244-2.977-0.333-5.943,1.502-8.598c0.713-1.032,2.004-1.689,1.89-3.302c-0.205-2.898,1.982-3.936,4.134-3.691
			c3.858,0.438-1.219,2.138,0.234,3.208C131.007,350.433,131.257,351.127,131.619,351.67z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#A2A1A1"
              d="M410.67,177.86c5.023,0.115,10.044-2.882,15.069-0.01
			c-0.347,2.969,0.859,6.104-0.949,8.918c-2.166-1.584-4.376-2.876-7.173-1.468c-2.923,1.472-3.761-1.957-5.82-2.529
			C410.405,181.368,410.113,179.711,410.67,177.86z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#6C6A6A"
              d="M181.962,474.907c7.903-0.291,9.759,1.402,8.659,7.271
			c-0.758,4.049-4.489,0.926-6.389,2.939c-0.705,0.748-0.844-2.1-0.615-3.424c0.301-1.74-0.506-3.26-0.864-4.867
			C182.097,476.348,181.329,475.917,181.962,474.907z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5E5C5D"
              d="M491.002,178.53c0.44,0.686,0.659,0.921,0.761,1.2
			c0.857,2.338,0.41,5.869,4.024,5.939c3.119,0.061,2.615-3.308,3.636-5.189c0.609-1.124,0.673-2.813,2.481-2.474
			c1.315,0.246,1.479,1.546,1.697,2.719c0.527,2.828,1.722,5.989-3.329,5.303c-0.302-0.041-0.654,0.111-0.951,0.244
			C491.353,189.845,489.81,187.391,491.002,178.53z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#6A6969"
              d="M84.987,571.833c-1.576,1.654-2.655,3.482-2.091,5.895
			c0.5,2.99,3.496,4.939,3.409,8.178c-0.262,0.717-0.641,1.252-1.535,0.979c-3.205-2.18-4.402-6.316-7.952-8.16
			c0.571-3.619,3.279-5.736,5.773-7.918C83.514,569.999,84.803,570.051,84.987,571.833z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#4C5D67"
              d="M197.871,165.898c0,0.28-0.03,0.57-0.08,0.88
			c-2,1.29-4.01,2.59-6.02,3.88c-0.31-0.38-0.63-0.75-0.94-1.12c-0.55-0.64-1.09-1.28-1.53-1.99c-0.87-1.39-2.04-3.04-1.27-4.6
			c1.07-2.18,2.48,0.16,3.76,0.25c1.4,0.1,2.04-0.96,3.05-1.43c0.14,0.07,0.27,0.15,0.4,0.23
			C196.781,162.908,197.871,164.088,197.871,165.898z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#626060"
              d="M550.821,177.72c2.293-0.777,5.131-2.101,6.003,1.301
			c0.814,3.175-1.137,5.671-4.272,6.707c-1.146,0.379-2.549-0.021-3.834-0.066C545.268,181.915,549.312,180.153,550.821,177.72z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#4D4B4B"
              d="M530.93,186.899c-0.327-2.259-0.763-4.052-0.791-5.851
			c-0.022-1.495,0.467-3.051,2.115-3.627c1.654-0.577,3.364-0.275,4.684,0.8c1.396,1.139-0.329,2.495-0.163,3.566
			C537.576,186.959,533.123,185.181,530.93,186.899z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#706E6F"
              d="M147.896,510.665c-1.811-2.348-2.887-6.943-6.481-0.938
			c-1.042,1.74-3.895,3.18-5.599,5.133c-0.361-0.316-1.037-0.635-1.036-0.949c0.01-4.857,4.593-6.504,7.223-9.287
			c2.026-2.145,4.788,0.555,6.671,2.455c0.813,0.82,1.503,1.764,2.249,2.65C150.199,510.961,149.002,510.668,147.896,510.665z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#898889"
              d="M52.642,353.646c-0.646,0.039-1.292,0.078-1.938,0.116
			c-1.064,0.811-2.09,0.887-3.058-0.158c0.986-0.559,2.281-0.883,2.909-1.712c5.594-7.39,6.342-7.603,16.433-3.082
			c-3.828,0-6.538-0.092-9.237,0.031c-1.79,0.082-1.696,0.214-1.55,2.494C56.376,354.073,53.281,352.017,52.642,353.646z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#6E6C6C"
              d="M533.709,250.594c-2.91-3.38-2.38-6.316-0.303-8.916
			c1.58-1.979,3.741-0.837,5.403,0.2c3.302,2.062,1.955,5.338,1.425,8.057c-0.262,1.346-1.926,0.971-2.469-0.418
			c-0.282-0.723-0.157-1.627-0.125-2.445c0.071-1.858-0.548-3.742-2.451-3.655c-2.224,0.102-0.926,2.343-1.216,3.618
			C533.761,247.969,533.817,248.965,533.709,250.594z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#595657"
              d="M47.646,353.604c1.019,0.053,2.039,0.105,3.058,0.158
			c0.093,2.68-1.807,5.109-0.988,7.88c-1.057,1.117-0.032,3.974-2.333,3.735c-2.588-0.268-1.535-2.81-1.676-4.434
			C45.475,358.301,43.066,354.998,47.646,353.604z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#71706F"
              d="M138.728,474.592c5.197,1.291,5.294,1.9,1.152,7.234
			c-1.943,0.133-0.932,4.328-4.002,2.912C135.988,481.122,134.378,477.02,138.728,474.592z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#515050"
              d="M104.612,375.037c0.395-1.414,0.79-2.829,1.185-4.243
			c0.33-0.005,0.66-0.011,0.99-0.016c0.389,1.07,0.778,2.141,1.167,3.211c1.877,4.775,2.482,9.504-0.529,14.097
			c-0.612,0.203-1.222,0.194-1.831-0.021C102.096,383.963,102.815,379.54,104.612,375.037z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#343132"
              d="M68.723,477.688c0.041-1.83,0.823-3.119,2.726-3.404
			c2.25-0.336,2.442,1.529,3.056,2.963c0.375,0.877,0.888,1.693,1.339,2.537c-1.838,1.676-4.571,1.947-6.555,5.123
			c-0.261-2.396-0.407-3.732-0.552-5.07C68.192,479.124,68.192,478.409,68.723,477.688z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#6D6B6B"
              d="M527.928,177.461c-1.783,2.418-2.55,4.435-1.474,6.857
			c0.593,1.334,0.283,2.739-1.284,3.218c-1.992,0.606-2.55-0.987-3.009-2.415C520.48,179.894,522.538,176.879,527.928,177.461z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#6E6C6C"
              d="M482.914,185.915c-1.347-3.227-2.637-7.047,0.995-8.434
			c4.726-1.806,6.358,0.633,3.382,4.931C486.322,183.812,484.543,184.649,482.914,185.915z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#302C2D"
              d="M550.821,177.72c0.468,2.957-4.188,4.557-2.104,7.941
			c-1.173,0.949-2.543,2.019-3.846,0.624c-1.579-1.692-1.189-3.996-0.962-6.087c0.147-1.349,0.816-2.472,2.507-2.438
			C547.883,177.789,549.352,177.736,550.821,177.72z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5A5858"
              d="M114.484,484.051c-2.016-0.465-3.386-1.709-4.509-3.029
			c-1.34-1.574-0.966-3.357,0.397-4.887c1.069-1.201,2.417-1.938,3.904-1.086c1.718,0.984,1.613,2.457,0.891,4.242
			C114.597,480.706,114.695,482.389,114.484,484.051z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#747373"
              d="M146.71,349.835c0.946,1.374,1.892,2.747,2.838,4.121
			c-1.354,1.374-3.038,0.469-4.564,0.66l-0.335,0.282c-1.155-2.458-4.009-1.826-5.775-3.174c-0.005-1.777,2.976-1.521,2.448-4.198
			C141.028,346.043,143.934,346.546,146.71,349.835z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#696868"
              d="M68.723,477.688c0.005,0.717,0.009,1.432,0.014,2.148
			c-3.154,0.07-4.091,4.633-7.692,3.797c-0.142-0.641-0.384-1.277-0.412-1.922c-0.302-7.078,1.058-7.457,6.217-4.264
			C67.337,477.749,68.092,477.62,68.723,477.688z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#6B6969"
              d="M106.095,480.709c0.938,4.268-2.093,3.928-4.73,4.332
			c-1.118,0.17-1.802-0.139-1.538-0.965c0.908-2.842,1.793-5.773,3.307-8.301c0.588-0.98,1.984,0.588,2.381,1.57
			C105.932,478.375,105.919,479.581,106.095,480.709z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#8B8A8A"
              d="M397.631,178.582c2.14-1.775,1.616-5.507,4.635-4.526
			c3.498,1.136,3.68,4.796,4.835,8.498C403.702,181.234,401.481,179.139,397.631,178.582z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2E2A2B"
              d="M48.785,371.708c-0.016,0.646-0.031,1.291-0.046,1.938
			c-4.636,0.093-9.272,0.186-13.908,0.279c-1.729-0.595-3.914,0.883-6.415-2.217C35.916,371.708,42.35,371.708,48.785,371.708z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#595757"
              d="M439.768,182.793c0.353,2.65-1.212,3.117-3.342,3.065
			c-1.357-0.033-3.05,0.175-2.557-1.884c0.523-2.185,0.822-5.364,3.184-5.697C439.282,177.963,439.547,180.825,439.768,182.793z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#636060"
              d="M500.695,249.736c-0.996-0.246-2.328-0.791-2.228-1.666
			c0.739-6.412-3.854-5.253-7.517-5.521c-0.608-0.045-1.193-0.405-1.789-0.619c0.312-0.098,0.622-0.194,0.933-0.294
			C499.576,238.595,501.452,240.028,500.695,249.736z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#7F7E7E"
              d="M120.778,474.782c5.441,5.518,5.176,8.051-1.132,10.814
			C118.468,481.829,117.654,478.098,120.778,474.782z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5C5959"
              d="M163.802,484.875c-1.765-3.277,0.583-7.488-3.757-8.996
			c-0.493-0.172-0.392-1.248,0.349-1.578c1.571-0.699,3.225-0.68,4.826-0.141c0.678,0.227,2.065,0.834,1.251,1.527
			C163.654,478.081,165.813,481.7,163.802,484.875z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#302C2D"
              d="M273.742,211.729c2.054,0.116,2.02,1.646,2.099,3.101
			c-0.551,2.244,0.592,4.973-2.259,7.038C272.478,218.264,269.628,215.065,273.742,211.729z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#4E4C4F"
              d="M713.975,155.67c5.057-3.504,11.168-3.486,15.735,0.044
			C724.465,155.699,719.22,155.685,713.975,155.67z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#4B4949"
              d="M122.734,523.735c-4.283-0.029-8.607-0.609-12.776,0.932
			C113.63,519.403,120.06,518.934,122.734,523.735z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#696869"
              d="M67.834,368.812c0.038-4.053,0.075-8.105,0.113-12.158
			c2.109-2.533,3.178-1.467,3.751,1.11c0.525,2.362-0.879,4.801,0.249,7.148c-0.925,1.795-2.264,3.136-4.194,3.829
			C67.753,368.741,67.834,368.812,67.834,368.812z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2E2B2C"
              d="M71.948,364.912c-3.5-0.717-1.126-3.006-1.305-4.654
			c-0.178-1.642,1.628-4.996-2.696-3.604c-0.261-1.43-0.872-3.153,1.407-3.332c1.713-0.136,3.546,0.504,3.432,2.564
			C72.619,358.895,73.927,362.074,71.948,364.912z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#504F53"
              d="M690.152,155.581c5.353-3.5,10.047-3.46,14.528,0.123
			C699.838,155.663,694.995,155.622,690.152,155.581z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#4D4B4E"
              d="M738.997,155.657c4.886-3.565,9.375-3.58,14.481-0.046
			C748.651,155.627,743.824,155.642,738.997,155.657z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2D292A"
              d="M471.928,249.688c-0.111-1.403-0.374-2.715-0.273-3.998
			c0.105-1.352-0.662-3.354,1.265-3.792c1.611-0.366,2.584,1.373,3.404,2.479c0.953,1.284,0.943,2.81-1.01,3.526
			C474.256,248.292,473.967,249.849,471.928,249.688z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#6A6868"
              d="M208.659,634.61c-2.063,4.861-4.91,7.453-8.833,8.92
			C201.118,639.411,203.787,636.651,208.659,634.61z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#787777"
              d="M469.601,241.541c-0.754,2.354-3.067,0.995-4.46,1.733
			c-1.416,0.751-2.801,0.495-2.967-1.221c-0.184-1.897,1.173-3.009,2.989-3.276C467.238,238.472,468.575,239.594,469.601,241.541z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#797979"
              d="M87.622,512.784c-0.923-3.887-4.735,3.217-5.641-1.232
			c-0.384-1.889,1.486-2.725,3.311-2.898c2.159-0.207,3.491,0.941,4.15,2.811C89.805,512.493,89.014,512.903,87.622,512.784z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2E2A2B"
              d="M120.778,474.782c-1.299,3.508-1.147,7.168-1.132,10.814
			c-0.566,0.205-1.591,0.762-1.637,0.053c-0.22-3.371-0.374-6.777-0.103-10.135C118.021,474.079,119.706,474.688,120.778,474.782z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2C2829"
              d="M484.771,243.776c0.382,1.323,0.521,2.641-0.086,3.943
			c-1.796-0.168-2.228,1.605-3.707,2.851c0-3.097,0-5.737,0-8.451C482.634,241.67,483.364,243.544,484.771,243.776z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#737272"
              d="M293.999,212.938c1.127-2.682,2.995-2.938,4.912-1.082
			c1.888,1.826,0.456,2.415-1.308,3.306c-0.932,0.471-1.094,2.464-1.604,3.77C293.855,217.426,295.485,214.662,293.999,212.938z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#9C9C9B"
              d="M181.962,474.907c0.264,0.639,0.527,1.279,0.791,1.92
			c-1.261,1.781-2.098,3.91-4.141,5.051c-2.385-1.588-1.077-3.83-1.034-5.85C179.04,475.655,180.501,475.28,181.962,474.907z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#3F3C3C"
              d="M227.905,638.827c-0.894-3.734-1.249-7.758-5.637-9.623
			c6.075,0.092,7.676,3.121,5.552,9.578C227.822,638.782,227.905,638.827,227.905,638.827z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#8A8A8A"
              d="M241.966,217.843c-0.994,2.618-1.951,5.237-0.197,7.924
			c-3.704-1.791-4.416-7.03-1.167-9.127C241.852,215.833,241.655,217.263,241.966,217.843z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#838182"
              d="M465.844,251.729c-1.111-0.112-2.97,0.121-2.759-1.294
			c0.427-2.869,3.245-1.18,4.874-1.917c0.812-0.366,1.871-0.21,1.701,0.928C469.341,251.581,467.542,251.664,465.844,251.729z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#8B8A8A"
              d="M405.845,183.571c0.25,3.625-1.725,4.311-4.256,4.252
			c-0.883-0.021-1.954-0.513-1.708-1.3C400.825,183.492,404.057,185.245,405.845,183.571z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2E2B2C"
              d="M410.67,177.86c1.302,1.424,1.105,3.192,1.127,4.911
			c-0.699,0.953-1.397,1.906-2.097,2.859c-2.897-2.34-1.092-5.226-0.92-7.921C409.411,177.76,410.041,177.81,410.67,177.86z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5F5D5D"
              d="M177.579,476.028c0.598,1.906-0.466,4.105,1.034,5.85
			c0.87,1.07,1.741,2.139,2.848,3.498c-1.668,1.059-3.187,0.471-4.644,0.369c0.049-3.258,0.098-6.518,0.147-9.775
			C177.175,475.928,177.38,475.948,177.579,476.028z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2B2728"
              d="M293.999,212.938c2.826,1.277,1.324,3.999,2,5.993
			c-0.45,1.116-0.899,2.231-1.35,3.348c-0.217-0.065-0.434-0.131-0.651-0.196C293.999,219.034,293.999,215.986,293.999,212.938z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#373435"
              d="M198.888,476.84c3.362,2.592,3.362,2.592-1.965,5.816
			C196.087,480.215,196.977,478.356,198.888,476.84z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#7D7D7D"
              d="M81.692,531.76c-3.625-0.877-4.927-3.352-4.726-6.861
			c0.569,0.025,1.136,0.051,1.704,0.074C79.677,527.237,80.685,529.499,81.692,531.76z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#7F7D7D"
              d="M303.298,220.456c-1.824-2.908-2.146-5.473-0.199-8.333
			C304.995,214.913,304.401,217.494,303.298,220.456z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#615F5F"
              d="M424.791,186.769c0.387-2.966-1.172-6.138,0.949-8.919
			C427.468,181.04,428.125,184.117,424.791,186.769z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#8A8A8A"
              d="M85.831,514.616c0.983,2.49,0.236,3.473-4.009,5.275
			C81.404,516.799,83.822,515.862,85.831,514.616z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#706E6E"
              d="M509.748,186.434c-1.534-3.189-1.029-5.399-0.811-7.805
			C511.156,181.1,511.156,181.1,509.748,186.434z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#7F7E7E"
              d="M269.669,212.341c-0.058,1.235-1.008,1.654-1.949,1.858
			c-1.257,0.272-1.713-0.675-1.854-1.691c-0.179-1.29,0.63-1.711,1.768-1.701C268.681,210.815,269.612,210.999,269.669,212.341z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#8B8A8A"
              d="M408.781,177.71c0.4,2.629-1.046,5.473,0.92,7.921
			c-0.524,1.005-1.488,2.589-1.899,0.327c-0.483-2.654,0.057-5.495,0.172-8.254c0.003-0.07,0.236-0.188,0.357-0.182
			C408.484,177.53,408.631,177.643,408.781,177.71z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#231F20"
              d="M1006.667,496.536c-3.112,0.559-6.227,1.104-9.335,1.678
			c-4.069,0.75-6.486-0.674-6.688-5.037c-0.1-2.16-0.243-4.322-0.25-6.484c-0.149-41.987-0.279-83.974-0.427-125.961
			c-0.004-1.326-0.144-2.652-0.22-3.979c0.276-24.61,0.798-49.216-0.34-73.827c-0.345-7.439,0.349-14.928,0.599-22.394
			c0.108-3.243,1.709-4.651,5.082-4.633c17.479,0.096,34.959,0.091,52.438,0.009c3.96-0.019,5.222,1.806,5.136,5.537
			c-0.162,6.988,0.112,13.988-0.105,20.973c-0.13,4.168,1.607,5.604,5.61,5.568c13.311-0.116,26.623-0.024,39.936-0.053
			c5.491-0.012,6.966-1.631,6.439-7.333c-0.351-3.795-1.34-7.574,0.701-11.547c1.451,1.479,1.398,3.175,1.353,4.869
			c-0.117,4.32-0.288,8.64-0.339,12.96c-0.081,6.968-1.065,8.03-8.216,8.041c-20.975,0.031-41.95,0.007-62.926,0.004
			c-2.164,1.78-4.736,1.271-7.213,1.292c-4.308,0.46-8.507-0.464-12.753-0.899c-1.727-0.178-3.624-0.574-5.306,0.478
			c-2.687,1.287-2.985,3.723-2.982,6.276c0.067,53.39-0.005,106.78,0.33,160.168C1007.265,473.69,1007.395,485.112,1006.667,496.536
			z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#231F20"
              d="M993.848,564.588c-0.078-0.57-0.156-1.139-0.235-1.709
			c-1.69-3.408-1.722-6.82-0.032-10.238c0.273-3.232-4.05-4.033-3.852-7.146c0.231-3.633,0.557-7.268,1.062-10.871
			c0.282-2.021,2.039-1.734,3.562-1.746c4.152-0.033,8.305-0.15,12.457-0.234c6.348,0.283,12.693,0.611,19.044,0.826
			c2.084,0.07,4.413,0.287,5-2.633c2.03-0.557,1.08,1.451,1.765,2.053c0.979,1.674,2.598,1.879,4.31,1.877
			c1.828,0,3.658-0.039,5.486,0.014c8.64,0.246,10.168,1.758,10.193,10.303c0.038,12.803-0.006,25.605,0.018,38.408
			c0.011,5.795,0.95,6.967,6.517,6.771c12.955-0.455,25.898-0.391,38.853-0.002c5.618,0.168,6.727-1.285,6.467-6.746
			c-0.126-2.654-0.114-5.314-0.103-7.973c0.014-3.133,1.181-4.822,5.146-3.26c-4.809,2.174-3.805,6.07-3.416,9.791
			c0.695,3.883-0.521,7.883,0.727,11.73l-0.024-0.061c-2.375,10.674-4.829,13.006-15.674,11.885
			c-10.47-1.082-20.873,0.354-31.31-0.297c-4.525-0.281-8.89,1.039-11.823,5.139c-1.603,2.24-3.647,3.219-5.627,0.541
			c-3.366-4.551-8.202-7.137-12.793-10.078c-0.918-1.959,0.399-3.307,1.287-4.775c0.649-1.084,1.177-2.244,1.962-3.246
			c1.961-2.24,1.81-5.01,1.506-7.594c-0.643-5.475-0.259-10.906,0.089-16.355c-0.003-1.287,0.024-2.574,0.001-3.857
			c-0.003-1.826,0.067-3.654,0.066-5.484c-0.006-7.877-0.58-8.451-8.708-8.297c3.542,1.012,5.665,3.498,6.948,6.68
			c1.398,3.469,1.042,6.537-2.671,8.475c-0.756,0.148-1.475,0.059-2.15-0.322c-1.536-1.748-0.28-3.715-0.45-5.566
			c0.042-2.107-1.347-3.004-3.073-3.6c-2.429-0.693-4.688,1.463-7.136,0.43c-1.14-0.543-1.907-1.363-2.003-2.682
			c-0.079-1.369,1.405-2.082,1.487-3.521c-2.729-0.473-5.445-0.064-8.148-0.131c-0.935,0.02-1.866,0.021-2.799,0.033
			c-2.456,0.057-4.919-0.232-8.063,0.186c2.247,1.43,4.146,0.846,5.946,0.865c1.002,0.01,2.109,0.055,2.383,1.262
			c0.343,1.51-0.813,1.992-1.958,2.328c-1.407,0.414-2.975,0.301-4.188,1.318c-1.437,1.805-1.795,4.121-2.925,6.057
			C996.286,564.299,995.804,566.077,993.848,564.588z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#858275"
              d="M1181.612,123.666c0.01,12.395,0.021,24.788,0.031,37.183
			c-3.52,1.457-4.823,4.035-4.488,7.841c0.366,4.156-0.901,8.235-1.139,12.381c-0.075,1.33-0.469,2.649-2.003,3.136
			c-0.54,0.051-1.021-0.102-1.438-0.452c-0.536-1.162-0.079-2.488-0.599-3.663c-0.158-0.766-0.172-1.533-0.091-2.305
			c0.526-3.106,0.62-6.244,0.419-9.37c-0.271-4.217-0.591-4.301-10.076-4.227c-3.021,0.023-6.2-0.394-6.027,4.26
			c0.117,3.126,0.05,6.264-0.014,9.396c-0.032,0.77-0.056,1.541-0.097,2.31c-0.021,0.757-0.11,1.506-0.286,2.236
			c-0.129,0.451-0.335,0.875-0.609,1.256c-1.889,0.195-3.796-0.049-5.701,0.123c-0.298-0.393-0.516-0.815-0.663-1.284
			c-0.197-0.74-0.284-1.49-0.321-2.25c-0.055-0.776-0.069-1.552-0.109-2.326c-0.068-3.294-0.055-6.589-0.052-9.883
			c0.002-2.704-1.265-3.896-3.936-3.819c-12.345,0.353-13.131-2.762-11.722,13.464c0.096,0.756,0.065,1.504-0.145,2.234
			c-1.287,0.892-0.136,1.959-0.194,2.942c-0.072,0.483-0.293,0.89-0.639,1.231c-2.471,0.482-4.578-0.633-6.74-1.531
			c-1.442-1.757-3.471-1.356-5.363-1.468c-1.741-0.102-3.579,0.153-4.979-1.283c0.247-2.385,2.262-2.327,3.74-2.257
			c4.651,0.221,5.566-2.689,5.604-6.319c0.028-2.642-0.392-5.266-0.997-7.834c-4.23-4.188-9.104-1.182-13.569-0.878
			c-2.278,0.154-0.78,2.994-0.618,4.67c0.396,2.101,0.416,4.193-0.056,6.282c-1.138,2.783-1.285,5.861-2.554,8.611
			c-0.964,1.232-2.285,1.773-3.786,1.974c-4.548,0.144-9.109,0.325-13.64-0.085c-1.742-0.157-4.637-0.461-1.844-3.414
			c4.108-0.696,8.315,0.569,12.395-0.593c0.883-0.616,1.066-1.525,1.039-2.524c0.007-1.163,0.017-2.318,0.016-3.476
			c-0.008-7.718-0.008-7.713-7.036-10.259c-0.947-0.343-1.856-0.775-1.626-2.072c2.555-0.738,5.134-0.113,7.067-0.334
			c-3.37-0.526-7.27,0.574-11.219,0.809c-2.651,0.156-3.311,1.318-3.396,3.726c-0.188,5.296,0.621,10.622-0.438,15.895
			c-1.296,2.303-3.556,2.401-5.804,2.538c-3.889-0.204-7.785,0.037-11.668-0.173c-1.784-0.097-4.08,0.409-4.545-2.373
			c4.693-0.815,9.384-0.413,14.084-0.304c-4.492-0.341-9.022,0.021-13.526-0.24c-1.489-1.923,0.495-2.248,1.4-3.073
			c2.687-1.012,5.511-0.751,8.281-0.699c3.783,0.071,5.37-1.786,5.407-5.387c0.03-2.974-0.06-5.914-1.444-8.633
			c-5.732-2.259-11.352-1.981-16.887,0.626c-1.358-0.693-2.015-2.929-4.13-1.95c-1.866,0.863-2.522,2.517-2.674,4.436
			c-0.182,2.311,0.009,4.634-0.167,6.943c-0.206,2.688-0.619,5.303-3.33,6.781c-1.245,0.203-2.107-0.203-2.419-1.5
			c-0.232-5.091,0.658-10.214-0.444-15.264c-1.828-2.13-4.182-2.348-6.715-1.86c-3.271,0.629-6.544,0.711-9.831,0.179
			c-4.747-1.575-9.567,0.304-14.36-0.271c-1.705-0.205-2.877,1.479-4.074,2.633c-0.595,0.484-1.208,0.902-2.019,0.492
			c-0.785-3.25-4.86-3.036-6.107-5.8c0.5-0.413,1.041-0.762,1.622-1.051c2.759-0.895,5.61-0.576,8.426-0.578
			c51.437-0.021,102.874-0.01,154.311-0.019c11.084-0.002,11.284-0.222,11.114-11.021c-0.073-4.661-0.013-9.321-0.055-13.982
			c-0.034-3.799-1.904-5.237-5.606-5.285c-52.912-0.693-105.826-0.501-158.74-0.495c-2.779,0-5.686-0.579-8.25,1.09
			c-1.685,1.554-1.354,3.606-1.362,5.553c-0.025,6.574,0.012,13.148-0.021,19.724c-0.01,2.123,0.198,4.317-1.44,6.062
			c-1.694-1.247-0.903-3.06-0.932-4.607c-0.13-7.146-0.23-14.295-0.128-21.44c0.05-3.463-1.29-7.302,2.582-10.016
			c0.783-0.549,0.438-2.803,0.496-4.275c0.219-5.594-0.412-11.235,0.971-16.762c0.863,5.015,1.528,10.035,1.477,15.152
			c-0.054,5.396,0.101,5.683,5.642,5.422c3.277-0.154,6.966,1.668,9.821-1.444c2.14-15.277,0.789-14.169-12.709-13.301
			c-1.119,0.072-2.185,0.787-3.359,0.286c-0.524-1.559-0.261-3.152-0.216-4.732c4.516-0.725,9.052-0.267,13.234-0.42
			c-3.914-0.765-8.3,0.911-12.549-0.399c-0.541,0.023-0.985-0.168-1.337-0.572c2.681-2.896,18.731-2.923,20.871,0.043
			c0.842,1.166,1.345,2.576,2.002,3.875c2.842,3.33,1.293,7.309,1.502,10.985c0.325,5.697,0.149,5.79,5.987,5.749
			c3.794-0.026,7.648,0.558,11.356-0.778c0.028-0.011,0.037-0.19-0.004-0.253c-0.09-0.137-0.217-0.249-0.336-0.376
			c-1.27-2.827-0.834-5.831-0.752-8.744c0.101-3.551-1.266-5.025-4.883-5.016c-3.552,0.01-7.237,0.771-10.637-1.025
			c-0.825-0.867-0.973-1.87-0.591-2.97c4.123-2.076,8.6-0.162,12.393-1.013c-3.519,0.206-7.479-0.01-11.441,0.083
			c-0.746,0.096-1.484,0.173-2.127-0.342c7.216-3.554,14.459-3.238,21.722-0.155c2.625,4.809,1.008,10.018,1.375,15.034
			c0.389,5.32,1.418,6.031,6.325,3.837c4.713,2.275,9.762,1.823,14.757,1.774c1.443-0.014,3.082,0.227,3.361-1.898
			c0.081-0.668,0.198-1.32,0.289-1.977c1.418-10.237,0.565-11.232-9.531-11.371c-2.285-0.031-4.676,0.334-6.711-1.201
			c-0.482-0.785-0.488-1.576-0.034-2.372c2.291-1.47,4.848-0.636,7.283-0.801c2.379-0.16,4.788,0.104,6.847-0.075
			c-4.381,0.048-9.055,0.161-13.742-0.118c-0.561-0.162-1.009-0.48-1.347-0.953c7.353-2.652,14.634-4.113,21.621,1.077
			c0.198,0.147,1.032-0.562,1.57-0.87c1.942,5.138,1.295,10.507,1.259,15.789c-0.023,3.315,0.953,5.008,4.527,4.759
			c3.958-0.275,7.984,0.499,11.874-0.801c0.018-0.006,0.015-0.184-0.03-0.242c-0.1-0.132-0.23-0.238-0.355-0.359
			c-1.387-4.677-1.074-9.263-0.011-13.819c-4.354-0.231-9.436,0.639-14.448-0.488c-2.271-0.842-1.854-3.241-2.808-4.851
			c6.224-3.963,12.971-1.796,19.524-1.561c1.804,0.065,1.869,2.573,3.313,3.408c1.58,1.572,1.332,3.634,1.43,5.574
			c0.108,2.144-0.096,4.305,0.151,6.438c0.763,6.563,0.923,6.742,7.646,6.665c3.103-0.036,6.299,0.605,9.271-0.877
			c0.074-0.037,0.146-0.211,0.12-0.284c-0.056-0.149-0.179-0.274-0.29-0.426c-1.108-2.74-0.82-5.616-0.663-8.402
			c0.227-4.004-1.585-5.236-5.332-5.195c-3.43,0.036-7.007,0.771-10.27-1.079c-0.826-0.982-0.914-2.049-0.37-3.186
			c4.983-1.807,10.022,0.408,15.028-0.438c-4.806,0.647-9.549-0.693-14.354-0.231c-0.597,0.057-1.07-0.157-1.422-0.641
			c6.079-3.483,12.643-1.919,18.951-1.378c3.319,0.285,2.399,3.894,2.19,6.225c-0.156,1.748-1.479,3.443,0.499,5.28
			c0.388-1.854,1.319-3.706,1.018-5.329c-1.104-5.938,2.218-6.781,6.85-6.642c2.817,0.086,5.652-0.26,8.462-0.098
			c6.259,0.361,7.289,1.518,7.811,8.037c-0.794-5.074,2.369-6.942,6.297-7.639c5.633-0.999,11.267-1.103,16.192,2.646
			c2.326,4.083,2.546,8.59,2.243,13.069C1176.907,119.561,1178.091,122.185,1181.612,123.666z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#CAC096"
              d="M1078.762,183.747c1.421-0.173,2.345-2.266,4.121-1.069
			c6.565,0.708,13.132,1.09,19.707,0.114c1.059-0.338,2.118-0.676,3.177-1.014c6.62,1.39,13.313,1.316,20.018,0.977
			c2.447-2.353,3.911,1.385,6.045,0.977c5.949-2.186,11.904-2.127,17.865-0.053c1.731-1.282,3.461-1.313,5.191,0.003
			c5.965-2.077,11.924-2.143,17.875,0.059c1.85,0.183,2.124-0.984,2.195-2.45c0.249-5.06,0.998-10.145,0.698-15.168
			c-0.296-4.945,1.755-6.057,5.989-5.273c0.002,40.979,0.004,81.96,0.006,122.939c-1.898-2.837-0.017-6.177-1.359-9.536
			c-9.398,5.479-17.252,2.011-24.506-4.474c1.367-1.472,3.211-1.978,5.047-2.506c2.816,0.105,5.563,0.814,8.335,1.223
			c3.314,0.487,4.988,0.01,5.311-3.684c0.226-2.582,1.188-5.107,2.649-7.396c-1.336-0.058-2.171,2.386-3.4,1.031
			c-1.637-1.804-1.018-4.005,0.086-6c0.353-0.617,1.842-0.632,1.532-0.838c-3.578-2.381-7.193-4.77-11.33-6.098
			c-1.798-0.576-3.689-0.817-4.251,1.797c-2.324,5.319-5.766,10.199-6.298,16.217c-0.398,0.712-0.972,1.181-1.799,1.293
			c-1.656-5.966-4.78-10.293-10.689-13.303c-4.693-2.391-5.184-8.558-6.897-13.258c-0.73-2.004,1.937-3.548,3.896-4.563
			c2.059,3.455,0.149,8.355,4.065,11.124c2.456,2.86,4.933,3.476,7.452,0.069c3.342-3.413,3.479-7.511,2.548-11.835
			c-0.298-1.382-1.359-2.673-0.48-4.188c1.137-1.939,2.458-3.81,2.337-6.222c0.854-0.659,1.025-2.111,2.449-2.113
			c2.25,1.687,1.856,4.541,2.985,6.726c0.716,3.882,2.878,4.562,6.087,2.679c2.009-1.178,4.238-1.863,6.271-2.987
			c1.827-1.01,2.827-2.272,1.802-4.41c0.068-1.051,0.661-1.87,1.335-2.565c2.989-3.086,3.101-6.259,0.592-9.702
			c-0.749-1.027-1.411-2.261-0.674-3.652c1.974-0.923,3.85,0.685,6.071,0.342c0-6.1-0.02-12.081,0.01-18.063
			c0.012-2.545-0.456-3.378-3.102-1.729c-5.577,3.476-11.589,3.394-18.149,3.028c-14.75-0.82-29.565-0.722-44.374,0.169
			c-13.593,0.817-27.245,1.082-40.857-0.234c-10.383-1.004-20.738-2.582-31.148,0.213c-2.815,0.756-5.948,0.446-8.934,0.422
			c-4.065-0.032-7.963,0.638-11.532,2.582c-4.472,2.437-8.173,1.275-11.825-1.824c-2.844-2.413-4.309-6.104-8.215-7.662
			c-2.095-0.836-0.091-2.377,1.072-3.264c4.294-0.185,8.635-0.578,12.174,2.671c1.061,1.99,2.158,3.912,4.956,2.564
			c3.07-0.756,4.839-4.164,8.315-4.081c4.854,0.803,9.591,2.508,14.681,0.993c2.015-0.601,3.677-1.34,4.919-2.999
			c2.915-4.008,1.92-8.626,1.956-13.055c0.02-2.3,0.348-4.236,2.47-5.594c3.49-2.232,4.996-1.538,5.677,2.758
			c2.604,4.675,0.81,9.771,1.304,14.65c0.371,3.677,1.543,5.656,5.446,5.268c4.095,0.002,8.189,0.015,12.285-0.009
			C1078.216,183.757,1078.488,183.752,1078.762,183.747z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#272323"
              d="M1060.487,316.872c2.208,2.642,0.172,5.432,0.17,8.132
			c-0.001,2.311,0.41,3.279,2.926,3.267c20.278-0.106,40.56,0.035,60.835-0.264c4.306-0.063,5.571,1.173,5.47,5.305
			c-0.191,7.804,0.035,15.618,0.087,23.428c6.957,3.14,9.111,8.531,7.686,15.819c-0.408,2.089,0.458,4.267,0.517,6.432
			c0.108,4.019-0.561,7.405-4.584,9.681c-3.087,1.745-3.152,4.655-0.112,6.765c3.241,2.249,3.787,5.154,3.736,8.617
			c-0.059,3.983-0.183,7.978,0.042,11.95c0.234,4.135,0.205,7.697-4.243,10.137c-3.025,1.66-1.176,6.009-3.043,8.634
			c-0.065,6.989-0.313,13.984-0.132,20.967c0.121,4.677-2.018,6.883-6.414,6.979c-5.325,0.116-10.656-0.084-15.985-0.077
			c-28.983,0.038-57.968,0.028-86.95,0.228c-4.132,0.028-5.198-0.977-5.137-5.332c0.277-19.479,0.005-38.964-0.031-58.447
			c-0.041-21.821-0.047-43.643-0.051-65.464c0-3.033,0.56-5.667,4.419-5.926c0.966-0.064,1.003-1.192,1.287-1.948
			c0.854-2.309-1.302-4.019-1.224-6.2c0.048-1.349-0.179-2.929,1.08-3.638c1.396-0.784,2.457,0.386,3.341,1.428
			c0.793,1.296,0.768,2.83,1.221,4.224c0.605,1.512,2.641,2.46,1.993,3.99c3.097-1.817,2.225-6.587,5.388-9.014
			c4.772,4.205,5.282,7.245,1.755,10.447c3.563,0.055,4.003-0.128,3.318-1.777c-1.309-3.153-1.614-6.188-0.931-9.509
			c0.215-1.04-1.155-1.914-1.779-2.883c-0.77-1.372-1.453-2.755-0.469-4.314c1.563-1.168,3.472-1.086,5.256-1.467
			c3.906,0.205,4.172,3.367,4.966,6.104c0.167,1.939,0.919,3.744,1.344,5.626c-1.344,3.256-0.873,5.265,3.017,6.503
			c3.931,1.251,6.092,0.749,7.464-3.38C1057.348,320,1057.24,317.014,1060.487,316.872z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F8EBB5"
              d="M1212.063,435.661c-0.094,2.559-0.733,4.932-2.365,6.968
			c-2.416,2.528-5.271-0.015-7.766,0.678c-0.748,0.01-0.229,1.086-0.328,0.427c-0.029-0.193,0.092-0.261,0.354-0.29
			c1.254-0.137,2.642-0.663,3.184,1.156c-2.25,0-4.501,0.001-6.751,0.001c-13.101-1.484-13.417-1.494-12.992-14.783
			c0.388-12.125,0.358-24.242,0.357-36.362c0-3.982,1.517-5.825,5.599-5.705c6.144,0.183,12.296-0.144,18.437,0.162
			c2.586,0.868,2.849,3.351,3.751,5.39c0.43,2.404,2.334,4.178,2.847,6.557c-0.201,9.896-0.151,19.792-0.011,29.677
			C1215.565,432.006,1211.997,432.552,1212.063,435.661z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F0F0EF"
              d="M1123.794,537.799c-0.365-0.717-0.73-1.434-1.096-2.152
			c-5.47-4.926-3.75-11.904-5.021-18.004c-0.409-1.965,1.972-2.846,3.039-4.246c1.097-1.438,2.113-2.949,3.412-4.23
			c2.773-2.266,5.424-4.744,9.168-5.346c6.59-0.611,12.803,0.221,18.07,4.697c1.328,1.863,2.993,3.529,3.585,5.846
			c0.679,2.855,2.067,5.451,3.068,8.191c1.067,4.25-0.622,7.873-2.598,11.436c-2.913,1.914-4.96,4.883-8.015,6.635
			C1138.858,545.358,1131.257,542.157,1123.794,537.799z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#D8CCA0"
              d="M912.799,354.733c-1.991,0.002-3.981,0.004-5.973,0.006
			c-2.517-3.414-1.573-7.322-1.321-11.013c0.618-9.072,0.223-18.114-0.264-27.164c-0.072-1.352-0.707-2.528-1.313-3.702
			c-2.5-0.783-2.956-3.76-5.187-4.832c-1.448-4.455-4.816-7.336-8.367-10.01c-1.314-0.991-2.709-1.846-3.668-3.223
			c-8.416-7.916-16.892-15.775-23.933-25.007c-2.615-3.931-5.318-7.774-9.788-9.864c-7.724-7.977-15.446-15.954-23.168-23.931
			c2.276-2.566-0.262-3.887-1.555-5.29c-3.107-3.375-6.415-6.565-9.642-9.829c5.348-0.516,9.589,1.418,11.964,6.251
			c2.094,4.263,6.273,6.308,9.32,9.53c7.852,8.305,14.638,17.632,23.76,24.759c2.827,2.208,4.806,5.47,7.368,8.056
			c10.2,10.297,20.67,20.333,30.677,30.813c12.897,13.505,16.958,29.485,11.863,47.559
			C912.922,350.159,912.896,352.42,912.799,354.733z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#322F2B"
              d="M1077.833,583.77c-4.426,4.469-11.706,4.725-17.364,0.609
			c-4.281-3.113-5.881-12.174-2.852-16.838c0.982-1.514,2.335-2.787,3.535-4.156c1.128-1.289,1.789-2.541-0.279-3.545
			c-4.997-2.428-4.415-6.557-3.03-10.672c1.52-4.52,5.252-5.666,9.586-5.377c4.654,0.311,7.095,3.172,8.172,7.434
			c0.241,0.957,0.301,1.977,0.314,2.969c0.028,2.162,0.45,3.33,3.163,2.58c3.5-0.967,6.13,0.723,7.83,3.809
			c1.655,3.002,1.219,5.779-1.263,8.129c-2.095,1.982-2.768,4.459-2.801,7.201c-0.025,2.201-0.268,4.313-2.001,5.928
			c-2.884,1.182-2.706-1.381-3.453-2.818c-1.749-3.363-5.213-3.625-7.52-0.584c-0.531,0.701-0.862,1.418-0.439,2.277
			c0.221,0.451,0.553,0.924,1.054,0.943c1.219,0.047,1.026-1.041,1.246-1.758c0.34-1.111,0.528-2.395,2.02-2.461
			c1.476-0.063,1.861,1.211,2.285,2.264C1076.592,581.081,1077.669,582.225,1077.833,583.77z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E0D4A5"
              d="M1129.973,434.774c-0.106-1.825-0.185-3.652-0.326-5.475
			c-0.241-3.097,0.739-5.27,4.187-5.503c2.245-0.151,2.564-1.479,2.512-3.39c-0.165-5.984-0.305-11.971-0.248-17.956
			c0.027-2.807-0.469-4.983-3.644-5.488c-3.039-0.483-2.825-2.862-2.877-4.981c-0.054-2.206,0.037-4.257,3.161-4.602
			c2.678-0.297,3.842-2.333,3.895-4.978c0.105-5.281-0.557-10.537-0.107-15.854c0.276-3.265-1.027-6.296-5.043-6.827
			c-2.005-0.266-1.717-1.63-1.507-2.982c11.474,0.342,18.22,8.763,15.574,20.732c-2.577,11.658-3.048,22.922-0.043,34.652
			C1148.199,422.636,1142.309,430.321,1129.973,434.774z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2E2A27"
              d="M1085.771,264.752c4.577-2.471,7.6,0.021,10.254,3.326
			c2.68,3.338,2.257,6.751-0.099,10.099c-2.536,3.603-5.799,5.39-10.284,4.14c-2.286-0.637-4.439-2.716-6.972-0.634
			c-2.336-0.114-2.774-3.063-5.006-3.337c-1.523-1.045-2.97-2.005-2.296,1.201c0.135,0.643-0.184,1.241-0.57,1.774
			c-2.04,1.729-3.969,3.777-7.068,2.488c-7.856-5.807-9.533-14.458-3.914-20.206c1.27,0.431,1.744,1.507,2.008,2.657
			c0.817,3.557,3.132,3.418,5.736,2.298c1.681-0.723,3.661-1.624,3.232-3.848c-0.388-2.005-2.353-1.268-3.776-1.354
			c-1.078-0.066-2.508,0.268-2.373-1.621c3.27-4.785,10.166-4.827,14.959-0.094c1.394,1.377,2.793,2.749,4.189,4.123
			c1.446,0.441,2.109,1.924,3.467,2.621C1088.156,266.563,1084.827,266.511,1085.771,264.752z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#DFD3A5"
              d="M936.835,112.753c8.035,4.491,9.409,11.6,8.045,19.944
			c-0.962,5.89,0.999,11.799-0.001,17.769c-1.017,6.064-4.7,10.18-10.833,10.235c-18.489,0.168-36.983,0.219-55.471-0.028
			c-5.984-0.079-10.984-2.557-12.672-9.115c1.909-1.444,3.988-1.146,6.093-0.603c4.158,2.888,8.817,3.445,13.775,3.387
			c13.154-0.154,26.312,0.014,39.468-0.073c12.094-0.08,13.07-1.138,13.097-13.109c0.009-4.159-0.173-8.323,0.384-12.473
			c0.485-3.621-0.381-6.919-2.67-9.813C935.395,116.716,935.099,114.602,936.835,112.753z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F0F0F1"
              d="M1152.784,563.481c1.09-6.129-2.874-5.957-6.984-5.602
			c-1.957-2.406-0.629-5.209-0.941-7.813c0.037-2.092,1.353-3.512,2.595-4.977c3.667-2.313,7.262-4.764,11.384-6.262
			c1.006-0.168,1.997-0.096,2.979,0.156c2.889,0.738,4.969,2.299,5.483,5.432c-0.204,2.447,0.552,4.936-0.201,7.332
			c0.812-0.857,0.642-1.992,0.647-3.096c0.069-1.166,0.121-2.488,1.489-2.748c1.13-0.215,1.641,0.908,2.096,1.766
			c3.771,7.082-1.421,16.381-9.564,17.154c-1.959,1.059-3.906,1.008-5.846-0.055C1154.419,565.45,1153.441,564.854,1152.784,563.481
			z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2C2827"
              d="M1185.329,357.76c-8.547,3.433-12.448,2.927-15.766-1.616
			c-2.857-3.913-1.66-7.79,0.931-10.976c2.944-3.62,7.221-3.878,11.368-2.194c4.208,1.709,5.633,6.153,4.438,13.038
			c4.431-1.064,9.034-2.457,11.679,2.878c1.446,2.92,1.34,5.789-1.169,8.203c-2.437,2.345-5.103,3.671-8.493,1.867
			C1183.699,366.503,1182.597,362.843,1185.329,357.76z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2B2725"
              d="M1205.714,457.836c0.112,4.974-1.51,8.322-7.284,7.971
			c-0.487-0.03-1.02,0.683-1.531,1.05c-1.378,1.262-1.068,3.053-1.474,4.626c-0.919,3.574-3.488,5.098-6.968,5.246
			c-3.071,0.129-5.498-0.988-6.809-3.975c-1.432-3.263-1.175-6.194,1.811-8.445c1.96-1.477,3.164-3.017,3.063-5.765
			c-0.234-6.369,2.223-8.923,8.631-9.682c5.928-0.701,8.266,1.007,9.842,7.149C1205.155,456.64,1205.471,457.229,1205.714,457.836z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#CCC197"
              d="M1154.774,533.858c0.771-3.695,3.539-7.012,2.115-11.125
			c0.355-3.107-0.48-5.771-2.918-7.84c-0.177-2.225-2.287-3.305-3.063-5.176c0.318-2.906,2.003-4.014,4.771-3.465
			c4.15,0.826,7.729-1.082,11.43-2.324c2.056-0.691,3.229-2.203,3.006-4.525c0.703-4.648,0.542-9.094-2.589-13.008
			c-0.8-0.998-1.41-2.229-0.756-3.623c6.306,0.357,9.09,3.82,10.053,12.244c0.814,7.127-0.508,12.904-7.613,16.709
			c-5.359,2.867-7.418,9.963-6.05,16.598c0.632,3.063,1.13,6.154,1.688,9.232c-1.707,2.129-4.09,0.861-6.15,1.227
			C1155.121,538.946,1152.792,538.118,1154.774,533.858z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F0F0F0"
              d="M1098.693,514.51c-0.17-0.635-1.851-0.979-0.758-1.717
			c2.866-1.936,1.812-4.035,0.636-6.252c-0.7-0.393-0.99-1.113-1.395-1.742c-0.47,0.498-0.146,1.119-0.252,1.678
			c-0.204,1.08-0.415,2.15-0.896,3.148c-1.065-0.297-2.132-0.594-3.198-0.891c-0.642-4.107-1.312-8.219,0.995-12.084
			c2.185-4.467,5.607-6.672,10.687-5.854c0.661-0.082-0.171,0.002,0.491-0.076c2.59-0.311,6.463-1.152,6.724,1.848
			c0.26,2.988-3.733,1.012-5.698,1.766c-0.321,0.123-0.639,0.16-0.711,0.234c4.142,0.211,8.505-1.035,12.67,0.959
			c3.669,9.99-3.047,21.004-13.127,21.553C1102.555,516.827,1100.185,516.719,1098.693,514.51z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F1F1F1"
              d="M1158.771,227.663c-2.282-1.756-1.334-4.832-2.826-6.911
			c-2.2-0.936-1.935-3.415-3.12-4.985c-0.012-0.913-0.023-1.825-0.035-2.738c0.568-3.003,3.361-3.299,5.458-4.304
			c2.198-1.053,4.511-1.893,6.779-2.805c1.683-0.471,3.495-1.412,4.966,0.212c1.65,1.821,2.811,0.075,4.171-0.314
			c0.194,0.268,0.388,0.534,0.581,0.8c0.361,0.739,0.669,1.514,1.095,2.217c4.201,6.935,4.021,8.175-2.052,13.73
			c-1.922,3.308-5.073,5.067-8.437,6.426C1162.981,229.947,1160.523,230.275,1158.771,227.663z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F3F3F4"
              d="M1144.851,489.733c0.305-0.289,0.609-0.576,0.915-0.865
			c3.587-4.246,8.315-4.041,13.166-3.389c2.178,0.479,4.106,1.484,5.904,2.777c4.68,3.35,3.34,8.086,2.924,12.619
			c-2.001,4.541-5.979,5.258-10.291,5.264c-3.561,0.006-6.766-1.385-9.998-2.658
			C1143.462,499.788,1142.507,494.754,1144.851,489.733z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#272323"
              d="M871.778,152.05c-1.958-0.164-3.916-0.328-5.875-0.492
			c-0.05-11.644,0.011-23.29-0.21-34.931c-0.08-4.222,1.73-5.563,5.683-5.448c20.272,0.587,40.55,0.632,60.825,0.115
			c1.757-0.045,3.255,0.446,4.634,1.459c0.041,1.999,0.083,3.998,0.124,5.996c0.863,1.824,1.361,3.711,1.363,5.764
			c0.007,6.98,0.083,13.966-0.125,20.939c-0.157,5.272-2.492,7.719-7.724,7.803c-17.62,0.282-35.243,0.162-52.866,0.067
			C875.58,153.312,873.764,152.286,871.778,152.05z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#433F37"
              d="M1060.487,316.872c-2.469,1.98-2.544,5.164-3.517,7.777
			c-1.093,2.939-2.469,3.558-5.108,2.63c-1.234-0.434-2.463-0.883-3.702-1.305c-5.914-2.014-5.924-2.038-2.404-7.057
			c1.337-2.192,3.995-1.953,6.102-3.21c-2.587-1.652-5.595-0.618-8.038-1.883c-1.069-2.214-0.268-5.685-4.073-6.059
			c-2.147,0.494-2.679-1.281-3.487-2.564c-0.85-1.349-1.474-2.855-2.993-3.639c-0.608-0.266-1.222-0.517-1.886-0.581
			c-5.048-0.495-5.309-0.906-3.823-6.066c2.521,0.003,5.04,0.007,7.56,0.011c1.006,1.979,2.478,3.69,4.394,4.614
			c1.758,0.848,2.522,1.972,2.772,3.744c0.674,4.789,3.339,6.902,8.258,6.517C1055.688,309.396,1058.985,311.963,1060.487,316.872z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#3F3B34"
              d="M1034.846,308.892c0.299,1.238,0.599,2.477,0.897,3.715
			c-0.122,0.184-0.244,0.366-0.364,0.552c-2.138,0.999-4.685,0.947-6.582,2.564c-1.627,0.517-2.706,2.232-4.617,2.132
			c-0.989-0.465-1.978-0.93-2.935-1.38c-1.796,3.146,2.428,6.279-0.267,9.279c-0.177-0.739,0.045-2.12-0.96-1.924
			c-2.957,0.578-4.731-0.165-6.612-2.809c-2.66-3.739-3.391-7.527-2.436-11.742c0.731-3.229,1.855-6.369,2.805-9.549
			c3.518-1.9,5.338-0.757,6.405,3.033c1.158,4.115,1.94,4.196,4.916,0.898c1.217-1.349,1.922-0.938,2.56,0.282
			c0.866,1.659,1.835,3.114,3.866,3.426C1032.764,307.595,1034.046,307.721,1034.846,308.892z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#A49E85"
              d="M1104.708,516.047c6.547-0.293,12.546-6.77,13.048-14.191
			c0.133-1.971,0.006-3.959-0.002-5.939c-0.742-1.09-0.903-2.164,0.079-3.209c0.307,0.02,0.609,0.061,0.91,0.123
			c2.856,2.707,4.69,5.902,4.369,9.961c-0.203,2.568,2.176,4.42,1.747,7c-0.16,0.285-0.268,0.623-0.489,0.85
			c-6.879,7.014-6.673,14.59-1.9,22.602c0.372,0.625,0.167,1.596,0.229,2.406c-5.837-1.221-5.467-6.682-6.655-10.633
			c-0.825-2.742-1.746-3.486-4.273-3.355c-4.163,0.217-8.35,0.363-12.075-2.064c0.651-2.672,2.644-2.805,4.834-2.543
			c0.451,0.055,0.886,0.242,1.354,0.168C1105.166,517.155,1104.717,516.821,1104.708,516.047z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F3F3F4"
              d="M1015.757,183.851c-3.61-2.513-8.052-1.964-11.995-3.255
			c-0.853-6.138,3.603-10.584,5.089-15.977c0.314-0.284,0.629-0.568,0.944-0.853c5.38-0.866,9.149,2.283,12.509,5.593
			c3.059,3.014,1.024,6.561-0.318,9.764c-0.764,1.824-1.494,3.724-3.117,5.044C1017.768,184.698,1016.732,184.562,1015.757,183.851z
			"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F0EFEE"
              d="M1141.863,245.89c-5.606-2.689-3.523-7.833-3.89-12.195
			c0.255-0.217,0.509-0.432,0.763-0.647c0.289-2.129,1.821-3.557,2.942-5.217c3.295,0.344,6.591,0.688,9.884,1.032
			c3.639,7.078,3.066,12.403-1.827,16.974C1147.123,247.632,1144.497,247.262,1141.863,245.89z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#8A8883"
              d="M1201.884,337.616c-0.082,3.404,3.338,4.466,4.636,6.919
			c4.124,7.795,7.794,15.788,10.075,24.35c-1.723,3.642-5.386,5.353-8.031,8.076c-0.543-1.422-1.085-2.843-1.628-4.265
			c1.583-2.06,3.576-3.676,5.716-5.319c-2.124,1.135-3.528,3.278-6.026,3.525c-2.29-2.602-1.77-6.408-3.896-9.079
			c0.308-0.792,0.998-1.269,1.521-1.886c0.879-1.037,3.053-2.386,1.564-3.4c-1.323-0.903-1.438-5.152-4.701-2.468
			c-0.296,0.244-0.942-0.065-1.388-0.288c-1.644-0.768-1.829-2.351-2.154-3.855c1.727-2.494,2.923-5.124,2.793-8.292
			C1200.306,340.191,1200.51,338.624,1201.884,337.616z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#868279"
              d="M1212.686,393.838c-0.638-1.623-1.275-3.245-1.912-4.868
			c-0.53-0.697-1.178-1.339-1.086-2.326c1.094-3.096-1.521-5.823-0.883-8.873c2.478-2.371,4.255-5.665,8.146-6.167
			c3.351,6.999,5.342,14.297,4.868,22.138C1218.798,396.039,1215.741,394.883,1212.686,393.838z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#D1D1D1"
              d="M1166.927,500.979c-0.058-2.141-0.337-4.307-0.118-6.42
			c0.279-2.697-0.812-4.383-3.083-5.535c-0.812-2.295-1.993-3.873-4.729-2.438c-4.674-0.771-9.041,0.24-13.231,2.281
			c0.791-4.209,2.411-7.787,6.967-9.125c3.45-0.82,6.894-0.32,10.338,0.051c0.913,1.391,2.16,2.365,3.699,2.979
			c2.344,4.199,5.919,7.912,5.03,13.369c-0.188,1.15-0.291,2.254-1.003,3.209C1170.063,501.211,1168.756,501.715,1166.927,500.979z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#D3C89C"
              d="M1030.706,308.692c-3.507,0.063-3.544-2.783-4.303-5.685
			c-0.882,2.354,0.207,5.866-3.046,5.495c-2.899-0.332-5.217-2.179-4.707-5.863c0.648-4.678-2.647-2.943-4.875-2.909
			c0.28-1.859,2.829-1.601,3.345-3.934c-2.527,0-4.901,0-7.275,0c5.807-2.259,11.81-0.552,17.713-0.881
			c0.084,1.766,0.168,3.53,0.259,5.442c2.205-0.269,4.401-2.267,6.263,0.296C1034.423,303.95,1034.647,307.195,1030.706,308.692z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#CDC29A"
              d="M1181.612,123.666c-4.883,1.018-6.498-1.116-5.869-5.81
			c0.68-5.077-0.81-10.079-0.824-15.145c1.772,0.782,3.544,1.563,5.652,2.494c0-16.943,0-33.557-0.001-50.169
			c0.29-1.088-0.019-2.365,1.012-3.223C1181.592,75.766,1181.602,99.716,1181.612,123.666z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#4B4740"
              d="M1209.688,386.644c0.921,0.515,1.228,1.315,1.086,2.326
			c-6.465-0.08-12.931-0.083-19.393-0.271c-3.437-0.101-4.739,1.381-4.671,4.756c0.131,6.464-0.181,12.942,0.093,19.397
			c0.274,6.49-0.889,12.862-1.021,19.304c-0.174,8.396,1.233,10.063,9.47,11.453c1.083,0.184,2.392-0.273,3.14,0.991
			c-2.989,0.017-5.988,0.188-8.966,0.005c-3.053-0.189-4.783-1.716-4.765-5.129c0.084-15.813,0.069-31.626,0.013-47.439
			c-0.014-3.864,1.799-5.313,5.538-5.266C1196.702,386.855,1203.195,386.699,1209.688,386.644z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#D0C69A"
              d="M1058.971,120.675c0.08,3.736-2.581,3.06-4.86,3.032
			c-2.677-0.031-3.29-1.668-3.279-3.971c0.027-5.988-0.019-11.978-0.035-17.967c2.609,0.984,5.219,1.969,7.828,2.953
			C1061.228,109.986,1060.774,115.315,1058.971,120.675z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#C0B693"
              d="M1147.813,545.698c-0.635,1.691-1.54,3.199-2.955,4.365
			c-6.508-1.15-12.903-2.527-17.759-7.596c-1.349-1.408-3.224-2.385-3.306-4.668c3.833-0.422,6.441,2.889,10.188,3.18
			c4.656,0.359,9.22,1.279,13.498-1.414C1149.804,541.489,1148.998,543.583,1147.813,545.698z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#6A6661"
              d="M1166.927,500.979c1.29-0.543,2.579-1.086,3.869-1.629
			c2.292,2.803,0.111,4.824-1.878,5.352c-4.517,1.195-8.771,4.596-13.826,2.574c-2.675-1.07-2.835,1.57-4.185,2.441
			c-3.513-3.553-8.219-4.053-12.761-4.855c-1.743-0.307-3.58-0.086-5.374-0.105c-0.608-0.664-0.564-1.336-0.023-2.014
			c1.332-0.086,2.664-0.172,3.997-0.258c3.686-0.52,7.372-0.918,11.059-0.002c3.545-0.313,6.216,2.588,9.964,2.35
			C1161.487,504.594,1164.534,503.86,1166.927,500.979z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#807D76"
              d="M1166.714,544.77c-1.023-2.318-2.273-4.396-5.097-4.809
			c1.13-2.111,3.818-0.299,5.101-2.025c2.752-0.109,2.986,3.039,5.01,3.873c0.099,1.475-0.313,3.273,0.381,4.373
			c4.302,6.811,0.747,12.508-2.677,17.91c-1.66,2.617-4.728,4.572-7.664,0.732c9.513-5.605,11.102-9.414,7.647-18.469
			c-1.494,0.898-0.108,2.418-0.775,3.447C1165.608,549.04,1167.699,546.317,1166.714,544.77z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#6E6B65"
              d="M1201.884,337.616c-0.066,2.296,0.197,4.665-0.29,6.867
			c-0.498,2.248-0.536,5.239-4.022,5.441c-0.452-5.066-5.239-7.622-6.935-11.969c2.897-1.445,6.649-1.547,8.152-5.185
			C1200.276,334.096,1201.901,335.331,1201.884,337.616z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#7C755E"
              d="M1072.433,94.877c-2.671-0.535-4.682-2.194-6.479,0.412
			c-1.041,1.509-2.744,1.196-3.889-0.083c-1.683-1.88-3.522-2.807-6.139-2.421c-2.188,0.322-2.444-1.342-2.093-2.945
			c0.27-1.227,1.143-3.371,2.345-2.342c3.044,2.606,4.033-0.252,5.115-1.948c1.856,1.228,1.348,2.509,1.39,3.575
			c0.056,1.41,0.156,2.864,1.776,3.117c1.843,0.288,1.283-1.486,1.656-2.436c0.457-1.161,1.129-2.037,2.535-1.979
			c1.507,0.061,2.572,0.853,2.969,2.235C1072.015,91.439,1072.115,92.901,1072.433,94.877z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#CCC19A"
              d="M1132.749,502.743c0.008,0.672,0.016,1.342,0.023,2.014
			c-2.638,1.678-5.275,3.357-7.913,5.035c-2.42-2.504-3.763-5.43-3.054-8.943c0.683-3.387-3.729-4.713-3.063-8.02
			c3.855-0.369,5.238,2.703,7.149,5.086C1127.703,500.17,1129.161,502.987,1132.749,502.743z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#DACDA0"
              d="M1053.66,469.905c2.14,1.627,0.815,3.953,1.192,5.936
			c0.17,0.895-0.295,2.211,1.045,2.385c1.156,0.15,1.772-0.881,1.771-1.9c-0.005-5.057,3.049-5.98,7.216-5.497
			c2.531,5.97,2.067,6.808-4.879,8.815c-1.035,0.467-2.087,0.902-3.104,1.406c-3.188,1.584-5.331,0.256-5.934-2.813
			c-0.531-2.705-0.173-5.594-0.143-8.399c0.012-1.079,0.444-2.007,1.755-1.81c1.041,0.156,1.071,1.078,1.102,1.914
			c-0.517,0.669-0.447,1.537-0.771,2.273C1053.401,471.522,1053.172,470.597,1053.66,469.905z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#928A6D"
              d="M909.559,263.63c-1.736-3.072-2.813-6.253-0.082-8.479
			c2.973-2.422,7.075-1.782,10.407-0.8c2.428,0.716,2.537,3.715,1.376,6.012c-1.222,2.418-3.534,2.936-5.973,2.41
			c-1.729-0.373-0.885-1.813-0.77-2.879c0.133-1.231,0.059-2.548-1.362-2.794c-1.889-0.328-1.463,1.339-1.574,2.367
			C911.422,260.947,911.322,262.408,909.559,263.63z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#C0B78E"
              d="M1064.644,261.735c1.484,2.187,2.92-0.223,4.429-0.096
			c2.033,0.172,3.761,0.343,3.612,2.924c-0.278,4.856-4.282,5.394-7.718,6.229c-3.392,0.824-4.296-1.422-4.411-4.327
			c-0.038-0.962-0.481-1.908-0.739-2.861C1061.425,262.98,1063.034,262.358,1064.644,261.735z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#DDD0A2"
              d="M1084.714,469.568c1.572-1.526,2.847,0.565,4.311,0.354
			c2.084,1.67,4.645-0.344,6.785,0.879c0,0.975,0.008,1.951-0.002,2.926c-0.038,3.842-6.135,7.684-9.701,6.025
			c-0.32-0.148-0.219-1.207-0.314-1.842C1083.292,475.407,1085.501,472.293,1084.714,469.568z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#3F3B38"
              d="M1058.971,120.675c-0.115-5.317-0.231-10.635-0.347-15.952
			c0.305-1.04-0.079-2.313,1.044-3.074c0.383,0.041,0.765,0.081,1.147,0.122c0.543,0.542,0.636,1.144,0.24,1.811
			c-0.028,0.785-0.058,1.571-0.086,2.356c1.83,2.339,1.084,5.11,1.268,7.715c0.355,5.021,1.692,6.326,6.767,6.675
			c2.929,0.201,6.047-0.695,8.689,1.361c1.095,0.887,1,1.873-0.374,1.912c-5.22,0.15-10.446,0.039-15.67,0.094
			C1059.501,123.717,1060.054,121.507,1058.971,120.675z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#393534"
              d="M1030.866,102.705c0.017,1.061,0.032,2.122,0.049,3.183
			c1.938,2.456,1.146,5.375,1.34,8.115c0.348,4.909,1.397,5.934,6.323,6.304c3.06,0.229,6.317-0.751,9.099,1.36
			c-1.824,1.969-1.823,2.097-3.984,2.129c-3.828,0.057-7.662-0.195-11.484-0.06c-2.775,0.1-3.34-1.225-3.327-3.638
			c0.024-4.824-0.171-9.65-0.274-14.475c0.156-1.233,0.312-2.467,0.468-3.699c0.518-0.052,1.035-0.104,1.553-0.155
			c0.383,0.123,0.546,0.303,0.489,0.539C1031.054,102.573,1030.971,102.705,1030.866,102.705z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#AAA080"
              d="M1072.737,477.883c1.656-2.414,0.906-4.469,1.157-6.385
			c0.167-1.278,0.588-2.329,2.081-2.26c1.156,0.053,1.712,0.903,1.88,1.98c0.392,2.514,0.16,5.063-0.795,7.334
			c-1.226,2.916-5.504,2.998-7.485,0.342c-0.294-0.393-0.681-0.844-0.708-1.287c-0.163-2.639-1.325-6.281,0.388-7.687
			c2.787-2.289,2.073,1.345,2.441,2.649c0.31,1.096,0.143,2.318,0.271,3.475C1072.015,476.473,1072.302,476.874,1072.737,477.883z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#363231"
              d="M1107.919,102.669c0.008,1.08,0.016,2.16,0.023,3.241
			c1.935,2.494,1.163,5.444,1.291,8.21c0.189,4.084,2.192,5.986,6.242,6.209c3.089,0.169,6.381-0.784,9.198,1.332
			c0.368,2.562-1.895,1.82-2.92,1.978c-2.271,0.348-4.623,0.221-6.941,0.185c-8.758-0.133-8.813-0.217-8.907-9.225
			c-0.038-3.637-0.133-7.272-0.201-10.909c0.046-0.815,0.182-1.591,0.946-2.08c0.376,0.054,0.751,0.107,1.127,0.161
			c0.318,0.154,0.444,0.339,0.381,0.554C1108.091,102.554,1108.011,102.669,1107.919,102.669z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#A2997A"
              d="M1077.833,583.77c-2.754-0.801-2.788-3.645-4.525-6.92
			c-0.356,3.303-0.57,5.289-0.818,7.588c-2.845-0.547-5.463-2.314-5.068-4.805c0.477-3.012,3.83-4.385,6.804-4.795
			c2.993-0.414,4.653,1.279,4.554,4.4c-0.049,1.49,0.225,2.648,2.065,2.602C1080.592,583.657,1079.309,583.864,1077.833,583.77z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#72706D"
              d="M1117.833,492.708c-0.026,1.068-0.053,2.139-0.079,3.209
			c-2.646-0.014-5.293-0.031-7.939-0.039c-2.472-0.006-4.944,0-8.002,0c2.717-3.654,6.075-3.051,10.163-2.568
			c-2.429-2.869-5.62-0.697-7.464-2.512c0.409-2.35,1.356-3.68,4.009-2.387C1111.594,489.907,1114.727,491.28,1117.833,492.708z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#938B6E"
              d="M1116.247,271.713c2.569,0.131,4.084,1.834,5.274,3.742
			c1.143,1.834,1.95,3.876,4.461,4.201c1.232,0.16,1.843,0.816,1.227,1.999c-1.138,2.185,0.707,5.235-1.823,6.869
			c-1.294,0.835-2.506-0.53-2.4-1.568c0.596-5.846-2.946-9.469-6.596-13.103c-0.219-0.218-0.367-0.573-0.408-0.884
			C1115.943,272.687,1116.095,272.377,1116.247,271.713z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#8C8469"
              d="M901.68,261.875c-3.227,2.354-6.563,4.123-11.266,2.099
			c2.09-1.995,6.12-0.256,6.184-4.199c0.047-2.878-2.125-3.627-4.616-4.506c3.28-2.554,5.411-1.208,7.396,0.796
			c0.99,1.001,1.306,2.83,3.28,2.567C903.516,260.069,902.843,261.046,901.68,261.875z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#CBC19B"
              d="M1147.805,502.483c-3.686,1.879-7.373-0.488-11.06,0.002
			c3.204-0.666,4.469-2.432,4.141-5.857c-0.282-2.953-0.038-6.15,3.964-6.895C1144.811,494.219,1144.298,498.817,1147.805,502.483z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#645F4E"
              d="M1063.73,283.81c3.109,0.779,4.242-3.001,6.999-3.035
			c1.2-0.907,1.632-2.628,3.261-3.082c3.167-0.556,3.378,2.357,4.681,3.99C1074.401,287.38,1068.936,284.683,1063.73,283.81z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#565249"
              d="M1199.726,353.781c3.578-3.193,5.64,1.369,8.511,1.864
			c0.727,0.125-0.356,2.352-1.141,3.325c-1.118,1.389-2.306,2.835-4.366,2.853C1201.729,359.143,1200.728,356.462,1199.726,353.781z
			"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#948B6E"
              d="M1100.701,480.831c-3.849-0.254-4.251-2.602-3.882-5.617
			c0.217-1.766,1.027-4.252,2.396-3.695c3.158,1.283,3.362,5.061,4.186,7.934C1103.999,481.534,1101.609,480.506,1100.701,480.831z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#544E45"
              d="M1144.655,285.834c-1.752,2.15-0.366,4.711-0.918,7.018
			c-1.591-8.053-1.28-16.064-0.836-24.074c0.585,0.007,1.169,0.014,1.754,0.021C1144.655,274.477,1144.655,280.155,1144.655,285.834
			z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#CEC399"
              d="M1086.498,85.956c2.051-0.759,4.107-2.52,6.139,0.037
			c-0.931,2.868,0.236,6.188-1.889,8.8c-1.586-1.323-3.171-2.647-4.757-3.971C1084.258,89.002,1085.022,87.442,1086.498,85.956z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#AAA080"
              d="M1084.714,469.568c2.416,2.515,0.841,5.544,1.078,8.341
			c-2.336-0.561-4.499,0.049-7.226,1.697c0.93-3.408-0.854-7.25,4.396-7.266C1083.549,472.338,1084.13,470.536,1084.714,469.568z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#756E5A"
              d="M1097.731,92.64c0.232-0.616,0.394-1.271,0.708-1.843
			c0.96-1.743,0.746-5.032,3.493-4.592c2.435,0.391,2.434,3.489,2.438,5.483c0.005,3.369-3.027,1.331-4.592,2.003
			c-0.571,0.246-1.313,0.094-1.978,0.123c-0.308-0.244-0.42-0.499-0.338-0.766C1097.546,92.776,1097.636,92.64,1097.731,92.64z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#B2AD9F"
              d="M1174.164,205.817c-0.854,2.794-1.979,4.67-4.556,1.279
			c-1.165-1.533-2.447-1.159-3.791-0.32c-1.247-0.73-3.045,0.554-4.038-1.105c2.404-1.284,5.159-1.488,7.588-3.026
			C1172.307,200.784,1173.162,203.798,1174.164,205.817z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#8A8269"
              d="M887.811,258.55c-0.113,0.999-0.403,2.406-2.291,1.851
			c-2.108-0.621-4.927-0.688-4.657-3.987c0.136-1.654,1.226-2.792,3.039-2.422C886.104,254.441,888.064,255.313,887.811,258.55z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2E2A2A"
              d="M1161.779,205.671c1.308,0.507,3.117-0.818,4.038,1.105
			c-4.157,2.469-9.303,2.88-13.026,6.252c-1.064-0.243-2.581,0.042-2.771-1.448c-0.164-1.294,1.29-1.177,2.088-1.547
			C1155.317,208.548,1158.554,207.12,1161.779,205.671z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#756F5B"
              d="M1060.006,479.643c3.771-1.752,6.508-4.076,4.879-8.815
			c3.753-0.63,2.567,2.198,2.888,4.048c0.434,2.5-0.457,4.238-2.928,4.729C1063.29,479.911,1061.623,479.649,1060.006,479.643z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#6D6753"
              d="M1126.427,590.993c-1.094,0.918-2.189,0.904-3.283,0.006
			c-0.219-1.611-0.299-3.262-0.696-4.832c-0.499-1.973,0.102-2.77,2.102-2.523c1.462,0.18,3.497-0.383,2.421,2.512
			C1126.422,587.631,1126.585,589.372,1126.427,590.993z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#817F7B"
              d="M1093.825,496.651c-0.55,4.01,0.469,8.15-0.995,12.084
			C1089.324,504.518,1089.736,499.522,1093.825,496.651z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5F5C5A"
              d="M1151.666,264.817c0.398-0.397,0.797-0.795,1.195-1.191
			c3.71-0.617,6.849-0.106,8.118,4.104c-1.425,1.461-3.159,2.137-5.195,2.049C1154.411,268.125,1153.038,266.471,1151.666,264.817z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#524E47"
              d="M1104.708,516.047c0.994,0.418,1.989,0.834,3.515,1.475
			c-3.117,0.818-6.356-0.412-8.527,2.074c-2.928,0.459-2.833-2.119-3.268-3.664c-0.501-1.787,1.446-0.994,2.267-1.422
			C1100.698,515.022,1102.703,515.536,1104.708,516.047z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#534D41"
              d="M1081.158,85.515c-6.222,0.708-1.394,6.821-4.89,8.854
			c-0.953-2.939,0.87-6.491-2.888-8.723C1076.065,84.109,1078.418,84.7,1081.158,85.515z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#7F7760"
              d="M1097.731,92.64c0.023,0.392,0.046,0.783,0.069,1.175
			c-1.813,4.198-4.393,2.881-7.053,0.979c0.276-3.01,0.397-6.052,1.889-8.8c0.065,1.311,0.114,2.623,0.2,3.933
			C1093.061,93.356,1094.659,94.332,1097.731,92.64z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#312D2B"
              d="M1086.498,85.956c-0.169,1.622-0.338,3.244-0.507,4.866
			c-0.865,1.279-1.73,2.558-3.438,5.08c0-3.606,0.089-5.813-0.02-8.011C1082.344,84.029,1083.947,84.08,1086.498,85.956z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#53504D"
              d="M1216.95,371.604c-3.029,1.64-4.348,5.54-8.146,6.167
			c-0.148-0.25-0.229-0.52-0.241-0.81c1.029-4.331,5.335-5.403,8.031-8.076C1216.714,369.791,1216.832,370.697,1216.95,371.604z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#908D85"
              d="M1163.07,479.793c-3.455,1.887-6.897,1.268-10.338-0.049
			C1155.997,476.708,1159.708,476.725,1163.07,479.793z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#A19879"
              d="M1121.668,93.526c-0.513,2.386-2.445,2.91-4.479,3.065
			c-1.018,0.078-2.256-0.564-1.744-1.743c0.806-1.855,2.465-3.277,4.366-3.786C1121.228,90.684,1121.475,92.283,1121.668,93.526z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#55524D"
              d="M1206.625,370.902c2.228-2.306,4.598-4.406,7.942-5.206
			c-1.573,3.365-4.229,5.571-7.632,7C1206.832,372.099,1206.729,371.5,1206.625,370.902z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#877F67"
              d="M1085.771,264.752c0.493,0.356,0.948,0.932,1.488,1.028
			c1.739,0.313,2.629,1.202,2.513,3.001c-0.055,0.827-0.11,1.7-1.158,1.866c-1.819,0.289-2.602-1.097-3.423-2.297
			c-0.551-0.805-0.938-1.72-1.399-2.586C1084.452,265.428,1085.111,265.09,1085.771,264.752z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#9E9578"
              d="M1111.918,86.971c-0.413,2.26,0.163,4.816-2.246,7.339
			c-0.688-3.305-1.71-5.816-0.982-8.608C1109.867,85.865,1111.214,85.604,1111.918,86.971z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2F2B29"
              d="M1205.142,444.599c-1.509-0.406-3.018-0.812-4.526-1.217
			c0.012-0.251,0.024-0.503,0.036-0.754c3.016,0,6.031,0,9.046,0c0.977,0.51,2.058,1.03,1.876,2.352
			c-0.142,1.024-1.138,0.806-1.869,0.893C1208.294,445.051,1206.812,444.488,1205.142,444.599z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#928B6F"
              d="M1032.617,532.889c-0.588-0.686-1.177-1.369-1.765-2.053
			c0.294-2.504-3.502-4.664-0.692-7.641C1032.972,526.104,1032.814,529.491,1032.617,532.889z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#36312E"
              d="M901.68,261.875c0.326-1.081,0.651-2.162,0.978-3.243
			c0.369-1.139-0.455-2.692,1.673-3.618c1.261,2.328,1.346,4.765,0.429,7.115C903.685,264.882,902.572,263.688,901.68,261.875z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#938B70"
              d="M1024.878,511.522c-3.9-1.145-5.829-2.895-6.195-6.803
			C1022.148,505.897,1023.556,508.084,1024.878,511.522z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#332F2F"
              d="M1166.718,537.936c0.542,0.967,0.807,1.846-0.779,1.92
			c-1.439,0.068-2.881,0.074-4.321,0.105c-0.952-0.047-1.905-0.096-2.857-0.145c-0.577-0.688-0.555-1.311,0.199-1.854
			c1.963-0.137,3.926-0.271,5.888-0.408C1165.471,537.682,1166.095,537.809,1166.718,537.936z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#756F5B"
              d="M1095.81,470.801c-2.307,0.053-5.078,3.686-6.785-0.88
			C1091.412,469.246,1093.964,467.301,1095.81,470.801z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#706D64"
              d="M1152.825,215.767c2.205,0.932,2.449,3.091,3.12,4.984
			c-0.077,1.288-0.479,2.223-2.048,1.891C1152.892,220.451,1151.724,218.285,1152.825,215.767z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#62605D"
              d="M1096.028,509.625c0.401-2.58-1.525-5.443,1.256-8.158
			c0.475,1.869,0.882,3.471,1.288,5.072C1097.724,507.569,1096.876,508.596,1096.028,509.625z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#79735D"
              d="M1139.754,270.405c-0.908,1.558-2.522,1.991-4.115,2.305
			c-0.979,0.192-1.649-0.725-1.53-1.494c0.354-2.281,2.553-1.557,3.954-2.082C1138.916,268.814,1139.591,269.402,1139.754,270.405z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#928D7B"
              d="M1141.679,227.83c0.541,2.597-1.134,3.942-2.941,5.216
			C1136.884,230.207,1137.172,229.697,1141.679,227.83z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#7EABC5"
              d="M1106.813,593.803c-1.903-3.811-0.715-7.813-0.728-11.734
			C1107.457,585.913,1106.272,589.911,1106.813,593.803z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#35312E"
              d="M1111.918,86.971c-1.152-0.229-2.673,0.476-3.229-1.27
			c1.342-1.156,2.834-1.523,4.419-0.617c0.446,0.255,1.046,1.047,0.931,1.328C1113.655,87.346,1112.641,86.765,1111.918,86.971z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#86847F"
              d="M1221.834,395.767c1.978,1.702,1.983,4.031,1.986,6.342
			c0.009,8.262-0.056,16.524,0.023,24.784c0.021,2.27-0.542,4.222-1.927,5.975c-1.841-0.031-4.087,0.628-4.154-2.437
			c1.982-2.434,2.662-5.241,2.624-8.35c-0.066-5.313-0.07-10.629,0.007-15.943c0.037-2.571-0.483-4.934-1.838-7.119
			C1218.494,396.772,1218.701,394.789,1221.834,395.767z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#302C2A"
              d="M1217.732,430.855c1.395,0.671,2.789,1.341,4.185,2.012
			c-0.054,0.635-0.107,1.27-0.16,1.904c-3.265-0.065-6.163,3.861-9.694,0.89c-0.344-1.517-0.688-3.032-1.067-4.705
			c1.612-0.131,3.177-0.258,4.74-0.385C1216.479,430.121,1217.142,430.236,1217.732,430.855z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#242021"
              d="M1221.834,395.767c-1.999-0.056-2.012,2.007-3.107,2.915
			c-0.9,0.952-1.904,1.026-2.997,0.353c-3.187-0.46-2.599-3.131-3.044-5.196c3.029-1.475,6.097,0.689,9.133-0.097
			C1221.824,394.416,1221.829,395.092,1221.834,395.767z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5E7987"
              d="M1132.96,760.793c-4.29,0.836-7.977,2.752-11.474,5.445
			c-4.841,3.727-7.346,3.135-9.775-1.531c0.084-2.484,2.665-2.088,4.035-3.553c-2.557-0.238-4.988,0.145-7.327-0.391
			c-1.337-0.307-2.759-0.885-2.59-2.518c0.147-1.42,1.654-1.611,2.749-1.59c4.034,0.08,7.376-1.52,10.625-3.717
			c2.209-1.496,2.4-3.414,1.975-5.664c-0.425-2.242-1.325-4.355-3.782-4.762c-4.441-0.734-8.979-1.029-13.347,0.309
			c-3.968,1.217-4.591,3.168-3.371,7.207c1.262,4.176,1.305,8.67,3.198,12.697c-6.023-0.535-11.155,1.568-16.384,4.475
			c-4.324,2.402-9.786,0.223-11.841-3.471c0.814-2.99,3.374-2.027,5.307-2.225c5.209-0.533,9.817-6.447,8.647-11.553
			c-0.849-3.701-0.861-8.053-5.735-9.338c-1.083-1.285-3.141-0.225-4.139-1.717c0.908-3.646,3.751-4.363,6.909-4.455
			c7.658-0.223,15.32-0.238,22.884,1.178c2.025,0.379,3.921,0.328,5.887-0.123c8.111-1.857,16.208-1.416,24.231,0.465
			c2.038,0.477,3.914,0.479,5.87-0.064c7.701-2.141,15.467-2.111,23.216-0.416c3.146,0.688,6.126,0.807,9.328,0.094
			c8.056-1.789,16.188-1.914,24.204,0.328c2.125,0.594,3.851-0.199,5.791-0.592c4.063-0.82,8.43-2.803,11.814,1.635
			c-0.109,3.273-1.585,5.387-4.979,5.873c-4.426-0.662-8.874-1.008-13.344-0.705c-5.841,0.396-7.204,2.572-4.727,7.699
			c1.875,3.877-0.955,5.742-3.101,7.957c-3.285,5.752-8.45,9.053-14.653,10.684c-3.525,0.926-5.277-1-5.238-4.576
			c2.383-2.109,6.041-1.895,8.162-4.516c1.023-1.268,2.986-2.756,1.256-4.145c-2.939-2.355-1.96-5.025-1.461-7.711
			c0.696-3.75-0.973-5.293-4.52-5.379c-1.664-0.039-3.328-0.082-4.989,0.033c-8.919,0.615-10.97,3.529-8.566,12.162
			c0.511,1.834,1.962,3.68-0.126,5.451c-4.471,3.098-9.128,5.881-14.093,8.111c-3.637,1.633-6.399,0.873-7.768-3.172
			c0.184-2.465,2.352-2.486,3.917-2.871c2.844-0.697,4.578-2.307,5.353-5.061c1.258-2.229,2.257-4.584,3.411-6.863
			c1.438-1.15,2.114,1.143,3.844,0.736c-2.083-1.008-2.176-4.027-5.02-3.48c-0.469-0.107-0.865-0.33-1.206-0.664
			c-2.359-4.471-6.473-4.627-10.769-4.459c-2.489,0.096-4.99-0.053-7.425,0.613c-2.625,0.447-4.153,2.127-5.055,4.514
			c-0.864,1.135-2.806,1.117-3.108,2.898c1.232,1.924,3.117-0.223,4.484,0.736c1.346,2.023,2.939,3.902,3.641,6.303
			C1133.96,758.444,1133.988,759.739,1132.96,760.793z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#242022"
              d="M1219.866,736.948c-3.455-1.861-7.309-1.857-10.635-0.572
			c-3.343,1.293-6.105,1.238-9.554,0.496c-8.223-1.771-16.576-2.355-24.572,1.521c-0.565,0.273-1.562-0.271-2.336-0.504
			c-9.622-2.895-19.274-3.213-28.867-0.021c-3.45,1.148-6.147-1.215-9.323-1.51c-5.996-0.557-11.935-1.252-17.766,0.414
			c-4.374,1.252-8.502,0.055-12.682-0.492c-6.48-0.848-12.957-0.201-19.43-0.416c-2.313-0.076-3.272,2.09-4.971,3.035
			c-4.863-1.76-9.621-3.688-15.06-2.191c-2.63,0.723-3.998-1.139-4.002-3.746c-0.025-15.631-0.009-31.264-0.028-46.895
			c-0.002-1.678,0.682-2.861,2.165-3.605c4.161,0.096,8.179-1.133,12.384-1.287c0.144-1.471-2.851-2.193-0.931-3.994
			c2.958-1.207,5.927-1.123,8.901-0.119c0.101,4.365,2.949,4.596,6.353,4.426c3.818-0.189,7.684-0.334,11.475,0.043
			c3.559,0.354,5.18-0.889,5.282-4.369c2.641-1.117,5.285-1.072,7.934-0.076c0.144,3.992,2.49,4.74,6,4.416
			c3.098-0.289,6.288-0.395,9.357,0.016c4.855,0.648,8.705-0.6,11.586-4.623c2.081-0.568,4.141-0.689,6.15,0.258
			c-0.912,4.389,1.668,4.52,4.976,4.363c5.589-0.264,11.202,0.105,16.747-0.195c0.26-1.945-4.064-1.756-1.525-4.295
			c3.843-1.094,7.682-1.068,11.515-0.041c0.512,3.029,2.245,3.723,5.271,3.555c5.59-0.311,11.214-0.191,16.813-0.004
			c-0.173-1.104-2.706-1.752-0.766-3.41c3.238-1.184,6.483-1.102,9.729-0.113c2.511,1.59-1.161,2.594-0.327,4.23
			c4.641,0.406,9.487-0.158,14.083,1.441c1.908,1.059,1.98,2.875,1.972,4.738c-0.066,14.238-0.164,28.477-0.164,42.715
			C1225.622,734.149,1224.546,737.008,1219.866,736.948z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E8E9EA"
              d="M1083.713,677.872c-3.255,0.012-6.51,0.023-9.766,0.035
			c-4.521,0.254-5.974-0.973-5.272-4.914c0.55-3.088,1.013-6.105,0.115-9.18c0.428-1.412,0.846-2.826,1.283-4.236
			c1.175-3.779,3.731-5.848,7.705-5.695c3.412,0.131,6.812,0.604,10.028,1.9c2.152,2.361,3.605,5.152,4.693,8.107
			c0.754,2.047,1.45,4.33-1.532,5.328c-2.456,0.82-0.774,2.453-0.785,3.691C1090.144,677.374,1088.129,678.965,1083.713,677.872z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#EAEBEC"
              d="M1147.745,677.885c-2.325-0.004-4.651-0.008-6.977-0.012
			c-6.232,3.172-8.331-2.377-11.592-5.488c-0.958-2.059-0.746-4.227-0.63-6.391c0.275-1.686,0.683-3.301,2.338-4.221
			c4.336-8.215,12.786-9.371,21.928-3.002c0.104,0.129,0.288,0.248,0.304,0.387c0.191,1.643,2.07,7.748,2.763,8.873
			c0.181,2.057-0.116,3.98-1.537,5.588c-1.257,0.447-2.089,1.174-2.688,2.518C1150.991,677.629,1149.801,679.006,1147.745,677.885z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#EBECEE"
              d="M1163.581,759.776c-0.761-4.455-2.125-8.781-1.815-13.445
			c0.206-3.096,1.21-4.604,4.361-4.727c4.307-0.17,8.604-0.555,12.91-0.766c3.835-0.188,4.986,2.008,4.495,5.422
			c-0.259,1.803-0.662,3.586-1.039,5.369c-0.162,0.773-0.576,1.369,0.601,1.922c2.752,1.291,3.377,3.742,1.172,5.668
			c-2.93,2.559-5.854,5.611-10.44,4.66C1170.477,762.346,1165.514,764.842,1163.581,759.776z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E7E8EA"
              d="M1083.87,740.616c3.804-0.164,5.576,0.346,6.326,5.395
			c0.726,4.881,0.623,8.822-1.585,12.916c-1.31,2.428-3.267,3.934-6.232,4.066c-2.244,0.102-4.629-0.764-6.727,0.738
			c-7.617-0.717-6.982-6.951-8.051-12.045c-0.189-4.051-0.45-8.125,3.565-10.785C1075.401,740.807,1079.636,740.711,1083.87,740.616
			z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E9EAEC"
              d="M1103.876,762.727c-2.008-2.701-6.052-4.658-3.134-9.158
			c0.343-0.529-0.41-1.883-0.825-2.773c-2.502-5.365-0.793-9.1,5.152-9.836c4.247-0.527,8.642-0.314,12.93,0.047
			c4.544,0.385,4.271,4.531,4.801,7.596c0.369,2.133,0.33,3.758-2.696,5.586c-4.386,2.648-8.631,3.693-14.356,3.672
			c4.596,3.752,9.143,0.764,13.312,2.156c-0.937,4.004-5.549,2.248-7.348,4.691C1108.948,764.647,1105.858,765.877,1103.876,762.727
			z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E7E8EA"
              d="M1114.749,677.913c-2.952-0.002-5.903-0.002-8.855-0.004
			c-5.372,0.316-7.196-1.711-6.314-6.996c2.506-4.084,3.153-8.484,2.2-13.154c4.856-4.232,10.335-3.422,15.895-1.959
			c5.724,3.48,8.81,14.041,5.45,19.596C1121.248,678.499,1118.364,679.821,1114.749,677.913z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E7E8E9"
              d="M1179.66,677.924c-4.268,0.01-8.535,0.021-12.803,0.031
			c-5.018-1.008-5.956-2.322-6.406-8.963c-0.021-1.352-0.024-2.705,0.135-4.055c1.669-3.672,1.756-8.602,7.276-9.246
			c3.757,0.035,7.516,0.166,11.269,0.064c1.955-0.055,3.495,0.406,4.61,2.043c1.664,1.127,1.825,2.82,1.064,4.348
			c-1.988,3.994-1.271,7.898-0.049,11.859C1184.179,676.77,1182.744,678.422,1179.66,677.924z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#EDEDEF"
              d="M1193.717,757.772c0.23-1.219-0.364-2.641,1.165-3.51
			c2.41-1.369,1.887-2.828,0.348-4.766c-2.844-3.586-2.179-6.805,2.104-7.85c5.82-1.418,11.92-2.354,17.553,1.172
			c-0.681,2.41-2.323,4.904,0.62,6.994c1.527,3.43-0.477,6.623-0.673,9.938C1206.272,766.543,1199.86,764.75,1193.717,757.772z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E9EAEB"
              d="M1210.724,677.893c-3.611,0.008-7.223,0.016-10.834,0.021
			c-3.926,1.051-5.562-0.955-6.04-4.518c1.288-5.123-0.924-10.67,1.866-15.617c4.655-3.031,9.646-3.543,14.913-1.99
			c2.711,0.695,4.165,2.648,5.114,5.109c-0.795,5.217-2.184,10.359-1.991,15.703
			C1213.226,678.165,1212.266,678.711,1210.724,677.893z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#EAEBED"
              d="M1151.735,756.762c0.201,3.551-1.345,5.58-4.976,6.107
			c-1.762,0.254-3.589,0.607-5.039,1.846c-3.164-0.764-6.082-2.076-8.761-3.922c-0.007-1.316-0.015-2.633-0.022-3.949
			c0.912-2.363-0.489-4.428-0.855-6.625c-0.478-2.867-1.406-5.768,0.745-8.396c4.969-1.922,10.125-1.197,15.152-0.668
			c2.739,0.287,4.578,2.525,4.966,5.516c1.265,1.77,0.453,3.41-0.4,4.979C1151.657,753.282,1151.285,754.944,1151.735,756.762z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5F7F90"
              d="M1100.828,670.768c-3.036,5.732,2.114,5.656,5.065,7.141
			c0.675,1.414,3.401,1.508,2.729,3.637c-0.692,2.189-2.808,1.125-4.266,1.154c-5.924,0.119-11.851,0.035-17.776,0.061
			c-1.821,0.008-3.605-0.014-5.137-1.316c-0.066-1.691,1.983-2.088,2.27-3.572c9.601-0.225,3.335-6.965,4.088-10.529
			c1.738-0.945,2.486,2.385,4.072,0.482c1.118-1.344-0.312-2.43-0.477-3.646c-0.426-3.131-4.082-4.877-3.59-8.396
			c4.075,0.297,7.251,3.066,11.086,4.025C1101.131,663.18,1102.059,666.784,1100.828,670.768z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5E7B8B"
              d="M1114.749,677.913c7.707-1.201,10.699-6.762,7.401-14.109
			c-1.247-2.777-2.97-5.342-4.477-8.004c0.869-1.08,2.402-1.408,2.92-0.385c2.18,4.311,6.404,4.998,10.29,6.359
			c-0.432,1.373-0.864,2.748-1.297,4.121c-4.176,2.518-1.534,4.605,0.426,6.734c0.956-0.424,2.652-1.629,2.69,0.1
			c0.135,6.094,4.567,4.744,8.065,5.145c-0.253,0.209-0.679,0.381-0.732,0.631c-0.923,4.268-0.912,4.27-6.236,4.27
			c-5.615,0-11.231,0.033-16.846-0.023c-1.587-0.016-3.641,0.67-4.55-1.072C1111.288,679.54,1114.376,679.456,1114.749,677.913z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#6D93A9"
              d="M1195.716,657.78c-0.329,5.326,1.08,10.75-0.884,15.986
			c-0.44,0.295-1.085,0.428-1.353,0.082c-3.094-3.992-6.207-2.561-9.35-0.111c-3.145-3.373-2.561-7.148-0.887-10.828
			c0.797-1.754,1.054-3.334,0.499-5.109c1.345-1.627,2.853-0.855,4.44-0.426C1190.644,658.04,1193.144,658.825,1195.716,657.78z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5D7A8B"
              d="M1210.724,677.893c1.009-0.43,2.018-0.859,3.027-1.289
			c3.587-1.244,5.433-3.033,2.67-6.881c-1.142-1.59-1.164-3.756,1.038-4.531c2.332-0.818,3.898,0.811,4.196,2.955
			c0.383,2.746,0.256,5.529-0.553,8.301c-0.77,2.635-0.668,5.238,2.712,6.234c-4.609,0.027-9.221,0.131-13.827,0.037
			c-1.32-0.027-3.435,0.92-3.799-1.014c-0.285-1.518,1.536-2.287,2.938-2.871C1209.69,678.598,1210.192,678.211,1210.724,677.893z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#7A8A94"
              d="M1068.79,663.813c3.006,1.998,2.562,5.029,1.506,7.525
			c-1.756,4.146-0.02,5.746,3.651,6.568c0.601,1.535,3.643,1.449,2.986,3.32c-0.65,1.855-2.988,1.547-4.78,1.502
			c-3.115-0.076-6.231-0.176-9.347-0.268c0.269-0.18,0.521-0.395,0.809-0.535c1.545-0.766,4.162,0.934,4.769-1.309
			c0.54-2-0.711-3.875-2.421-5.477c-2.877-2.695-3.001-5.637,0.273-8.373C1067.223,665.944,1067.946,664.807,1068.79,663.813z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#738A98"
              d="M1167.862,655.692c-3.757,1.908-4.545,5.836-6.228,9.156
			c-1.876,1.895-3.259,4.934-6.812,2.932c-3.313-2.309-3.313-2.309-2.012-9.008c4.1,1.137,8.079,2.451,10.557-2.969
			C1164.045,654.323,1166.279,651.928,1167.862,655.692z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#555E64"
              d="M1214.834,759.75c-2.534-3.5,0.46-6.623,0.674-9.938
			c1.109-1.785,2.22-3.57,3.329-5.357C1221.075,749.375,1218.932,757.565,1214.834,759.75z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5E7987"
              d="M1071.167,740.901c-2.562,3.141-1.465,7.49-3.565,10.785
			c-4.267-3.877-4.392-6.871-0.922-11.084C1068.579,738.295,1069.809,738.737,1071.167,740.901z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#BDBFC2"
              d="M1100.828,670.768c0.26-3.813-2.626-7.07-1.937-10.961
			c0.963-0.684,1.925-1.365,2.888-2.049C1105.947,662.422,1102.686,666.545,1100.828,670.768z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#686767"
              d="M1031.766,596.741c-0.606,1.465-2.224,2.396-2.203,4.189
			c-0.263-0.008-0.525-0.021-0.788-0.037c-1.459-2.158-4.972-2.672-4.871-6.082C1026.852,594.122,1029.396,595.075,1031.766,596.741
			z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#636C72"
              d="M1215.743,660.899c-1.705-1.703-3.409-3.406-5.113-5.109
			c1.407-1.814,2.681-1.031,4.02,0.143C1216.262,657.344,1217.104,658.862,1215.743,660.899z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#45555F"
              d="M1252.045,694.374c2.159-1.268,3.934-2.496,6.763-1.002
			C1256.556,695.075,1254.844,696.649,1252.045,694.374z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#ABBBD2"
              d="M801.833,566.799c4.521-3.568,9.764-1.463,14.687-1.863
			c6.415,1.607,12.588,4.029,19.025,5.58c10.613,2.555,21.401,1.578,32.135,1.807c2.426,0.053,5.031-0.484,7.03,1.57
			c0.017,4.613-0.242,9.246,0.127,13.832c0.356,4.422-1.101,6.777-5.667,6.977c-1.196,0.053-2.381,0.402-4.03,0.697
			c2.268,1.877,4.003,0.941,5.771,0.162c1.637-0.723,3.316-1.727,4.912,0.107c-0.009,7.619,0.003,15.238-0.043,22.857
			c-0.011,1.846,0.076,3.637,0.983,5.295c-8.163,0.035-16.326,0.078-24.49,0.098c-6.739,0.018-7.752-1.432-5.299-7.648
			c0.495-1.254,1.043-2.445,0.555-3.832c-2.209,2.033-3.984,8.549-2.548,10.934c1.939,3.221,5.016,1.836,7.996,1.668
			c6.254-0.352,12.547-0.08,18.821,0.045c1.683,0.031,2.854,1.074,2.86,2.846c0.006,1.672-0.74,2.871-2.692,2.832
			c-1.499-0.031-3-0.006-4.499-0.006c-39.994,0.004-79.988,0.014-119.982,0.006c-6.652,0-7.824-1.162-7.49-7.912
			c0.188-3.824-0.663-6.252-5.179-5.666c-2.462,0.32-3.244-1.078-2.926-3.326c2.513-5.096,7.109-8.289,11.162-11.914
			c3.235-2.893,6.243-5.912,8.668-9.518c-4.03,5.814-9.753,9.949-14.729,14.818c-1.293,1.264-2.737,3.061-5.112,2.418
			c-0.084-5.645-0.298-11.291-0.201-16.934c0.055-3.213-0.217-6.66,2.518-9.184c0.59-0.545,0.819-1.115,0.119-1.578
			c-4.625-3.061-1.961-3.279,1.273-3.367c5.616-0.152,4.475-4.361,4.296-7.641c-0.2-3.695,0.379-6.289,4.855-6.209
			c2.601,0.047,4.078,0.996,4.063,3.795c-0.017,2.988,0.086,5.979,0.138,8.967c1.517,1.609,3.132,3.068,5.403,3.52
			c4.509,0.945,8.958,3.063,14.538,0.605c-5.27-1.346-9.948,0.396-14.253-1.424c-0.813-0.709-1.461-1.477-0.98-2.658
			c0.172-8.646,0.373-8.803,8.774-7.041c0.806,0.17,1.661,0.346,2.463,0.252c5.639-0.652,8.016,1.992,7.731,7.457
			c-0.015,0.281,0.19,0.592,0.347,0.855c0.074,0.125,0.276,0.174,0.421,0.256c1.432-10.234,0.797-11.721-5.537-12.971
			c5.318-3.078,7.831-1.686,7.91,4.398c0.028,2.131,0.005,4.264,0.342,6.398c1.426-2.568,0.463-5.305,0.724-7.943
			c0.291-2.951,1.601-4.543,4.771-4.471c3.211,0.072,4.369,1.584,4.251,4.713c-0.139,3.645,0.063,7.303,0.13,10.955
			c0.002,0.098,0.151,0.191,0.232,0.287c3.636-14.365,3.636-14.365,14.398-13.752c2.443,5.018,0.487,10.402,1.139,14.559
			C801.958,577.27,801.621,572.036,801.833,566.799z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FDFDFD"
              d="M897.805,378.607c3.172,1.388,6.468,2.25,9.947,2.323
			c4.438,6.513,9.378,12.601,15.222,17.917c1.167,1.062,1.309,2.57,1.874,3.897c0.987,0.995,1.974,1.99,2.961,2.986
			c0.279,2.616-1.352,4.538-2.857,6.157c-5.853,6.292-12.683,11.517-19.302,16.963c-2.895-2.906-4.747-2.492-7.36,0.977
			c-5.513,7.321-13.224,8.635-18.693,3.992c-1.332-1.708-1.207-3.735-1.198-5.715c0.068-14.906-0.201-29.812,0-44.717
			c0.091-6.656,0.802-7.112,7.676-6.495C890.027,377.248,893.955,377.613,897.805,378.607z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#BEBEBD"
              d="M1031.766,596.741c-2.81,0.123-5.311-1.006-7.862-1.93
			c-3.148,0.014-6.341,0.42-8.338-3.053c-0.901-1.564-2.414,0.025-3.652,0.023c-2.171-0.004-4.342,0.16-6.282-1.096
			c-0.701-2.049-2.315-2.637-4.275-2.701c-3.92-0.127-5.675-2.193-5.422-6.064c0.061-0.918,0.57-2.176-0.854-2.385
			c-1.191-0.176-1.544,1.012-1.971,1.895c-1.304,2.699-0.57,5.961-2.353,8.529c-1.934,2.783-3.587,2.15-5.067-0.424
			c-1.293-2.25-0.904-4.676-0.717-7.078c4.529-2.088,1.575-3.92-0.199-5.791c3.762-0.813,6.937-2.508,9.277-5.703
			c1.659-2.264,2.248-4.322-0.203-6.375c2.979-2.141,3.41-5.742,4.989-8.688c1.43,3.143,3.092,6.137,5.481,8.672
			c1.115,3.332,0.467,8.15,6.325,7.469c1.828,0.076,3.397,1.279,5.265,1.189c0.827-0.109,2.233-0.309,2.342,0.215
			c0.962,4.658,5.61,7.531,5.944,12.447c-0.454,0.949-1.46,1.27-2.143,1.854c2.574,0.068,5.095,0.623,7.572,1.438
			c1.52,1.221,2.75,2.641,3.197,4.605C1032.47,594.774,1032.117,595.756,1031.766,596.741z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#373435"
              d="M899.544,527.598c-2.721-3.738-7.063-2.758-9.542-5.268
			c-2.499-2.527-3.998-5.564-4.947-9.164c2.369-0.51,4.637-0.32,5.979-2.924c1.168-2.264,3.869-2.242,5.637-0.684
			c2.829,2.496,5.715,3.754,9.66,1.732c-0.732,4.033-3.787,4.531-6.031,5.99c1.424,0.98,2.771,1.91,4.619,3.182
			C901.659,521.786,898.067,522.631,899.544,527.598z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5C5A5A"
              d="M928.822,487.622c-2.568-1.43-2.257-3.203-2.396-4.814
			c-0.095-1.098,0.256-2.631-1.339-2.754c-1.301-0.1-1.854,1.035-2.174,2.287c-1.326,5.201-1.358,5.193-5.104,1.605
			c-1.789,0.484-0.244,2.955-1.988,3.443c-0.692,0.244-0.824-0.305-1.025-0.736c-0.443-0.949,0.1-2.619-1.655-2.611
			c-1.512,0.008-1.977,0.883-1.509,2.354c0.104,0.322-0.224,1.072-0.517,1.182c-1.633,0.605-3.53,0.932-4.293-1.035
			c-1.106-2.852-1.017-6.01,0.972-8.342c0.816-0.957,3.558-1.648,4.014,1.373c0.132,0.879,0.8,2.078,1.32,1.18
			c1.958-3.383,5.731-1.477,8.286-3.303c3.27-2.336,6.444-0.662,7.312,3.348c0.104,0.482,0.09,0.992,0.094,1.49
			C928.829,483.932,928.822,485.577,928.822,487.622z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#787777"
              d="M993.58,552.641c0.011,3.412,0.021,6.826,0.032,10.238
			c-2.562-2.535-5.794-3.057-9.196-3.059c-6.569-0.002-13.139,0-20.926,0c6.361-4.854,12.335-5.672,18.775-4.727
			C986.314,555.69,990.162,555.088,993.58,552.641z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2D2A2B"
              d="M897.77,559.719c-0.785-0.139-1.659-0.104-2.338-0.451
			c-1.771-0.904-4.481,1.867-4.861,0.115c-0.763-3.512-5.453-5.26-3.963-9.512c-0.32-2.021,1.304-1.861,2.445-2.064
			c1.891-0.332,2.312-1.414,1.858-3.088c0.03-0.793-0.151-2.189,0.125-2.285c2.016-0.713,2.21,1.912,3.744,2.254
			c2.078,1.641,3.024,4.26,5.136,5.891c0.791,0.611,1.523,1.768,0.532,2.49C898.091,554.79,899.431,557.833,897.77,559.719z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#787777"
              d="M921.067,528.397c2.879-3.371,5.417-4.932,9.723-3.6
			c3.459,1.068,7.359,0.719,11.065,0.971c1.338,0.092,2.772-0.008,2.868,1.9c0.096,1.875-1.201,2.242-2.619,2.115
			C935.322,529.178,928.41,530.663,921.067,528.397z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#343232"
              d="M990.757,589.959c-0.469-3.553,0.244-6.934,1.536-10.215
			c0.609-1.549,1.09-3.139,3.506-2.145c2.096,0.861,2.492,1.514,1.59,3.891c-1.409,3.713,0.366,5.375,4.942,5.402
			c2.579,0.018,5.693-0.516,3.3,3.793C1000.657,590.756,995.831,587.823,990.757,589.959z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#535151"
              d="M897.77,559.719c0.602-2.557-2.878-6.258,2.456-7.42
			c-2.859-2.082-4.961-4.291-5.445-7.611c1.378-1.588,2.558-3.387,4.976-4.65c-0.141,3.539,3.371,7.373,3.058,8.258
			C901.453,552.139,903.902,558.065,897.77,559.719z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5C5A5B"
              d="M935.659,477.627c2.505,0.104,4.443,0.609,4.092,2.994
			c-0.273,1.855-0.066,4.674-3.201,4.547c-2.187-0.088-3.736-1.285-3.691-3.725C932.898,479.295,933.463,477.483,935.659,477.627z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#626060"
              d="M945.438,475.038c2.357,4.311-4.068,6.893-1.671,10.938
			C939.715,481.829,939.955,480.383,945.438,475.038z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#3C393A"
              d="M984.773,576.667c1.363,0.182,3.239-0.895,3.925,0.955
			c0.55,1.48,0.704,3.268-0.337,4.752c-1.042,1.482-2.235,0.939-3.39,0.084C984.982,580.524,983.004,578.659,984.773,576.667z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#747373"
              d="M890.911,544.719c1.096,1.063,2.202,2.361,1.583,3.857
			c-0.933,2.252-2.495-0.289-3.825,0.162c-0.794,0.268-1.569,0.385-2.062,1.133C885.266,545.834,889.497,546.456,890.911,544.719z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#737272"
              d="M915.667,527.655c0.162,1.273-0.09,1.934-1.14,2.033
			c-0.932,0.09-1.641-0.246-1.696-1.234c-0.06-1.061,0.5-2.051,1.484-2.217C914.726,526.168,915.337,527.288,915.667,527.655z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#242122"
              d="M894.574,741.7c-42.894-0.012-85.792,0.572-128.675-0.93
			c-1.024-1.248-1.091-2.744-1.093-4.254c-0.023-13.141-0.018-26.281-0.005-39.424c0.001-1.514,0.106-2.996,0.975-4.311l0.175,0.146
			c4.846-1.818,10.56-0.518,14.915-4.162c0.559-0.467,1.284-0.02,1.854,0.299c8.109,4.533,16.855,1.467,25.29,2.088
			c1.966,0.145,3.145-1.678,4.689-2.59c8.793,5.018,18.331,2.135,27.554,2.549c2.114,0.096,3.238-2.229,5.235-2.615
			c3.112,2.605,6.76,3.104,10.677,2.918c3.467-0.164,6.958-0.209,10.419,0.012c3.803,0.244,7.189-0.566,10.214-2.879
			c0.561-0.127,1.064-0.008,1.514,0.346c1.837,2.232,4.322,2.523,7.012,2.531c2.865,0.008,5.877-0.578,8.436,1.393
			c0.382,0.643,0.763,1.285,1.145,1.928c1.293,2.072,1.289,4.396,1.292,6.713c0.013,11.234,0.018,22.467-0.004,33.701
			C896.188,737.461,896.374,739.833,894.574,741.7z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#ECECEE"
              d="M834.765,670.758c4.685-6.27,9.924-7.316,19.963-3.988
			c4.088,5.27,2.561,11.439,1.834,17.021c-0.602,4.623-4.995,5.885-10.789,4.59c-4.383,0.73-9.083,2.336-12.205-2.432
			C830.134,680.708,830.969,675.557,834.765,670.758z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E6E7E9"
              d="M868.831,666.784c1.929-2.854,4.271-3.939,7.398-1.75
			c0.266,0.186,0.598,0.422,0.888,0.408c8.197-0.395,9.675,5.426,10.825,11.605c0.114,0.611,0.611,1.15,0.931,1.723
			c3.11,2.877,0.367,3.869-1.154,6.273c-2.722,4.301-5.761,4.564-9.822,3.598l-0.007-0.049c-0.326-0.051-0.659-0.121-0.974-0.066
			c-10.128,1.756-13.195-0.77-13.452-11.064c0.001-0.797,0.13-1.576,0.387-2.328c4.743-2.707,9.236-6.271,15.094-4.836
			c-2.834-1.283-5.692,0.217-8.61,0.738c-1.456,0.26-3.175,0.957-3.832-0.984C865.881,668.219,867.45,667.454,868.831,666.784z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#EAEBED"
              d="M823.81,761.754c-0.953,3.955-7.641,9.813-11.176,9.789
			c-4.527-0.029-9.016-4.514-9.785-9.775c5.601-0.902,2.049-3.826,0.849-5.4c-1.348-1.768-4.046-2.809-2.624-5.602
			c1.296-2.543,3.856-3.65,6.568-3.934c2.955-0.307,5.914-0.525,8.872-0.869c4.866-0.566,8.964,6.961,6.613,11.814
			C822.339,759.405,820.485,760.942,823.81,761.754z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E8E8EA"
              d="M793.683,761.653c-1.19-0.324-2.528-1.52-3.52,0.074
			c-1.229,1.975,0.467,3.693,0.947,5.598c-4.392,1.053-8.495,2.939-13.098,2.424c-6.229-0.699-7.488-2.803-5.159-8.852
			c-1.526-4.256-2.705-8.535-0.867-13.016c6.995-1.707,13.952-2.457,20.756,0.801C795.936,752.163,796.264,756.69,793.683,761.653z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E8E9EA"
              d="M856.549,753.989c-0.722,1.295-1.476,2.572-2.152,3.891
			c-0.419,0.814-1.522,1.873-0.507,2.592c4.458,3.152,0.811,5.145-1.174,6.752c-2.919,2.363-6.737,4.822-10.286,3.402
			c-5.319-2.129-9.322-6.211-9.582-12.643c-0.041-0.771,0.262-1.428,0.681-2.045c1.053-1.361,1.037-3.02,1.326-4.607
			c0.313-1.721,0.471-3.67,2.939-3.742c5.293-1.395,10.57-1.596,15.821,0.203C855.273,749.536,856.131,751.659,856.549,753.989z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#ECECEE"
              d="M769.827,684.678c-1.126-1.816,0.457-2.615,1.345-3.729
			c1.995-2.502,3.989-4.789,0.907-8.184c-1.783-1.963,2.367-6.334,6.231-7c6.716-1.156,11.973,0.732,15.411,6.842
			c1.415,2.123,1.727,4.293,0.012,6.375c-1.037,1.26-1.061,2.668-1.13,4.168c-0.212,4.59-1.406,5.875-5.918,5.943
			c-3.249,0.049-6.497-0.234-9.761-0.229C774.04,688.872,770.913,688.223,769.827,684.678z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#657E8E"
              d="M864.657,676.782c0.056,0.973,0.103,1.947,0.168,2.922
			c0.419,6.238,1.881,7.594,7.987,7.99c1.684,0.109,3.628-0.707,5.077,0.898c-2.506,3.934-6.221,4.463-10.535,4.221
			c-5.452-0.305-10.936-0.129-16.403-0.051c-2.871,0.041-4.86-0.826-5.166-3.992c-0.343-0.195-0.456-0.389-0.343-0.584
			s0.227-0.293,0.342-0.293c4.131,0.748,7.529-0.193,9.685-4.121c0.669-1.219,2.212-2.305,0.11-3.639
			c-0.742-0.473-1.035-1.438-0.668-2.213c1.786-3.77,0.185-7.441-0.184-11.15c4.699,1.996,9.401,0.664,14.104,0.014
			c-0.491,1.156-1.876,1.916-1.669,3.291c5.124,0.842,10.603-4.045,16.131,1.998c-6.097-0.939-11.455-2.559-15.245,2.867
			c-0.957,1.369-2.08-2-3.312-0.182c-0.76,0.816-1.554,1.584-2.707,1.881C862.883,677.417,863.822,676.163,864.657,676.782z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E9EAEB"
              d="M864.897,750.665c3.461-5.615,15.233-6.129,20.67-2.871
			c2.112,1.266-0.185,2.701,0.546,3.908c2.065,3.248-0.299,6.783,0.66,10.104c0.125,5.891-4.343,6.947-8.626,8.389
			c-5.142,1.73-8.451-0.775-11.382-4.461c0.27-3.611,2.503-2.643,4.605-1.973c1.945,0.619,3.732,2.299,6.093,0.9
			c-1.19-1.1-2.568-0.502-3.803-0.645c-5.86-0.674-7.715-2.756-7.43-8.742C866.315,753.522,866.138,751.973,864.897,750.665z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5C7989"
              d="M769.827,684.678c5.955,4.934,13.079,2.479,19.714,3.072
			c1.037,0.092,2.396-1.166,2.089-1.723c-2.8-5.09,3.002-8.73,2.092-13.42c3.316,2.479,6.075,5.441,7.952,9.17
			c3.714,1.916,6.71,5.227,11.151,5.732c0.285,0.443,0.295,0.887-0.01,1.326c-0.2,3.217-2.098,4.031-5.052,3.963
			c-6.296-0.143-12.6-0.131-18.897-0.006c-3.125,0.063-6.009,0.014-7.123-3.912c-4.163,7.133-10.699,2.48-15.792,4.047
			c3.525-1.072,5.003-3.029,3.021-6.656C768.813,685.983,769.521,685.221,769.827,684.678z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#ECEEEF"
              d="M812.787,687.942c-5.263,0.754-8.521-2.104-11.113-6.164
			c0.718-0.67,1.437-1.338,2.154-2.006c1.693-3.143-0.325-7.908,4.271-9.791c2.438-0.787,4.903-0.609,7.375-0.215
			c2.616,1.086,6.087,0.068,7.9,3.164c0.666,1.576,0.852,3.418,2.456,4.496c0.923,1.117,1.194,2.432,1.187,3.836
			C825.364,688.395,820.478,690.684,812.787,687.942z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5D7786"
              d="M812.787,687.942c1.79-0.129,3.581-0.371,5.371-0.363
			c4.664,0.021,7.391-2.057,7.78-6.846c0.897-1.176,2.172-1.771,3.613-2.225c-1.659-0.49-3.074-1.889-4.848-0.715
			c-2.842-0.256-2.291-2.068-1.776-3.867c0.542-0.967-0.341-2.391,0.997-3.104c3.613-0.021,7.227-0.043,10.84-0.064
			c0.992,1.045,0.815,2.137-0.019,3.117c-3.034,3.566-1.967,7.143-0.457,10.982c1.017,2.586,2.692,3.008,5.028,2.939
			c2.152-0.061,4.311,0.057,6.467,0.096c0.011,0.293,0.011,0.584,0.001,0.877c-1.722,3.205-4.224,4.361-7.985,4.1
			c-5.632-0.389-11.329-0.373-16.964-0.004c-3.719,0.244-6.284-0.814-8.021-4.029C812.8,688.538,812.79,688.241,812.787,687.942z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5C7685"
              d="M877.896,688.641c1.289-0.238,2.585-0.711,3.864-0.668
			c2.755,0.092,4.318-0.49,4.093-3.82c-0.233-3.449,1.779-2.047,3.352-1.051c1.013-1.584-0.772-2.871-0.332-4.332
			c2.699-2.256,4.027-2.209,4.035,1.893c0.008,4.049-0.915,8.215,0.852,12.154c-3.949-0.016-7.899-0.078-11.848-0.035
			C879.048,692.813,877.52,691.686,877.896,688.641z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5E8397"
              d="M896.94,696.895c2.896,0.559,2.814,2.893,2.824,5.002
			c0.052,10.57,0.02,21.141,0.027,31.709c0.002,2.785-1.101,5.1-2.927,7.125c-1.189-1.199-1.055-2.742-1.058-4.229
			c-0.024-11.814-0.03-23.629,0.007-35.443C895.819,699.592,895.579,698.014,896.94,696.895z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#CED0D2"
              d="M823.925,670.823c-0.429,1.004,0.251,2.363-0.997,3.104
			c-1.314-3.615-5.166-1.027-7.18-2.936c0.526-0.91,1.309-0.807,2.127-0.605c-3.365-1.137-6.704-0.59-10.038,0.248
			c-3.269,2.199-0.464,7.063-4.01,9.139c-0.023-0.496,0.068-1.031-0.09-1.48c-1.438-4.088-3.217-7.953,2.395-10.779
			c6.119-3.082,11.468-3.061,15.914,1.029C822.765,669.202,823.303,670.057,823.925,670.823z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#BCCCD7"
              d="M765.776,692.782c0.041,15.996,0.082,31.992,0.123,47.988
			c-2.265-0.779-2.116-2.682-2.115-4.525c0.003-12.939,0.006-25.879-0.003-38.82C763.78,695.579,763.64,693.698,765.776,692.782z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#A6B6C0"
              d="M896.94,696.895c-0.025,14.611-0.051,29.225-0.076,43.836
			c-0.763,0.322-1.526,0.646-2.29,0.969c0.11-15.65,0.22-31.303,0.33-46.955C896.281,694.801,896.78,695.686,896.94,696.895z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5D676E"
              d="M793.683,761.653c-0.505-4.309,1.565-8.805-0.939-12.971
			c3.274-2.051,3.979,0.727,4.714,2.738c1.288,3.525,1.96,7.293,4.401,10.324C799.112,763.649,796.362,765.706,793.683,761.653z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#A9AAAC"
              d="M864.897,750.665c2.755,0.615,3.178,2.488,2.772,4.93
			c-0.886,5.324,0.673,7.133,6.119,7.227c0.83,0.016,1.696-0.148,2.483,0.037c1.412,0.33,4.036-1.125,3.98,1.307
			c-0.05,2.186-2.491,2.723-4.66,2.695c-1.609-0.02-2.908-0.613-4-1.713c-1.882-1.898-3.459-1.594-4.827,0.586
			c-0.323-0.303-0.646-0.607-0.969-0.91c-0.822-2.32-2.04-4.529-2.038-7.088C864.138,755.377,864.518,753.02,864.897,750.665z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#65696D"
              d="M771.986,747.881c0.509,4.324,1.382,8.623,0.868,13.014
			c-2.632,0.219-4.549-1.842-7.001-2.193c0.333-2.781,0.282-5.684,1.122-8.303C767.604,748.442,768.095,744.188,771.986,747.881z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#757576"
              d="M886.773,761.805c-2.259-0.154-2.054-1.695-1.805-3.238
			c0.369-2.289,0.761-4.574,1.144-6.863c4.068-0.943,4.469,2.908,5.18,4.879C892.479,759.874,888.873,760.508,886.773,761.805z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#77797A"
              d="M837.794,747.588c-1.896,1.469-1.877,3.555-1.887,5.668
			c-0.008,1.541,0.376,3.383-2.029,3.611c-1.26-0.807-1.127-2.098-1.155-3.332c1.295-1.244,0.026-2.834,0.461-4.018
			C833.915,747.524,835.181,745.627,837.794,747.588z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#6B90A4"
              d="M856.549,753.989c-2.388-1.398-2.704-3.775-2.933-6.195
			c3.133-2.309,4.742-0.533,5.982,2.232c-1.186,1.002-1.432,2.695-2.685,3.648L856.549,753.989z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#312E2F"
              d="M832.723,753.536c1.391,0.762,0.992,2.145,1.155,3.332
			c-0.343,0.371-0.686,0.742-1.029,1.115c-2.283-0.033-4.594,0.379-6.827-0.426c0.287-0.727-0.593-2.432,1.003-2.016
			C829.545,756.202,830.862,754.27,832.723,753.536z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#343334"
              d="M856.914,753.674c-0.482-2.23-0.312-3.98,2.685-3.65
			c0.119,2.252,0.239,4.504,0.358,6.754C856.016,758.614,858.941,753.715,856.914,753.674z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#6F7683"
              d="M758.254,58.333c-4.316,2.222-7.463,3.937-9.207,7.532
			c-0.588,1.213-2.477,4.25-4.807,0.939c-0.555-0.788-1.245-0.571-2.057,0.083c-2.609,2.101-3.358,0.438-3.77-1.975
			c-0.489-2.872-1.72-5.703,3.586-4.25c2.764,0.757,6.196,0.79,8.85-1.81C752.5,57.235,754.814,57.484,758.254,58.333z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#707885"
              d="M692.887,75.198c-0.129-1.851-0.006-3.819,2.123-4.961
			c0.973-0.521,1.908-0.53,2.735,0.279c0.94,0.921,0.619,1.591-0.274,2.44c-2.451,2.33-3.081,4.461,1.147,5.382
			c0.791,0.173,1.346,0.476,1.081,1.169c-0.27,0.706-0.838,1.492-1.489,1.825C695.144,82.902,692.828,80.3,692.887,75.198z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5B5F69"
              d="M675.078,77.587c-1.796,0.337-0.431,5.239-3.674,1.591
			c-0.973-1.094-1.628-0.638-2.431,0.127c-0.688,0.655-1.418,1.658-2.48,0.675c-0.956-0.886-1.207-2.331-0.46-3.189
			c1.403-1.612,2.063-4.937,4.482-4.343C672.231,72.868,673.389,75.565,675.078,77.587z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#747D8B"
              d="M793.429,67.279c-2.874-2.407-0.448-6.505-3.366-8.728
			c-0.621-0.474,0.306-1.468,1.062-1.902c1.415-0.815,2.82-0.997,4.164,0.15c0.634,0.541,1.471,1.238,0.749,2.039
			C793.903,61.208,794.436,64.41,793.429,67.279z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#38373B"
              d="M759.575,136.59c5.93-7.209,1.396-15.958,4.007-23.722
			c0,7.244,0.156,14.493-0.091,21.729C763.412,136.906,761.421,137.305,759.575,136.59z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#4D4E54"
              d="M667.354,53.736c1.52,7.983-0.419,9.395-9.205,6.465
			C662.814,59.737,666.75,58.966,667.354,53.736z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#72757E"
              d="M728.316,149.66c-4.315,1.573-8.672,1.073-13.036,0.241
			C719.598,148.409,723.949,148.651,728.316,149.66z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#636974"
              d="M789.12,131.79c-3.895-2.273-7.261-3.719-7.031-8.656
			C785.135,125.282,787.894,127.12,789.12,131.79z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5C5F66"
              d="M691.577,148.884c4.487,0,7.896,0,12.46,0
			C699.465,151.425,696.057,151.086,691.577,148.884z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#656B77"
              d="M696.385,57.669c-2.21-0.752-3.832-1.521-3.469-3.873
			c0.045-0.291,0.806-0.781,1.005-0.688C695.629,53.908,696.982,55,696.385,57.669z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#515155"
              d="M740.462,149.346c4.079-0.476,7.961-0.964,12.949,0.016
			C748.256,150.366,744.253,149.872,740.462,149.346z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#595D65"
              d="M682.368,80.187c-0.046,1.064-0.604,1.339-1.269,1.45
			c-1.259,0.211-2.322-0.196-2.778-1.398c-0.363-0.957,0.307-1.681,1.224-1.451C680.576,79.046,681.499,79.736,682.368,80.187z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2A2729"
              d="M667.418,34.346c1.008,3.91,1.007,6.643-0.054,9.963
			C666.275,40.85,666.147,37.985,667.418,34.346z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E8EAEC"
              d="M175.865,749.782c-3.408,2.561-7.767,3.936-9.866,8.148
			c-0.452,0.908-1.449-0.191-2.209-0.096c-8.078,1.393-14.939-1.094-20.741-6.676c-1.753-1.688-3.662-3.502-2.434-6.408
			c-0.38-0.707-0.761-1.412-1.142-2.119c-4.216-5.115-1.827-10.115,0.252-15.129c2.807-5.877,7.139-9.979,13.581-11.613
			c9.64-3.195,18.639,2.408,24.445,10.914C181.775,732.696,180.747,743.346,175.865,749.782z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#EDEEEF"
              d="M125.793,722.709c-1.239-3.152-4.637-3.941-6.757-6.164
			c-3.97-4.16-3.985-4.994,0.697-8.646c0.985-2.666,2.52-4.652,5.734-4.074c1.886,0.34,2.994-1.109,4.504-1.621
			c2.413-0.344,4.17,0.822,5.715,2.479c1.423,3.209,6.161,4.502,5.239,9.066c-1.113,4.051-3.814,7.207-6.644,9.967
			C131.576,726.35,128.475,724.489,125.793,722.709z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F1F1F2"
              d="M182.688,696.981c5.378,1.766,10.02,4.732,14.325,8.385
			c1.87,1.586,2.616,1.846-0.209,3.559c-3.895,2.361-7.42,5.59-9.854,9.623c-1.094,1.814-2.511,3.322-4.285,1.84
			c-4.635-3.871-9.788-7.574-9.794-14.615C177.973,704.883,179.321,699.805,182.688,696.981z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#EFEFF0"
              d="M171.654,764.737c2.951-4.881,8.831-6.414,12.292-10.742
			c0.762-0.953,1.856-0.729,2.339,0.484c0.976,2.451,3.235,3.914,4.358,6.391c3.494,7.703,3.677,7.621-2.113,13.957
			c-3.291,3.6-8.617,2.709-10.81-1.883c-1.258-2.635-2.613-5.043-5.95-5.287C171.046,766.711,171.377,765.723,171.654,764.737z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F1F1F2"
              d="M137.719,754.772c0.708,2.137,2.36,3.523,3.888,5.045
			c3.623,3.607,3.644,6.084,0.116,9.748c-1.26,1.309-2.781,2.369-4.007,3.707c-1.307,1.426-2.362,1.453-3.686,0.051
			c-1.209-1.281-2.658-3.389-4.467-1.959c-1.561,1.234,1.682,2.559,1.022,4.295c-1.219-0.301-2.438-0.6-3.656-0.9
			c-1.387-2.621-5.738-3.104-5.158-7.146c1.422-5.1,6.309-7.764,8.619-12.367C131.912,752.209,135.034,751.305,137.719,754.772z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#434549"
              d="M153.006,717.09c-0.78-0.258-1.715-0.252-2.085-1.215
			c6.253-3.783,12.501-1.057,18.75,0.016c3.594,4.127,8.829,6.686,10.619,12.576c1.788,5.881,2.49,11.547,0.054,17.291
			c-0.826,1.949-1.838,3.967-4.479,4.023c0.436-4.896,4.011-8.926,3.208-14.314C177.109,722.286,166.395,714.52,153.006,717.09z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#4B4E52"
              d="M140.616,744.75c2.736,6.791,9.938,11.824,16.988,11.918
			c2.12,0.027,4.338-0.357,6.186,1.166c-1.927,3.139-5.311,2.463-7.527,1.627c-4.131-1.561-9.146-1.492-12.141-5.713
			c-0.677-0.953-2.236-1.281-3.389-1.896C140.292,749.491,136.328,747.188,140.616,744.75z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#4D616E"
              d="M224.401,720.5c-0.943,5.119-2.253,6.504-8.422,8.906
			C217.421,725.106,218.588,723.872,224.401,720.5z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#3A4044"
              d="M136.959,730.868c0.558-1.445,1.027-2.934,1.713-4.316
			c0.294-0.592,0.949-1.59,1.747-0.941c0.454,0.367,0.328,1.453,0.463,2.213c-2.224,4.77-4.415,9.541-1.408,14.809
			c-2.402,0.018-3.029-1.518-2.96-3.521C136.61,736.36,136.806,733.616,136.959,730.868z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#506572"
              d="M97.861,762.141c3.852,1.377,5.959,3.84,7.985,7.299
			C100.879,769.17,98.556,766.94,97.861,762.141z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#636A70"
              d="M121.771,767.612c1.514,2.531,4.892,3.717,5.158,7.146
			c-2.589-1.129-5.21-2.219-7.149-4.387c-0.735-0.822-2.243-1.596-1.525-2.818C119.19,765.958,120.585,767.178,121.771,767.612z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#EDEEEF"
              d="M132.791,95.668c-0.23,0.24-0.46,0.48-0.7,0.71
			c-1.93,1.89-4.14,3.37-6.76,4.17c-0.22,0.07-0.44,0.13-0.67,0.19c-2.19,1.92-3.96,4.41-7.53,4.01c-2.47-0.28-2.16-1.41-1.47-2.98
			c0-0.01,0-0.01,0-0.02c-0.46-2.7-0.44-5.42-0.37-8.21c0.06-2.14,0.16-4.33,0.09-6.59c-0.58,2.1,0.07,4.1-1.18,5.63
			c-0.07,0.09-0.15,0.17-0.23,0.25v9.42c-4.13-3.75-4.06-8.98-7.82-11.55c-0.18-0.09-0.33-0.19-0.47-0.3c-1.31-1-1.21-2.78-1.76-4.2
			c0-0.04-0.02-0.07-0.03-0.1c-0.1-0.23-0.2-0.44-0.35-0.64c-0.17-0.28-0.41-0.52-0.71-0.73l0.2-0.24
			c6.28-4.61,12.81-8.67,20.74-9.71c0.26,0.01,0.51,0.04,0.73,0.09c0.92,0.17,1.54,0.63,1.89,1.35c0.25,0.47,0.37,1.05,0.38,1.73
			c1,0.95,1.99,1.9,2.99,2.85c0.07,0.42,0.23,0.81,0.44,1.17c1.03,1.79,3.27,3.03,2.82,5.49c-0.02,0.12-0.04,0.24-0.08,0.37
			c-0.05,1.6-0.63,3.4,0.95,4.56C137.17,94.778,133.601,94.728,132.791,95.668z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#3C4851"
              d="M116.61,115.028c-0.62,0.53-1,1.12-1.22,1.75
			c-0.06-0.39-0.15-0.78-0.29-1.16c-0.1-0.19-0.23-0.37-0.37-0.54C115.13,114.798,115.73,114.758,116.61,115.028z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#57646D"
              d="M98.761,106.818c-0.19-0.06-0.39-0.13-0.58-0.2
			c-1.76-0.68-3.41-1.83-5.54-1c-1.12,0.43-2.62,0.17-3.08-0.98c-2.51-6.24-7.32-4.07-11.74-3.37c-0.38,0.06-0.76,0.13-1.14,0.2
			c-0.27,0.06-0.54,0.11-0.81,0.16c-0.22-2.74,1.14-3.95,3.79-3.91c1.64,0.03,3.31-0.11,4.92,0.13c3.58,0.53,6.3-0.2,7.16-4.21
			c0.33,0,0.65,0,0.96,0.01c3.41,0.05,5.86,0.83,3.44,5.57c-0.7,1.38-0.13,3.17,1.31,3.14
			C103.931,102.228,98.59,105.288,98.761,106.818z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#4F5154"
              d="M122.65,166.008c-0.43,5.28-4.2,5.02-7.95,4.79
			c-0.28-0.26-0.54-0.54-0.77-0.83c-0.83-0.99-1.37-2.13-1.43-3.49c-0.35-8.1-0.51-16.18,1.09-24.19c0.03-0.17,0.07-0.33,0.1-0.5
			c0.22-0.1,0.44-0.2,0.66-0.3c0.46-0.21,0.92-0.41,1.37-0.62c0.9,1.4,1.31,3.15,2.93,4.02c0.02,0.53,0.05,1.05,0.07,1.58
			c0.03,0.77,0.06,1.54,0.09,2.3c-0.18,0.53-0.32,1.07-0.42,1.6c-0.92,4.28,0,8.66-0.29,12.98c-0.15,2.13,1.46,1.71,2.76,1.65
			c0.02,0,0.04,0,0.06,0.01c0.14,0.01,0.27,0.03,0.4,0.05c0.01,0.01,0.01,0.01,0.01,0c0.36,0.08,0.67,0.21,0.93,0.45
			C122.411,165.638,122.541,165.798,122.65,166.008z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#435059"
              d="M94.67,86.498c0,0.02-0.01,0.04-0.01,0.06
			c-0.02,0.16-0.05,0.32-0.09,0.47c-0.24-0.13-0.48-0.25-0.72-0.35c0.11-0.45,0.15-0.81,0.15-1.09
			C94.181,85.878,94.4,86.188,94.67,86.498z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#435059"
              d="M100.701,86.808c-0.11,0.17-0.22,0.33-0.33,0.47
			c-0.19-0.03-0.38-0.08-0.55-0.14C100.11,87.078,100.4,86.968,100.701,86.808z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#536C7B"
              d="M136.811,121.788c-0.02-0.01-0.04-0.03-0.06-0.04
			c0-0.01,0.01-0.02,0.01-0.03L136.811,121.788z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#4E626E"
              d="M166.831,141.718v0.09l-0.05-0.06
			C166.8,141.738,166.811,141.728,166.831,141.718z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#4E626F"
              d="M136.84,121.748c-0.01,0.01-0.02,0.03-0.03,0.04l-0.05-0.07
			C136.791,121.728,136.82,121.738,136.84,121.748z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5B5A5B"
              d="M126.391,76.218c0.25,0.47,0.37,1.05,0.38,1.73
			c-0.16-0.17-0.32-0.34-0.48-0.51c-0.72-0.75-1.43-1.51-2.14-2.26c-0.13-0.13-0.25-0.27-0.38-0.4c0.26,0.01,0.51,0.04,0.73,0.09
			c0.83-0.9,1.15-2.44,2.42-2.77C126.38,73.388,126.46,74.818,126.391,76.218z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#4E626E"
              d="M166.831,141.808h-0.1c0.02-0.02,0.03-0.04,0.05-0.06
			L166.831,141.808z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#393F44"
              d="M93.851,88.798c-0.04,0.09-0.09,0.19-0.14,0.28
			c-0.42,0.82-0.62,1.69-0.74,2.59c-0.34-0.09-0.68-0.18-0.98-0.2c-0.39-1.12-0.21-2.09,0.76-2.85
			C93.13,88.638,93.5,88.698,93.851,88.798z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#635F57"
              d="M903.929,312.86c2.667,1.3,2.915,3.624,2.901,6.255
			c-0.063,11.875-0.014,23.75-0.004,35.624c-6.352,1.645-12.801,1.077-19.235,0.859c3.577-0.92,7.255-1.148,10.875-0.795
			c4.373,0.428,5.844-0.801,5.887-5.559C904.464,337.107,904.81,324.981,903.929,312.86z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#83807C"
              d="M886.707,294.796c5.037,1.788,8.831,5.076,11.451,9.69
			c0.59,1.039,1.793,2.139,0.583,3.541c-5.497-6.891-13.053-9.344-22.339-9.271c2.39-2.827,4.695-1.512,6.597-1.975
			C884.417,296.438,886.554,297.488,886.707,294.796z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#989797"
              d="M874.798,303.105c-1.058,4.062-0.744,7.39,3.706,9.146
			c0.914,0.36,1.739,2.577,0.69,3.983c-0.769,1.03-2.196,0.695-3.257,0.319c-4.145-1.473-5.809-10.767-2.465-13.532
			C873.564,302.945,873.793,303.034,874.798,303.105z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#7B7875"
              d="M852.986,259.925c4.588,0.111,8.888,4.444,9.788,9.864
			c-2.422,1.949-4.844,3.898-7.351,5.916c-1.333-2.195,0.301-3.353,1.33-4.323c3.188-3.005,2.493-5.234-0.803-7.581
			C854.685,262.899,853.958,261.239,852.986,259.925z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#898888"
              d="M861.337,286.69c-2.1-0.271-5.064,0.826-5.323-2.725
			c-0.181-2.48,1.729-3.194,3.691-2.553c2.154,0.702,6.016-1.437,6.107,2.993C865.88,287.618,863.163,286.498,861.337,286.69z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#A1A2A2"
              d="M75.738,442.65c10.901-1.457,21.798-1.173,32.729-0.229
			c5.289,0.457,10.635,0.224,15.95,0.447c1.597,0.067,3.897-1.042,4.37,1.856c0.795,0.868,0.525,1.591-0.3,2.234
			c-2.146-0.396-2.803,3.416-5.352,1.903c-6.4-0.51-12.692-2.389-19.137-2.001c-7.065,0.425-14.129,1.149-21.132,2.17
			c-2.838,0.414-4.926,2.167-5.125,5.743c-0.553,9.934-4.458,17.212-15.631,18.161c-0.734,0.061-1.429,0.742-2.114,1.18
			c-3.496,2.232-6.516,1.133-7.059-2.988c-0.277-2.103-1.199-3.706-2.083-5.444c1.125-0.658,1.541-2.028,2.682-2.681
			c1.889-0.682,3.943-0.835,5.766-0.376c3.973,0.999,6.828-0.196,8.968-3.528c3.167-3.371,3.178-7.151,0.009-11.155
			c-0.713-1.141-1.19-2.313-0.548-3.655C69.849,441.044,72.68,441.289,75.738,442.65z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F4F4F5"
              d="M67.67,444.943c0.401,0.972,0.802,1.943,1.204,2.915
			c2.096,3.899,2.032,7.814,0.085,11.743c-3.24,3.931-4.154,4.311-10.075,4.169c-1.965-0.048-3.929-0.102-5.894-0.152
			c0.471-2.167-0.654-3.607-2.208-4.854c-0.66-0.331-1.32-0.662-1.98-0.994c-3.681-2.999-1.612-6.001-0.002-9.002
			c4.978-5.937,11.861-3.593,18.075-4.16C67.257,444.443,67.522,444.555,67.67,444.943z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#272324"
              d="M123.135,448.863c2.324,1.034,2.256-3.505,4.763-2.044
			c2.074,3.614,1.989,7.244,0.079,10.888c-1.318,2.328-3.365,1.243-5.208,1.112C122.891,455.5,123.013,452.181,123.135,448.863z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2F2B2C"
              d="M48.8,448.768c0.484,3.001-2.291,6.002,0.002,9.002
			c-1.023,0.632-2.044,1.362-3.082,0.022c-0.051-1.93-0.151-3.86-0.14-5.789C45.594,449.919,46.51,448.634,48.8,448.768z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#555354"
              d="M50.783,458.764c1.694,0.189,2.979,1.211,4.375,2.118
			c0.192,1.654-2.172,1.279-2.167,2.735c1.036,2.496-0.478,2.356-2.137,2.065C50.83,463.376,50.806,461.071,50.783,458.764z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F2F3F3"
              d="M114.701,170.798c-7.54,3.34-7.5,3.32-13.05-3.64
			c-1.04-1.3-1.66-3.11-3.5-3.62c-0.09-0.03-0.18-0.05-0.28-0.07c-6.05-3.53-6.23-3.63-2.19-10.87c0.12-0.21,0.24-0.43,0.36-0.65
			c4.55-1.13,7.35-4.72,10.78-7.49c1.86-1.5,3.34-5.07,6.87-2.67c0.04,0.15,0.08,0.29,0.11,0.44c1.69,6.76-0.64,13.53-0.14,20.31
			c0.18,2.44-0.02,4.9,0.68,7.27C114.44,170.138,114.561,170.468,114.701,170.798z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5F7C8C"
              d="M87.4,141.378c-2.16,0.21-4.25,0.74-6.38,1.11
			c-0.27,0.05-0.55,0.09-0.81,0.12c-1.77,0.22-3.36,0.01-4.42-1.86c0.54,0.06,1.08,0.11,1.62,0.14
			c12.42,0.83,25.31-4.18,25.24-20.14c0-1.48,0.01-2.96,0.01-4.44c0-0.51,0.01-1.02,0.01-1.53c0.41,0.92,0.73,1.87,0.96,2.84
			C106.101,128.018,98.21,140.278,87.4,141.378z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#4D565D"
              d="M137.431,89.288c-1.79,0.39-3.06-0.8-4.49-1.46
			c-0.05-0.1-0.09-0.19-0.14-0.29c-0.67-1.43-1.43-2.84-2.88-3.55c-1.64-0.79-1.03-1.59-0.54-2.38c0.17-0.27,0.33-0.54,0.38-0.81
			C134.931,81.318,136.82,83.388,137.431,89.288z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#231F20"
              d="M68.67,132.278c-0.01,0.71,0.4,1.8-0.76,1.9
			c-0.36,0.03-0.81-0.91-1.21-1.41c0-0.61,0.01-1.23,0.01-1.84c0.02-4.45,0.01-8.9,0.12-13.36c0.03-1.13-0.85-3.29,1.77-2.66
			c0.05,0.01,0.11,0.02,0.17,0.04C68.741,120.728,68.73,126.498,68.67,132.278z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#63676A"
              d="M132.791,95.668c-0.23,0.24-0.46,0.48-0.7,0.71
			c-1.35,4.74-2.29,5.28-6.76,4.17c-0.22,0.07-0.44,0.13-0.67,0.19c0.09-0.1,0.19-0.2,0.28-0.29c2.08-2.13,4.4-3.86,7.31-4.65
			C132.431,95.758,132.61,95.708,132.791,95.668z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#3B3838"
              d="M116.041,95.298c-0.28,2.19-0.52,4.34-0.37,6.47
			c-0.01-0.01-0.01-0.01-0.01-0.02c-2.32-2.64,0.32-6.23-1.69-8.92v-0.13c-0.04-3.39-0.22-6.8,1.3-11.3
			C117.241,86.428,116.581,90.958,116.041,95.298z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#313336"
              d="M106.15,90.698c-0.42,1.14-1.02,2.19-2.45,1.88
			c-1.32-0.29-0.97-1.54-0.99-2.44c-0.02-1.29,0.03-2.59,0.07-3.89c0.02-0.51,0.04-1.01,0.05-1.52c0.15,0.01,0.29,0.02,0.42,0.03
			c0.36,0.04,0.67,0.11,0.93,0.19c2.46,0.81,1.44,3.54,1.9,5.46C106.09,90.508,106.121,90.608,106.15,90.698z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#435058"
              d="M79.21,155.138c-0.06,0-0.13,0-0.2-0.01
			c-0.01-0.01-0.03-0.02-0.04-0.02v-0.12C79.07,155.018,79.15,155.068,79.21,155.138z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#435058"
              d="M81.761,154.308c-0.19,0.15-0.39,0.28-0.59,0.39
			c0.16-0.3,0.34-0.6,0.57-0.84C81.75,154.008,81.761,154.158,81.761,154.308z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#747271"
              d="M1166.887,44.903c0.8,1.72,2.963,0.443,3.933,1.79
			c-21.626,2.425-43.322,0.715-64.982,1.096c-0.854-1.118-0.983-2.2,0.156-3.221c7.482,0.901,14.967,1.315,22.459,0.112
			c1.753-2.02,0.599-4.253,0.514-6.424c-0.073-1.853-0.711-4.309,2.725-3.482c0.405,0.863,1.088,1.528,0.915,2.705
			c-0.98,6.652-0.94,6.658,5.472,6.662c1.821,0.001,3.644,0.004,5.465-0.001c3.479-0.01,7.136,0.654,10.389-0.207
			c4.26-1.128,0.749-6.294,4.101-9.445C1157.698,41.285,1158.993,46.372,1166.887,44.903z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#827E6F"
              d="M923.679,49.575c0.014-1.197,0.026-2.395,0.039-3.592
			c6.736,1.116,13.512,0.54,20.278,0.547c3.072,0.003,4.11-1.286,3.901-4.198c-0.13-1.801,0.093-3.626,0.158-5.44
			c2.175-1.094,2.283,0.519,2.541,1.937c-0.247,0.789-0.306,1.604-0.323,2.431c-0.123,5.564,0.174,5.813,5.655,6.06
			c2.096,0.095,4.762-0.937,5.752,2.195C949.015,50.072,936.347,49.844,923.679,49.575z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#73716E"
              d="M999.73,39.687c2.106,2.995-1.52,7.214,2.109,9.884
			c-9.043,0.029-18.087,0.059-27.13,0.088c-1.017-1.558-0.278-2.653,0.953-3.618c5.085-0.048,10.174-0.25,15.253-0.097
			C995.571,46.085,999,44.888,999.73,39.687z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#7F7C76"
              d="M961.681,49.514c-1.672-1.62-3.784-2.077-5.924-1.704
			c-5.358,0.933-6.672-1.69-5.971-6.354c0.146-0.969,0.155-1.958,0.228-2.938c0.755,0.179,1.283,0.504,0.912,1.41
			c-0.094,6.687-0.092,6.771,6.589,6.649c4.258-0.077,8.52-0.732,12.762-0.593c2.83,0.093,2.253-2.244,3.297-3.396
			c1.228,2.311,1.239,4.674,0.313,7.079C969.817,49.616,965.749,49.564,961.681,49.514z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#868274"
              d="M1087.848,45.879c3.243-1.771,6.815-1.329,10.18-1.014
			c4.862,0.456,6.591-1.338,5.863-6.038c-0.161-1.037-0.426-2.347,0.85-3.102c0.867,4.03,0.898,8.06-0.015,12.087
			c-4.568-0.662-9.106,1.618-13.716,0.082C1089.725,47.467,1088.433,47.3,1087.848,45.879z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#262223"
              d="M973.886,49.667c-0.104-2.359-0.208-4.72-0.313-7.079
			c0-1.741,0-3.482,0-5.224c2.45,2.727-0.173,6.076,2.09,8.676c-0.318,1.206-0.636,2.412-0.954,3.618c0.001,0-0.41,0.083-0.41,0.083
			L973.886,49.667z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2A2627"
              d="M1104.726,47.813c0.005-4.028,0.01-8.058,0.015-12.087
			c2.91,2.595,0.111,5.998,1.253,8.843c-0.053,1.073-0.104,2.147-0.156,3.221C1105.467,47.797,1105.097,47.805,1104.726,47.813z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#474035"
              d="M969.845,738.354c-0.357,1.141,0.874,3.75-2.08,3.068
			c-2.013-0.463-5.684,0.607-4.881-3.729c0.411-2.225,0.371-4.986,3.618-4.941C970.175,732.805,969.776,735.709,969.845,738.354z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#6A6149"
              d="M954.961,742.36c0.104-3.01-0.966-5.566,0.693-7.977
			c0.68-0.988,1.683-1.76,2.973-1.346c1.776,0.57,0.883,1.766,0.512,2.822C958.338,738.139,957.986,740.69,954.961,742.36z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#423D33"
              d="M973.24,733.104c1.538,2.709-0.685,5.957,1.594,7.348
			c1.889,1.154,2.465-1.844,3.659-3.258c0.746,2.471,0.723,4.375-1.788,5.148c-1.349,0.418-3.017,1.201-4.056-0.449
			C970.899,739.116,971.143,736.327,973.24,733.104z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#D3D3D4"
              d="M38.792,699.125c4.835-0.279,9.573-2.748,14.524-0.574
			c1.2,0.527,2.65,0.668,2.403,2.518c-0.265,1.977-1.866,1.475-3.066,1.598c-3.102,0.314-6.231,0.416-9.313,0.863
			c-3.103,0.451-4.845-0.387-4.602-3.809C38.869,699.532,38.886,699.333,38.792,699.125z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#67899C"
              d="M36.992,706.721c0.169-0.059,0.341-0.064,0.515-0.02
			c3.26,4.73,0.964,9.27-0.478,13.84c0,0-0.154,0.146-0.153,0.145c-3.182-0.025-3.296-2.201-3.037-4.492
			c0.166-1.461,0.594-2.893,0.905-4.338c1.551-1.16,0.594-2.895,0.988-4.318C36.152,707.266,36.572,706.993,36.992,706.721z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2D2C2E"
              d="M35.732,707.538c1.792,1.316,1.159,3.023,0.672,4.662
			c-0.013,0.045-1.083-0.223-1.66-0.344C34.258,710.229,32.887,708.401,35.732,707.538z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#868274"
              d="M1131.69,34.774c-2.629,0.485-1.697,2.6-1.965,4.203
			c-0.321,1.92,1.022,4.225-1.272,5.702c0.794-5.991,0.334-6.476-5.494-5.365c-2.005,0.382-3.918,0.72-5.684-0.679
			c-1.161-0.92-1.711-2.078-1.134-3.479c0.617-1.498,1.662-1.441,3.225-1.132C1123.411,34.826,1127.682,32.601,1131.69,34.774z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F6F6F7"
              d="M509.71,25.565c6.122,0.128,12.259,0.566,18.361,0.284
			c4.76-0.22,5.892,1.604,5.842,6.08c-0.188,16.947-0.008,33.897,0.034,50.847c0.066,2.511-1.759,3.074-3.662,3.567
			c-2.49-0.034-4.977-0.231-7.475-0.177c-6.075-0.128-12.15,0.25-18.221-0.205c-3.729-0.895-4.236-3.795-4.26-6.939
			c-0.061-7.97-0.143-15.942,0.015-23.909c0.135-6.812-0.645-13.564-0.855-20.344C499.267,27.595,502.3,25.12,509.71,25.565z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#242021"
              d="M509.71,25.565c-7.957,1.474-9.577,2.963-8.749,10.896
			c1.493,14.304,0.615,28.609,0.826,42.913c0.034,2.355-0.186,4.807,2.886,5.573c0.463,1.188-0.094,2.215-0.492,3.271
			c-0.222,0.623-0.542,1.24-0.577,1.894c-0.848,15.676,0.254,31.396-1.129,47.066c-0.368,4.167,3.485,6.099,5.523,8.986
			c-1.258,2.354-3.576,2.06-5.631,2.097c-5.079,0.094-10.163,0.195-15.245-0.057c-1.439-0.398-3.254-0.278-3.465-2.428
			c2.232-2.021,5.017-1.328,7.613-1.494c4.863-0.311,5.85-1.14,5.983-5.966c0.175-6.315,0.196-12.641,0.015-18.954
			c-0.207-7.17,0.558-14.271,1.041-21.396c0.271-3.994-0.583-7.596-3.463-10.547c-0.894-1.707,0.603-2.68,1.188-3.938
			c1.221-1.925,1.219-4.09,1.265-6.257c0.272-12.77-0.357-25.536,0.848-38.316c0.948-10.055-3.395-13.627-13.479-12.226
			c-3.31,0.46-6.613,0.505-9.932,0.603c-5.368,0.158-6.32,1.069-6.416,6.542c-0.133,7.637-0.014,15.277-0.053,22.916
			c-0.009,1.822,0.049,3.661-0.622,5.413c-3.704,3.911-3.88-0.051-4.888-2.183c0.034-4.061-1.334-8.044-0.732-12.145
			c3.005-0.75,2.454-3.453,2.915-5.507c1.175-5.232-0.418-10.406-0.537-15.617c-0.041-1.792-1.038-2.23-2.62-1.797
			c-0.747,0.464-1.396,0.371-1.934-0.343c-0.876-1.841,0.294-1.857,1.605-1.855c11.287,0.019,22.609-0.487,33.847,0.263
			C500.036,23.291,504.975,24.307,509.71,25.565z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#CDCDCC"
              d="M459.849,24.567c0.645,0.114,1.289,0.229,1.934,0.343
			c0.018,9.227-2.105,12.221-11.02,14.311c-7.197,1.688-12.327,5.358-14.602,12.643c-0.524,1.679-1.501,3.839-3.67,3.413
			c-1.963-0.386-2.396-2.587-2.644-4.405c-0.456-3.341,0.447-6.576,0.896-9.854c0.578-4.221-1.049-8.361-0.243-12.752
			c0.449-2.446,1.184-2.456,2.946-2.495c6.784-0.151,13.618,0.671,20.328-0.995C455.8,24.705,457.825,24.637,459.849,24.567z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#302C2D"
              d="M530.515,85.725c0.821-1.359,3.119-0.999,3.433-2.948
			c1.634-2.266,0.755-4.847,0.788-7.265c0.121-8.663,0.018-17.329,0.074-25.993c0.011-1.593-0.587-3.369,0.885-4.715
			c2.466,1.989,2.269,4.841,2.459,7.563c0.04,0.656-0.018,1.319,0,1.98c0.086,3.243-0.727,6.87,3.248,8.601
			c3.89,1.693,8.742,0.327,11.942-3.256c1.272-0.854,2.443-0.497,3.582,0.29c1.372,1.271,2.342,2.695,1.725,4.7
			c-2.093,1.349-5.212-0.482-6.868,2.218c-2.264,1.316-4.904-0.68-7.147,0.814c-0.33,0.018-0.693,0.134-0.986,0.037
			c-7.806-2.581-7.922-2.583-7.776,5.596c0.211,11.868-0.651,23.761,0.773,35.603c2.697,3.214,1.313,7.035,1.595,10.61
			c-2.29,8.148-0.99,16.495-1.335,24.759c-0.487,1.736-1.545,3.091-3.3,3.422c-4.776,0.899-9.588,0.954-14.368-0.063
			c-0.847-0.181-1.441-0.79-1.639-1.688c2.401-2.566,5.566-1.563,8.438-1.701c5.642-0.27,6.121-0.657,6.235-6.407
			c0.24-12.077-0.706-24.192,1.008-36.22C534.087,96.01,531.542,91.017,530.515,85.725z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#A3A4A4"
              d="M537.003,52.853c-0.437-2.683-0.872-5.366-1.309-8.049
			c-1.586-5.081,0.742-10.069,0.36-15.121c2.855,0.394,3.141,2.97,3.451,4.869c0.719,4.392,3.055,6.362,7.368,6.276
			c-1.791,2.722-5.568,3.482-6.72,6.83C539.341,49.534,539.619,52.077,537.003,52.853z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#9F9F9F"
              d="M950.926,39.928c-0.305-0.47-0.609-0.938-0.913-1.408
			c-0.082-1.23-0.618-1.914-1.958-1.628c1.842-4.138,4.826-5.213,8.829-3.149c1.481,0.763,2.855,0.456,4.331,0.182
			c1.844-0.344,3.408,0.108,3.668,2.261c0.277,2.297-1.255,2.658-3.134,2.778C958.135,39.195,954.532,39.597,950.926,39.928z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#585758"
              d="M67.67,444.943c-0.285-0.063-0.55-0.175-0.795-0.335
			c-1.669-1.711-3.807-0.862-5.595-0.677c-5.94,0.614-11.547-2.104-17.471-1.479c-0.994,0.104-2.276-0.396-2.897-1.559
			c11.963-0.048,23.925-0.097,35.888-0.145c-0.354,0.634-0.708,1.268-1.063,1.901C72.799,442.538,69.922,442.639,67.67,444.943z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F0F0F1"
              d="M532.873,262.724c5.64,3.003,5.64,3.003,5.275,10.672
			c4.491-4.705,4.491-4.705,7.534,1.497c-1.335,1.832-2.67,3.665-4.005,5.497c-2.098,5.682-2.168,5.723-8.149,3.398
			c-1.813-0.704-2.819,1.738-4.585,1.093c-2.977-0.263-4.592-4.233-8.124-2.982c-0.721-0.723-1.441-1.446-2.161-2.169
			c-1.105-3.366-0.448-6.679,1.231-9.512c1.681-2.835,3.012-5.887,5.005-8.536C528.273,256.52,530.585,259.522,532.873,262.724z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#EDEEEE"
              d="M496.808,272.796c-8.012-6.296-6.88-12.785,3.167-18.165
			c1.895-1.228,3.479-1.034,4.66,1.015c4.457-0.282,4.87,3.344,6.035,6.243c2.189,4.322,1.731,7.869-1.36,10.535
			C505.634,275.594,500.826,275.736,496.808,272.796z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#9D9E9E"
              d="M496.808,272.796c1.442,0.299,2.875,0.787,4.33,0.864
			c7.471,0.397,10.037-2.815,9.533-11.772c1.378-3.714,3.05-1.751,4.458-0.117c1.448,1.679,1.705,4.275,4.031,5.45
			c1.893-1.95,2.276-5.375,5.732-5.539c0.071,1.485,0.261,3.187-0.807,4.24c-3.883,3.835-4.198,8.983-5.429,13.808
			c-0.441,0.214-1.15,0.723-1.285,0.601c-4.547-4.134-10.121-1.996-15.243-2.601C499.274,277.393,496.437,276.746,496.808,272.796z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5A5859"
              d="M477.786,263.574c-1.968,0.039-3.936,0.079-5.903,0.119
			c0.311-3.282,2.627-3.24,5.008-2.96C479.172,261.002,478.196,262.4,477.786,263.574z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#575555"
              d="M465.661,268.572c-0.28,0.142-0.577,0.223-0.891,0.242
			c0.163-1.667-0.463-3.715,1.852-4.414c1.383-0.418,2.938-0.846,3.238,1.406C468.46,266.729,467.06,267.65,465.661,268.572z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5D5A5B"
              d="M464.832,268.756c0,2.193,0,4.388,0,6.825
			c-2.574-1.542-1.553-3.928-2.072-5.808C463.255,269.037,463.884,268.571,464.832,268.756z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#3E3A3B"
              d="M504.635,255.646c-1.427-0.918-3.047-0.952-4.66-1.015
			c0.165-2.195,1.631-2.086,3.222-1.867C505.131,253.029,505.083,254.223,504.635,255.646z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#655C44"
              d="M56.789,774.768c-2.797,0.496-4.542-0.186-4.154-3.518
			c0.17-1.469,0.116-2.965,0.164-4.449c0.545-0.438,1.181-0.531,1.856-0.516c2.268,0.051,5.023-0.576,5.79,2.438
			C61.201,771.702,59.736,773.795,56.789,774.768z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#B2A471"
              d="M56.789,774.768c2.055-1.648,3.577-4.021,2.66-6.357
			c-1.152-2.938-4.329-1.336-6.651-1.609c4.976-1.59,9.934-1.303,14.879,0.148c-3.441,0.982-5.401,2.803-3.998,6.719
			c0.415,1.156-0.093,2.232-0.927,3.117C60.825,775.934,59.097,774.493,56.789,774.768z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5B5440"
              d="M62.752,776.786c-0.053-1.809,0.043-3.637-0.188-5.42
			c-0.54-4.166,1.3-5.389,5.113-4.418c-0.039,1.543-1.458,1.559-2.349,2.203c-1.41,1.021-1.522,2.316-0.772,3.943
			c0.558,1.211,3.787,0.186,2.329,2.734C65.989,777.397,64.184,776.645,62.752,776.786z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#3F3B36"
              d="M47.171,766.752c0.999,3.721,0.562,6.168,0.151,9.652
			C44.072,773.083,44.732,770.547,47.171,766.752z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#A09FA0"
              d="M847.997,34.018c1.834,0.544,2.924-0.386,4.148-0.773
			c1.351-0.427,2.155,0.428,2.883,1.361c0.746,0.959,0.426,1.82-0.261,2.662c-0.954,1.168-1.906,1.085-2.867,0.033
			c-0.993-1.089-1.878-1.014-2.866,0.076c-1.055,1.163-2.43,1.995-3.862,0.849c-0.952-0.763-0.807-2.166-0.441-3.254
			C845.451,32.825,847.05,33.847,847.997,34.018z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#C3C2C2"
              d="M852.736,307.777c-0.348,2.093,1.641,5.362-2.335,5.681
			c-3.109,0.249-3.502-2.197-3.422-4.662C848.428,305.799,850.573,306.737,852.736,307.777z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#B1B1B1"
              d="M826.864,286.622c-2.799-1.553-4.083-4.464-6.06-6.753
			C827.397,279.794,828.661,281.202,826.864,286.622z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#CACACC"
              d="M83.809,115.799c1.701-2.534,3.148-5.001,7.105-3.102
			c3.853,1.85,4.633,4.239,3.11,7.991c-0.803,1.979-1.158,4.136-1.766,6.197c-1.848,6.271-2.37,6.555-8.674,4.866
			c-4.859-1.302-5.663-2.676-3.494-7.427c0.846-1.853-0.037-4.074,1.6-5.602c1.239,0.711,1.455,2.092,2.098,3.194
			c0.567,0.972,1.115,2.302,2.491,1.741c1.475-0.602,1.199-2.126,1.08-3.42C87.153,117.986,85.003,117.271,83.809,115.799z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#818182"
              d="M83.809,115.799c4.484,0.813,6.719,4.843,4.616,8.563
			c-1.262,2.232-3.163,2.488-4.701,0.401c-1.264-1.714-1.971-3.818-2.033-6.039C82.397,117.749,83.103,116.773,83.809,115.799z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#ACACAC"
              d="M617.081,39.793c-1.557-0.195-4.017-0.473-6.466-0.828
			c-1.088-0.157-2.471,0.023-2.744-1.547c-0.181-1.032,0.102-1.954,1.016-2.611c1.157-0.832,2.391-1.264,3.679-0.405
			c3.054,2.035,5.818,5.333,9.651,0.893c1.028-1.192,2.039,0.538,2.397,1.549c0.508,1.431,0.087,2.741-1.647,2.915
			C621.329,39.922,619.662,39.793,617.081,39.793z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#969596"
              d="M886.261,37.07c-0.836,1.101-2.059,1.729-3.5,1.823
			c-1.757,0.114-2.168-1.113-2.18-2.556c-0.012-1.501,0.589-2.551,2.246-2.576C884.857,33.73,885.537,35.235,886.261,37.07z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#454343"
              d="M1152.784,563.481c1.045,0.43,2.09,0.859,3.136,1.289
			c-2.657,4.557-4.676,0.221-7.196-0.094C1149.897,562.356,1151.465,563.516,1152.784,563.481z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#727171"
              d="M1013.134,34.685c1.454,0.024,2.176,0.783,2.099,1.812
			c-0.154,2.075-1.866,2.462-3.534,2.702c-1.028,0.147-2.251,0.045-1.72-1.389C1010.536,36.305,1011.488,34.888,1013.134,34.685z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#9B9A9B"
              d="M1032.546,35.702c-0.436-1.896,0.308-2.946,2.262-2.792
			c1.579,0.125,3.761,0.075,3.572,2.362c-0.162,1.951-1.19,4.071-3.569,4.126C1032.449,39.452,1032.776,37.275,1032.546,35.702z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#717070"
              d="M35.756,38.082c-1.433,2.024-0.491,5.741-4.589,5.317
			c-3.822-0.395-2.761-3.559-3.95-6.769C30.379,38.39,32.997,37.259,35.756,38.082z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F4F4F5"
              d="M104.612,375.037c0.257,4.254-1.06,8.651,1.161,12.726
			c1.499,1.618,1.396,3.645,1.418,5.63c0.149,13.246-0.012,26.493-0.016,39.739c0,1.95,0.263,4.035-1.468,5.558
			c-3.313,0.079-6.653-0.082-9.929,0.309c-2.946,0.351-4.167-0.967-4.73-3.503c-0.655-20.562-0.382-41.125-0.123-61.689
			C95.47,374.41,100.276,372.115,104.612,375.037z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F2F2F3"
              d="M37.029,720.542c-1.146-4.658,1.682-9.18,0.478-13.84
			c3.132-0.619,6.259-1.701,9.396-1.729c3.392-0.031,6.702,0.074,9.913-1.168c0.666,1.242,1.109,2.537,1.001,3.973
			c1.429,1.805,2.108,3.744,1.082,5.992c0.279,0.658,0.558,1.314,0.837,1.973c0.161,1.785,0.004,3.4-2.116,3.971
			C50.8,721.081,43.931,721.227,37.029,720.542z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#333032"
              d="M57.62,719.713c0.706-1.324,1.411-2.648,2.116-3.971
			c2.501,0.459,2.825,2.111,1.662,3.879C60.46,721.049,58.929,721.86,57.62,719.713z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#353234"
              d="M58.899,713.77c-0.255-2.016-1.523-3.85-1.082-5.992
			C62.39,709.014,61.985,711.151,58.899,713.77z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E5E5E6"
              d="M121.331,165.058c0,0.01,0,0.01-0.01,0
			c-0.19,0.16-0.4,0.31-0.62,0.46c-1.1,0.62-2.19,1.24-3.92,2.22c0-6.21,0.03-11.87-0.02-17.53c-0.01-1.19,0.32-1.71,1.05-1.69
			c0.27,0.01,0.61,0.1,1,0.25c1.14,2.73,3.22,4.78,5.04,7.02c0.24,0.69,0.41,1.35,0.5,1.99
			C124.82,160.678,123.84,163.118,121.331,165.058z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#AAABAC"
              d="M122.65,166.008c-0.22-0.05-0.43-0.11-0.65-0.16
			c-0.43-0.11-0.87-0.22-1.3-0.33c0.07-0.17,0.15-0.34,0.22-0.51c1.14-2.57,2.64-5.03,2.9-7.88c0.04-0.44,0.05-0.88,0.03-1.34
			C128.371,161.368,128.371,161.368,122.65,166.008z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#EEEEEF"
              d="M262.215,251.767c-6.153,0-12.309-0.112-18.458,0.037
			c-4.59,0.111-5.812-2.619-5.58-6.362c0.542-8.765,0.629-8.619,9.903-8.514c10.136,0.116,20.276-0.086,30.414-0.106
			c6.023-0.012,8.099,1.912,8.196,7.441c0.096,5.456-2.058,7.484-8.013,7.502C273.19,251.78,267.703,251.768,262.215,251.767z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#DEDEDF"
              d="M921.544,39.591c-3.647,0.126-7.295,0.267-10.942,0.371
			c-1.344,0.038-2.929,0.084-3.355-1.476c-0.399-1.458,0.291-3.002,1.206-4.127c0.959-1.178,2.181-0.232,3.13,0.327
			c2.309,1.361,4.214,1.945,6.546-0.42c1.457-1.478,3.758-0.559,5.514,0.606C923.008,36.475,920.879,37.411,921.544,39.591z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#423F40"
              d="M528.943,284.881c1.673-1.321,3.346-2.644,5.357-4.234
			c1.215,1.389-0.496,4.432,1.999,4.416c2.844-0.017,3.37-3.328,5.378-4.673c1.261,0.371,1.497,1.562,1.071,2.343
			c-1.126,2.066-2.38,4.087-5.17,4.377C534.385,287.441,530.743,289.843,528.943,284.881z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#231F21"
              d="M758.721,26.843c-0.021,0.605-0.042,1.212-0.063,1.817
			c-4.729,2.173-9.697,0.641-14.55,0.881c-0.92,0.045-1.986-0.636-1.753-1.883c0.195-1.047,1.25-0.902,2.017-0.902
			C749.155,26.758,753.938,26.809,758.721,26.843z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5F5E5E"
              d="M969.702,788.678c-1.935-0.002-3.87-0.004-5.805-0.006
			c-0.09-4.406,3.824-3.355,5.739-3.324C971.694,785.379,970.601,787.467,969.702,788.678z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E1E1E1"
              d="M1187.155,110.497c0-16.492,0-32.984,0-49.477
			c0.301-0.003,0.601-0.006,0.901-0.009c0,16.479,0,32.957,0,49.436C1187.756,110.464,1187.456,110.48,1187.155,110.497z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#EEEFEF"
              d="M1188.116,183.594c0,15.283,0,30.565,0,45.849
			c-0.271,0.005-0.541,0.01-0.812,0.015c0-15.318,0-30.637,0-45.956C1187.575,183.532,1187.846,183.563,1188.116,183.594z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F8EAB5"
              d="M1183.911,112.002c0-17.558,0-35.116,0-52.674
			c0.178-0.001,0.355-0.001,0.534-0.002c0,17.558,0,35.116,0,52.674C1184.267,112.001,1184.089,112.001,1183.911,112.002z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E5D7A7"
              d="M1183.932,231.239c0-17.502,0-35.004,0-52.507
			c0.162-0.001,0.325-0.003,0.487-0.005c0,17.502,0,35.005,0,52.507C1184.257,231.236,1184.094,231.237,1183.932,231.239z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FDFDFD"
              d="M761.886,431.849c1.858-4.825,5.678-8.472,7.869-13.098
			c3.732-5.627,6.135-12.048,10.43-17.352c0.76-0.939,1.321-1.817,2.645-1.736c0.482,0.132,1.127,0.108,1.421,0.419
			c4.76,5.029,8.459,3.502,12.255-1.442c4.258-5.546,10.549-8.56,16.492-11.938c0.998-0.567,1.843-1.404,2.759-2.116
			c3.176-1.32,6.339-2.67,9.528-3.955c8.289-3.339,16.792-5.596,25.839-5.473c2.26,0.031,4.646-0.109,6.683-1.457
			c2.69,0.077,5.142,2.035,7.973,1.021c1.079-0.565,2.243-0.512,3.411-0.5c8.626,0.084,10.408,1.813,9.822,10.287
			c-0.201,2.904,0.33,6.061-1.727,8.656c-0.673,0.849-0.407,1.747-0.15,2.789c3.1,12.548,1.866,25.197,0.733,37.838
			c-1.306,2.271-3.521,2.154-5.679,2.137c-4.799-0.037-9.666-0.642-14.383-0.045c-10.105,1.278-19.812,3.75-25.175,13.853
			c-0.278,4.583-3.506,8.073-4.411,12.547c-1.981,9.791,4.295,24.553,13.041,29.326c4.088,2.23,8.577,3.596,12.594,5.965
			c3.627,0.217,7.271,0.283,10.875,0.691c4.242,0.479,6.595,2.797,6.134,7.338c1.226,5.26,0.233,10.58,0.337,15.869
			c0.101,5.131,0.579,10.301-0.357,15.418c-1.229,2.26-1.338,4.191,0.044,6.752c2.521,4.668,0.992,9.729-0.487,14.395
			c-0.802,2.529-3.821,2.293-6.066,2.176c-8.407-0.439-16.805-1.057-25.207-1.605c-8.631-1.746-17.006-4.193-24.548-8.938
			c-1.859-1.17-4.04-1.43-6.084-2.068c-0.893-0.355-1.47-1.066-2.01-1.813c-0.392-0.48-0.787-0.965-1.236-1.396
			c-0.923-0.756-1.967-1.396-2.521-2.523c-11.026-9.254-20.285-20-27.167-32.674c-1.358-2.504-3.045-2.873-5.603-2.723
			c-13.3,0.777-14.737-0.76-14.905-14.229c-0.103-8.258,1.074-16.479-0.048-24.795c-0.771-5.708,1.576-7.101,8.616-6.464
			c2.279,2.945,1.072,6.349,0.967,9.494c-0.068,2.046-0.038,3.866,0.612,4.997c-1.458-3.963-0.275-8.704-0.141-13.378
			c0.063-2.245,2.653-1.309,4.092-2.109c-1.675-1.786-3.908-3.34-2.402-6.247c0.05-1.631,0.212-3.269,0.124-4.892
			c-0.152-2.84,0.683-4.859,3.796-5.311c1.914-0.59,3.514,0.449,5.211,0.986c-1.557-1.031-3.504-0.893-5.138-1.693
			c-0.819-2.312-1.426-4.146-4.788-4.538C763.571,438.018,759.975,436.046,761.886,431.849z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FDFDFD"
              d="M721.957,598.256c0,11.645,0.022,23.289-0.01,34.934
			c-0.015,5.428-0.887,5.977-6.48,5.889c-9.127-0.145-18.262-0.555-27.386,0.254c-4.807,0.426-7.146-1.838-6.963-6.672
			c0.867-22.918,0.742-45.834,0.079-68.754c-0.105-3.641,1.331-6.059,5.499-6.033c9.98,0.063,19.962,0.043,29.942,0.037
			c3.992-0.002,5.458,1.881,5.388,5.91C721.827,575.295,721.956,586.778,721.957,598.256z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FDFDFD"
              d="M836.83,450.79c1.254-1.07,2.493-2.159,3.766-3.207
			c5.512-4.537,11.364-7.466,18.993-6.962c8.939,0.59,17.954,0.084,26.936,0.002c5.735-0.053,7.217,1.026,6.889,6.975
			c-0.67,12.131-0.367,24.257-0.438,36.384c-0.052,8.799,0.017,8.68-9.099,8.824c-7.631,0.121-15.256,0.563-22.884,0.863
			c-0.592-0.348-1.242-0.467-1.909-0.393c-16.936,1.893-30.398-12.191-27.646-29.193C832.206,459.338,832.057,454.002,836.83,450.79
			z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#565455"
              d="M806.816,544.719c0.354,0.598,0.709,1.195,1.063,1.795
			c0.124,2.738-2.019,2.037-3.483,2.23c-7.444,0.977-14.921,0.313-22.381,0.551c7.365-0.254,14.737,0.412,22.107-0.455
			c1.779-0.209,3.653-0.449,5.448,0.064c1.188,0.34,2.261,1.123,2.081,2.43c-0.151,1.102-1.379,1.305-2.385,1.35
			c-3.987,0.176-7.979,0.039-10.911,0.074c4.503-0.191,10.082,0.432,15.627-0.445c0.978-0.154,1.994-0.057,2.987,0.041
			c2.554,0.246,5.723,0.67,5.57,3.689c-0.13,2.563-3.323,1.98-5.371,2.203c-3.826,0.418-7.591-0.645-11.408-0.535
			c-1.787-0.313-3.573-0.891-5.362-0.898c-18.156-0.066-36.313-0.051-54.469-0.02c-1.43,0.004-3.056-0.467-4.134,1.049
			c0.214,0.824-0.148,1.113-0.936,1.01c-2.492-2.105-5.364-0.887-8.08-0.865c-0.96,0.01-1.921,0.707-2.873,0.688
			c-2.304-0.051-5.701,1.197-5.892-2.568c-0.179-3.529,3.052-3.281,5.493-3.295c9.106-0.053,18.215-0.021,27.332-1.141
			c-10.469,0-20.938,0-31.406,0c-0.019-0.473-0.036-0.947-0.055-1.42c7.717,0,15.434,0,23.15,0c0.005-0.477,0.01-0.953,0.015-1.43
			c-7.305,0-14.609,0-21.914,0c-0.048-0.471-0.095-0.939-0.142-1.41c5.367-0.883,10.708-2.215,16.108-2.541
			c8.101-0.486,16.23-0.232,24.329,0.795c2.22,0.281,4.972,1.096,6.426-2.162c0.545-1.219,2.438-0.674,3.735-0.68
			c6.986-0.029,13.973,0.025,20.958-0.047c1.58-0.016,3.366,0.627,4.682-0.906c1.048,0.617,2.096,1.236,3.144,1.854
			c-3.819,3.287-8.598,1.844-12.909,2.389c-5.107,0.646-10.275-0.357-15.362-1.088C787.351,546.688,797.086,546.596,806.816,544.719
			z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#3C3C40"
              d="M874.71,573.893c-6.277,0.041-12.559-0.035-18.832,0.146
			c-11.884,0.342-23.238-2.258-34.429-5.889c-1.929-0.627-3.523-1.779-4.929-3.215c13.399-4.414,27.019-3.855,40.644-1.529
			c4.504,0.77,9.311-0.936,14.796,2.445c-8.519-0.582-15.658,1.049-23.688,1.082c1.876,1.012,2.958,1.48,4.69,1.361
			c5.852-0.406,11.744-0.689,17.599-0.459C876.16,568.057,876.808,569.342,874.71,573.893z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#3C3A3A"
              d="M836.83,450.79c-2.891,6.046-5.272,12.339-4.597,19.107
			c1.278,12.814,11.58,22.238,24.453,22.924c1.473,0.078,3.026-0.227,4.306,0.848c-0.645,0.121-1.29,0.242-3.367,0.633
			c12.254,1.301,23.347-0.951,34.067,2.5c-10.694,0-21.388-0.037-32.081,0.033c-1.922,0.012-3.991-0.66-5.756,0.74
			c-8.791,0.371-21.468-9.197-24.759-18.914c-3.442-10.161-4.061-20.067,3.535-28.924
			C834.147,449.622,835.532,450.033,836.83,450.79z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#A9B8CF"
              d="M740.861,558.852c0.312-0.338,0.623-0.674,0.936-1.01
			c17.798,0.047,35.597,0.146,53.396,0.105c5.036-0.012,9.303,1.537,13.058,4.754c-0.741,1.596-1.893,1.008-2.745,1.01
			c-20.131,0.047-40.263-0.045-60.393,0.125C741.149,563.87,739.962,562.493,740.861,558.852z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#504E4E"
              d="M877.868,433.792c-0.019-10.957-0.12-21.915-0.007-32.871
			c0.036-3.509-2.235-4.845-5.829-6.456c6.65-1.085,5.935-5.784,5.841-9.563c-0.201-8.058,1.674-8.793-7.774-9.268
			c-1.503-0.076-2.999-0.045-4.32-0.914c-1.328-4.064,0.002-5.14,4.322-5.096c13.498,0.139,26.71,2.868,40.164,3.542
			c-5.096,3.942-10.43,0.332-15.926,0.656c1.149,1.942,1.452,3.958,3.466,4.783c-5.114-0.066-10.251,0.982-15.315-0.9
			c-3.201-1.19-2.556,1.579-2.566,3.179c-0.095,13.985-0.108,27.971-0.168,41.958c-0.015,3.659-0.104,7.318-0.159,10.978
			C879.02,433.812,878.444,433.802,877.868,433.792z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FCFCFC"
              d="M757.245,527.84c1.162,0,2.327-0.047,3.488,0.006
			c9.659,0.436,10.599,4.463,7.857,13.43c-0.518,1.689-1.992,2.965-3.836,2.434c-3.976-1.148-7.875,0.127-11.801-0.018
			c-5.046-0.184-6.415-1.783-6.332-7.295c0.104-6.965,1.367-8.605,6.637-8.617c1.329-0.002,2.657,0,3.986,0
			C757.245,527.799,757.245,527.819,757.245,527.84z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#383738"
              d="M805.762,557.711c5.23-2.229,10.813,0.021,16.281-1.291
			c-0.734-2.814-2.944-2.682-5.075-2.682c-6.977-0.002-13.953,0.043-20.928-0.039c-1.534-0.018-3.281,0.666-5.515-1.707
			c7.358,0,13.849,0,20.34,0c0.01-0.523,0.02-1.047,0.029-1.57c-5.033-1.26-10.251-0.412-15.368-0.566
			c-5.265-0.16-10.537-0.035-15.808-1.125c5.752,0,11.505,0.061,17.255-0.021c3.753-0.055,7.488-0.389,10.906-2.195
			c1.479,0.184,3.282-0.115,4.384,0.627c8.237,5.555,18.099,7.219,26.863,11.467C827.937,560.883,816.755,562.809,805.762,557.711z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#EBEBEB"
              d="M870.844,536.891c0.007-10.43,0.014-20.859,0.021-31.287
			c0.682-0.174,1.004,2.455,1.728,0.389c0.452-1.293,0.044-2.936-0.159-4.396c-0.305-2.191,0.367-3.617,2.719-3.719
			c2.152-0.092,2.838,1.27,2.783,3.207c-0.042,1.484-0.063,2.971-0.059,4.457c0.021,6.359-0.315,6.627-6.114,4.797
			C870.865,519.168,872.58,528.09,870.844,536.891z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#ABAAAA"
              d="M748.175,504.715c-1.899-14.139-1.401-28.297-1.228-42.452
			c0.409-0.008,0.818-0.015,1.228-0.022C748.175,476.399,748.175,490.557,748.175,504.715z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5B6069"
              d="M731.878,609.663c7.56-5.777,14.986-11.678,19.673-20.393
			c1.781,1.039,3.523,2.521,1.183,4.799c-6.869,6.678-13.886,13.203-20.844,19.789C731.886,612.459,731.882,611.061,731.878,609.663
			z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#8B8A8A"
              d="M766.75,453.743c-0.824,4.669,3.843,4.577,5.971,6.564
			c-0.171,0.358-0.204,0.554-0.32,0.651c-2.068,1.752-7.889-1.688-6.577,4.713c0.64,3.121-1.969,6.472,0.782,9.47
			c0.476,0.516,0.326,1.584,0.526,2.383c0.298,1.189,0.481,2.682-0.924,2.883c-1.726,0.246-1.215-1.549-1.454-2.553
			c-1.323-5.557-0.968-11.23-1.132-16.868c1.893-0.853,1.156-2.566,1.307-3.988C766.217,456.293,765.299,454.356,766.75,453.743z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5B595A"
              d="M738.686,519.118c12.515-1.371,23.818-2.535,35.09,0.355
			C762.505,518.44,751.239,522.792,738.686,519.118z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#8A95A6"
              d="M753.647,577.555c0.386,0.789,0.771,1.578,1.157,2.367
			c0.311,1.025-0.165,1.59-1.107,1.877c-2.923,0.053-4.805-1.047-4.758-4.287c3.229-1.984,0.354-4.732,0.792-7.123
			c0.995-5.416,2.577-6.604,7.771-4.594C751.703,569.043,750.371,573.108,753.647,577.555z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E7E7E6"
              d="M872.61,552.407c0.564-1.779-1.05-3.852,0.716-5.213
			c1.227-0.945,2.822-0.914,4.062,0.248c0.925,0.865,0.821,9.195-0.126,10.037c-1.065,0.945-2.383,1.314-3.603,0.354
			C871.755,556.333,873.326,554.104,872.61,552.407z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#BCBBBB"
              d="M895.029,484.606c0-11.373,0-22.744,0-34.116
			c0.198,0,0.397-0.001,0.596-0.002c0,11.417,0,22.833,0,34.251C895.427,484.694,895.228,484.649,895.029,484.606z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FCFCFC"
              d="M723.978,615.163c0-11.586,0-23.172,0-34.758
			c0.163,0,0.327-0.002,0.49-0.004c0,11.576,0,23.152,0,34.729C724.305,615.139,724.141,615.151,723.978,615.163z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#595D67"
              d="M801.833,566.799c1.294,5.318,1.701,10.615,0.092,15.861
			c-0.44,0.01-0.88,0.018-1.319,0.027c0-5.246,0-10.492-0.001-15.738C801.015,566.899,801.424,566.848,801.833,566.799z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E2E2E2"
              d="M729.063,612.237c0-9.035,0-18.068,0-27.104
			c0.2-0.002,0.4-0.004,0.6-0.004c0,9,0,18,0,27C729.464,612.167,729.264,612.202,729.063,612.237z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#BDBCBC"
              d="M877.55,516.276c0,8.537,0,17.074,0,25.611
			c-0.181,0.004-0.36,0.008-0.54,0.012c0-8.484,0-16.969,0-25.455C877.189,516.389,877.369,516.333,877.55,516.276z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#DDDCDC"
              d="M873.718,516.225c0,8.377,0,16.754,0,25.131
			c-0.196,0.002-0.394,0.006-0.591,0.01c0-8.301,0-16.6,0-24.9C873.324,516.385,873.521,516.305,873.718,516.225z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F4F4F4"
              d="M751.598,487.461c0,4.086,0,8.17,0,12.256
			c-0.16,0.006-0.321,0.014-0.481,0.021c0-4.047,0-8.092,0-12.137C751.276,487.555,751.438,487.508,751.598,487.461z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#EBEBEB"
              d="M751.375,479.534c-1.43-4.02-0.838-7.217,0-10.469
			C751.375,472.309,751.375,475.553,751.375,479.534z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#D6D5D5"
              d="M924.848,402.745c-4.174-5.485-10.068-9.396-13.887-15.219
			c-1.36-2.073-2.586-4.18-3.209-6.596c3.158-0.477,5.357,1.293,7.354,3.303c3.606,3.63,7.13,7.344,10.653,11.056
			C929.909,399.661,929.893,399.677,924.848,402.745z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#989696"
              d="M851.237,343.485c0-7.844,0-15.688,0-23.53
			c0.335-0.006,0.67-0.013,1.005-0.019c0,7.847,0,15.692,0,23.538C851.907,343.479,851.572,343.481,851.237,343.485z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#535051"
              d="M827.379,362.279c7.603,0,15.204,0,22.807,0
			c-0.001,0.328-0.002,0.656-0.004,0.984c-7.602,0-15.204,0-22.806,0C827.377,362.936,827.378,362.607,827.379,362.279z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F6F6F6"
              d="M848.663,343.64c-1.387-7.914-1.402-15.827,0.006-23.74
			C850.325,327.813,850.264,335.727,848.663,343.64z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#525050"
              d="M899.153,368.257c-6.247,0-12.493,0-18.74,0
			c0.002-0.329,0.003-0.657,0.005-0.986c6.246,0,12.493,0,18.739,0C899.156,367.6,899.154,367.928,899.153,368.257z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#585757"
              d="M819.844,368.875c5.305,0,10.61,0,15.915,0
			C830.503,371.479,825.193,371.306,819.844,368.875z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#7E7D7D"
              d="M770.743,442.833c3.196,0.133,6.417,0.188,9.627,2.644
			c-4.272,1.912-6.732-1.169-9.7-1.937C770.548,443.29,770.571,443.053,770.743,442.833z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#AAA9A9"
              d="M708.268,746.436c-1.849-5.02-1.886-51.498,0-56.973
			C708.268,709.006,708.268,727.102,708.268,746.436z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E2E2E2"
              d="M708.899,752.989c-1.523-1.699-3.18-3.396-0.034-5.125
			c1.135,0.223,2.942-0.828,3.112,1.275C712.145,751.211,710.939,752.514,708.899,752.989z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F6F6F7"
              d="M466.72,62.724c-0.021-9.787,0.031-19.575-0.087-29.361
			c-0.083-6.946,0.357-7.691,7.56-7.463c6.118,0.194,12.158-1.789,18.315-0.31c5.424,1.303,7.579,3.303,7.205,8.873
			c-1.034,15.406-1.03,30.817-0.86,46.238c0.02,1.738-0.219,3.313-2.031,4.135c-2.899,1.166-5.912,0.037-8.724,0.438
			c2.189,0.44,4.859-0.998,6.827,1.303c3.685,1.303,5.066,4.2,4.823,7.776c-1.056,15.55-1.151,31.11-0.861,46.683
			c0.057,3.033-1.56,4.579-4.573,4.656c-3.479,0.089-6.958,0.154-10.437,0.23c-2.404,1.708-5.155,2.226-7.999,2.028
			c-7.996-0.556-11.132-4.579-10.236-12.948c0.004-0.814,0.007-1.63,0.011-2.444c-0.757-3.175-0.573-6.377-0.298-9.587
			c-0.12-10.115,0.073-20.227,0.055-30.338c-0.005-2.699,0.082-5.405,3.149-6.665c3.205-0.593,6.271,0.865,8.59,0.345
			c-1.622-0.105-4.172-0.094-6.666-0.587c-4.616-0.893-5.988-4.135-6.058-8.327C464.46,72.393,463.894,67.292,466.72,62.724z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F6F6F7"
              d="M265.726,33.66c0.387-1.792,1.907-2.454,3.331-2.521
			c6.921-0.321,13.879-0.905,20.771-0.495c6.762,0.402,9.867,4.31,8.968,10.944c-1.815,13.388-1.075,26.8-1.072,40.21
			c0.001,5.938-0.902,6.853-6.919,6.9c-3.649,0.029-7.299,0.061-10.948,0.09c-1.693,1.634-3.849,1.324-5.913,1.422
			c-7.595-0.588-9.174-1.863-10.536-8.479c-0.106-2.37-0.107-4.743-0.067-7.118c0.367-2.308-0.611-4.956,1.698-6.774
			c1.719-3.121,0.731-6.496,0.781-9.735C265.943,49.958,265.776,41.809,265.726,33.66z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F6F6F7"
              d="M397.914,84.806c-1.746,3.012-4.596,1.757-7.1,1.836
			c-1.103-1.033-2.483-0.752-3.791-0.784c-7.246-0.178-7.246-0.174-7.246-7.67c0-10.476-0.125-20.953,0.067-31.426
			c0.067-3.695-0.91-7.23-0.943-10.873c-0.07-7.626,2.48-10.153,10.005-10.059c1.99,0.025,3.981-0.073,5.972-0.115
			c2.419-0.258,3.016,0.903,2.962,3.192c-0.122,5.265,0.035,10.536,0.079,15.806c1.645,1.814,1.189,4.07,1.26,6.195
			c0.327,9.813-0.623,19.651,0.563,29.45C399.96,82.174,399.142,83.577,397.914,84.806z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#A4A5A5"
              d="M294.792,149.747c3.623-0.072,7.347,1.208,10.826-0.803
			c3.187-1.888,6.447-0.21,9.672-0.215c5.03,0.887,10.056,1.562,15.005-0.41c0.362-0.343,0.741-0.663,1.119-0.982
			c1.82-2.304,1.236-4.938,1.247-7.571c0.019-4.824-1.501-9.949,2.213-14.216c2.986-0.842,4.855,1.721,7.341,2.408
			c0.4,0.251,0.709,0.593,0.933,1.009c0.094,0.446,0.116,0.901,0.074,1.352c1.368,3.121,4.637,3.858,6.336,2.075
			c1.781-1.868,6.967-2.521,4.377-7.225c-0.971-2.918-1.945-5.833-0.885-8.946c1.263-1.752,2.427-3.577,3.623-5.381
			c10.148,7.93,9.606,23.292-1.006,27.956c-1.043,0.459-2.241,0.977-3.317,0.885c-6.918-0.588-10.939,2.517-12.644,9.174
			c-1.902,7.427-7.868,9.667-14.463,9.798c-15.657,0.309-31.325,0.213-46.986,0.045c-6.494-0.07-11.268-3.409-14.324-9.151
			c3.732-1.355,7.493,0.184,11.26-0.115c5.437-0.432,10.935,0.436,16.364-0.652C292.741,148.543,293.848,148.972,294.792,149.747z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#A4A5A5"
              d="M414.799,149.592c3.59-0.539,3.102-3.442,3.12-5.782
			c0.063-7.812-0.105-15.625-0.11-23.438c-0.019-28.929-0.034-57.858,0.04-86.787c0.007-2.53-0.797-5.265,1.009-7.594
			c6.217,7.967,3.813,17.307,3.875,26.128c0.213,30.62,0.085,61.243,0.081,91.865c-0.002,9.822-2.965,12.799-12.731,12.808
			c-5.325,0.006-10.658-0.171-15.975,0.05c-5.259,0.218-9.413-1.478-12.377-5.883c5.487-2.706,11.51-1.997,17.302-2.53
			C404.289,147.944,409.503,149.648,414.799,149.592z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#CDCDCC"
              d="M429.806,90.229c-0.002-6.311-0.106-12.624,0.06-18.931
			c0.053-2.031,0.025-4.721,2.599-5.336c2.845-0.681,3.403,2.059,4.35,3.902c3.428,6.677,8.869,10.706,16.231,11.728
			c5.866,0.814,6.341,5.132,6.681,9.448c0.359,4.573-2.413,7.008-6.648,7.804c-7.707,1.448-13.35,5.517-16.666,12.686
			c-0.737,1.594-1.876,3.147-3.666,2.816c-2.315-0.428-2.837-2.74-2.881-4.688C429.716,103.185,429.808,96.706,429.806,90.229z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#AEAFAF"
              d="M517.944,145.911c4.872,1.835,9.942,0.662,14.915,0.936
			c1.902,0.104,2.876-1.261,3.132-3.094c1.626-9.244,5.981-13.228,15.316-14.009c5.95-0.498,7.566-2.252,8.278-8.396
			c0.48-4.151,1.403-8.285-1.778-11.853c-0.044-0.24-0.083-0.481-0.122-0.724c3.899-0.365,8.228,3.34,9.053,7.601
			c1.901,9.811-6.301,19.805-15.108,20.326c-5.657,0.334-8.713,3.134-9.944,8.576c-1.402,6.199-5.096,9.213-11.688,9.438
			c-7.777,0.266-15.602,0.817-23.313,0.75c-9.911-0.086-19.908-0.12-29.779-0.997c-3.335-0.296-7.424-2.62-9.117-6.688
			c1.285-1.466,2.979-1.21,4.564-0.916c4.852,0.9,9.694-0.098,14.537,0.058c6.878-0.756,13.869,0.941,20.694-0.957
			C511.031,144.735,514.482,144.22,517.944,145.911z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#CDCDCC"
              d="M372.784,94.521c0,3.498,0.037,6.996-0.013,10.493
			c-0.032,2.254-0.582,4.478-2.798,5.447c-2.153,0.942-4.482,0.242-6.047-1.304c-3.881-3.834-8.298-6.363-13.589-7.876
			c-3.468-0.991-4.29-4.757-4.068-7.836c0.211-2.931,0.494-6.542,4.492-7.548c5.513-1.387,10.639-3.325,15.031-7.298
			c2.79-2.522,6.512-0.335,6.876,3.938c0.338,3.968,0.072,7.987,0.072,11.983C372.753,94.521,372.769,94.521,372.784,94.521z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#9B9D9D"
              d="M332.881,93.676c1.967-1.152,0.814-2.978,0.886-4.48
			c0.371-7.854-1.049-15.86,2.1-23.466c4.031,1.368,8.275,1.637,12.418,2.403c-0.202,2.248-0.202,2.248-3.912,3.027
			c4.349,1.949,6.897-0.851,9.433-3.171c2.229-2.04-0.431-2.979-1.323-4.286c-0.409-2.026,0.07-3.968,0.531-5.914
			c1.226-1.712,0.691-4.781,3.84-5.021c3.095,1.741,6.614,11.02,5.735,15.121c-1.121,5.229-5.662,9.482-11.065,9.802
			c-2.513,0.149-4.922,0.598-7.298,1.216c-2.822,0.733-5.307,3.255-4.947,5.579c1.148,7.421-2.73,15.16,1.513,22.298
			c1.092,1.148,1.716,2.439,1.247,4.067c-2.07,1.716-4.399,3.013-6.823,4.16c-3.472-1.848-2.254-5.533-3.286-8.341
			c-0.311-3.111,0.867-6.158,0.398-9.279C332.235,96.111,331.717,94.767,332.881,93.676z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#B2B3B2"
              d="M264.682,74.622c-0.03,2.475-0.061,4.95-0.091,7.426
			c0.757,8.089,0.655,16.198,0.564,24.306c-0.03,2.686-0.093,5.4-2.86,6.984c-4.134,0.343-8.237,0.277-12.23-1.049
			c-1.737-2.452,0.111-3.098,1.915-3.653c1.662-0.513,3.642-0.17,4.838-1.879c5.869-3.549,6.937-8.77,5.479-15.057
			c-2.135-9.206-2.029-9.403-11.392-10.83c-5.851-0.892-9.076-4.509-11.033-9.504c-1.7-4.338-1.489-9.086-2.028-13.657
			c-0.417-3.532,2.56-5.098,4.825-6.981c1.429,1.056,2.045,2.677,2.897,4.137c2.26,4.461,1.445,8.82-0.423,13.141
			c2.56,4.653,3.392,4.956,10.646,3.842c-1.852-0.439-3.728-0.637-5.492-1.229c-0.996-0.334-2.431-0.753-1.178-2.356
			c2.859-1.297,5.917-0.816,8.895-1.017c0.625,0.215-0.162,0.047,0.475,0.07C263.261,67.487,264.977,70.188,264.682,74.622z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#B8B9B8"
              d="M456.717,101.8c4.938-3.212,7.567-7.292,5.229-13.388
			c-0.336-0.877-0.129-1.966-0.151-2.958c-0.128-5.548-1.784-7.292-7.311-7.687c-8.611-0.614-14.718-7.36-14.602-16.131
			c1.054-0.305,1.897,0.202,2.746,0.696c2.92,1.433,5.843,2.749,9.247,2.519c1.813-0.123,3.658,0.06,4.189,2.371
			c-0.189,1.229-1.522,1.541-2.084,2.538c1.271,0.43,2.557,0.477,3.82,0.584c7.474,0.634,7.475,0.623,7.473,8.231
			c1.483,9.64,1.12,19.302,0.432,28.96c-0.127,1.784-0.295,3.61-1.008,5.299c-1.482,2.194-2.378,0.674-3.047-0.618
			c-0.973-1.879-1.931-3.709-3.886-4.756c-1.146-1.222-3.104-1.341-3.993-2.915c-0.014-0.209-0.013-0.417,0.002-0.625
			C454.305,102.588,456.035,102.921,456.717,101.8z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#EEEEEF"
              d="M462.025,47.831c2.275,3.71,1.792,7.791,1.593,11.834
			c-0.547,2.805-1.897,4.985-4.693,6.055c-5.548-0.12-11.146-0.025-15.973-3.486c-2.624-5.145-2.309-9.834,0.892-13.536
			c5.228-3.582,10.916-3.814,16.843-2.29C461.132,46.882,461.579,47.356,462.025,47.831z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F3F3F4"
              d="M536.979,119.668c-0.111-3.573-0.222-7.146-0.333-10.718
			c1.332-2.31,3.431-3.153,5.984-3.123c3.727-0.013,7.498-0.572,11.103,0.91c0.649,0.359,1.298,0.719,1.947,1.079
			c1.579,1.822,1.018,3.698,0.148,5.584c-0.456,0.901-0.564,1.874-0.645,2.868c-0.738,9.082-2.14,10.262-11.442,9.696
			C540.298,125.148,538.213,122.864,536.979,119.668z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F4F4F5"
              d="M537.003,52.853c0.922-1.936,1.894-3.855,1.788-6.093
			c4.325-2.247,8.659-1.335,12.994-0.036c0.628,1.942,2.212,2.127,3.899,2.12c1.208,4.013-0.593,7.564-1.72,11.219
			c-1.592,4.445-5.945,6.275-12.466,4.718c-3.68-0.878-5.123-3.703-4.688-7.483C536.98,55.827,536.945,54.335,537.003,52.853z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#A7A7A7"
              d="M263.622,142.807c-2.781-2.434-6.085-3.279-9.678-3.007
			c-3.46,0.264-6.241-1.036-8.267-3.681c-4.764-6.22-8.386-12.841-6.941-21.168c0.354-2.042,1.368-3.286,3.078-4.173
			c1.25,0.83,2.235,1.921,3.104,3.133c3.324,4.531,1.396,9.748,1.923,14.645c-0.402,3.769,1.752,4.565,4.62,4.2
			c-1.386-0.027-3.341-0.153-3.561-2.1c-0.238-2.113,1.882-1.837,3.239-2.073c1.133-0.196,2.308-0.1,3.464-0.207
			c3.165,0.251,4.856-4.16,8.416-2.66C266.303,131.307,266.107,137.017,263.622,142.807z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F3F3F4"
              d="M258.922,67.87c-3.282,1.531-6.811,0.389-10.184,0.94
			c-1.769,0.705-3.313,0.188-4.757-0.876c1.972-4.251,2.325-8.606,0.849-13.081c-0.147-3.435,1.57-4.701,4.839-4.166
			c4.518,0.773,9.668,0.073,12.28,5.279C265.065,63.006,264.314,65.959,258.922,67.87z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#4C494A"
              d="M414.799,149.592c-1.479,2.03-3.751,1.438-5.49,1.114
			c-9.235-1.728-18.391,0.327-27.579,0.253c-2.595-0.542-4.035-1.884-4.015-4.788c0.13-18.493,0.168-36.986,0.236-55.479
			c0.571-0.318,1.142-0.637,1.713-0.955c1.475,4.287,1.611,8.785,1.566,13.207c-0.128,12.643,0.722,25.3-0.599,37.934
			c-0.48,4.59,1.013,6.053,5.528,6.348c0.498,0.032,0.999,0.021,1.498,0.028c8.702,0.151,8.873-0.287,8.771-8.815
			c-0.176-14.646-0.625-29.301,0.311-43.938c0.104-1.618-0.132-3.377,1.12-4.75c0.591,0.327,1.183,0.654,1.774,0.981
			c2.148,1.942,1.653,4.565,1.665,6.979c0.071,14.068-0.004,28.137,0.049,42.205c0.026,6.75,0.21,6.858,6.867,7.399
			c2.285,0.185,4.668,0.083,6.621,1.647C414.874,149.174,414.861,149.385,414.799,149.592z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2A2627"
              d="M263.622,142.807c-0.374-5.705,1.84-11.661-1.648-17.065
			c-1.72-4.311-1.609-8.63-0.057-12.954c2.218-1.608,1.89-4.03,1.902-6.274c0.038-6.579-0.049-13.158-0.022-19.736
			c0.006-1.6-0.435-3.317,0.794-4.729c0.55,5.418,4.147,6.862,8.888,6.814c0.368,1.746-1.113,1.956-2.096,2.123
			c-3.946,0.67-5.151,3.247-5.005,6.809c0.565,13.781-0.463,27.557-0.096,41.338c0.192,7.216,0.916,8.053,8.044,8.121
			c4.82,0.046,9.642-0.056,14.461,0.044c2.11,0.044,4.39-0.297,6.01,1.643c0.002,0.27,0,0.539-0.005,0.808
			c-10.295,1.223-20.585,1.761-30.859-0.199C263.829,147.301,263.726,145.054,263.622,142.807z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#A1A2A2"
              d="M335.868,54.807c-3.091-6.501-2.351-13.231-1.2-19.987
			c4.258,2.516,3.427,8.389,7.342,11.131c0.902,2.595,3.315,2.258,5.311,2.907c1.513,0.492,4.5,0.233,3.011,3.509
			C345.549,53.42,340.551,53.181,335.868,54.807z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#A2A2A2"
              d="M440.767,121.705c0.517-2.388,1.346-4.59,0.478-7.255
			c-2.396-7.356-0.134-9.98,7.736-9.544c0.333,1.02-0.006,1.977-0.26,2.943c-1.203,4.362-0.236,9.051-2,13.332
			C444.825,122.363,442.917,123.423,440.767,121.705z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#3B3838"
              d="M350.794,51.765c-1.722-2.125-4.183-1.641-6.46-1.858
			c-2.543-0.244-4.483-0.916-2.324-3.956c6.671-1.479,11.434,1.197,14.843,6.818c-1.447,1.347-1.117,3.761-2.936,4.885
			C351.266,56.545,351.088,54.124,350.794,51.765z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#403C3D"
              d="M341.923,110.596c-0.823-1.139-1.443-2.337-1.13-3.812
			c6.213-2.248,10.931,1.358,15.88,4.058c0.529,2.571-0.071,4.48-2.961,5.046C350.033,113.565,345.999,112.033,341.923,110.596z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#3A3637"
              d="M440.767,121.705c1.958-0.263,3.916-0.526,5.875-0.79
			c3.358,3.389,8.197,2.826,12.18,4.564c0.913,1.471,0.468,2.466-1.076,3.074c-6.623,3.074-11.077-1.967-16.2-4.406
			C440.791,123.788,439.969,122.854,440.767,121.705z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#3C3839"
              d="M258.922,67.87c5.285-2.88,2.729-7.755,3.028-11.903
			c1.626-2.517,1.804-5.475,3.088-8.274c0,6.716,0,13.432,0,20.147c-0.118,2.261-0.237,4.521-0.355,6.782
			c-1.725-1.513-0.425-3.786-1.369-5.631C261.846,68.691,259.2,72.394,258.922,67.87z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#3B3839"
              d="M249.669,50.688c-2.369,0.511-3.984,1.896-4.839,4.166
			c-1.765-0.828-2.591-2.148-2.161-4.126c3.148-1.297,5.382-4.349,9.028-4.692c2.085-0.196,4.905-0.937,5.123,1.992
			c0.226,3.03-2.717,1.83-4.445,2.009C251.43,50.134,250.404,49.753,249.669,50.688z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#272324"
              d="M243.645,113.627c-1.09-0.641-2.013-1.39-1.832-2.849
			c4.118-4.638,9.514-4.51,15.006-4.021c0.459,2.27-0.387,3.229-2.776,3.211c-1.583-0.012-3.712-0.892-4.431,1.648
			C248,113.405,245.877,113.68,243.645,113.627z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#636061"
              d="M457.746,128.554c0.339-0.99,0.679-1.98,1.018-2.971
			c0.205-3.103,2.256-4.521,4.912-5.392c2.24,0.095,2.364,1.978,2.936,3.482c-0.319,2.962-0.639,5.923-0.958,8.884
			C465.056,127.195,460.681,129.296,457.746,128.554z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#636061"
              d="M486.889,146.92c-6.303,1.708-12.755,0.112-19.102,0.858
			c-3.493-3.792-3.912-8.101-2.146-12.776c0.478,9.905,5.363,13.355,15.299,10.885c0.919-0.229,1.954,0.012,2.937,0.035
			C484.645,146.968,485.917,146.493,486.889,146.92z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#413D3E"
              d="M460.686,46.407c-0.797,0.14-1.606,0.449-2.389,0.391
			c-4.752-0.355-9.48-0.707-13.426,2.786c-1.022-0.617-2.428-0.584-3.091-1.814c2.794-2.976,6.646-2.504,10.163-3.178
			c1.336-0.256,3.473,1.008,3.738-1.688l0.239-0.271C458.233,42.979,460.062,43.932,460.686,46.407z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#858484"
              d="M441.781,47.77c1.246,0.237,2.503,0.457,3.091,1.814
			c-4.28,3.839-2.449,8.151-0.964,12.437c-0.331,1.364-1.066,1.559-2.151,0.745c-0.625-0.376-1.249-0.751-1.874-1.128
			C440.333,56.989,438.043,51.967,441.781,47.77z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#898989"
              d="M558.651,64.683c-0.626-1.299-1.251-2.597-1.877-3.896
			c0.006-3.773-2.038-7.935,2.064-10.936C562.761,55.317,562.71,59.324,558.651,64.683z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#3E3B3C"
              d="M551.786,46.724c-4.331,0.013-8.662,0.024-12.994,0.036
			c2.016-2.901,5.008-4.473,8.083-5.932c1.588,0.103,3.309-0.313,4.911,1.418c-2.073,1.314-4.61-0.007-7.152,2.096
			c2.516,0.135,4.321,0.296,6.129,0.31C552.611,44.666,552.627,45.449,551.786,46.724z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#CDCDCC"
              d="M558.838,49.852c-2.384,3.325,0.642,7.671-2.064,10.936
			c-0.854-0.563-1.68-1.229-2.809-0.725c-1.285-4.025,0.495-7.58,1.721-11.22C557.124,47.968,558.227,48.14,558.838,49.852z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#CDCDCC"
              d="M554.774,112.697c0.876-1.521,1.241-3.137,0.907-4.882
			c0.668,0.319,1.337,0.638,2.006,0.956c-0.002,0,0.12,0,0.12,0c1.837,4.166,2.776,8.299-0.447,12.278
			c-2.169,0.221-2.408-1.304-2.511-2.862C554.728,116.361,554.812,114.527,554.774,112.697z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#454243"
              d="M552.775,103.664c-3.458,0-5.833,0-9.43,0
			C546.843,100.742,549.236,100.331,552.775,103.664z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#262223"
              d="M447.801,106.704c0.393-0.6,0.786-1.198,1.179-1.798
			c1.598-0.12,3.195-0.24,4.793-0.36c1.242,0.883,4.283-1.491,3.977,2.196C454.425,108.918,451.109,108.916,447.801,106.704z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2F2B2C"
              d="M553.733,106.737c-3.709-0.206-7.551,1.208-11.103-0.91
			C546.504,104.013,550.236,103.949,553.733,106.737z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#302C2D"
              d="M551.799,67.737c-0.44,2.438-2.106,3.131-4.236,2.638
			c-0.955-0.222-3.05-0.386-1.391-2.392C547.962,65.944,549.898,67.222,551.799,67.737z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#383435"
              d="M456.717,101.8c0.492,2.752-1.598,1.919-2.942,2.12
			c-1.483-0.675-3.293,0.33-4.923-1.082C451.312,100.521,454.139,101.94,456.717,101.8z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#413D3E"
              d="M455.682,42.904c-2.104,0-4.209,0-7.141,0
			c3.037-2.723,5.226-3.258,7.378-0.271C455.921,42.633,455.682,42.904,455.682,42.904z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#CDCDCC"
              d="M551.799,67.737c-1.876,0.082-3.751,0.164-5.627,0.246
			c-0.512-0.089-1.024-0.179-1.536-0.269c2.055-3.139,4.658-1.476,7.146-0.814C551.785,67.18,551.791,67.458,551.799,67.737z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#545353"
              d="M148.871,40.413c-6.215,2.132-12.464,3.28-19.184-0.457
			C136.481,35.288,142.646,39.875,148.871,40.413z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#9E9F9F"
              d="M84.77,586.883c0.352-0.375,0.704-0.748,1.055-1.121
			c2.256-0.279,3.552,3.053,6.114,1.617c5.444-1.209,8.675-4.834,10.711-9.771c1.044-1.213,2.088-2.424,3.132-3.635
			c-0.625-0.838-0.574-1.619,0.192-2.344c1.951,0.061,3.901,0.119,5.853,0.18c2.576,2.363,5.461,2.549,8.721,1.576
			c3.589-1.072,7.192-1.457,9.738,2.285c0.356,0.721,0.498,1.484,0.45,2.283c-0.875,3.828-3.77,2.666-6.024,2.156
			c-4.573-1.035-8.903-1.137-12.93,1.652c-3.122,1.111-5.114,3.645-7.347,5.863C95.66,596.342,91.421,595.799,84.77,586.883z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#4E4C4D"
              d="M129.711,575.7c-3.198-2.447-6.544-1.904-10.073-0.748
			c-3.334,1.092-6.598,1.346-7.811-3.143c11.31,2.119,19.691-3.773,27.881-10.191c2.731,0.02,4.739,0.953,5.061,4.012
			c-4.575,1.18-7.434,4.898-11.056,7.486C132.417,574.042,131.508,575.553,129.711,575.7z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#535151"
              d="M105.974,571.629c-0.064,0.781-0.128,1.563-0.192,2.344
			c-5.042-2.061-8.986-5.643-12.875-9.297c-0.568-2.455,1.762-2.785,2.917-3.977C99.801,563.792,103.864,566.803,105.974,571.629z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F6F6F7"
              d="M294.797,148.939c-7.964-0.017-15.933-0.184-23.892,0.013
			c-4.036,0.099-5.851-1.464-5.805-5.451c0.146-12.606,0.281-25.214,0.352-37.821c0.018-3.134-0.158-6.273-0.36-9.402
			c-0.287-4.458,1.182-7.123,6.198-6.568c0.785,0.087,1.671-0.023,2.189-0.847c2.125-0.024,4.251-0.049,6.376-0.073
			c3.864,2.162,8.039,0.894,12.04,0.88c4.072-0.015,5.841,1.552,5.834,5.525c-0.025,16.15-0.027,32.301,0.006,48.451
			C297.741,146.02,297.018,147.863,294.797,148.939z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F6F6F7"
              d="M332.881,93.676c0.189,1.029,0.378,2.059,0.567,3.088
			c-2.171,1.618-3.863,0.113-5.255-1.132c-3.306-2.957-6.811-5.127-11.468-4.789c-0.805,0.059-1.574-0.438-2.101-1.142
			c-2.685-1.856-5.864-1.893-8.809-1.66c-4.155,0.329-5.177-1.449-5.124-5.151c0.112-7.799,0.089-15.604-0.1-23.402
			c-0.156-6.455-0.759-12.898-0.928-19.354c-0.176-6.713,1.171-8.166,8.025-9.553c3.72-2.677,7.424,0.429,11.138,0.029
			c0.496,0.05,0.99,0.136,1.486,0.146c13.948,0.281,12.433-1.642,12.467,12.635c0.031,12.971-0.072,25.942,0.054,38.911
			c0.039,3.987-0.719,6.897-7.128,6.384C329.576,90.212,331.909,91.114,332.881,93.676z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F6F6F7"
              d="M314.625,89.701c5.636-0.634,10.684,0.4,14.555,4.98
			c1.087,1.286,2.39,2.326,4.27,2.082c0.897,3.45-0.562,6.793-0.469,10.207c2.786,12.207-0.528,24.489,0.434,36.725
			c0.144,1.833-0.388,3.633-1.718,5.064l0.099-0.086c-5.192,1.753-10.556,1.201-15.88,1.186c-3.433-0.306-6.865-0.61-10.298-0.915
			c-4.068-0.769-6.228-3.381-5.925-7.357c1.213-15.961,0.848-31.943,0.946-47.92c0.016-2.658,1.278-3.952,4-3.911
			C307.966,89.806,311.296,89.726,314.625,89.701z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F6F6F7"
              d="M397.86,89.749c-0.012,10.27-0.134,20.539,0.003,30.807
			c0.098,7.3-0.49,14.604,0.427,21.91c0.591,4.71-1.167,6.336-5.863,6.284c-1.996-0.022-3.991-0.079-5.986-0.074
			c-6.741,0.016-7.681-0.663-7.189-7.215c0.999-13.29,0.23-26.585,0.504-39.874c0.081-3.95-1.086-7.893-0.09-11.851
			C383.117,87.32,394.329,87.328,397.86,89.749z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F6F6F7"
              d="M399.663,27.747c0.721-2.997,2.621-3.922,5.562-3.359
			c1.618,0.31,3.307,0.477,4.949,0.398c6.484-0.312,6.54-0.311,6.548,6.361c0.021,16.622-0.069,33.246,0.112,49.867
			c0.05,4.546-1.542,6.835-6.102,5.749c-3.657-0.871-8.05,2.419-11.036-1.966c-1.693-13.04-1.02-26.141-0.614-39.198
			C399.268,39.655,398.661,33.666,399.663,27.747z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F6F6F7"
              d="M414.836,148.961c-4.345-0.097-8.729,0.121-13.021-0.406
			c-2.602-0.319-2.04-3.062-2.044-4.971c-0.034-16.457-0.014-32.913-0.02-49.37c0-1.161-0.077-2.322-0.117-3.483
			c0.6-2.599,2.335-3.41,4.816-3.078c1.148,0.153,2.3,0.359,3.454,0.386c8.86,0.206,8.836,0.194,8.823,9.336
			c-0.021,15.086-0.003,30.172-0.01,45.258C416.716,144.921,417.095,147.336,414.836,148.961z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F1F1F2"
              d="M350.794,51.765c2.208,1.344,1.172,4.409,3.124,5.889
			c-0.125,2.115,0.024,4.272-0.955,6.258c0.689,3.413-0.659,5.067-4.181,4.848c-4.432-0.471-9.405,1.368-12.915-3.029
			c0-3.642,0-7.282,0.001-10.923C340.044,49.872,345.691,52.152,350.794,51.765z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#524F50"
              d="M399.663,27.747c0.011,19.018,0.022,38.034,0.034,57.051
			c-0.595,0.003-1.189,0.006-1.783,0.008c0.001-13.364,0.003-26.729,0.005-40.093c1.818-3.448,0.406-7.155,0.858-10.713
			C399.042,31.911,398.099,29.652,399.663,27.747z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5E5C5D"
              d="M318.828,30.61c-3.713-0.01-7.425-0.02-11.138-0.029
			c-1.104-1.55-3.332-0.133-4.695-1.99C308.787,26.361,313.79,28.65,318.828,30.61z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F3F3F4"
              d="M127.977,457.707c-0.026-3.63-0.053-7.259-0.079-10.888
			c0.296-0.698,0.593-1.396,0.889-2.094c4.253-0.015,8.521,0.192,12.753-0.112c3.44-0.248,4.767,0.826,4.389,4.33
			c-0.244,2.257,0.037,4.571,0.083,6.86c0.917,2.676-0.832,4.46-2.772,4.903c-4.138,0.946-8.494,1.772-12.462-0.915
			C129.844,459.096,128.911,458.401,127.977,457.707z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#474445"
              d="M130.777,459.792c6.884,2.463,13.977,1.271,15.234-3.988
			c2.079-2.787-0.829-6.598,1.899-9.252c4.787,5.641,4.71,8.613-0.315,13.462c-0.325,0.314-0.669,0.758-0.724,1.177
			c-0.23,1.766,0.186,4.414-2.135,4.439c-4.081,0.044-8.322,2.48-12.773-0.319c3.571-2.143,7.542,0.885,10.938-1.994
			c-3.368-1.996-6.81-1.578-10.124-1.917C132.073,461.327,129.441,462.724,130.777,459.792z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F5F5F6"
              d="M106.975,387.813c1.659-4.514,0.429-9.231,0.979-13.823
			c5.098-0.75,10.211-1.342,15.374-1.215c11.298,0.277,22.573-0.139,33.853-0.815c6.99-0.42,7.765,0.501,7.743,7.249
			c-0.027,8.161-0.093,16.323-0.143,24.484c-0.833,0.904-1.913,1.37-3.095,1.414c-15.716,0.582-31.349,3.177-47.138,1.989
			c-5.711-0.43-7.547-2.038-8.243-7.158c0.691-3.558,0.615-7.144,0.296-10.735C106.316,388.628,106.442,388.165,106.975,387.813z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F5F5F6"
              d="M164.795,407.734c1.94,9.689,1.465,19.418,0.417,29.146
			c-0.175,1.622-1.116,2.564-3.031,2.561c-17.488-0.032-34.977,0.021-52.464,0c-0.593-0.001-1.185-0.524-1.777-0.804
			c-1.212-0.963-1.116-2.347-1.124-3.662c-0.04-7.315-0.102-14.632,0.019-21.947c0.072-4.332,1.768-6.249,6.013-6.188
			c15.458,0.222,30.897-0.601,46.348-0.643C161.167,406.192,163.233,406.122,164.795,407.734z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2C292A"
              d="M164.795,407.734c-17.138,0.019-34.277,0.035-51.416,0.054
			c-3.926,0.004-5.688,1.891-5.609,5.893c0.166,8.317,0.125,16.637,0.169,24.957c-0.717,1.164-1.453,1.534-2.232,0.052
			c0.022-16.975,0.043-33.951,0.065-50.926c0.4,0.017,0.801,0.033,1.202,0.05c0.141,0.217,0.141,0.433-0.002,0.648
			c0.502,3.545,0.46,7.092,0.016,10.639c-0.404,4.201,2.002,6.402,5.721,6.229c11.549-0.535,23.115,1.482,34.697-1.071
			c5.562-1.228,11.567-0.44,17.372-0.565C165.834,405.036,165.727,406.384,164.795,407.734z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2F2C2D"
              d="M144.984,354.616c1.283-1.869,3-0.733,4.564-0.66
			c0.179,3.491,1.313,7.146-1.744,10.078C146.177,361.102,149.453,356.699,144.984,354.616z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#262223"
              d="M142.841,443.335c-4.051,0.169-8.172,1.048-13.366,0.1
			C134.583,442.002,138.654,441.856,142.841,443.335z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#B2B2B2"
              d="M157.944,578.752c2.649,5.971,1.975,8.355-3.678,13.008
			c-5.069,4.172-10.491,5.053-14.487,2.213c-0.56-0.398-0.674-1.424-0.994-2.158c3.356-0.549,6.135-1.883,7.311-5.393
			c1.606-1.621,2.959-3.576,5.38-4.135C154.603,582.891,155.752,579.864,157.944,578.752z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#B2B2B2"
              d="M111.781,581.762c2.368-3.799,5.717-4.209,9.931-3.713
			c2.562,0.301,5.628,3.775,8.099-0.311c2.917,3.172,7.882,4.166,9.4,8.836c-0.079,1.285-0.365,2.471-1.566,3.207
			c-2.26-0.17-3.828-1.834-4.583-3.443c-1.992-4.244-5.408-4.689-9.367-4.588C119.725,581.852,115.752,581.766,111.781,581.762z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#666566"
              d="M137.644,589.782c0.176-1.121-0.228-2.463,1.192-3.105
			c2.659-1.271,5.333-3.252,7.883,0.184c-0.861,4.508-2.967,7.021-7.934,4.955C138.405,591.137,138.024,590.459,137.644,589.782z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#656364"
              d="M157.944,578.752c-1.53,2.234-1.885,6.352-6.314,3.936
			c-0.611-2.533-0.1-5.025,0.258-7.527c0.175-0.496,0.473-0.906,0.887-1.23C154.498,575.538,156.221,577.145,157.944,578.752z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#797879"
              d="M152.775,573.93c0.069,0.246,0.086,0.496,0.054,0.748
			c-4.127-0.477-6.387-2.674-6.389-6.945c1.105,0.379,2.21,0.756,3.315,1.135C151.322,570.221,153.398,571.27,152.775,573.93z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#EFEFF0"
              d="M146.44,567.733c1.375,3.01,3.342,5.475,6.389,6.945
			c-0.882,2.598,0.943,5.602-1.198,8.01c-1.637,1.391-3.274,2.781-4.911,4.172c-2.573-2.418-5.281,0.988-7.883-0.184
			c-2.31-3.684-7.785-4.174-9.026-8.938c-0.033-0.68-0.066-1.359-0.099-2.039c3.058-2.938,6.127-5.863,9.17-8.816
			c1.713-1.664,3.719-1.805,5.887-1.254C145.326,566.331,145.883,567.032,146.44,567.733z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#717071"
              d="M151.995,524.592c0.012-0.564,0.023-1.131,0.036-1.695
			c1.212-1.543,0.273-4.609,3.393-4.801c0.775-0.047,1.225,0.322,1.306,1.107c0.294,2.855-1.667,4.102-3.76,5.234
			C152.697,524.586,152.323,524.545,151.995,524.592z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#B8B9B8"
              d="M91.793,587.764c-2.163,2.205-2.646,2.043-5.968-2.002
			c-0.439-2.875-4.843-4.303-2.929-8.035c3.261,1.566,5.085,5.301,8.96,5.943C91.263,585.026,92.569,586.413,91.793,587.764z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#6C6A6B"
              d="M91.793,587.764c0.304-1.359-4.396-2.797,0.063-4.094
			c2.445,1.875,4.343,2.43,6.057-1.156c0.93-1.947,3.117-3.291,4.738-4.906c1.871,1.176,1.137,2.502,0.11,3.701
			c-2.331,2.725-5.873,4.096-7.758,7.469C94.073,590.442,92.958,587.961,91.793,587.764z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E9E9E9"
              d="M91.242,31.976c1.948,2.026,1.984,3.807,0.511,5.587
			c-0.422,0.51-1.225,0.762-1.656,0.068C88.795,35.537,90.361,33.924,91.242,31.976z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#626161"
              d="M419.213,183.621c-2.516,0.183-2.45-1.599-2.539-3.263
			c-0.097-1.805,0.612-2.858,2.563-2.591c1.807,0.249,3.185,1.349,3.189,3.115C422.432,182.676,421.247,183.872,419.213,183.621z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F7E9B4"
              d="M1035.946,272.768c0,3.653,0.021,7.307-0.006,10.959
			c-0.026,3.632-2.243,4.993-5.496,4.91c-8.528-0.217-8.473-0.261-8.359-9.138c0.067-5.313,0.133-10.642-0.179-15.94
			c-0.252-4.27,1.222-6.131,5.632-5.997c8.467,0.257,8.46,0.141,8.254,8.729c-0.051,2.158-0.008,4.318-0.008,6.478
			C1035.839,272.768,1035.893,272.768,1035.946,272.768z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F3F3F4"
              d="M1003.204,264.599c10.751,0,10.751,0,10.76,10.742
			c0.009,11.308-0.046,11.365-11.364,11.359c-1.829-0.001-3.662,0.019-5.487-0.079c-3.164-0.17-4.811-2.05-5.161-5.049
			c-0.211-1.81-0.136-3.655-0.153-5.485C991.681,264.6,991.685,264.6,1003.204,264.599z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#CBCBCA"
              d="M991.79,421.523c-0.003-2.331,0.11-4.668-0.027-6.991
			c-0.229-3.85,1.008-5.856,5.328-5.862c4.336-0.006,5.492,2.181,5.466,5.891c-0.034,4.995-0.089,9.99,0.016,14.982
			c0.085,4.068-2.175,5.079-5.718,5.018c-3.437-0.06-5.346-1.277-5.096-5.05C991.936,426.859,991.793,424.186,991.79,421.523z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#CCCCCB"
              d="M991.831,464.375c-0.001-1.83-0.003-3.66-0.001-5.49
			c0.009-6.699,0.098-6.576,6.596-6.374c3.173,0.099,3.968,1.54,4.138,4.227c0.323,5.116-0.659,10.195-0.14,15.348
			c0.335,3.311-2.283,4.643-5.261,4.551c-2.94-0.09-5.739-0.627-5.371-4.777C992.011,469.381,991.832,466.87,991.831,464.375z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#CCCCCB"
              d="M996.675,390.77c-3.619,0.248-5.47-1.325-4.955-5.295
			c0.473-3.642,0.327-7.239-0.576-10.849c-0.732-2.922,0.845-5.078,3.1-6.765c1.871-1.4,4.144-1.9,6.097-0.825
			c2.542,1.399,2.208,4.048,2.018,6.63c-0.263,3.551-0.443,7.168-0.055,10.69C1002.82,389.037,1001.58,390.784,996.675,390.77z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#A5A4A4"
              d="M1004.144,474.997c0-6.991,0-13.982,0-20.974
			c0.314,0.003,0.629,0.006,0.944,0.01c0,6.991,0,13.983,0,20.974C1004.772,475.004,1004.458,475,1004.144,474.997z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#BBBABA"
              d="M1004.671,410.221c0,7.486,0,14.971,0,22.457
			c-0.173,0.003-0.347,0.005-0.521,0.008c0-7.485,0-14.971,0-22.456C1004.324,410.227,1004.498,410.224,1004.671,410.221z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#BBBABA"
              d="M1004.669,368.418c0,6.862,0,13.725,0,20.587
			c-0.198,0-0.397,0-0.597,0c0-6.862,0-13.725,0-20.587C1004.271,368.418,1004.471,368.418,1004.669,368.418z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#EAEAEB"
              d="M1023.834,585.594c-0.253-2.377-2.387-3.381-3.655-5.018
			c-1.069-1.377-2.315-2.557-1.856-4.705c0.829-3.887-1.587-0.99-2.571-1.107c-1.893-2.775-0.504-4.746,1.739-6.281
			c2.157-1.479,4.246-1.346,6.311,0.57c1.797,1.668,3.695,3.281,5.841,4.541c2.085-1.609,0.817-7.385,6.056-4.719
			c-0.747,7.189,0.11,14.369,0.134,21.555c0.008,2.188-0.471,3.611-3.01,3.359c-1.492-1.049-2.899-2.16-3.123-4.16
			c-1.244-2.412,0.965-4.697,0.26-7.057c-0.162,1.766,0.242,3.926-1.98,4.709C1026.292,587.875,1024.729,587.352,1023.834,585.594z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#6E6C6D"
              d="M1035.697,568.875c-5.631-0.172-3.512,4.254-4.277,7.375
			c-3.382-2.047-6.978-3.27-9.21-7.109c-1.309-2.25-3.743-0.42-5.333,0.994c-1.402,1.248-1.076,3.004-1.126,4.629
			c-1.677-0.693-3.354-1.389-5.031-2.084c-2.955-1.846-4.48-4.701-5.57-7.867c-0.001-0.494,0.034-1,0.241-1.445
			c2.416-5.205,2.462-7.662,0.23-12.51c-0.132-0.287-0.046-0.637,0.056-0.961c1.09,0.016,2.18,0.031,3.27,0.045
			c2.012,1.967,4.986,2.564,6.813,4.793c0.729,0.736,1.457,1.475,2.186,2.213c3.099,4.447,4.659-0.146,6.827-1.162
			c1.705,0.969,4.225,1.131,3.981,4.025c-0.373,1.959-6.193,3.104-0.882,5.91c0.662,0.039,1.325,0.076,1.988,0.115
			c2.432,2.066,3.615-2.189,5.844-1.154C1035.702,566.081,1035.699,567.477,1035.697,568.875z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#D9D8D9"
              d="M1005.677,549.897c1.334,3.027,5.799,4.225,5.766,7.463
			c-0.031,3.111-4.922,3.898-5.67,7.326c-0.545,0.695-1.158,0.678-1.819,0.178c-3.397-2.021-5.729-4.65-5.116-8.963
			c1.72-2.596,4.977,0.064,7.736-2.559c-3.021,0.213-5.146,0.389-7.274,0.504c-2.157,0.115-3.817-1.045-3.641-3.061
			c0.191-2.182,2.376-0.887,3.612-1.002C1001.385,549.588,1003.54,549.836,1005.677,549.897z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E7E7E8"
              d="M1035.704,564.682c-1.5,1.176-2.978,2.383-4.511,3.514
			c-0.71,0.523-1.534,0.551-2.062-0.285c-0.583-0.924,0.512-1.369,0.729-2.074c2.989-1.855,2.924-4.461,1.84-7.348
			c-1.688-4.486-4.774-6.703-9.812-5.66c1.066-2.873,1.062-2.98,2.338-3.047c1.828-0.096,3.666,0.035,5.497-0.02
			c4.457-0.137,6.398,1.988,6.115,6.424C1035.659,559.01,1035.74,561.85,1035.704,564.682z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#57544E"
              d="M1083.169,593.309c-7.884,0-15.768,0-23.65,0
			c0.001-0.334,0.003-0.666,0.004-1c7.883,0,15.766,0,23.648,0C1083.171,592.643,1083.17,592.975,1083.169,593.309z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#CDCDCE"
              d="M1015.76,554.735c-2.62-1.102-6.187-0.857-6.813-4.793
			c1.629-0.061,3.27-0.27,4.883-0.141c1.716,0.135,4.198-1.012,4.994,0.617C1019.979,552.782,1016.39,552.946,1015.76,554.735z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#C4C4C4"
              d="M1024.772,555.786c0.214,2.219-1.661,3.207-2.967,4.113
			c-3.8,2.635-3.797-0.338-3.86-2.951C1020.312,557.092,1022.124,553.983,1024.772,555.786z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#D9D8D9"
              d="M1027.872,565.721c-1.353,1.166-2.741,1.104-3.711-0.328
			c-0.885-1.307-0.049-2.424,0.99-3.35c1.073-0.957,1.973-2.18,3.603-2.232C1029.363,561.917,1027.97,563.723,1027.872,565.721z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F4F4F5"
              d="M1003.805,125.746c6.32-2.024,12.815-0.837,19.209-0.856
			c32.126-0.098,64.252-0.011,96.378,0.131c15.462,0.067,30.924,0.397,46.386,0.605c1.165,0.016,2.333,0.046,3.495-0.01
			c10.887-0.526,10.8-0.533,10.593,10.612c-0.096,5.146,0.135,10.303,0.375,15.446c0.221,4.701-1.453,6.892-6.154,7.034
			c-5.322,0.161-10.653,0.012-15.979,0.013c-51.438,0.013-102.877,0.028-154.316,0.043
			C1002.997,147.758,1002.979,136.752,1003.805,125.746z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#EFEFF0"
              d="M1154.81,178.458c0.016-3.799,0.316-7.624-0.032-11.389
			c-0.364-3.92,1.241-5.063,4.782-4.514c0.322,0.051,0.663-0.025,0.99,0.012c4.176,0.467,10.26-2.054,12.116,1.168
			c2.304,3.996,1.331,9.891,0.122,14.93C1166.769,180.891,1160.771,181.3,1154.81,178.458z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#EFEFF0"
              d="M1131.793,178.665c-1.374-4.575-0.398-9.26-0.633-13.888
			c-0.071-1.404,0.685-2.156,2.116-2.179c0.5-0.008,1.005-0.075,1.499-0.027c4.882,0.473,11.572-2.277,14.184,1.243
			c2.421,3.266,0.703,9.602,0.808,14.585C1143.818,181.386,1137.817,180.854,1131.793,178.665z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#EEEEEF"
              d="M1154.806,121.597c0.101-4.316,0.313-8.634,0.26-12.948
			c-0.03-2.53,0.644-3.784,3.42-3.219c1.122,0.229,2.318,0.077,3.479,0.126c11.361,0.475,11.353,0.476,11.509,11.894
			c0.019,1.316,0.204,2.63,0.312,3.944C1167.482,123.693,1161.152,123.386,1154.806,121.597z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F2F2F3"
              d="M1077.776,161.998c0.363,4.424,1.396,8.813,0.515,13.298
			c-0.409,2.078-0.835,3.638-3.659,3.433c-3.605-0.263-7.252,0.043-10.881,0.104c-2.471,0.039-3.403-1.459-3.971-3.631
			c-0.92-3.519-0.159-6.942,0.428-10.344c0.332-1.927,1.869-3.291,3.882-3.347C1068.654,161.383,1073.265,160.503,1077.776,161.998z
			"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F2F2F3"
              d="M1047.789,161.973c-0.499,5.645,1.465,11.442-1.065,16.959
			c-2.967,1.781-6.373,1.829-9.6,1.913c-5.086,0.131-8.852-4.404-8.54-10.229c0.379-7.104,2.68-9.907,8.071-9.479
			C1040.353,161.431,1044.142,160.651,1047.789,161.973z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#EFEFF0"
              d="M1130.795,121.391c0.972-4.216,1.123-8.512,1.029-12.8
			c-0.052-2.396,0.331-3.527,3.191-3.198c3.268,0.377,6.624,0.273,9.918,0.044c3.576-0.25,4.718,1.57,4.744,4.702
			c0.032,3.806,0.061,7.611,0.091,11.416C1143.427,123.427,1137.101,123.681,1130.795,121.391z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F2F2F3"
              d="M1107.669,173.858c0.008-2.389,0.016-4.777,0.023-7.166
			c-0.884-2.934,0.15-5.061,3.175-5.281c4.63-0.338,9.337-1.128,13.925,0.468c-0.379,4.221,0.924,8.33,0.337,12.676
			c-0.472,3.495-1.498,4.483-4.753,4.146c-1.915-0.198-3.955-0.312-5.699,0.933C1109.712,181.079,1107.421,179.191,1107.669,173.858
			z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F1F1F1"
              d="M1006.935,107.899c1.514-3.729,4.883-2.139,7.391-2.293
			c10.69-0.659,10.697-0.536,10.42,10.254c-0.052,1.999-0.023,4.011-1.009,5.848c-4.405,2.009-8.978,1.19-13.447,0.521
			c-2.294-0.344-3.564-2.403-3.75-4.684C1006.278,114.332,1005.733,111.068,1006.935,107.899z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F1F0F1"
              d="M1077.693,121.688c-2.812,0.048-5.625,0.187-8.435,0.127
			c-7.152-0.153-8.551-1.649-8.49-8.929c0.02-2.316,0.131-4.633,0.201-6.948c4.319-0.154,8.656-0.123,12.953-0.522
			c3.132-0.291,4.104,1.197,4.396,3.886C1078.774,113.471,1078.476,117.588,1077.693,121.688z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F3F3F4"
              d="M1091.631,161.969c0.601,0.245,1.185,0.554,1.806,0.724
			c2.528,0.694,5.948-1.121,7.539,0.836c1.955,2.404,0.624,6.032,0.785,9.125c0.104,1.97,0.014,3.949,0.01,5.925
			c-4.805,2.84-9.863,1.739-14.816,0.725c-1.83-0.374-2.889-2.085-3.083-4.094c-0.271-2.78-0.46-5.546-0.074-8.346
			C1084.455,162.086,1086.366,160.846,1091.631,161.969z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F2F2F3"
              d="M1047.677,121.667c-3.29,0.058-6.584,0.235-9.871,0.146
			c-5.541-0.151-7.037-1.663-7.095-7.041c-0.031-2.96,0.13-5.923,0.204-8.884c0.324-0.056,0.648-0.146,0.975-0.16
			C1048.536,105.047,1048.536,105.048,1047.677,121.667z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F4F4F5"
              d="M1124.674,121.661c-0.165,0.018-0.33,0.042-0.495,0.053
			c-16.497,1.01-16.816,0.699-16.236-15.804c0.324-0.056,0.646-0.146,0.974-0.161
			C1125.593,104.939,1125.593,104.939,1124.674,121.661z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F3F3F4"
              d="M1084.798,105.766c4.313-0.031,8.628,0.005,12.938-0.123
			c2.304-0.068,3.658,0.155,3.358,3.16c-0.427,4.272-0.268,8.603-0.359,12.908c-4.142,1.947-8.483,0.939-12.749,0.782
			c-1.34-0.05-2.46-0.982-3.284-2.092C1082.739,117.759,1082.808,109.058,1084.798,105.766z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#AFA685"
              d="M1018.613,183.502c1.015-2.25,1.971-4.529,3.056-6.745
			c2.236-4.562,0.585-7.585-3.718-9.535c-2.688-1.218-5.436-2.308-8.156-3.455c1.952-3.335,4.399-4.681,8.516-3.453
			c3.36,1.001,7.216-2.262,10.64,0.658c1.224,1.232,1.037,2.804,0.794,4.245c-0.701,4.16-0.524,8.26,0.334,12.373
			c0.389,1.862,0.065,3.646-1.231,5.149c-3.297,0.074-4.259,4.657-7.89,4.086C1019.519,186.181,1018.804,185.027,1018.613,183.502z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2F2C2B"
              d="M1028.847,182.739c0.034-7.256,0.068-14.512,0.104-21.768
			c5.485-0.205,10.948-1.456,16.463-0.333c0.952,0.193,1.978,0.186,2.376,1.334c-3.989-0.06-7.979-0.082-11.968-0.19
			c-2.978-0.081-4.57,1.253-5.18,4.208c-0.513,2.488-0.734,4.953-0.338,7.428c0.764,4.768,3.235,6.71,7.984,5.962
			c2.824-0.444,5.612-0.525,8.436-0.448c0.694,0.271,1.389,0.541,2.082,0.812c0.699,2.573-0.722,3.441-2.94,4.033
			C1040.051,185.328,1034.436,184.456,1028.847,182.739z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2C2829"
              d="M1091.631,161.969c-6.215,0.336-7.69,2.21-7.401,9.404
			c0.26,6.447,1.684,7.742,8.137,7.481c3.134-0.126,6.27-0.187,9.404-0.277c0.074,0.914-0.011,1.757-0.985,2.2
			c-4.256,0.901-8.528,0.543-12.802,0.177c-0.726-0.048-1.485,0.199-1.555,0.896c-0.131,1.305,1.024,0.989,1.726,0.991
			c4.812,0.015,9.624-0.026,14.436-0.05c-6.581,2.167-13.15,2.077-19.706-0.113c-0.009-6.148,0.101-12.3-0.078-18.442
			c-0.083-2.847,0.914-3.798,3.721-3.693c4.766,0.177,9.543,0.049,15.584,0.049C1097.884,162.867,1094.667,161.364,1091.631,161.969
			z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2F2B2B"
              d="M1077.776,161.998c-3.155-0.064-6.326,0.047-9.463-0.233
			c-4.952-0.442-7.346,1.467-7.77,6.6c-0.337,4.084-1.136,7.963,3.207,10.468c-0.597,0.696-1.192,1.394-1.789,2.09
			c0.239,0.197,0.306,0.376,0.203,0.536c-0.098,0.151-0.191,0.227-0.284,0.227c2.291,2.442,5.214,0.653,7.802,1.146
			c2.992,0.57,6.208-0.978,9.079,0.916l0.01-0.002c-4.315,1.307-8.802-0.157-13.139,0.811c-4.7,0.563-6.774-1.805-6.81-6.252
			c-0.037-4.816,0.052-9.634,0.085-14.451c0.871-2.246,2.059-3.875,4.924-3.296C1068.426,161.485,1073.402,158.807,1077.776,161.998
			z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2B2727"
              d="M1107.669,173.858c0.323,4.367,3.205,5.63,7.008,5.775
			c2.736-0.031,5.475,0.012,8.206-0.116c2.404-0.113,3.189,1.034,2.901,3.237c-6.805,2.383-13.521,2.969-20.018-0.977
			C1106.225,179.096,1105.669,176.17,1107.669,173.858z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2E2A2A"
              d="M1084.798,105.766c-0.209,0.623-0.542,1.232-0.61,1.87
			c-1.442,13.445,0.487,15.081,16.548,14.075c-0.085,2.375-2.091,1.864-3.371,2.025c-14.008,1.765-14.61,1.196-14.485-12.893
			c0.027-2.996-0.012-5.992-0.02-8.988l0.008-0.013C1084.233,102.795,1084.575,104.251,1084.798,105.766z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#363333"
              d="M1006.935,107.899c-0.114,13.89,0.064,14.072,13.851,13.962
			c0.984-0.008,1.968-0.101,2.951-0.153c1.663,1.582,0.372,1.904-0.922,1.923c-4.79,0.067-9.583-0.013-14.373,0.058
			c-2.71,0.039-2.729-1.709-2.72-3.644c0.024-6.11,0.008-12.22,0.008-18.33l0.004-0.009c0.428,0.024,0.855,0.049,1.284,0.073
			c0.215,0.199,0.284,0.396,0.208,0.589c-0.075,0.192-0.15,0.288-0.224,0.288C1006.979,104.404,1006.957,106.151,1006.935,107.899z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#363333"
              d="M1154.806,121.597c6.328,0.085,12.659,0.467,18.979-0.202
			c-0.418,1.145-1.013,2.198-2.384,2.21c-4.983,0.042-9.967,0.037-14.949-0.006C1155.231,123.588,1154.757,122.757,1154.806,121.597
			z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#B0AFAF"
              d="M1154.778,182.643c0.002-0.601,0.004-1.201,0.006-1.802
			c5.975-1.764,11.962-1.603,17.958-0.116c1.082,0.622,0.197,1.302,0.058,1.96C1166.79,183.568,1160.783,183.669,1154.778,182.643z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#B0AFAF"
              d="M1149.799,180.912c0.002,0.556,0.004,1.111,0.006,1.666
			c-6.001,1.203-12.011,0.943-18.023,0.11c-0.14-0.659-1.023-1.339,0.056-1.964C1137.84,179.267,1143.831,179.001,1149.799,180.912z
			"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#363333"
              d="M1130.795,121.391c6.319,0.648,12.647,0.338,18.974,0.164
			c0,0.167,0.072,0.406-0.011,0.489C1146.997,124.802,1133.494,124.327,1130.795,121.391z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#231F20"
              d="M1149.799,180.912c-5.987-0.063-11.975-0.125-17.962-0.188
			c-0.015-0.687-0.029-1.373-0.044-2.06c5.991-0.089,11.982-0.177,17.974-0.266C1149.777,179.237,1149.788,180.074,1149.799,180.912
			z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#231F20"
              d="M1172.742,180.725c-5.986,0.038-11.972,0.077-17.958,0.116
			c0.009-0.795,0.017-1.589,0.025-2.383c5.992,0.069,11.985,0.138,17.979,0.207C1172.772,179.352,1172.757,180.038,1172.742,180.725
			z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#C0BFBF"
              d="M1133.758,103.019c5.044-3.697,10.193-0.418,15.175-1.245
			c0.035,0.415,0.07,0.83,0.105,1.245C1144,103.019,1138.962,103.019,1133.758,103.019z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#CCCBCB"
              d="M1007.002,102.656c-0.082-0.294-0.077-0.586,0.016-0.877
			c4.526,0.869,9.058-1.685,13.588,0.026c0.88,0.333,1.976,0.32,2.366,2.035C1017.546,103.148,1012.184,104.938,1007.002,102.656z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#231F20"
              d="M1003.805,125.746c-0.005,11.007-0.01,22.013-0.015,33.019
			c-0.349,0.018-0.697,0.036-1.047,0.055l-0.003-0.004c-0.06-9.063-0.194-18.129-0.119-27.191
			C1002.638,129.663,1001.483,127.302,1003.805,125.746z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#CCCBCB"
              d="M1061.056,103.581c-0.08-0.604-0.16-1.207-0.24-1.811
			c5.318,0.357,10.787-0.92,15.923,1.811C1071.511,103.581,1066.284,103.581,1061.056,103.581z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#262223"
              d="M1124.792,161.879c-3.758-0.035-7.521,0.049-11.271-0.141
			c-3.622-0.183-5.56,1.331-5.828,4.954c-1.874-5.504-1.617-5.959,3.848-6.048C1115.995,160.572,1120.61,158.96,1124.792,161.879z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#BBBAB9"
              d="M1156.825,103.197c5.073-2.868,9.467-2.052,13.895-0.714
			C1166.366,102.279,1162.164,104.37,1156.825,103.197z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#D2D1D1"
              d="M1107.919,102.669c-0.082-0.294-0.129-0.594-0.142-0.898
			c5.368,0,10.736,0,16.104,0C1118.603,104.491,1113.229,102.244,1107.919,102.669z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#D2D2D1"
              d="M1099.887,102.252c-4.886,2.183-9.562-0.181-14.33,0
			C1090.333,102.252,1095.11,102.252,1099.887,102.252z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#D2D2D1"
              d="M1030.866,102.705c-0.14-0.296-0.22-0.608-0.239-0.936
			c5.53,0,11.062,0,16.592,0c-0.008,0.635-0.015,1.271-0.022,1.906C1041.746,102.515,1036.31,103.03,1030.866,102.705z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#231F20"
              d="M1132.489,160.938c5.543,0,11.087,0,16.63,0
			c-0.001,0.153-0.002,0.307-0.003,0.46c-5.544,0-11.087,0-16.63,0C1132.487,161.245,1132.488,161.092,1132.489,160.938z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#231F20"
              d="M1131.781,182.688c6.008-0.036,12.016-0.073,18.023-0.109
			c-0.036,0.366-0.073,0.733-0.11,1.1c-5.955,0.018-11.91,0.035-17.865,0.053C1131.813,183.384,1131.797,183.036,1131.781,182.688z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#231F20"
              d="M1171.857,161.437c-5.401,0-10.803,0-16.203,0
			c-0.001-0.164-0.002-0.327-0.003-0.491c5.444,0,10.889,0,16.333,0C1171.942,161.109,1171.899,161.272,1171.857,161.437z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#231F20"
              d="M1154.778,182.643c6.007,0.014,12.014,0.028,18.021,0.042
			c-0.014,0.352-0.026,0.704-0.039,1.056c-5.959-0.02-11.917-0.039-17.875-0.059C1154.85,183.335,1154.814,182.988,1154.778,182.643
			z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F4F4F4"
              d="M1061.881,181.685c-0.08-0.266-0.053-0.52,0.081-0.763
			c5.067,0,10.135,0,15.201,0c-0.003,0.255-0.006,0.509-0.009,0.763C1072.063,181.685,1066.972,181.685,1061.881,181.685z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F3F3F3"
              d="M1087.983,180.954c4.268-0.06,8.534-0.118,12.802-0.177
			C1096.546,182.877,1092.272,182.464,1087.983,180.954z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F0F0F0"
              d="M1160.979,267.729c-1.955-2.854-5.242-3.072-8.118-4.104
			c0.154-4.551,0.029-9.101,3.977-12.571c1.104-0.971,1.333-2.938,1.964-4.446c6.604-1.682,10.637,2.706,14.984,6.348
			c1.324,1.532-0.6,3.177,0.207,4.767c0.84-0.686,1.719-1.425,2.623-2.134c0.824-0.646,1.818-1.133,2.619-0.193
			c0.838,0.982,0.051,1.666-0.717,2.427c-2.337,2.317-2.838,5.412-3.048,8.489c-0.209,3.051-0.994,4.721-4.565,3.56
			C1167.692,268.826,1163.968,269.979,1160.979,267.729z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#EEE0AD"
              d="M1180.571,250.422c-4.381-1.791-7.92-3.85-11.079-6.481
			c-2.339-1.948-2.305-3.883,0.654-5.356c3.371-1.68,6.721-3.402,10.425-5.281C1180.571,239.039,1180.571,244.417,1180.571,250.422z
			"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#545150"
              d="M1158.771,227.663c3.606,1.754,6.652,0.492,9.613-1.669
			c1.719-1.255,3.596-2.293,5.402-3.43c2.697,0.697,3.658,2.063,0.889,3.768c-3.658,2.252-7.374,4.685-11.405,5.961
			C1160.553,233.153,1157.787,231.639,1158.771,227.663z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#555251"
              d="M1173.786,252.956c-5.107-1.85-8.994-6.582-14.984-6.348
			c-0.272-4.637,3.018-3.245,5.033-2.552c4.847,1.671,9.333,4.157,13.38,7.592C1176.414,253.382,1174.913,252.684,1173.786,252.956z
			"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5E5B56"
              d="M1141.863,245.89c2.624-0.018,5.248-0.036,7.872-0.054
			C1147.253,249.306,1144.801,249.322,1141.863,245.89z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#666460"
              d="M1018.613,183.502c1.439,0.644,2.152,1.799,2.343,3.323
			c-3.377,1.882-5.571,1.697-5.198-2.976C1016.709,183.734,1017.661,183.618,1018.613,183.502z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#858275"
              d="M1065.633,184.556c4.308-1.44,8.762-0.496,13.139-0.811
			C1074.495,185.682,1070.033,184.629,1065.633,184.556z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F5E7B2"
              d="M1016.974,337.783c-0.199-6.039,1.453-7.979,7.238-7.986
			c32.586-0.038,65.171,0.015,97.756-0.056c4.387-0.01,6.889,1.938,6.391,6.188c-0.798,6.809-0.601,13.6-0.434,20.395
			c0.075,3.074-1.188,4.314-4.036,4.225c-2.648-0.083-5.309-0.173-7.933-0.501c-2.42-0.303-4.647-0.362-6.344,1.75
			c-0.804,2.394,0.742,6.061-3.598,6.535c-4.18,0.457-5.894-2.39-7.294-5.503c-1.694-3.766-1.368-7.795-1.124-11.765
			c0.55-8.943-5.998-15.518-15.004-14.792c-3.122,0.251-6.263,0.721-9.154,2.098c-2.457,0.735-5.178,1.168-5.248,4.628
			c0.133,5.034-0.491,10.08,0.427,15.081c0.358,1.282,0.709,2.566,1.054,3.854c0.346,1.611,0.438,3.205-0.385,4.722
			c-1.278,1.414-3.061,1.989-4.698,2.809c-3.175,0.524-4.515-1.249-4.931-4.049c-0.337-2.273-0.156-4.576-0.205-6.866
			c-0.094-4.438-1.314-8.391-4.82-11.357c-0.803-0.545-1.688-0.035-2.528-0.168c-0.876-0.346-1.575-0.979-2.399-1.419
			c-10.32-0.485-20.629-1.367-30.903,0.454C1015.653,343.862,1018.338,340.377,1016.974,337.783z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E3D6A7"
              d="M1072.783,337.71c10.568-5.31,18.542-3.226,23.715,3.343
			c2.037,2.587,3.556,5.175,2.59,9.063c-0.848,3.414-0.849,7.263,0.308,10.845c0.932,2.888,1.96,5.954,5.42,6.438
			c4.109,0.575,3.515-3.438,4.797-5.602c0.056,7.153,0.287,14.312,0.087,21.458c-0.092,3.255,1.208,4.031,4.064,3.845
			c2.983-0.195,5.987-0.082,8.973-0.265c3.892-0.237,5.696,2.209,5.773,5.384c0.078,3.242-1.718,5.366-5.648,5.348
			c-3.13-0.014-6.179-1.021-9.396-0.3c-2.746,0.616-4.016,1.784-3.935,4.672c0.173,6.151,0.222,12.31,0.192,18.464
			c-0.013,2.593,1.221,3.627,3.632,3.608c2.494-0.019,4.99,0,7.48-0.11c6.542-0.291,7.199,0.331,6.975,6.909
			c-0.034,0.995-0.135,1.988-0.205,2.982c-6.07,1.999-10.871-1.186-15.748-3.923c-2.221-1.246-3.967-4.356-6.925-2.775
			c-3.071,1.643-2.671,5.083-2.774,8.06c-0.081,2.35,0.725,4.615,0.799,6.94c0.248,7.761-1.56,14.558-9.12,18.489
			c-6.032,0-12.063,0-18.095,0.001c-2.826-2.033-6.357-3.666-4.68-8.257c0.772-0.345,1.572-0.388,2.395-0.248
			c4.418,0.726,8.266,3.487,12.992,3.281c6.261-0.273,8.479-2.053,8.906-8.192c0.972-13.955,4.06-27.688,4.59-41.667
			c0.372-9.791-0.782-19.585-0.894-29.393c-0.022-2.008-1.023-3.847-2.634-5.22c-1.169-1.25-2.194-2.588-2.655-4.275
			c-0.643-3.125-0.133-6.2,0.333-9.299c0.805-5.359,0.615-10.532-3.436-14.779c-1.627-2.398-4.128-2.295-6.609-2.386
			C1080.158,340.009,1076.155,340.283,1072.783,337.71z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FAECB5"
              d="M1093.836,460.583c6.552-6.335,9.796-13.637,7.073-22.899
			c-0.496-1.687-0.278-3.666-0.047-5.466c0.357-2.785,0.633-5.96,3.7-7.11c2.896-1.087,4.917,1.093,6.955,2.868
			c4.592,4.002,10.262,5.153,16.087,5.816c0.095,6.309,0.25,12.617,0.267,18.926c0.012,4.807-2.038,7.792-7.251,8.229
			C1111.67,461.698,1102.768,460.307,1093.836,460.583z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F8E9B4"
              d="M1070.773,452.917c0.872,3.063,3.546,4.96,4.968,7.668
			c-14.401,0.112-28.802,0.198-43.202,0.36c-2.963,0.033-5.922,0.46-8.886,0.505c-2.249,0.035-4.683,0.22-6.027-2.282
			c-0.694-3.972-0.119-7.979-0.33-11.964c-0.123-2.341,0.908-3.372,2.902-1.994c10.453,7.223,21.82,3.901,32.969,3.03
			c3.396-0.266,5.178-2.699,6.582-5.489c0.6-1.191,1.131-2.414,1.723-3.607c0.764-1.536,1.676-3.06,3.676-2.826
			c2.072,0.241,3.113,1.779,3.629,3.66c0.712,2.596,0.026,5.333,0.758,7.927C1069.865,449.591,1070.431,451.226,1070.773,452.917z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E6D9A9"
              d="M1068.617,448.785c-1.643-2.432-0.558-5.161-0.812-7.743
			c-0.181-1.847-0.931-3.428-2.633-3.814c-2.03-0.462-2.23,1.507-2.605,2.903c-2.131,7.936-7.775,9.63-15.333,10.216
			c-10.126,0.785-19.985,2.252-29.608-5.683c0,5.457,0,9.98,0,14.504c-1.752-2.448-0.531-5.157-0.539-7.708
			c-0.104-37.892-0.096-75.784-0.113-113.676c2.037,2.352-0.24,5.583,1.624,7.97c2.005,1.605,1.566,3.901,1.568,5.993
			c0.027,28.026,0.064,56.053,0.012,84.08c-0.004,1.896-0.805,4.053,1.335,5.438c3.85,3.917,8.287,5.481,13.938,4.684
			c4.519-0.639,9.138,0.811,13.82-0.238c6.549-1.468,8.178-2.812,9.565-9.569c0.499-2.434,0.636-4.938,1.44-7.311
			c0.657-1.937,1.607-3.642,3.548-4.588c0.506-0.206,1.032-0.301,1.578-0.3c0.549,0.04,1.063,0.199,1.545,0.464
			c1.526,1.551,3.104,3.072,3.445,5.389c-0.421,5.311-0.121,10.635-0.296,15.954C1069.97,446.94,1069.713,448.068,1068.617,448.785z
			"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F5F5F6"
              d="M1110.803,410.944c0.481-2.925-1.129-7.755,3.048-9.161
			c5.019-1.689,10.703-3.38,15.927-1.118c5.53,2.394,2.397,8.312,3.774,12.484c0.814,2.469-0.039,5.268-2.57,6.996
			c-1.33,0.908-2.847,0.744-4.314,0.762c-1.984,0.023-3.987-0.246-5.953-0.067C1112.146,421.623,1110.712,420.311,1110.803,410.944z
			"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F6F6F7"
              d="M1110.725,374.331c0.479-3.603-0.954-8.27,3.085-9.487
			c5.409-1.632,11.338-3.534,16.947-0.7c3.417,1.727,3.833,15.93,0.94,18.565c-1.397,1.272-3.053,1.157-4.694,1.204
			c-3.133,0.089-6.221-0.192-9.386,0.623c-3.458,0.891-5.777-1.132-6.511-4.525C1110.69,378.087,1110.823,376.044,1110.725,374.331z
			"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#CFC399"
              d="M1035.743,312.606c2.939,1.425,4.691,2.958,1.803,6.306
			c-1.229,1.424,0.261,3.3,1.105,4.799c0.686,1.219,2.376,2.326,1.41,3.788c-0.962,1.456-2.881,1.422-4.568,1.298
			c-1.305-0.095-2.605-0.24-3.99-0.37c-0.309-3.54,5.744-2.411,4.338-5.126c-1.084-2.093-1.405-4.552-2.921-6.413
			c-1.604,0.208-2.564-0.531-2.497-2.108c0.067-1.592,1.433-1.448,2.563-1.494c0.964-0.039,1.961,0.148,2.853-0.399
			L1035.743,312.606z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#999172"
              d="M1035.838,312.886c-0.745,0.271-1.479,0.584-2.239,0.805
			c-0.868,0.252-2.197-0.256-2.404,1.074c-0.174,1.114,0.869,1.634,1.726,2.123c-0.817,4.342-2.575,8.185-6.01,10.461
			c1.065-2.247-1.589-3.088-3.041-4.63c4.015-1.277,2.603-5.211,3.841-7.848C1030.207,313.34,1032.759,312.031,1035.838,312.886z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#C1B791"
              d="M1043.819,313.825c2.664-2.188,5.742,1.079,8.569-0.64
			c0.544-0.331,1.71,0.995,1.355,2.163c-0.313,1.033-0.883,3.068-1.665,2.57c-2.632-1.678-4.119,1.62-6.323,0.999
			C1044.111,317.601,1043.662,315.828,1043.819,313.825z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#726B57"
              d="M1030.706,308.692c2.331-2.173,1.994-5.466,3.372-8.038
			c3.327,1.226,2.531,5.736,5.669,7.112c-1.305,1.812-3.295,0.633-4.901,1.124C1033.466,308.825,1032.086,308.759,1030.706,308.692z
			"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E6D9A8"
              d="M1027.71,314.871c1.377,6.792,1.053,7.456-3.841,7.848
			c0.103-1.621,0.206-3.243,0.31-4.864C1025.427,316.944,1025.87,315.08,1027.71,314.871z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FBFAF4"
              d="M1215.729,399.034c0.999-0.117,1.998-0.235,2.997-0.353
			c1.981,0.076,3.003,1.099,3.006,3.089c0.013,8.062,0.174,16.127-0.077,24.181c-0.066,2.137-0.194,5.231-3.923,4.903
			c-0.665-0.095-1.33-0.189-1.996-0.284c-1.958-7.516-0.552-15.167-0.854-22.749
			C1214.766,404.903,1214.113,401.829,1215.729,399.034z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#807D76"
              d="M1158.959,537.963c-0.066,0.617-0.133,1.234-0.199,1.854
			c-3.649,1.959-7.298,3.92-10.946,5.881c-0.111-2.045-0.222-4.088-0.333-6.133c2.106-2.316,3.799-5.166,7.294-5.707
			C1154.137,537.297,1156.195,537.991,1158.959,537.963z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#817D72"
              d="M1153.972,514.893c4.693,1.229,4.816,4.158,2.918,7.84
			C1155.7,520.202,1154.331,517.735,1153.972,514.893z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E6DBAA"
              d="M1067.667,571.588c-1.081-0.277-2.219-0.424-3.232-0.859
			c-1.833-0.783-1.916-2.432-1.736-4.133c0.157-1.49,1.121-2.295,2.425-2.748c2.69-0.936,2.478,1.904,3.699,2.885
			C1070.267,568.786,1069.362,570.282,1067.667,571.588z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#948C6F"
              d="M1067.667,571.588c0.386-1.619,0.771-3.236,1.155-4.855
			c1.154,0.006,2.38-0.238,3.448,0.066c2.117,0.607,3.561,2.428,3.24,4.414c-0.464,2.881-2.521,0.697-3.9,0.629
			C1070.295,571.778,1068.981,571.674,1067.667,571.588z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#9C9375"
              d="M1066.686,546.78c1.16,0.037,2.584,0.049,2.431,1.604
			c-0.132,1.338-1.15,2.332-2.693,2.396c-1.309,0.055-2.371-0.49-2.409-1.846C1063.968,547.262,1065.253,546.834,1066.686,546.78z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E5D9A8"
              d="M1073.99,277.692c-0.077,2.096-0.944,3.356-3.261,3.082
			c-0.053-0.322-0.09-0.647-0.159-0.966c-0.333-1.542-2.869-3.097-0.516-4.608C1072.574,273.581,1072.524,277.053,1073.99,277.692z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#B9B08C"
              d="M871.778,152.05c19.232-0.09,38.464-0.181,57.695-0.271
			c6.275-0.029,7.324-1.249,7.297-7.677c-0.036-8.451,0.117-16.902,0.188-25.354c3.279,1.813,3.997,5.402,3.524,8.175
			c-1.132,6.635-0.444,13.223-0.655,19.819c-0.151,4.73-1.564,7.681-6.978,8.374c-3.647,0.467-7.239,0.581-10.861,0.596
			c-12.772,0.052-25.548-0.116-38.317,0.09C879.158,155.876,875.163,155.151,871.778,152.05z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#3F3C3D"
              d="M1166.714,544.77c2.038,1.145,0.14,3.793,1.926,5.033
			c0.366,2.324-0.088,4.176-3.299,4.969C1166.842,551.354,1164.896,547.905,1166.714,544.77z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#787677"
              d="M1158.997,486.586c-1.254-1.484-0.503-1.99,1.024-1.994
			c2.977-0.008,4.504,1.254,3.704,4.432C1162.199,488.114,1160.884,486.797,1158.997,486.586z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F6F6F7"
              d="M901.108,150.691c-9.316,0-18.635-0.11-27.949,0.051
			c-4.087,0.071-5.582-1.461-5.47-5.6c0.239-8.81,0.257-17.638-0.016-26.446c-0.133-4.261,1.728-5.638,5.564-5.633
			c18.799,0.026,37.599,0.025,56.398,0c3.572-0.005,5.437,1.07,5.308,5.245c-0.271,8.806-0.147,17.632,0.095,26.441
			c0.121,4.415-1.286,6.203-5.984,6.038C919.749,150.461,910.425,150.691,901.108,150.691z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#C8BE95"
              d="M1022.376,310.946c-0.704,2.623-1.697,4.399-4.46,3.88
			c-1.31-0.247-2.193-1.525-1.671-2.512C1017.496,309.958,1019.814,310.592,1022.376,310.946z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#7B735C"
              d="M1053.66,469.905c0.114,2.092,0.659,4.193-0.22,6.617
			c-2.647-2.502-1.803-4.557,0.243-6.581C1053.683,469.942,1053.66,469.905,1053.66,469.905z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#B8B8B9"
              d="M1132.827,741.823c-0.25,3.658-0.089,7.242,1.331,10.723
			c0.652,1.6,1.69,3.588-1.221,4.299c-1.267-1.918-3.674-3.227-3.22-6.059c-1.128-1.127-1.623-2.418-1.027-3.99
			C1127.915,743.346,1130.304,742.528,1132.827,741.823z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2C292B"
              d="M1128.69,746.795c1.074,1.141,1.236,2.518,1.027,3.99
			c-1.347,1.98-3.525,1.102-5.317,1.549c-0.163-0.766-0.478-1.533-0.463-2.295C1123.999,746.793,1127.062,747.825,1128.69,746.795z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#A9AAAB"
              d="M1151.735,756.762c-2.107-1.301-2.338-2.918-0.76-4.785
			c1.301-1.541,1.874-3.33,1.969-5.307c0.239,0.063,0.479,0.115,0.723,0.156c1.16,0.643,1.648,1.58,1.271,2.896
			C1155.19,752.67,1153.913,754.922,1151.735,756.762z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#272425"
              d="M1154.938,749.723c-0.423-0.965-0.847-1.932-1.271-2.896
			c0.665-0.568,0.763-2.674,2.271-0.969c0.732,0.83,1.121,1.955,1.708,2.922c0.765,1.258,3.66,0.297,3.077,2.918
			c-0.071,0.322-0.74,0.816-0.988,0.742C1157.938,751.909,1155.503,752.426,1154.938,749.723z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F6F6F7"
              d="M1142.858,734.831c-24.493,0-48.986,0.002-73.479-0.002
			c-5.874,0-7.502-1.357-7.226-7.377c0.558-12.137,0.629-24.248,0.002-36.387c-0.303-5.873,1.315-7.221,7.433-7.211
			c15.493,0.029,30.986,0.211,46.48,0.197c33.155-0.025,66.312-0.143,99.467-0.207c7.591-0.014,8.252,0.672,8.254,8.199
			c0.002,11.996,0.01,23.992-0.004,35.988c-0.007,6.27-0.486,6.73-6.945,6.732c-24.66,0.004-49.32,0.002-73.981,0.002
			C1142.858,734.788,1142.858,734.809,1142.858,734.831z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5F7B8B"
              d="M1161.545,668.702c0.03,4.084,0.818,7.732,5.312,9.254
			c0.753,0.281,1.484,0.645,2.26,0.824c1.553,0.361,3.205,1.09,2.938,2.73c-0.239,1.471-2.127,1.232-3.418,1.238
			c-6.435,0.031-12.871,0.076-19.305-0.018c-1.829-0.027-4.445,0.965-5.136-1.59c-0.689-2.555,2.815-1.623,3.55-3.258
			c1.784-0.268,2.996-1.221,3.549-2.963c0.419-1.32,1.15-1.912,2.496-1.068c2.127-0.01,1.748-2.068,2.563-3.148
			C1157.678,668.952,1158.939,667.106,1161.545,668.702z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5A7989"
              d="M1184.13,673.737c3.814-5.648,3.814-5.648,10.702,0.029
			c0.405,2.945,2.613,3.689,5.058,4.148c1.324,1.1,2.648,2.197,4.329,3.592c-9.657,0-18.788,0-27.96,0
			c-0.474-3.02,2.582-2.172,3.401-3.582C1181.655,677.067,1183.433,675.977,1184.13,673.737z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#363435"
              d="M1161.545,668.702c-2.996,0.34-4.894,1.711-4.86,5.02
			c0.01,1.014-0.229,2.041-1.596,1.951c-1.099-0.072-1.011-1.107-1.298-1.822c0.344-2.023,0.688-4.047,1.032-6.07
			c2.842,0.35,4.266-2.596,6.813-2.932C1161.604,666.131,1161.575,667.417,1161.545,668.702z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#282425"
              d="M1130.013,672.629c-0.681,0.836-1.74,1.674-2.026,0.053
			c-0.336-1.91-1.046-2.951-2.915-3.42c-1.08-0.271-1.663-1.295-1.266-2.344c0.576-1.52,1.643-0.041,2.462-0.205
			c1.116-0.223,2.214-0.541,3.319-0.818C1129.729,668.139,1129.871,670.385,1130.013,672.629z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#494747"
              d="M1146.691,660.684c-3.601,0-7.2,0-11.646,0
			C1139.478,657.954,1143.131,658.223,1146.691,660.684z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#8D8D8E"
              d="M1179.989,758.334c-3.767,1.9-7.459,2.152-11.205,0
			C1172.52,758.334,1176.255,758.334,1179.989,758.334z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#818082"
              d="M1075.302,759.415c2.313-2.539,5.464-1.213,7.857-2.326
			c0.16-0.074,0.84,0.498,0.946,0.867c0.302,1.043-0.585,1.467-1.311,1.65C1080.421,760.208,1078.034,760.038,1075.302,759.415z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#3E3C3C"
              d="M1178.86,660.682c-3.566,0-7.134,0-11.595,0
			C1171.728,657.854,1175.294,658.411,1178.86,660.682z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#787779"
              d="M1200.386,759.266c3.298-2.381,6.058-1.26,8.946-1.512
			C1206.93,760.311,1206.93,760.311,1200.386,759.266z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#3E3C3D"
              d="M1208.767,660.788c-3.109,0-6.218,0-9.326,0
			C1202.458,658.143,1205.584,658.237,1208.767,660.788z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#8C8B8D"
              d="M1147.125,757.145c-4.104,4.918-5.71,4.895-10.207-0.154
			C1140.606,757.946,1143.891,758.534,1147.125,757.145z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#302D2F"
              d="M753.697,581.799c0.369-0.627,0.738-1.252,1.107-1.877
			c5.519,0,11.036,0,16.989,0C767.583,585.206,763.231,585.528,753.697,581.799z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#6D7583"
              d="M834.62,588.78c1.857,0.09,2.963,1.066,3.048,3.039
			c0.073,1.701,0.149,3.344,2.318,3.74c1.066,0.195,1.999,0.66,1.356,1.936c-0.498,0.986-1.475,1.217-2.523,0.756
			c-3.359-1.477-5.157-4.533-6.77-7.473C831.202,589.233,833.171,588.85,834.62,588.78z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5B616B"
              d="M829.625,591.44c-0.399,2.57-1.988,4.525-4.319,5.566
			c-1.013,0.451-1.47-1.094-1.492-2.063c-0.065-2.736,1.083-4.977,3.682-5.871C828.654,588.672,829.468,590.002,829.625,591.44z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#7A8392"
              d="M797.015,585.004c-0.001,2.369-1.075,3.572-3.211,3.795
			c-2.03,0.211-4.155-0.654-4.334-2.445c-0.264-2.641,2.295-1.283,3.616-1.486C794.344,584.672,795.672,584.938,797.015,585.004z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#636A75"
              d="M849.83,606.893c1.928-4.291,4.487-6.807,9.453-8.311
			C856.674,602.766,854.674,605.92,849.83,606.893z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#8F8E8E"
              d="M894.841,394.372c-0.837,5.73-1.683,11.151-0.191,16.789
			C889.217,406.479,889.264,399.924,894.841,394.372z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#332F30"
              d="M1023.834,585.594c5.016,1.844,5.53,1.426,5.102-4.066
			c-0.096-1.227-0.747-2.684,1.591-3.482c0.374,4.031,0.836,7.84-0.828,11.584c-4.083,0.83-7.747-1.225-11.778-1.484
			C1019.526,586.038,1022.407,587.606,1023.834,585.594z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#3F3D3D"
              d="M1003.953,564.864c0.606-0.059,1.213-0.119,1.819-0.178
			c1.649,2.666,3.299,5.33,4.947,7.994C1005.336,574.358,1003.104,571.778,1003.953,564.864z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F6F6F7"
              d="M829.646,739.764c-18.831,0-37.664-0.129-56.493,0.09
			c-4.843,0.057-6.474-1.613-6.367-6.375c0.241-10.822,0.062-21.652,0.082-32.48c0.011-5.775,1.358-7.139,6.975-7.129
			c35.162,0.061,70.323,0.131,105.485,0.195c1.666,0.002,3.334,0.021,4.997-0.047c8.102-0.332,9.862,1.551,9.126,9.471
			c-0.863,9.289-0.867,18.598,0.002,27.887c0.638,6.814-0.644,8.387-7.312,8.389C867.309,739.766,848.478,739.764,829.646,739.764z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#514F50"
              d="M840.194,671.245c3.251-3.547,6.794-2.402,10.376-0.619
			C847.111,670.833,843.652,671.038,840.194,671.245z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#3C3939"
              d="M864.657,676.782c-1.442,2.945-3.377,1.533-5.538,0.5
			c1.576-1.764,3.569-2.197,5.617-2.523C864.71,675.432,864.684,676.106,864.657,676.782z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#9D9C9D"
              d="M807.559,763.137c3.598,0,6.127,0,9.941,0
			C813.764,766.417,811.211,766.208,807.559,763.137z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#959596"
              d="M775.461,763.047c4.473-0.002,7.82-1.242,11.499,1.27
			C783.068,765.43,779.781,766.764,775.461,763.047z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#ABABAC"
              d="M845.453,762.793c1.779,0.271,3.792,0.398,3.646,2.133
			c-0.127,1.504-2.119,1.811-3.62,1.867c-1.548,0.059-3.409-0.168-3.281-2.072C842.324,762.87,844.469,763.327,845.453,762.793z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#343132"
              d="M786.67,671.374c-3.081-0.881-6.269-0.316-9.353-1.145
			C780.484,669.938,783.872,666.659,786.67,671.374z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#282526"
              d="M824.704,677.793c0.827-1.898,1.018-1.875,4.892-1.102
			c1.233,0.246,2.271,0.367,2.286,1.922c0.033,3.043-2.136,0.754-3.16,1.277c-0.85,0.434-1.85,0.572-2.783,0.842
			C825.589,679.727,825.827,678.475,824.704,677.793z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#474445"
              d="M807.837,670.633c3.346-2.404,6.81-2.535,10.424-0.605
			c-0.549,1.232-1.684,0.676-2.513,0.963C813.111,670.872,810.475,670.752,807.837,670.633z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#78A1B8"
              d="M797.01,786.557c-1.385-0.258-2.42-1.396-2.149-3.352
			c0.248-1.795,1.404-3.236,3.217-3.164c2.213,0.09,2.226,2.188,2.083,3.73C800.033,785.139,799.733,786.891,797.01,786.557z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#807F80"
              d="M190.257,704.948c2.54-0.787,3.866,0.061,4.517,2.07
			c0.192,0.592,0.128,1.607-0.591,1.529C192.267,708.34,191.06,707.073,190.257,704.948z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#77A1B7"
              d="M117.58,697.555c-0.429,1.873-1.603,3.076-3.433,2.967
			c-2.187-0.131-2.362-1.939-2.319-3.703c0.042-1.729,0.759-2.855,2.609-2.986C117.208,693.637,116.681,696.163,117.58,697.555z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2D2A2B"
              d="M68.959,459.601c-0.028-3.914-0.057-7.829-0.085-11.743
			c2.433-0.222,3.674,1.37,2.97,3.244C70.787,453.91,74.595,458.432,68.959,459.601z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F6F6F7"
              d="M517.944,145.911c-3.452,0.164-6.901,1.06-10.36,0.052
			c-4.906-0.729-6.859-4.35-6.598-8.497c0.989-15.683,0.928-31.367,0.819-47.06c-0.011-1.589,0.519-2.896,1.937-3.746
			c4.92-1.583,10.064-0.705,15.068-1.369c1.6-0.213,3.28,0.396,4.819-0.468c2.326,0.065,4.744-0.576,6.886,0.901
			c4.44,4.226,4.78,9.729,4.166,15.199c-1.452,12.942-0.645,25.905-0.665,38.853c-0.007,4.635-1.555,6.304-6.101,5.955
			C524.616,145.479,521.269,145.829,517.944,145.911z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#6F6C6D"
              d="M523.629,84.823c-6.524,1.75-13.174,2.135-19.888,1.837
			c0.311-0.57,0.621-1.141,0.932-1.712C510.992,84.906,517.311,84.864,523.629,84.823z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#868686"
              d="M459.784,64.624c1.277-1.653,2.556-3.306,3.834-4.959
			c1.376,0.672-0.07,4.208,3.103,3.059c-0.374,5.058-0.747,10.114-1.121,15.172c-0.207,0.024-0.414,0.048-0.621,0.072
			c0.563-5.113-1.601-6.972-6.749-6.34c-2.417,0.296-4.988-0.67-7.916-1.13c2.09-1.409,3.764-2.537,5.437-3.665
			C457.005,65.935,458.937,66.271,459.784,64.624z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#727071"
              d="M494.925,86.576c-2.655-0.454-5.311-0.908-7.965-1.362
			c0.009-0.126,0.018-0.252,0.027-0.378c3.278,0,6.557,0,9.834,0C496.19,85.416,495.557,85.996,494.925,86.576z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#9A9B9B"
              d="M556.705,120.835c2.312-3.844,0.477-8.065,1.103-12.063
			c3.592,2.229,4.622,5.299,3.435,9.374c-0.498,1.705-0.215,3.622-0.484,5.414c-0.596,3.97-2.743,7.609-6.774,7.363
			c-9.81-0.598-15.021,4.315-17.992,12.83c-0.828-8.076-1.525-16.146,0.988-24.085c1.98,1.748,3.96,3.496,5.94,5.245
			C548.589,128.935,553.192,127.573,556.705,120.835z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#282425"
              d="M806.816,544.719c-0.251,2.057-1.746,2.098-3.306,2.053
			c-9.193-0.27-18.462,0.908-27.564-1.773c10.024,0.451,20.042,0.84,29.926-1.275C806.187,544.055,806.502,544.387,806.816,544.719z
			"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#777575"
              d="M782.393,446.1c7.85-0.75,4.077-9.687,9.799-11.886
			c0.684,1.853-0.178,3.459-0.849,4.938c-2.473,5.454-2.524,5.431,0.416,10.278C788.126,450.091,785.312,449.238,782.393,446.1z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#818080"
              d="M785.731,459.882c3.215-0.017,6.527-0.43,10.294,3.101
			c-7.911-1.694-9.701,3.235-11.842,8.188c-0.74-2.783-0.398-5.681-1.566-8.243C783.267,461.515,784.842,461.049,785.731,459.882z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#413E3E"
              d="M849.283,392.023c6.537-0.339,11.75-0.065,16.928,1.076
			C860.976,393.368,855.654,395.731,849.283,392.023z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#B6B5B5"
              d="M809.278,464.76c-2.104-0.281-4.589-0.582-4.845-2.697
			c-0.259-2.146,2.156-1.093,3.367-1.287c1.869-0.301,3.9,0.08,4.208,2.268C812.281,464.984,810.263,464.373,809.278,464.76z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#858484"
              d="M863.81,541.278c-3.801,0.066-7.736,2.35-12.097-0.969
			C856.334,539.631,860.151,538.946,863.81,541.278z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#969696"
              d="M812.82,526.36c-3.578-1.094-5.373-3.201-7.104-6.398
			C810.173,520.139,812.268,521.915,812.82,526.36z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#3F3D3D"
              d="M785.731,459.882c0.604,2.694-1.115,3.014-3.114,3.045
			c-1.87-0.379-3.821-0.618-5.051-3.475C780.537,459.609,783.134,459.746,785.731,459.882z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#B1B0B0"
              d="M769.403,489.047c0.093,1.098-0.066,2.486-1.22,2.15
			c-1.577-0.461-2.579-1.908-2.505-3.764c0.052-1.279,0.061-3.178,1.679-2.854C769.538,485.02,768.56,487.643,769.403,489.047z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#AAA9A9"
              d="M841.054,538.305c2.695-0.881,4.78-0.299,6.264,1.85
			c0.652,0.947-0.054,1.508-1.038,1.582C843.727,541.93,841.776,541.194,841.054,538.305z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#7B7A7A"
              d="M806.075,452.883c4.195-0.133,7.032,0.557,10.107,3.27
			C812.115,456.579,809.296,455.977,806.075,452.883z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#706F6F"
              d="M803.852,452.428c-3.424,0.91-5.985,0.092-8.354-1.72
			C798.472,449.237,798.892,449.311,803.852,452.428z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#949393"
              d="M800.78,512.094c1.88,2.064,3.185,3.498,4.594,5.047
			C800.942,517.793,800.425,517.307,800.78,512.094z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#6E6C6C"
              d="M839.912,396.197c-2.436,1.337-4.457,2.116-7.102,0.956
			C834.849,394.878,836.918,394.684,839.912,396.197z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#9B9A9A"
              d="M787.206,480.803c-0.111,1.092-0.481,1.938-1.458,1.811
			c-1.369-0.178-1.782-1.404-1.854-2.564c-0.063-1.012,0.356-2.137,1.635-1.867C786.9,478.471,786.78,479.961,787.206,480.803z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#282425"
              d="M464.978,77.968c0.208-0.014,0.415-0.038,0.621-0.072
			c-0.169,3.686,1.879,5.759,5.084,6.99c0.654,1.127-0.347,1.343-0.972,1.767c-3.882,0.692-3.012,3.806-3.034,6.274
			c-0.093,10.248-0.056,20.498-0.066,30.747c-1.14-0.844-1.337-2.665-3.037-2.929c-1.547-2.305-1.533-4.616-0.027-6.933
			C466.34,101.957,465.627,89.961,464.978,77.968z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#8C8A8B"
              d="M469.711,86.652c0.324-0.589,0.648-1.178,0.972-1.767
			c2.914,0.423,5.829,0.845,9.122,1.322C476.24,87.33,472.962,86.724,469.711,86.652z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F2F2F3"
              d="M341.923,110.596c4.926-0.457,9.446-0.008,11.789,5.292
			c0.355,3.288,0.71,6.575,1.065,9.863c-2.999,4.59-6.662,5.488-12.06,2.957c-2.859,0.394-4.735-1.482-6.792-2.941
			c-2.407-3.6-2.249-7.247-0.208-10.932C336.139,111.012,339.704,111.789,341.923,110.596z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2C2829"
              d="M335.717,114.835c0.069,3.645,0.139,7.288,0.208,10.932
			c-3.452,4.784-1.825,10.154-1.774,15.344c0.027,2.816,1.027,5.955-2.455,7.649c0.939-2.303,1.111-4.688,1.109-7.169
			c-0.01-11.54,0.105-23.08,0.175-34.62C334.408,109.413,333.244,112.757,335.717,114.835z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#4C4A4B"
              d="M342.717,128.708c4.109-0.622,8.724,0.82,12.06-2.957
			c1.096,0.474,3.021,0.262,2.26,2.379c-1.655,4.606-10.785,7.452-14.961,4.7c-2.528-1.666-0.192-2.219,0.731-3.089
			c1.087,1.08,2.559,0.951,3.871,1.325c-1.323-0.364-2.753-0.381-3.92-1.271C342.744,129.433,342.73,129.07,342.717,128.708z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2C2829"
              d="M315.916,149.859c5.236-1.157,10.567-1.045,15.879-1.186
			C326.733,152.17,321.312,150.84,315.916,149.859z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#3F3C3D"
              d="M348.782,68.759c2.946-0.277,3.314-2.777,4.181-4.848
			c1.601-0.215,3.134-0.041,3.641,1.746c0.411,1.445-0.573,2.488-1.604,3.413c-5.883,5.273-7.337,5.528-13.586,2.233
			C343.07,68.047,346.885,71.059,348.782,68.759z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F1F1F2"
              d="M243.645,113.627c1.81-1.2,3.657-2.295,5.967-2.011
			c4.067,0.759,8.331-0.558,12.305,1.171c0.314,4.316,0.746,8.633,0.057,12.954c-2.595,0.837-3.578,4.424-6.889,4.04
			c-2.889-2.198-6.349,0.61-9.298-1.062c-0.744-1.144-1.125-2.286-0.64-3.707C246.518,120.994,245.525,117.241,243.645,113.627z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#332F30"
              d="M243.981,67.935c1.687-0.256,3.264,0.082,4.757,0.876
			c2.816,1.889,7.063-0.005,9.281,3.499C249.328,75.219,245.557,74.041,243.981,67.935z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F1F1F2"
              d="M463.546,113.813c0.009,2.311,0.019,4.622,0.027,6.933
			c-2.061,1.158-3.592,2.843-4.811,4.838c-2.255-0.147-4.517-0.477-6.764-0.398c-3.125,0.108-4.836-1.26-5.357-4.27
			c-0.736-4.828-0.3-9.561,1.159-14.211c3.315,0.013,6.632,0.025,9.948,0.038C463.167,106.24,461.494,111.554,463.546,113.813z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2D292A"
              d="M459.784,64.624c-0.284,2.674-1.473,3.694-4.034,2.209
			c-3.34-1.978-7.299,0.262-10.748-1.646c-1.245-0.688-2.822-0.69-3.245-2.422c0.8-0.011,1.675,0.198,2.151-0.745
			c0.646-0.123,1.698-0.557,1.876-0.324C449.63,66.747,455.027,64.15,459.784,64.624z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#312E2F"
              d="M556.705,120.835c0.974,1.652,2.018,3.396-0.912,4.087
			c-1.77,0.416-2.538,2.019-3.926,2.871c-3.822,2.348-6.926,1.375-8.947-2.88c1.657-0.057,3.34-0.324,4.967-0.129
			c4.539,0.546,5.627-2.337,6.161-5.879c0.312-2.061-0.683-4.274,0.727-6.208C556.761,115.092,553.845,118.648,556.705,120.835z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#282425"
              d="M245.788,128.72c3.093,0.41,6.496-1.894,9.298,1.062
			c-2.127,0.11-4.254,0.221-6.843,0.354c2.089,2.464,4.203,1.966,6.289,1.034c0.803-0.358,1.562-0.838,2,0.169
			c0.185,0.425-0.146,1.418-0.555,1.703c-2.885,2.007-6.123,0.791-9.041,0.337C244.749,133.038,245.496,130.484,245.788,128.72z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#515050"
              d="M106.989,399.1c-0.005-3.546-0.011-7.093-0.017-10.639
			C108.233,392.005,108.227,395.551,106.989,399.1z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#939292"
              d="M1000.497,277.561c-1.156,0.092-2.313,0.266-3.47,0.257
			c-1.707-0.014-2.857-0.827-3.05-2.629c-0.238-2.235,1.36-2.009,2.867-2.38c2.791-0.688,5.441-0.626,8.219,0.006
			c1.477,0.336,3.32,0.124,3.332,2.109c0.011,1.956-1.619,2.775-3.425,2.904c-1.482,0.105-2.979,0.021-4.469,0.021
			C1000.5,277.753,1000.499,277.657,1000.497,277.561z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#CECDCE"
              d="M1014.642,565.756c-1.245,0.381-2.683,2.092-3.695,0.137
			c-0.713-1.379-0.648-3.361,0.313-4.648c1.579-2.113,3.104-0.148,4.602,0.52C1017.007,563.569,1015.888,564.682,1014.642,565.756z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#322F30"
              d="M1014.642,565.756c0.406-1.33,0.813-2.662,1.219-3.992
			c1.924,0.559,5.499-1.365,5.455,1.904c-0.04,2.977-3.51,1.975-5.688,2.096C1015.3,565.782,1014.97,565.76,1014.642,565.756z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F2F2F2"
              d="M1032.286,181.042c4.544,0,9.088,0,13.633,0
			c0.01,0.155,0.02,0.312,0.029,0.467c-4.48,0-8.961,0-13.441,0C1032.433,181.354,1032.359,181.197,1032.286,181.042z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F2F2F2"
              d="M1109.567,181.046c4.515,0,9.03,0,13.546,0
			c-0.003,0.163-0.005,0.326-0.008,0.488c-4.561,0-9.121,0-13.683,0C1109.472,181.372,1109.519,181.209,1109.567,181.046z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#D2C79C"
              d="M1065.168,424.557c-3.369,1.515-3.973,4.626-4.355,7.773
			c-1.42,11.674-2.319,13.271-13.587,14.941c-6.483,0.961-13.276,0.82-19.856-0.506c-2.33-0.47-4.271-1.731-5.603-3.788
			c1.61-2.046,3.934-1.412,6.034-1.611c6.45,0.014,12.905,0.038,19.358-0.018c1.751-0.015,3.737,0.461,4.857-1.585
			c0.878-12.458,0.198-24.916,0.314-37.363c0.156-16.781,0.098-33.565-0.022-50.347c-0.019-2.573,0.703-4.886,1.423-7.254
			c7.238,4.229,7.276,11.219,7.107,18.338c-0.061,2.573-0.703,5.717,3.587,5.577c0.412,0.541,0.685,1.148,0.709,1.826
			c0.604,16.738,1.525,33.47,1.026,50.231C1066.122,422.11,1066.03,423.435,1065.168,424.557z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F3F3F3"
              d="M1051.875,345.058c-0.147,0.691,0.031,1.247,0.665,1.616
			c0.35,3.692-2.545,3.299-4.633,3.391c-5.797,0.255-11.606,0.028-17.409,0.126c-1.891,0.032-4.134-0.56-5.04,2.027
			c-0.548,17.819-0.09,35.633-0.249,53.442c-0.049,5.495-0.005,10.992,0.001,16.484c-0.085,12.236-0.085,12.225,12.377,12.244
			c3.823,0.006,7.651-0.157,11.469,0.138c1.947,0.149,3.685,0.885,4.087,3.005c0.387,2.04-0.446,3.575-2.632,4.112
			c-8.63,0.242-17.258,0.117-25.888,0.069c-3.484-0.38-4.804-2.545-4.808-5.809c-0.036-28.424-0.029-56.849-0.013-85.273
			c0.002-2.885,1.203-5.142,4.004-5.501C1033.14,343.933,1042.516,343.589,1051.875,345.058z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#262223"
              d="M1050.853,440.902c1.604-0.723,2.209-2.002,1.672-3.615
			c-0.506-1.524-1.893-1.534-3.264-1.525c-5.769,0.039-11.538-0.042-17.305,0.055c-6.579,0.11-8.08-1.278-8.156-8.006
			c-0.02-1.644,0.125-3.288,0.195-4.932c2.813,0.709,1.728,3.549,2.852,5.183c0.678,2.749,2.527,4.072,5.227,4.628
			c4.771,0.983,9.573,0.785,14.367,0.523c2.877-0.156,4.564-1.938,4.787-4.797c0.297-3.801,0.334-7.613-0.168-11.414
			c-0.565-4.279-3.025-6.545-7.279-6.689c-6.116-0.205-12.253-0.618-18.313,0.767c-1.896-2.167-1.787-4.362-0.09-6.578
			c6.968,3.244,14.377,0.968,21.559,1.624c2.948,0.27,4.391-1.91,4.422-4.87c0.058-5.315-0.089-10.635,0.049-15.945
			c0.095-3.668-2.881-4.97-4.751-7.154c-0.231-0.511-0.238-1.027-0.031-1.548c4.137-2.638,4.912-6.559,4.869-11.187
			c-0.132-14.146-0.333-14.393-14.571-14.329c-4.396,0.021-8.247,0.804-10.881,4.686c-3.674-0.467-0.663-3.64-2.039-5.04
			c-0.616-2.956,1.61-2.23,3.054-2.241c7.246-0.055,14.494,0.032,21.739-0.076c1.36-0.02,3.556,1.064,3.745-1.744
			c0.245-0.722-0.203-1.884,1.19-1.875c0.657,12.143,0.209,24.288,0.123,36.434c-0.143,20.139-0.2,40.278-0.293,60.417
			C1052.424,442.249,1051.224,443.076,1050.853,440.902z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2D2A2A"
              d="M1051.875,345.058c-8.775,0.182-17.579-1.205-26.331,0.672
			c-3.47,0.745-4.835,2.332-4.82,6.106c0.112,27.477-0.006,54.953-0.084,82.43c-0.01,3.153,0.033,6.147,4.139,6.699
			c0.713,1.04,3.135-0.874,2.954,1.71c-1.988,0.101-3.978,0.202-5.966,0.303c-2.455-0.598-3.325-2.013-3.314-4.663
			c0.126-30.854,0.118-61.708,0.146-92.562c0.407-1.624,1.411-2.906,3.098-2.697c9.122,1.129,18.276,0.203,27.404,0.601
			C1050.168,343.702,1051.552,343.41,1051.875,345.058z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2D2929"
              d="M1065.168,424.557c-0.798-18.607-1.096-37.22-0.742-55.841
			c1.047-1.508,2.569-2.061,4.32-2.154c5.823-2.75,12.003-0.93,18.007-1.339c2.735-0.187,5.651-0.483,8.056,1.498
			c0.685,1.016,1.369,2.031,2.055,3.047c2.975,7.227,1.346,14.78,1.31,22.165c-0.026,5.278-0.875,10.571-0.889,15.896
			c-0.004,1.915,0.125,4.101-2.175,5.134c-2.26-1.66-1.785-4.158-1.809-6.407c-0.101-9.647,0.019-19.297-0.064-28.945
			c-0.057-6.557-0.738-7.236-7.097-7.293c-4.822-0.042-9.646-0.078-14.471,0.053c-2.85,0.076-4.264,1.45-4.285,4.313
			c-0.102,13.974-0.104,27.946,0.001,41.919c0.023,3.063,1.454,4.579,4.56,4.593c4.824,0.02,9.646,0.071,14.47-0.057
			c3.393-0.091,5.921-1.953,8.521-3.755c2.007-0.232,2.683,0.981,2.738,2.668c0.065,1.989-0.818,3.534-2.617,4.436
			c-1.521,0.764-3.206,0.823-4.865,0.819c-8.243-0.019-16.501,0.421-24.712-0.719L1065.168,424.557z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#D2C79C"
              d="M1094.809,366.721c-8.688-0.054-17.375-0.106-26.063-0.159
			c0.004-1.547,0.009-3.093,0.013-4.64c1.967-2.21,4.616-1.378,7.022-1.524c1.985-0.12,3.985-0.021,5.979-0.026
			c7.608-0.021,7.606-0.021,7.634-7.85c0.009-2.326-0.093-4.655,0.076-6.975c0.118-1.624,0.066-3.424,2.17-4.009
			c5.15,4.968,4.816,11.114,3.559,17.353C1094.669,361.517,1094.932,364.112,1094.809,366.721z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#292525"
              d="M1091.64,341.538c-1.851,4.628-0.521,9.432-0.676,14.143
			c-0.144,4.393-1.291,6.696-6.325,6.284c-5.259-0.431-10.584-0.063-15.88-0.043c-1.496-0.638-0.734-2.116-1.173-3.147
			c-0.858-5.729,0.354-11.538-0.653-17.263c0.415-3.63,3.259-3.521,5.851-3.802c4.554,0.209,9.107,0.579,13.66,0.564
			C1089.051,338.267,1090.791,339.037,1091.64,341.538z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#D2C79C"
              d="M1066.933,341.512c1.886,5.691,0.638,11.501,0.653,17.263
			C1065.822,353.079,1067.074,347.269,1066.933,341.512z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#CCC197"
              d="M1065.479,424.587c8.868-1.295,17.783-0.125,26.671-0.488
			c1.679-0.068,3.486,0.167,4.017-1.95c0.393-1.567,1.635-3.544-1.193-4.282c-1.679-1.711-1.669-3.431-0.026-5.159
			c1.942-1.704,1.153-4.001,1.202-6.05c0.293-12.295,0.486-24.593,0.713-36.891c2.656,1.6,3.894,3.977,3.932,7.047
			c0.098,7.963,0.508,15.926,0.744,23.868c0.303,10.197-1.455,20.417-2.991,30.566c-0.864,5.709-1.341,11.489-1.706,17.257
			c-0.317,5.004-2.955,7.701-7.926,8.328c-5.951,0.751-10.916-1.98-16.151-3.867c3.288-2.872,7.249-1.392,10.89-1.513
			c7.674-0.255,7.819-0.092,7.691-7.951c-0.066-4.052,1.007-8.251-1.053-12.136c-2.555-2.227-5.624-0.923-8.452-1.189
			c-2.745-0.26-5.541,0.25-8.271-0.446c-2.514-1.294-4.261-3.698-6.907-4.813C1066.268,424.808,1065.874,424.698,1065.479,424.587z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#262223"
              d="M1091.637,429.826c2.613,6.636,0.858,13.516,1.07,20.28
			c0.079,2.515-2.061,2.642-3.964,2.667c-5.326,0.071-10.653,0.13-15.979,0.193c-0.663-0.017-1.327-0.033-1.99-0.05
			c-2.536-0.429-1.826-2.552-2.156-4.132c0.119-1.002,0.238-2.005,0.357-3.008c1.086-1.188-0.936-3.742,1.839-4.191
			c1.413,2.546,3.478,4.563,5.427,6.674c0.139,1.12-0.249,1.844-1.469,1.934c4.187-0.584,8.368-0.69,12.188-0.064
			c-2.975-1.771-7.524,1.763-10.401-2.221c1.67-1.889,3.946-1.382,6.036-1.472c7.015-0.299,9.302-3.589,7.257-10.427
			c-0.574-1.919-2.034-3.425-2.377-5.434C1088.526,428.463,1090.085,429.167,1091.637,429.826z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#4C4843"
              d="M1091.637,429.826c-1.317,0.07-2.786-0.558-3.82,0.826
			c-2.841,1.758-6.06,1.212-9.13,1.4c-5.732,0.351-6.333,0.822-6.97,6.651c-0.124,1.136,0.038,2.353-1.123,3.103
			c-0.54,1.324-1.079,2.647-1.619,3.971c0.256-5.646-1.308-11.402,1.023-16.923c0.832-1.004,1.782-0.718,2.749-0.253
			c4.812,0.029,9.625-0.018,14.432,0.134C1088.684,428.783,1090.558,427.853,1091.637,429.826z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#D2C79C"
              d="M1072.747,428.601c-0.916,0.084-1.833,0.169-2.749,0.253
			c-1.723-0.795-2.944-2.014-3.335-3.937C1069.911,424.128,1071.694,425.763,1072.747,428.601z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#888687"
              d="M912.995,130.121c-1.437,5.061-3.867,6.788-7.663,6.758
			c-3.147-0.024-6.297,0.047-9.443,0.002c-1.903-0.027-3.948-0.306-3.783-2.817c0.152-2.318,1.91-3.783,4.108-3.325
			C901.601,131.86,906.937,131.942,912.995,130.121z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#A4A5A5"
              d="M342.757,129.795c2.411,0,4.821,0,7.232,0
			c-2.601,3.191-5.008,3.251-7.183-0.054L342.757,129.795z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#5B5750"
              d="M1027.732,442.675c-0.984-0.57-1.97-1.14-2.954-1.71
			c8.691-0.021,17.383-0.042,26.074-0.063c0.692,1.011,1.771,0.628,2.709,0.749C1045.009,443.433,1036.359,442.775,1027.732,442.675
			z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#575454"
              d="M1046.847,376.978c0.081,0.28,0.06,0.553-0.064,0.816
			c-1.758,3.14-4.852,2.319-7.52,2.37c-12.035,0.227-11.98,0.178-12.066,12.166c-0.03,4.224,0.523,8.574-1.726,12.511
			c-0.954,1.983-1.212,3.949,0.143,5.876c2.76,5.708,2.816,11.412-0.032,17.109c-1.557-1.319-0.016-3.632-1.586-4.947
			c0.002-24.047,0.005-48.094,0.008-72.141c1.631,1.306-0.111,3.716,1.633,4.984c2.756,3.161,1.45,6.981,1.556,10.507
			c0.236,7.955,0.758,8.392,8.611,9.835C1039.504,376.743,1043.404,374.142,1046.847,376.978z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F5F5F6"
              d="M1025.471,404.841c0.103-7.248,0.733-14.462-0.02-21.756
			c-0.434-4.202,3.288-4.694,6.656-4.554c4.916,0.206,9.873,1.3,14.675-0.737c6.254,0.665,6.286,4.76,5.842,9.708
			c-0.413,4.605-0.068,9.299,0.191,13.94c0.233,4.168-1.479,6.56-5.67,6.246c-5.459-0.409-10.88-0.394-16.324,0.046
			C1028.483,407.923,1026.623,406.974,1025.471,404.841z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F6F6F7"
              d="M1046.847,376.978c-4.907-0.05-9.79,0.317-14.732-0.069
			c-4.718-0.369-6.393-2.464-6.342-6.851c0.057-4.777-0.083-9.557-0.137-14.336c0.084-3.422,2.062-5.09,5.249-5.493
			c5.296-0.67,10.608-0.544,15.914-0.096c3.786,0.318,5.67,2.634,5.798,6.213c0.173,4.813,0.194,9.645-0.028,14.454
			C1052.408,374.261,1050.855,376.867,1046.847,376.978z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F6F6F7"
              d="M1025.581,427.826c0.011-5.703,0.021-11.405,0.032-17.109
			c3.438-2.894,21.412-2.79,24.624,0.128c1.329,1.208,1.588,2.852,1.931,4.475c1.005,4.766,0.665,9.598,0.51,14.391
			c-0.098,3.031-2.092,5.014-5.174,4.934c-5.292-0.137-10.578,0.137-15.893-0.52
			C1027.452,433.612,1025.706,431.735,1025.581,427.826z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F6F6F7"
              d="M1094.947,412.708c0.009,1.72,0.018,3.441,0.026,5.16
			c-0.497,4.738-4.592,4.484-7.539,4.71c-5.952,0.456-11.966,0.079-17.954,0.108c-2.786,0.014-3.72-1.311-3.706-4.024
			c0.079-15.305,0.082-30.611,0.004-45.916c-0.016-3.022,1.224-4.157,4.164-4.14c6.647,0.041,13.296-0.061,19.943-0.151
			c4.078-0.057,4.864,2.313,4.855,5.829C1094.712,387.092,1094.864,399.9,1094.947,412.708z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F6F5F6"
              d="M1079.411,341.573c3.625,0.298,7.773-1.124,9.422,3.196
			c1.695,4.447,2.111,9.435-0.467,13.493c-3.395,5.343-9.232,0.998-13.862,2.286c-2.679,0.745-4.36-1.862-5.132-4.454
			c-0.969-3.251-0.93-6.603-0.433-9.881c0.38-2.512,2.165-4.091,4.774-4.363C1075.696,341.643,1077.701,341.649,1079.411,341.573z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F6F6F7"
              d="M1070.594,441.806c0.638-1.788-0.4-3.485-0.139-5.378
			c0.562-4.069,2.383-5.905,6.519-5.733c3.607,0.149,7.229-0.017,10.843-0.043c3.527,3.222,5.083,9.302,3.47,13.527
			c-0.936,2.449-2.645,3.651-5.327,3.603c-2.987-0.055-5.978,0.057-8.967,0.097c-0.469,0.45-0.95,0.463-1.441,0.036
			C1071.479,447.841,1071.189,444.7,1070.594,441.806z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#CCCCCB"
              d="M1075.551,447.915c0.48-0.012,0.961-0.024,1.441-0.036
			c3.701,2.244,8.419-0.314,12.348,2.67c-5.69,0.729-11.03,0.576-16.463,0.104C1073.086,448.925,1076.127,450.208,1075.551,447.915z
			"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default OfficeMap;
