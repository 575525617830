import React from 'react';
import './SalaryCard.scss';

const SalaryCard = () => {
  return (
    <a href="https://salary.mpya.digital/" target="_blank" rel="noopener noreferrer">
      <div className="salary-card">
        <img
          // eslint-disable-next-line
          src={require(`../../assets/salary.png`)?.default}
          alt="book icon"
        />
        <h2>Salary</h2>
        <p className="description">MPYA's salary model.</p>
      </div>
    </a>
  );
};

export default SalaryCard;
