import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';

const graphUrl = 'https://graph.microsoft.com/v1.0';

const getAccessToken = async (instance, account) => {
  try {
    const { accessToken } = await instance.acquireTokenSilent({
      ...loginRequest,
      account,
    });
    return accessToken;
  } catch {
    try {
      const { accessToken } = await instance.acquireTokenRedirect(loginRequest);
      return accessToken;
    } catch (e) {
      console.error('An error occurred while fetching access Token');
    }
  }
};

const useMsGraphCall = () => {
  const { instance, accounts } = useMsal();

  const authHeaders = (accessToken, type = 'application/json') =>
    new Headers({
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': type,
      Prefer: `outlook.timezone = "UTC"`,
    });

  return {
    apiPost: (endpoint, body) => async () => {
      const accessToken = await getAccessToken(instance, accounts[0]);
      try {
        return (
          await fetch(`${graphUrl}${endpoint}`, {
            method: 'POST',
            headers: authHeaders(accessToken),
            body: JSON.stringify(body),
          })
        ).json();
      } catch (error) {
        throw error;
      }
    },

    apiGet:
      (endpoint, type = 'application/json') =>
      async () => {
        const accessToken = await getAccessToken(instance, accounts[0]);
        const response =
          type !== 'application/json'
            ? (
                await fetch(`${graphUrl}${endpoint}`, { headers: authHeaders(accessToken, type) })
              ).blob()
            : (
                await fetch(`${graphUrl}${endpoint}`, { headers: authHeaders(accessToken, type) })
              ).json();
        if (!response.ok) new Error('Could not get');

        return response;
      },

    apiDelete: (endpoint) => async () => {
      const accessToken = await getAccessToken(instance, accounts[0]);
      const response = await fetch(`${graphUrl}${endpoint}`, {
        method: 'DELETE',
        headers: authHeaders(accessToken),
      });
      if (!response.ok) throw new Error('Could not delete');
    },
  };
};

export default useMsGraphCall;
