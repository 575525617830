import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import compareEmailAddresses from '../utils/compareEmailAddresses';
import useMsGraph from './useMsGraph';

const usePollEventForAcceptance = ({ roomEmail, roomId }) => {
  const [refetchInterval, setRefetchInterval] = useState(1000);
  const [eventId, setEventId] = useState();
  const [accepted, setAccepted] = useState();
  const [pollIndex, setPollindex] = useState(0);
  const queryClient = useQueryClient();

  const { getMyEventById } = useMsGraph();

  const { error, isFetching } = useQuery(['getMyEventById', eventId], getMyEventById(eventId), {
    refetchIntervalInBackground: true,
    refetchInterval,
    onSuccess: (data) => {
      setPollindex((index) => index + 1);
      const attendeeResponse = data?.attendees?.find((attendee) =>
        compareEmailAddresses(attendee.emailAddress.address, roomEmail)
      )?.status.response;
      if (attendeeResponse === 'accepted' || attendeeResponse === 'declined') {
        setRefetchInterval(false);
        setAccepted(attendeeResponse === 'accepted');
        queryClient.invalidateQueries('getMyEventsToday');
        queryClient.invalidateQueries('getCalendarsEventsToday');
        queryClient.invalidateQueries(['getSchedual', roomId]);
        setPollindex(0);
      } else if (!attendeeResponse) {
        setRefetchInterval(false);
        stopPolling();
      } else {
        setAccepted(undefined);
        setRefetchInterval(Math.max(2000 - pollIndex * 1000, 500));
      }
    },
    onError: () => setRefetchInterval(false),
    enabled: Boolean(eventId),
    cacheTime: 0,
  });

  const startPolling = (id) => setEventId(id);
  const stopPolling = () => setEventId(undefined);

  return { accepted, startPolling, stopPolling, eventId, error, isFetching };
};

export default usePollEventForAcceptance;
