import React, { useContext, useState, useEffect } from 'react';
import { FormContext } from '../../providers/formProvider';
import { useMutation, useQueryClient } from 'react-query';
import useMsGraph from '../../hooks/useMsGraph';
import usePollEventForAcceptance from '../../hooks/usePollEventForAcceptance';
import Button from '../Button/Button';

const BookButton = ({
  userDisplayName,
  roomEmail,
  roomName,
  bookFrom,
  duration,
  attendees,
  roomId,
  label,
  size,
}) => {
  const {
    bookFrom: formFrom,
    duration: formDuration,
    attendees: formAttendees,
  } = useContext(FormContext);
  const [isLoading, setIsLoading] = useState(false);
  const { createEvent } = useMsGraph();
  const { startPolling, accepted, eventId } = usePollEventForAcceptance({
    roomEmail,
    roomId,
  });

  const { deleteEvent } = useMsGraph();
  const queryClient = useQueryClient();
  const { mutate: deleteEventMutation } = useMutation(deleteEvent(eventId), {
    onSuccess: () => {
      queryClient.invalidateQueries('getMyEventsToday');
      queryClient.invalidateQueries('getCalendarsEventsToday');
    },
  });

  const createEventMutation = useMutation((body) => createEvent(body)(), {
    onSuccess: (data) => {
      startPolling(data.id);
    },
    onError: () => {
      alert('Booking failed');
    },
  });

  useEffect(() => {
    if (accepted !== undefined) setIsLoading(false);
    if (accepted === false && eventId) {
      window.alert(
        'Since you obviously tried to commit a henious act and steal someone elses meeting time I took the liberty to help you become a better human being and denied your request😎'
      );
      deleteEventMutation && deleteEventMutation();
    }
  }, [accepted, eventId, setIsLoading, deleteEventMutation]);

  const TIMEZONE = 'Europe/Stockholm';

  const book = (e) => {
    e.preventDefault();
    const event = {
      subject: userDisplayName,
      start: {
        dateTime: (bookFrom || formFrom).toISOString(),
        timeZone: TIMEZONE,
      },
      end: {
        dateTime: new Date(Date.parse(bookFrom || formFrom) + (duration || formDuration)),
        timeZone: TIMEZONE,
      },
      location: {
        displayName: roomName,
        locationEmailAddress: roomEmail,
      },
      attendees: [
        {
          emailAddress: {
            address: roomEmail,
            name: roomName,
          },
          type: 'resource',
        },
        ...((attendees || formAttendees)?.length > 0
          ? (attendees || formAttendees).map((attendee) => ({
              emailAddress: {
                address: attendee.scoredEmailAddresses[0].address,
                name: attendee.displayName,
              },
              type: 'required',
            }))
          : []),
      ],
      isOnlineMeeting: true,
      onlineMeetingProvider: 'teamsForBusiness',
    };
    createEventMutation.mutate(event);
    setIsLoading(true);
  };

  return (
    <Button onClick={book} disabled={isLoading} size={size}>
      {label.toUpperCase()}
    </Button>
  );
};

export default BookButton;
