import React, { useContext } from 'react';
import { FormContext } from '../../providers/formProvider';

const SelectTime = ({ form }) => {
  const { duration, setDuration } = useContext(FormContext);

  const DURATIONS = [15, 30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 180].map(minutes => ({
    value: minutes * 60 * 1000,
    label: `${minutes >= 60 ? `${Math.floor(minutes / 60)}h` : ''} ${minutes % 60}min`
  }));

  return (
    <select
      value={duration && duration.value}
      form={form}
      onChange={e => {
        setDuration(DURATIONS.find(time => time.value === parseInt(e.target.value, 10)).value);
      }}
    >
      {DURATIONS.map(time => (
        <option key={time.label} value={time.value}>
          {time.label}
        </option>
      ))}
    </select>
  );
};

export default SelectTime;
