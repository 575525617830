import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import useMsGraph from '../../hooks/useMsGraph';
import usePollForDeletion from '../../hooks/usePollForDeletion';
import { initialState } from '../../store';
import timeNowInUTC from '../../utils/timeNowInUTC';
import Button from '../Button/Button';
import s from './OngoingMeeting.module.scss';

const OngoingMeeting = ({ event }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { deleteEvent } = useMsGraph();
  const { deleted, startPolling, stopPolling } = usePollForDeletion({
    roomId: initialState.rooms.find(({ name }) => name === event?.location?.displayName)?.id,
    iCalUId: event?.iCalUId,
  });
  const { mutate: deleteEventMutation } = useMutation(deleteEvent(event?.id), {
    onSuccess: () => {
      startPolling(event.id);
    },
    onError: (error) => {
      stopPolling();
      alert(error);
    },
  });

  const endMeeting = () => {
    if (
      window.confirm(
        '⚠ You are about to end a meeting and thereby delete it from the calendar.\nAre you sure you want to do this?'
      )
    ) {
      deleteEventMutation();
      setIsLoading(true);
    }
  };

  useEffect(() => {
    if (deleted) {
      setIsLoading(false);
    }
  }, [deleted]);

  return (
    <>
      {event?.id && (
        <div className={s.root}>
          <h2 className={s.title}>Ongoing meeting in {event.location.displayName}</h2>
          <p className={s.text}>
            Time remaining{' '}
            <strong>
              {Math.round((new Date(event.end.dateTime) - timeNowInUTC()) / 1000 / 60)} minutes
            </strong>
            .
          </p>
          <div className={s.btnWrapper}>
            <Button size="small" variant="delete" onClick={endMeeting} disabled={isLoading}>
              End meeting
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default OngoingMeeting;
