import React, { useState, createContext } from 'react';

export const FormContext = createContext({});

export const FormProvider = ({ formName, children }) => {
  const [bookFrom, setBookFrom] = useState();
  const [duration, setDuration] = useState(15 * 60 * 1000);
  const [attendees, setAttendees] = useState([]);

  return (
    <FormContext.Provider
      value={{
        formName,
        bookFrom,
        setBookFrom,
        duration,
        setDuration,
        attendees,
        setAttendees,
      }}
    >
      <form form={formName}>{children}</form>
    </FormContext.Provider>
  );
};

export default FormProvider;
