import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { initialState } from '../../store';
//import LuncerCard from '../../components/LuncherCard/LuncherCard';
import Room from '../../components/Room/Room';
import HandbookCard from '../../components/HandbookCard/HandbookCard';
import SalaryCard from '../../components/SalaryCard/SalaryCard';
// import RediesCard from '../../components/RediesCard/RediesCard';
// import { authProvider } from '../../providers/authProvider';
// import * as GraphService from '../../services/graph.service';
// import RoomInfo from '../../components/RoomInfo/RoomInfo';
import s from './Home.module.scss';
import Space from '../../components/Space/Space';
import { useMsal } from '@azure/msal-react';
import useMsGraph from '../../hooks/useMsGraph';
import { useQuery } from 'react-query';
import timeNowInUTC from '../../utils/timeNowInUTC';
import OngoingMeeting from '../../components/OngoingMeeting/OngoingMeeting';
import OfficeMap from '../../components/OfficeMap/OfficeMap';
import Button from '../../components/Button/Button';

const Home = () => {
  const { accounts } = useMsal();
  const { getMyEventsToday } = useMsGraph();
  const [ongoingEvent, setOngoingEvent] = useState();
  const [isLocateRoomOpen, setIsLocateRoomOpen] = useState();
  const { data: myEventsToday } = useQuery(
    ['getMyEventsToday'],
    getMyEventsToday(accounts?.[0].username),
    {
      refetchInterval: 1000 * 60 * 1,
      refetchIntervalInBackground: true,
    }
  );
  const upcommingEvents = myEventsToday?.value?.filter(
    (item) => new Date(item.end.dateTime) >= timeNowInUTC()
  );

  useEffect(() => {
    const getTime = (timeString) => new Date(timeString);
    const now = timeNowInUTC();
    if (upcommingEvents?.length > 0) {
      const ongoingEvent = upcommingEvents.find(
        ({ start, end }) => getTime(start.dateTime) < now && getTime(end.dateTime) > now
      );
      if (Boolean(ongoingEvent)) {
        setOngoingEvent(ongoingEvent);
      } else {
        setOngoingEvent(null);
      }
    }
  }, [myEventsToday?.value, upcommingEvents]);

  const close = () => setIsLocateRoomOpen(false);

  return (
    <>
      {isLocateRoomOpen && <LocateRoomModal close={close} />}
      <div className={s.home}>
        <h2 className={s.title}>
          Rooms{' '}
          <button className={s.roomLocationButton} onClick={() => setIsLocateRoomOpen(true)}>
            Locations 📍
          </button>
        </h2>
        {Boolean(ongoingEvent) && <OngoingMeeting event={ongoingEvent} />}
        <div className={s.roomGrid}>
          {initialState.rooms
            .sort((a, b) => a.size - b.size)
            .map((room) => (
              <Room key={room.id} room={room} />
            ))}
        </div>
        <Space size="large" />
        <div className={s.apps}>
          <h2 className={s.title}>Apps</h2>
          <HandbookCard />
          <SalaryCard />
          {/* <RediesCard /> */}
          {/* <LuncerCard />*/}
        </div>
      </div>
    </>
  );
};

const LocateRoomModal = ({ close }) => {
  const [activeRoomIndex, setActiveRoomIndex] = useState(0);

  return ReactDOM.createPortal(
    <>
      <div className={s.root} />
      <div className={s.modal}>
        <h2 className={s.title}>Room locations</h2>
        <div className={s.switcher}>
          <Button size="small" onClick={() => setActiveRoomIndex((index) => index - 1)}>
            {'< Prev'}
          </Button>
          <h3>{initialState.rooms[Math.abs(activeRoomIndex) % initialState.rooms.length]?.name}</h3>
          <Button size="small" onClick={() => setActiveRoomIndex((index) => index + 1)}>
            {'Next >'}
          </Button>
        </div>
        <OfficeMap
          roomId={initialState.rooms[Math.abs(activeRoomIndex) % initialState.rooms.length]?.id}
        />
        <div className={s.buttonWrapper}>
          <Button size="medium" onClick={close}>
            Close
          </Button>
        </div>
      </div>
    </>,
    document.getElementById('modal')
  );
};

export default Home;
